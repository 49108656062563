import { default as React } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { Audience } from "../models/audienceModels";

export interface RemoveAudienceModalProps {
  show: boolean;
  audience: Audience;
  onDelete(audience: Audience): void;
  onCancel(): void;
}

export const RemoveAudienceModalFC: React.FC<
  RemoveAudienceModalProps
> = props => {
  return (
    <Modal isOpen={props.show} toggle={props.onCancel}>
      <ModalHeader toggle={props.onCancel}>Delete Audience</ModalHeader>
      <ModalBody>
        Would you like to delete this audience? This action cannot be undone.
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            props.onCancel();
          }}
          color="link"
          data-qa-id="delete-audience-modal-cancel"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => {
            props.onDelete(props.audience);
          }}
          data-qa-id="delete-audience-modal-delete"
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
};
