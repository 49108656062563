import {
  connectWithLifecycle,
  LifecycleDispatchProps
} from "react-lifecycle-component";
import { State } from "../../reducers";
import { Dispatch } from "redux";
import { reduxForm, formValueSelector } from "redux-form";
import {
  closeUpdateModalAF,
  updateExternalSegmentAF
} from "../actions/externalSegmentUpdateActions";

import {
  attributeProps,
  ExternalSegment,
  externalSegmentProps
} from "../../api/attributeApi";
import {
  StateProps,
  DispatchProps,
  Props,
  ExternalSegmentUpdateModalFC
} from "../components/ExternalSegmentUpdateModalComponent";
import {
  getExternalSegments,
  getExternalSegmentUpdateAttribute,
  getExternalSegmentUpdating
} from "../selectors/externalSegmentSelectors";

const FORM_NAME = "external_segment_update";
const formSelector = formValueSelector(FORM_NAME);

function mapStateToProps(state: State): StateProps {
  const externalSegment = getExternalSegmentUpdateAttribute(state);

  return {
    externalSegment,
    allSegments: getExternalSegments(state),
    updating: getExternalSegmentUpdating(state),
    dataType: formSelector(
      state,
      `${externalSegmentProps.attribute}.${attributeProps.data_type_name}`
    ),
    initialValues: externalSegment
  };
}

function mapDispatchToProps(
  dispatch: Dispatch
): DispatchProps & LifecycleDispatchProps<Props> {
  return {
    componentDidMount() {},
    closeModal() {
      dispatch(closeUpdateModalAF.create({}, {}));
    }
  };
}

const containerWrapper = connectWithLifecycle(
  mapStateToProps,
  mapDispatchToProps
);
const formWrapper = reduxForm<ExternalSegment, Props>({
  form: FORM_NAME,
  onSubmit(externalSegment: ExternalSegment, dispatch: Dispatch<any>) {
    dispatch(updateExternalSegmentAF.create(externalSegment, {}));
  }
});

export const ExternalSegmentUpdateModalContainer = containerWrapper(
  formWrapper(ExternalSegmentUpdateModalFC)
);
