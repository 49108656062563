import { default as React } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { SitemapNavToggleFC } from "./SitemapNavToggleComponent";
import { SitemapNavContentFC } from "./SitemapNavContentComponent";
import { NavNode } from "../models/navigationModels";
import styles from "./SitemapNavComponent.module.scss";

export interface SitemapNavProps extends StateProps, DispatchProps {}

export interface StateProps {
  navigation: NavNode[];
  subNavRootNode: NavNode;
}

export interface DispatchProps {}

export const SitemapNavFC: React.FC<SitemapNavProps> = props => {
  return (
    <div>
      {props.navigation && (
        <UncontrolledDropdown
          id="template-sitemap-nav"
          className={styles.sitemap}
        >
          <DropdownToggle tag={"div"} className={styles.dropdownToggle}>
            <SitemapNavToggleFC activeNavigationNode={props.subNavRootNode} />
          </DropdownToggle>
          <DropdownMenu
            className={styles.dropdownMenu}
            modifiers={{ preventOverflow: { padding: 0 } }}
          >
            <SitemapNavContentFC navigation={props.navigation} />
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </div>
  );
};
