import { default as React } from "react";
import classNames from "classnames";

export interface PagingInfoProps {
  total: number;
  current: number;
  pluralName?: string;
  className?: string;
}

export const PagingInfoFC: React.FC<PagingInfoProps> = props => {
  return (
    <div className={classNames("text-muted small", props.className)}>
      Displaying {props.current} of {props.total} {props.pluralName}
    </div>
  );
};

PagingInfoFC.defaultProps = {
  pluralName: "results",
  className: ""
};
