import { default as React } from "react";
import {
  IN_LAST_DAYS,
  NOT_EXISTS,
  ON_OR_AFTER,
  ON_OR_BEFORE,
  X_DAYS_OR_LESS_AGO,
  X_DAYS_OR_MORE_AGO
} from "../constants/operatorConstants";
import { Condition } from "../models/conditionBuilderModels";
import {
  isGeoServiceAttribute,
  isTopInterestAttribute
} from "../constants/attributeIdConstants";
import { GeoLocationNameContainer } from "../containers/GeoLocationNameContainer";
import { Attribute } from "../api/attributeApi";
import { SCORE_SEGMENTS } from "../constants/attributeSourceTypeConstants";
import { ScoreNameComponent } from "./ScoreNameComponent";
import { TopInterestComponent } from "./TopInterestComponent";

export interface ConditionTextProps extends StateProps, DispatchProps {}

export interface StateProps {
  condition: Condition;
  topInterestAttribute: Attribute;
}

export interface DispatchProps {}

export const ConditionTextFC: React.FC<ConditionTextProps> = props => {
  const valueOverride = getValueOverride(
    props.condition.operatorId,
    props.condition.value
  );
  const subsetValueOverride = getValueOverride(
    props.condition.subsetOperatorId,
    props.condition.subsetValue
  );

  const attributeValue = props.condition.attribute.attribute_values.find(v => {
    return v.value === props.condition.value;
  });

  const attributeOperator = props.condition.attribute.attribute_operators.find(
    o => {
      return props.condition.operatorId === o.id.toString();
    }
  );

  const attributeSubsetOperator = props.condition.attribute.attribute_subset_operators.find(
    o => {
      return o.id.toString() === props.condition.subsetOperatorId;
    }
  );

  return (
    <span>
      {isGeoServiceAttribute(props.condition.attribute.id) ? (
        <GeoLocationNameContainer
          attributeId={props.condition.attribute.id}
          geoLocationId={props.condition.value}
        />
      ) : props.condition.attribute.source_type === SCORE_SEGMENTS &&
        attributeOperator &&
        attributeSubsetOperator ? (
        <ScoreNameComponent
          attributeOperator={attributeOperator}
          attributeValue={props.condition.value}
          attributeSubsetOperator={attributeSubsetOperator}
          attributeSubsetValue={props.condition.subsetValue}
        />
      ) : isTopInterestAttribute(props.condition.attribute.id) ? (
        <TopInterestComponent
          attributeOperator={attributeOperator}
          attributeValue={props.topInterestAttribute.name}
        />
      ) : (
        <span>
          {valueOverride ? (
            <span>{valueOverride}</span>
          ) : attributeOperator &&
            attributeOperator.id.toString() === NOT_EXISTS.toString() ? (
            <span className="text-danger">{attributeOperator.name}</span>
          ) : (
            <span>
              {attributeOperator.name}
              &nbsp;
              {attributeValue
                ? attributeValue.display_name
                : props.condition.value}
            </span>
          )}
          {props.condition.subsetOperatorId && (
            <span>
              &nbsp;
              {subsetValueOverride ? (
                <span>{subsetValueOverride}</span>
              ) : (
                <span>
                  {attributeSubsetOperator &&
                    ` ${attributeSubsetOperator.name} `}
                  {props.condition.subsetValue}
                </span>
              )}
            </span>
          )}
        </span>
      )}
    </span>
  );
};

function getValueOverride(operatorId: string, value: string): string {
  switch (operatorId) {
    case X_DAYS_OR_MORE_AGO.toString():
      return `${value} days or more ago`;
    case X_DAYS_OR_LESS_AGO.toString():
      return `${value} days or less ago`;
    case ON_OR_BEFORE.toString():
      return `on or before ${value}`;
    case ON_OR_AFTER.toString():
      return `on or after ${value}`;
    case IN_LAST_DAYS.toString():
      return `in the last ${value} days`;
    default:
      return undefined;
  }
}
