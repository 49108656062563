import { default as React } from "react";
import { Helmet } from "react-helmet";

export const ForbiddenPage: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>403</title>
      </Helmet>
      <h1>403</h1>
    </div>
  );
};
