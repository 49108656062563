import { State } from "../reducers";

export function getPermissionEnabled(
  state: State,
  permissionId: number,
  entityId: number
) {
  const permissionsByEntityId = state.auth.permissions.byEntityId[entityId];

  return (
    !!permissionsByEntityId &&
    permissionsByEntityId[permissionId] &&
    permissionsByEntityId[permissionId].enabled
  );
}

export function getPermissionPending(
  state: State,
  permissionId: number,
  entityId: number
) {
  const permissionsByEntityId = state.auth.permissions.byEntityId[entityId];

  if (!permissionsByEntityId || !permissionsByEntityId[permissionId]) {
    return true;
  }

  return permissionsByEntityId[permissionId].pending;
}

export function getTrackedPermissionIds(state: State): number[] {
  return state.auth.permissions.settings.trackedPermissionIds;
}
