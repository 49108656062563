import { default as React } from "react";

export interface ClickAwayProps {
  onClickAway(e: MouseEvent): void;
}

export class ClickAway extends React.Component<ClickAwayProps> {
  ref: React.RefObject<HTMLSpanElement> = React.createRef();

  componentWillMount() {
    window.addEventListener("mouseup", this.onClick, false);
  }

  componentWillUnmount() {
    window.removeEventListener("mouseup", this.onClick, false);
  }

  onClick = (e: MouseEvent) => {
    if (this.ref.current && !this.ref.current.contains(e.target as Node)) {
      return this.props.onClickAway(e);
    }
  };

  render() {
    return <span ref={this.ref}>{this.props.children}</span>;
  }
}
