import {
  connectWithLifecycle,
  LifecycleDispatchProps
} from "react-lifecycle-component";
import { State } from "../../reducers";
import { Dispatch } from "redux";
import {
  reduxForm,
  getFormValues,
  change,
  formValueSelector
} from "redux-form";
import { PartialScoreSegment, ScoreSegment } from "../../api/attributeApi";
import { readAllScoreBucketsAF } from "../actions/readActions";
import {
  closeCreateModalAF,
  createScoreSegmentAF,
  createSetIsMax
} from "../actions/createActions";
import {
  StateProps,
  DispatchProps,
  Props,
  ScoreSegmentCreateModalFC
} from "../components/ScoreSegmentCreateModalComponent";
import { DataPoint } from "highcharts";
import {
  getScoreBuckets,
  isCreateFormSubmitting,
  showScoreSegmentCreateModal,
  getCreateIsMax,
  getScoreSegmentsByParentId
} from "../selectors/scoreSelectors";
import { Range } from "../components/ScoreRangeComponent";
import { getRangeFieldName } from "../constants/score";

// use the same empty array every time to avoid multiple renders
const EMPTY_ARRAY: DataPoint[] = [];
const FORM_NAME = "score_segments_create";
const formSelector = formValueSelector(FORM_NAME);

export interface OwnProps {
  goals: ScoreSegment[];
}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  const score = getFormValues(FORM_NAME)(state) as PartialScoreSegment;

  const goal =
    score && score.attribute && score.attribute.parent_id
      ? props.goals.find(goal => {
          return goal.attribute.id === score.attribute.parent_id;
        })
      : props.goals[0];
  const scoreBuckets = goal ? getScoreBuckets(state) : EMPTY_ARRAY;

  return {
    goal,
    scoreSegments: goal
      ? getScoreSegmentsByParentId(state, goal.attribute.id)
      : [],
    goals: props.goals,
    open: showScoreSegmentCreateModal(state),
    isFormSubmitting: isCreateFormSubmitting(state),
    initialValues: score,
    dataPoints: scoreBuckets,
    name: score && score.attribute && score.attribute.name,
    isMax: getCreateIsMax(state),
    ranges:
      score && score.children && score.children.length
        ? score.children.map((child, index) => {
            return {
              id: null,
              name:
                formSelector(state, getRangeFieldName(index)) ||
                (child.attribute && child.attribute.name),
              from: child.min,
              to: child.max
            };
          })
        : []
  };
}

function mapDispatchToProps(
  dispatch: Dispatch
): DispatchProps & LifecycleDispatchProps<Props> {
  return {
    onGoalChange(goal: ScoreSegment) {
      dispatch(change(FORM_NAME, "attribute.parent_id", goal.attribute.id));
      dispatch(readAllScoreBucketsAF.create({ model: goal.model }, {}));
    },
    onSegmentNameChange(index: number, name: string) {
      dispatch(change(FORM_NAME, getRangeFieldName(index), name));
    },
    onRangesChange(ranges: Range[]) {
      const segments = ranges.reduce((segments, range, index) => {
        segments.push({
          attribute: {
            name: range.name
          },
          min: range.from,
          max: range.to
        });
        return segments;
      }, [] as PartialScoreSegment[]);

      dispatch(change(FORM_NAME, "children", segments));
    },
    closeModal() {
      dispatch(closeCreateModalAF.create({}, {}));
    },
    onSetIsMax(isMax: boolean) {
      dispatch(createSetIsMax.create({ isMax }, {}));
    }
  };
}

const containerWrapper = connectWithLifecycle(
  mapStateToProps,
  mapDispatchToProps
);
const formWrapper = reduxForm<PartialScoreSegment, Props>({
  form: FORM_NAME,
  onSubmit(
    scoreSegment: PartialScoreSegment,
    dispatch: Dispatch<any>,
    props: Props
  ) {
    if (props.isMax) {
      delete scoreSegment.children[scoreSegment.children.length - 1].max;
    }

    dispatch(
      createScoreSegmentAF.create(
        { score: scoreSegment, formName: FORM_NAME },
        {}
      )
    );
  }
});

export const ScoreSegmentCreateModalContainer = containerWrapper(
  formWrapper(ScoreSegmentCreateModalFC)
);
