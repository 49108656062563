import { default as React } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { default as URI } from "urijs";
import { setTrackedData } from "../actions/trackedDataActions";
import { TrackedData as TrackedDataEnum } from "../constants/templateConstants";
import { State } from "../reducers";
import { getSelectedBusinessUnit } from "../selectors/businessUnitSelectors";

export interface TrackedDataProps
  extends TrackedDataStateProps,
    TrackedDataDispatchProps,
    RouteComponentProps<{}> {}

class TrackedDataComponent extends React.Component<TrackedDataProps> {
  checkUpdateTrackedData() {
    const props = this.props;

    const uri = URI(window.location.search);
    const searchMap = uri.search(true);

    if (
      !uri.hasQuery(TrackedDataEnum.COMPANY_BUSINESS_UNIT_ID) ||
      !uri.hasQuery(TrackedDataEnum.CLIENT_ID)
    ) {
      console.info(
        `No tracked data present...
        setting to: BU - ${props.businessUnitId}, C - ${props.clientId}`
      );

      props.updateTrackedData(props.businessUnitId, props.clientId);
    } else if (
      searchMap[TrackedDataEnum.COMPANY_BUSINESS_UNIT_ID] !==
        props.businessUnitId.toString() ||
      searchMap[TrackedDataEnum.CLIENT_ID] !== props.clientId.toString()
    ) {
      console.info(
        `Tracked data does not match selection...
        setting to: BU - ${props.businessUnitId}, C - ${props.clientId}`
      );

      props.updateTrackedData(props.businessUnitId, props.clientId);
    }
  }

  componentDidMount() {
    this.checkUpdateTrackedData();
  }

  componentDidUpdate(prevProps: TrackedDataProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.checkUpdateTrackedData();
    }
  }

  render(): null {
    return null;
  }
}

export interface TrackedDataStateProps {
  clientId: number;
  businessUnitId: number;
}

export interface TrackedDataDispatchProps {
  updateTrackedData(businessUnitId: number, clientId: number): void;
}

function mapStateToProps(state: State): TrackedDataStateProps {
  const businessUnit = getSelectedBusinessUnit(state);

  return {
    clientId: businessUnit ? businessUnit.client : undefined,
    businessUnitId: businessUnit ? businessUnit.id : undefined
  };
}

function mapDispatchToProps(dispatch: Dispatch): TrackedDataDispatchProps {
  return {
    updateTrackedData: (businessUnitId: number, clientId: number) => {
      dispatch(setTrackedData(businessUnitId, clientId));
    }
  };
}

export const TrackedDataContainer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TrackedDataComponent)
);
