import { ActionTypes } from "../constants/templateConstants";
import {
  NormalizedBusinessUnitSelectorData,
  BusinessUnit
} from "../models/businessUnitModels";
import { Action, ActionM, ActionP, ActionPM } from "../../lib/reduxLib";

export interface SelectBusinessUnitAction
  extends ActionP<{ readonly id: number }> {
  readonly type: ActionTypes.SELECT_BUSINESS_UNIT;
}

export interface SearchBusinessUnitsAction
  extends ActionP<{ readonly query: string }> {
  readonly type: ActionTypes.SEARCH_BUSINESS_UNITS;
}

export interface SearchBusinessUnitsDebouncedAction
  extends ActionP<{ readonly query: string }> {
  readonly type: ActionTypes.SEARCH_BUSINESS_UNITS_DEBOUNCED;
}

export interface ClearBusinessUnitSearchAction extends Action {
  readonly type: ActionTypes.CLEAR_BUSINESS_UNIT_SEARCH;
}

export interface TrackedData {
  readonly clientId: number;
  readonly businessUnitId: number;
}

export interface FetchBusinessUnitsMeta {
  readonly trackedData: TrackedData;
}

export interface FetchBusinessUnitsAction extends Action {
  readonly type: ActionTypes.FETCH_BUSINESS_UNITS;
}

export interface FetchBusinessUnitsPendingAction
  extends ActionM<FetchBusinessUnitsMeta> {
  readonly type: ActionTypes.FETCH_BUSINESS_UNITS_PENDING;
}

export interface FetchBusinessUnitsFulfilledAction
  extends ActionPM<NormalizedBusinessUnitSelectorData, FetchBusinessUnitsMeta> {
  readonly type: ActionTypes.FETCH_BUSINESS_UNITS_FULFILLED;
}

export interface FetchBusinessUnitsRejectedAction
  extends ActionPM<Error, FetchBusinessUnitsMeta> {
  readonly type: ActionTypes.FETCH_BUSINESS_UNITS_REJECTED;
}

export interface UpdateRecentBusinessUnitsMeta {
  readonly businessUnitId: number;
}

export interface UpdateRecentBusinessUnitsPendingAction
  extends ActionM<UpdateRecentBusinessUnitsMeta> {
  readonly type: ActionTypes.UPDATE_RECENT_BUSINESS_UNITS_PENDING;
}

export interface UpdateRecentBusinessUnitsFulfilledAction
  extends ActionPM<BusinessUnit[], UpdateRecentBusinessUnitsMeta> {
  readonly type: ActionTypes.UPDATE_RECENT_BUSINESS_UNITS_FULFILLED;
}

export interface UpdateRecentBusinessUnitsRejectedAction
  extends ActionPM<Error, UpdateRecentBusinessUnitsMeta> {
  readonly type: ActionTypes.UPDATE_RECENT_BUSINESS_UNITS_REJECTED;
}

export function selectBusinessUnit(id: number): SelectBusinessUnitAction {
  return {
    type: ActionTypes.SELECT_BUSINESS_UNIT,
    payload: {
      id
    }
  };
}

export function searchBusinessUnits(query: string): SearchBusinessUnitsAction {
  return {
    type: ActionTypes.SEARCH_BUSINESS_UNITS,
    payload: {
      query
    }
  };
}

export function searchBusinessUnitsDebounced(
  query: string
): SearchBusinessUnitsDebouncedAction {
  return {
    type: ActionTypes.SEARCH_BUSINESS_UNITS_DEBOUNCED,
    payload: {
      query
    }
  };
}

export function clearBusinessUnitSearch(): ClearBusinessUnitSearchAction {
  return {
    type: ActionTypes.CLEAR_BUSINESS_UNIT_SEARCH
  };
}

export function fetchBusinessUnits() {
  return {
    type: ActionTypes.FETCH_BUSINESS_UNITS
  };
}

export function fetchBusinessUnitsPending(
  trackedData: TrackedData
): FetchBusinessUnitsPendingAction {
  return {
    type: ActionTypes.FETCH_BUSINESS_UNITS_PENDING,
    meta: {
      trackedData
    }
  };
}

export function fetchBusinessUnitsFulfilled(
  data: NormalizedBusinessUnitSelectorData,
  trackedData: TrackedData
): FetchBusinessUnitsFulfilledAction {
  return {
    type: ActionTypes.FETCH_BUSINESS_UNITS_FULFILLED,
    payload: data,
    meta: {
      trackedData
    }
  };
}

export function fetchBusinessUnitsRejected(
  error: Error,
  trackedData: TrackedData
): FetchBusinessUnitsRejectedAction {
  return {
    type: ActionTypes.FETCH_BUSINESS_UNITS_REJECTED,
    payload: error,
    meta: {
      trackedData
    }
  };
}

export function updateRecentBusinessUnitsPending(
  businessUnitId: number
): UpdateRecentBusinessUnitsPendingAction {
  return {
    type: ActionTypes.UPDATE_RECENT_BUSINESS_UNITS_PENDING,
    meta: {
      businessUnitId
    }
  };
}

export function updateRecentBusinessUnitsFulfilled(
  businessUnits: BusinessUnit[],
  businessUnitId: number
): UpdateRecentBusinessUnitsFulfilledAction {
  return {
    type: ActionTypes.UPDATE_RECENT_BUSINESS_UNITS_FULFILLED,
    payload: businessUnits,
    meta: {
      businessUnitId
    }
  };
}

export function updateRecentBusinessUnitsRejected(
  error: Error,
  businessUnitId: number
): UpdateRecentBusinessUnitsRejectedAction {
  return {
    type: ActionTypes.UPDATE_RECENT_BUSINESS_UNITS_REJECTED,
    payload: error,
    meta: {
      businessUnitId
    }
  };
}
