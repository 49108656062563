import { default as React } from "react";
import { IN_LAST_DAYS } from "../../constants/operatorConstants";
import {
  baseStyles,
  ConditionBuilderBaseProps,
  qaIds,
  baseDefaultProps,
  inputIds
} from "./SharedData";
import { AttributeOperator } from "../../api/attributeApi";
import { AttributeName } from "../AttributeName";
import { CustomInput, Input } from "reactstrap";

export const VisitRecencyFC: React.FC<ConditionBuilderBaseProps> = props => {
  return (
    <div className={baseStyles.conditionContainerClass}>
      <div className={baseStyles.itemMarginClass}>
        <AttributeName
          attribute={props.attribute}
          qaNameId={qaIds.selectedConditionName}
          qaTooltipId={qaIds.selectedConditionTooltipPath}
          qaTooltipIconId={qaIds.selectedConditionTooltipIcon}
          qaTooltipPathId={qaIds.selectedConditionTooltipPath}
        />
      </div>
      {props.attribute.attribute_operators.length > 0 && (
        <div className={baseStyles.itemMarginClass}>
          <CustomInput
            id={inputIds.operatorId}
            value={props.operatorId}
            type={"select"}
            onChange={e => {
              props.handleChangeOperatorId(
                props.attribute,
                (e.currentTarget as any).value,
                props.value
              );
            }}
            data-qa-id={qaIds.selectedConditionOperator}
          >
            {props.attribute.attribute_operators.map(
              (operator: AttributeOperator) => {
                return (
                  <option value={operator.id} key={operator.id}>
                    {operator.name}
                  </option>
                );
              }
            )}
          </CustomInput>
        </div>
      )}
      {props.attribute.attribute_values.length === 0 && (
        <div className={baseStyles.itemMarginClass}>
          <Input
            id={inputIds.value}
            type="text"
            value={props.value}
            onChange={e => {
              props.handleChangeValue(
                props.attribute,
                props.operatorId,
                (e.currentTarget as any).value
              );
            }}
            data-qa-id={qaIds.selectedConditionValue}
          />
        </div>
      )}
      {props.attribute.attribute_subset_operators.map(
        (v: AttributeOperator) => {
          return (
            <div key={v.id} className={baseStyles.itemMarginClass}>
              {v.id === IN_LAST_DAYS && (
                <div className={"d-flex align-items-center"}>
                  <div className={"text-nowrap mr-3"}>in the last</div>
                  <Input
                    id={inputIds.subsetValue}
                    type="text"
                    value={props.subsetValue}
                    onChange={e => {
                      props.handleChangeSubsetValue(
                        props.attribute,
                        props.subsetOperatorId,
                        (e.currentTarget as any).value
                      );
                    }}
                    data-qa-id={qaIds.selectedConditionSubsetValue}
                  />
                  <div className={"ml-3"}>days</div>
                </div>
              )}
            </div>
          );
        }
      )}
    </div>
  );
};

VisitRecencyFC.defaultProps = {
  ...baseDefaultProps
};
