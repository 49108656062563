import { State } from "../reducers";
import { User } from "../models/authModels";

export function getUser(state: State): User | undefined {
  return state.auth && state.auth.user && state.auth.user.user;
}

export function getIsAuthenticated(state: State): boolean {
  return getUser(state) !== undefined;
}

export function getIsAuthenticationPending(state: State): boolean {
  return state.auth && state.auth.user ? state.auth.user.pending : false;
}

export function getUserToken(state: State): string | undefined {
  const user = getUser(state);
  return user && user.Token;
}

export function getUserKey(state: State): string | undefined {
  const user = getUser(state);
  return user && user.Key;
}

export function getUserId(state: State): number | undefined {
  const user = getUser(state);
  return user && user.UserId;
}

export function getUserName(state: State): string | undefined {
  const user = getUser(state);
  return user && user.UserName;
}
