import * as React from "react";
import { clamp, min, max, isNaN } from "lodash";
import { Range as RangeComp, createSliderWithTooltip } from "rc-slider";
import "rc-slider/assets/index.css";
import { default as HighchartReact } from "highcharts-react-official";
import {
  DataPoint,
  default as Highcharts,
  AreaChartSeriesOptions,
  AxisEvent
} from "highcharts";
import { NumberOfSegmentsType } from "../constants/score";
import { ScoreSegment, PartialScoreSegment } from "../../api/attributeApi";
import { Field } from "redux-form";
import { required } from "../../../lib/validationLib";
import variables from "../../../../node_modules/@ione/bootstrap-variables/scss/ione/exports.scss";
import { FormGroupInputField } from "../../forms/components/FormGroupInputField/FormGroupInputField";
import {
  Button,
  Col,
  Container,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";
import styles from "./ScoreRangeComponent.module.scss";
import classNames from "classnames";
import { CSSProperties } from "react";

const rangeColors = {
  low: variables.primary,
  medium: variables.secondary,
  high: variables.primary
};

const segmentColors = {
  low: "rgba(255, 131, 8, 0.0)",
  medium: "rgba(0, 136, 177, 0.0)",
  high: "rgba(255, 131, 8, 0.0)"
};

function getSegmentColor(index: number): string {
  switch (index) {
    case 0:
      return segmentColors.low;
    case 1:
      return segmentColors.medium;
    default:
      return segmentColors.high;
  }
}

function getRangeColor(index: number): string {
  switch (index) {
    case 0:
      return rangeColors.low;
    case 1:
      return rangeColors.medium;
    default:
      return rangeColors.high;
  }
}

function getSegmentText(index: number, numberOfSegments: number): string {
  switch (numberOfSegments) {
    case 1:
      return "Segment";
    default:
      return `Segment ${index + 1}`;
  }
}

function getTrackStyle(length: number): CSSProperties[] {
  const trackStyle: CSSProperties[] = [];

  for (let i = 0; i < length; i += 1) {
    trackStyle.push({ backgroundColor: getRangeColor(i) });
  }

  return trackStyle;
}

function getHandleStyle(length: number): CSSProperties[] {
  switch (length) {
    case 1:
      return [
        { borderColor: rangeColors.low },
        { borderColor: rangeColors.low }
      ];
    case 2:
      return [
        { borderColor: rangeColors.low },
        { borderColor: rangeColors.medium },
        { borderColor: rangeColors.medium }
      ];
    default:
      return [
        { borderColor: rangeColors.low },
        { borderColor: rangeColors.medium },
        { borderColor: rangeColors.medium },
        { borderColor: rangeColors.high }
      ];
  }
}

export interface Range {
  id: string;
  name: string;
  from: number;
  to: number;
}

function createPlotBand(value: number): Highcharts.PlotLines {
  return {
    value,
    width: 1,
    zIndex: 10001,
    color: variables.dark
  };
}

function getPlotLines(ranges: Range[]): Highcharts.PlotLines[] {
  switch (ranges.length) {
    case 1:
      return [createPlotBand(ranges[0].from), createPlotBand(ranges[0].to)];
    case 2:
      return [
        createPlotBand(ranges[0].from),
        createPlotBand(ranges[0].to),
        createPlotBand(ranges[1].to)
      ];
    default:
      return [
        createPlotBand(ranges[0].from),
        createPlotBand(ranges[0].to),
        createPlotBand(ranges[2].from),
        createPlotBand(ranges[2].to)
      ];
  }
}

function getRangeTooltips(ranges: Range[]): number[] {
  switch (ranges.length) {
    case 1:
      return [ranges[0].from, ranges[0].to];
    case 2:
      return [ranges[0].from, ranges[0].to, ranges[1].to];
    default:
      return [ranges[0].from, ranges[0].to, ranges[2].from, ranges[2].to];
  }
}

function getChartDataSet(
  goal: string,
  dataPoints: DataPoint[]
): AreaChartSeriesOptions {
  return {
    name: goal,
    type: "area",
    data: dataPoints,
    fillColor: {
      linearGradient: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 1
      },
      stops: [
        [0, "rgba(0, 136, 177, 1.0)"],
        [1, "rgba(0, 136, 177, 0.25)"]
      ]
    },
    lineWidth: 1,
    color: variables.dark,
    marker: {
      lineWidth: 1,
      lineColor: variables.secondary,
      fillColor: variables.secondary,
      symbol: "circle",
      radius: 2,
      enabled: false
    }
  };
}

export interface Bounds {
  ranges: Range[];
  min: number;
  max: number;
}

function isRangesUsingMax(ranges: Range[] = []): boolean {
  return ranges.length > 0 ? ranges[ranges.length - 1].to == null : true;
}

function getStartingBounds(
  distribution: DataPoint[],
  numberOfSegments: NumberOfSegmentsType,
  initialRanges: Range[] = []
): Bounds {
  const values = distribution.map(point => {
    return point.x as number;
  });
  const nos = initialRanges.length || numberOfSegments;
  const minValue = min<number>(values) || 0;
  const maxValue = max<number>(values) || 0;
  const stepSize = maxValue / nos;

  let ranges: Range[];

  if (initialRanges.length === 0) {
    ranges = [];

    for (let i = 0; i < nos; i += 1) {
      ranges[i] = {
        id: null,
        name: getSegmentText(i, nos),
        from: Math.floor(minValue + i * stepSize),
        to: Math.floor(minValue + (i + 1) * stepSize)
      };
    }
  } else {
    ranges = initialRanges.map(range => ({ ...range }));

    if (isRangesUsingMax(ranges)) {
      ranges[ranges.length - 1].to = maxValue;
    }
  }

  return {
    ranges,
    min: minValue,
    max: maxValue
  };
}

type SegmentStatistics = SegmentStatistic[];

interface SegmentStatistic {
  ratio: number;
  count: number;
}

const EMPTY_SEGMENT_STATISTIC: SegmentStatistic = {
  ratio: 0,
  count: 0
};

function getSegmentStatistics(
  data: DataPoint[],
  bounds: Bounds
): SegmentStatistics {
  const segmentStatistics: SegmentStatistics = bounds.ranges.map(() => {
    return {
      ratio: 0,
      count: 0
    };
  });

  data.forEach(point => {
    bounds.ranges.forEach((range, index) => {
      if (point.x >= range.from && point.x <= range.to) {
        segmentStatistics[index].count += point.y as number;
      }
    });
  });

  const total = segmentStatistics.reduce((acc, segmentStatistic) => {
    return acc + segmentStatistic.count;
  }, 0);

  if (total !== 0) {
    segmentStatistics.forEach(segmentStatistic => {
      segmentStatistic.ratio = (segmentStatistic.count / total) * 100;
    });
  }

  return segmentStatistics;
}

const RANGE_WIDTH = 634;

const RangeWithTooltips = createSliderWithTooltip(RangeComp);

export interface ScoreRangeProps {
  initialNumberOfSegments: NumberOfSegmentsType;
  initialRanges: Range[];
  dataPoints: DataPoint[];
  ranges: Range[];
  goals: ScoreSegment[];
  goal: ScoreSegment;
  isMax: boolean;
  isEdit?: boolean;
  onGoalChange(goal: ScoreSegment): void;
  onSegmentNameChange(index: number, name: string): void;
  onRangesChange(ranges: Range[]): void;
  getNameFieldName(): string;
  getRangeFieldName(index: number): string;
  onSetIsMax(isMax: boolean): void;
  nameIsUnique(
    value: string,
    allValues: PartialScoreSegment,
    props: any
  ): string | undefined;
}

export interface ScoreRangeState {
  numberOfSegments: NumberOfSegmentsType;
  dataPoints: DataPoint[];
  chartDataSet: AreaChartSeriesOptions;
  bounds: Bounds;
  statistics: SegmentStatistics;
  zoom: {
    scale: number;
    offset: number;
  };
}

export class ScoreRangeFC extends React.Component<
  ScoreRangeProps,
  ScoreRangeState
> {
  static defaultProps: Partial<ScoreRangeProps> = {
    ranges: [],
    initialRanges: [],
    initialNumberOfSegments: 3,
    isEdit: false
  };

  constructor(props: ScoreRangeProps) {
    super(props);

    const chartDataSet = getChartDataSet(
      props.goal.attribute.name,
      props.dataPoints
    );
    const bounds = getStartingBounds(
      chartDataSet.data as DataPoint[],
      props.initialNumberOfSegments,
      props.initialRanges
    );
    const statistics = getSegmentStatistics(
      chartDataSet.data as DataPoint[],
      bounds
    );

    this.state = {
      bounds,
      chartDataSet,
      statistics,
      numberOfSegments: bounds.ranges.length as NumberOfSegmentsType,
      dataPoints: props.dataPoints,
      zoom: {
        scale: 1,
        offset: 0
      }
    };
  }

  changeGoalId(goalId: number): void {
    const goal = this.props.goals.find(goal => {
      return goal.id === goalId;
    });

    if (goal) {
      this.setState((state, props) => {
        const chartDataSet = getChartDataSet(
          goal.attribute.name,
          state.dataPoints
        );
        const bounds = getStartingBounds(
          chartDataSet.data as DataPoint[],
          state.numberOfSegments,
          props.initialRanges
        );

        this.resetZoom();
        props.onGoalChange(goal);
        props.onSetIsMax(isRangesUsingMax(props.initialRanges));
        props.onRangesChange(bounds.ranges);

        return {
          bounds,
          statistics: getSegmentStatistics(
            chartDataSet.data as DataPoint[],
            bounds
          ),
          zoom: {
            scale: 1,
            offset: 0
          }
        };
      });
    }
  }

  onGoalIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.changeGoalId(+e.target.value);
  };

  onNumberOfSegmentsChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const numberOfSegments = +e.target.value as NumberOfSegmentsType;

    this.setState((state, props) => {
      const chartDataSet = state.chartDataSet;
      const bounds = getStartingBounds(
        chartDataSet.data as DataPoint[],
        numberOfSegments,
        []
      );

      this.resetZoom();
      props.onRangesChange(bounds.ranges);

      return {
        numberOfSegments,
        bounds,
        statistics: getSegmentStatistics(
          chartDataSet.data as DataPoint[],
          bounds
        ),
        zoom: {
          scale: 1,
          offset: 0
        }
      };
    });
  };

  setRanges = (ranges: Range[]) => {
    this.setState(
      (state, props) => {
        if (props.isMax && ranges[ranges.length - 1].to < state.bounds.max) {
          props.onSetIsMax(false);
        }
        const bounds = {
          ...state.bounds,
          ranges
        };
        const statistics = getSegmentStatistics(
          state.chartDataSet.data as DataPoint[],
          bounds
        );

        return {
          statistics,
          bounds
        };
      },
      () => this.props.onRangesChange(this.state.bounds.ranges)
    );
  };

  onRangeChange = (values: number[]): void => {
    const ranges = this.state.bounds.ranges.map((range, index) => {
      return {
        id: this.props.ranges[index] ? this.props.ranges[index].id : range.id,
        name: this.props.ranges[index]
          ? this.props.ranges[index].name
          : range.name,
        from: values[index],
        to: values[index + 1]
      };
    });
    this.setRanges(ranges);
  };

  onAfterRangeChange = (values: number[]): void => {
    this.setState(state => {
      return {
        statistics: getSegmentStatistics(
          state.chartDataSet.data as DataPoint[],
          state.bounds
        )
      };
    });
  };

  clamp(value: number): number {
    return clamp(value, this.state.bounds.min, this.state.bounds.max);
  }

  onZoom = (event: AxisEvent) => {
    const rangeDiff = this.clamp(event.max) - this.clamp(event.min);
    const scale = (this.state.bounds.max - this.state.bounds.min) / rangeDiff;
    const step = RANGE_WIDTH / rangeDiff;
    const offset = -(step * this.clamp(event.min));

    if (isNaN(scale) || isNaN(offset)) {
      return;
    }

    if (scale !== this.state.zoom.scale || offset !== this.state.zoom.offset) {
      this.setState({
        zoom: {
          scale,
          offset
        }
      });
    }
  };

  resetZoom() {
    this.chart.xAxis[0].setExtremes(null, null);
  }

  onSetIsMax = () => {
    const values = this.state.bounds.ranges.map(range => range.from);
    values.push(this.state.bounds.max);
    this.props.onSetIsMax(true);
    this.onRangeChange(values);
  };

  private updateRange(ranges: Range[], index: number, range: Range) {
    const left = ranges[index - 1],
      right = ranges[index + 1];

    if (left) {
      ranges[index - 1] = {
        ...left,
        from: clamp(left.from, this.state.bounds.min, range.from),
        to: range.from
      };
    }
    if (right) {
      ranges[index + 1] = {
        ...right,
        from: range.to,
        to: clamp(right.to, range.to, this.state.bounds.max)
      };
    }

    return range;
  }
  private updateRanges(ranges: Range[], index: number, direction: number = 1) {
    const nextRange = ranges[index];

    if (nextRange) {
      this.updateRange(ranges, index, nextRange);
      this.updateRanges(ranges, index + direction, direction);
    }
  }
  private changeAndValidateBounds(index: number, from: number, to: number) {
    const range = {
        id: this.props.ranges[index]
          ? this.props.ranges[index].id
          : this.state.bounds.ranges[index].id,
        name: this.props.ranges[index]
          ? this.props.ranges[index].name
          : this.state.bounds.ranges[index].name,
        from,
        to
      },
      ranges = [...this.state.bounds.ranges];

    ranges[index] = range;

    this.updateRanges(ranges, index, -1);
    this.updateRanges(ranges, index, 1);

    this.setRanges(ranges);
  }

  createOnBlurLowerBound = (index: number) => (from: number, to: number) => {
    this.changeAndValidateBounds(
      index,
      from,
      Math.max(this.state.bounds.ranges[index].to, to)
    );
  };
  createOnBlurUpperBound = (index: number) => (from: number, to: number) => {
    this.changeAndValidateBounds(
      index,
      Math.min(this.state.bounds.ranges[index].from, from),
      to
    );
  };

  componentDidMount() {
    this.changeGoalId(this.props.goal.id);
  }

  componentDidUpdate(previousProps: ScoreRangeProps) {
    if (this.props.dataPoints !== previousProps.dataPoints) {
      this.setState((state, props) => {
        const chartDataSet = getChartDataSet(
          props.goal.attribute.name,
          props.dataPoints
        );
        const bounds = getStartingBounds(
          chartDataSet.data as DataPoint[],
          state.numberOfSegments,
          props.initialRanges
        );
        const statistics = getSegmentStatistics(
          chartDataSet.data as DataPoint[],
          bounds
        );

        this.resetZoom();
        props.onSetIsMax(isRangesUsingMax(props.initialRanges));
        props.onRangesChange(bounds.ranges);

        return {
          bounds,
          chartDataSet,
          statistics,
          numberOfSegments: state.numberOfSegments,
          dataPoints: props.dataPoints,
          zoom: {
            scale: 1,
            offset: 0
          }
        };
      });
    }
  }

  chart: Highcharts.ChartObject;

  afterRender = (chart: Highcharts.ChartObject) => {
    this.chart = chart;
  };

  render() {
    const rangeTooltips = getRangeTooltips(this.state.bounds.ranges);

    return (
      <Container fluid>
        <Row className={"mb-3"}>
          <Col xs={6} lg={4}>
            <Field
              id={"score-range-name"}
              name={this.props.getNameFieldName()}
              component={FormGroupInputField}
              type="text"
              label="Name"
              validate={[required, this.props.nameIsUnique]}
              props={{} as any}
              data-qa-id="score-range-name-input"
              required
            />
          </Col>
          <Col xs={6} lg={4}>
            <FormGroup>
              <Label htmlFor="number-of-segments">Number of Segments</Label>
              <CustomInput
                id="number-of-segments"
                type="select"
                data-qa-id="selected-number-of-segments-type"
                value={this.state.numberOfSegments}
                disabled={this.props.isEdit}
                onChange={this.onNumberOfSegmentsChange}
              >
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
              </CustomInput>
            </FormGroup>
          </Col>
          <Col xs={12} lg={4}>
            <FormGroup>
              <Label htmlFor="goal">Goal</Label>
              <CustomInput
                id="goal"
                type="select"
                label="goal"
                data-qa-id="selected-goal-type"
                value={this.props.goal.attribute.id}
                disabled={this.props.isEdit}
                onChange={this.onGoalIdChange}
              >
                {this.props.goals.map(goal => {
                  return (
                    <option key={goal.attribute.name} value={goal.attribute.id}>
                      {goal.attribute.name}
                    </option>
                  );
                })}
              </CustomInput>
            </FormGroup>
          </Col>
        </Row>
        <Row className={"mb-3"}>
          <Col>
            <HighchartReact
              highcharts={Highcharts}
              callback={this.afterRender}
              options={{
                title: {
                  text: null
                },
                chart: {
                  zoomType: "x",
                  panning: true,
                  panKey: "shift"
                },
                legend: {
                  enabled: false
                },
                series: [this.state.chartDataSet],
                yAxis: {
                  title: {
                    text: "Identifiers",
                    rotation: 270
                  },
                  type: "logarithmic"
                },
                xAxis: {
                  title: {
                    text: "Score"
                  },
                  events: {
                    afterSetExtremes: this.onZoom
                  },
                  plotBands: this.props.ranges.map((range, index) => {
                    return {
                      label: {
                        text: range.name
                      },
                      color: getSegmentColor(index),
                      from: range.from,
                      to: range.to
                    };
                  }),
                  plotLines: getPlotLines(this.state.bounds.ranges)
                }
              }}
            />
          </Col>
        </Row>
        <Row className={"mb-3 d-none d-lg-block"}>
          <Col>
            <div
              className={
                this.state.zoom.scale > 1
                  ? styles.zoomedRangeContainer
                  : styles.rangeContainer
              }
            >
              <RangeWithTooltips
                style={{
                  width: RANGE_WIDTH * this.state.zoom.scale,
                  left: this.state.zoom.offset
                }}
                value={rangeTooltips}
                count={rangeTooltips.length}
                pushable={true}
                min={this.state.bounds.min}
                max={this.state.bounds.max}
                onChange={this.onRangeChange}
                onAfterChange={this.onAfterRangeChange}
                trackStyle={getTrackStyle(this.state.bounds.ranges.length)}
                handleStyle={getHandleStyle(this.state.bounds.ranges.length)}
              />
            </div>
          </Col>
        </Row>
        <Row className={"mb-3 d-none d-lg-block"}>
          <Col className={"d-flex justify-content-end"}>
            <Button
              color={"primary"}
              data-qa-id="score-segment-max"
              disabled={this.props.isMax}
              onClick={this.onSetIsMax}
            >
              Max
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className={"d-flex justify-content-around flex-wrap"}>
            {this.props.ranges.map((range, index, ranges) => {
              return (
                <Bound
                  className={`${styles.boundContainer} ${styles.boundRange} mb-3`}
                  key={index}
                  index={index}
                  title={range.name}
                  color={getRangeColor(index)}
                  ratio={
                    (this.state.statistics[index] || EMPTY_SEGMENT_STATISTIC)
                      .ratio
                  }
                  count={
                    (this.state.statistics[index] || EMPTY_SEGMENT_STATISTIC)
                      .count
                  }
                  lowerBound={range.from}
                  upperBound={
                    index === ranges.length - 1 && this.props.isMax
                      ? undefined
                      : range.to
                  }
                  onBlurLowerBound={this.createOnBlurLowerBound(index)}
                  onBlurUpperBound={this.createOnBlurUpperBound(index)}
                  getFieldName={this.props.getRangeFieldName}
                />
              );
            })}
          </Col>
        </Row>
      </Container>
    );
  }
}

interface BoundProps extends BoundRangeProps, SegmentStatistic {
  title: string;
  color: string;
  index: number;
  getFieldName(index: number): string;
}

interface BoundRangeProps {
  index: number;
  lowerBound?: number;
  upperBound?: number;
  className?: string;
  onBlurLowerBound(from: number, to: number): void;
  onBlurUpperBound(from: number, to: number): void;
}
interface BoundRangeState {
  lowerBound?: number;
  upperBound?: number;
}

class BoundRange extends React.Component<BoundRangeProps, BoundRangeState> {
  constructor(props: BoundRangeProps) {
    super(props);

    this.state = {
      lowerBound: props.lowerBound,
      upperBound: props.upperBound
    };
  }

  onBlurChangeLowerBound = (e: React.FocusEvent<HTMLInputElement>) =>
    this.props.onBlurLowerBound(this.state.lowerBound, this.state.upperBound);
  onBlurChangeUpperBound = (e: React.FocusEvent<HTMLInputElement>) =>
    this.props.onBlurUpperBound(this.state.lowerBound, this.state.upperBound);

  onChangeLowerValue = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({
      lowerBound: +e.target.value
    });
  onChangeUpperValue = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({
      upperBound: +e.target.value
    });

  UNSAFE_componentWillReceiveProps(nextProps: BoundRangeProps) {
    if (
      this.state.lowerBound !== nextProps.lowerBound ||
      this.state.upperBound !== nextProps.upperBound
    ) {
      this.setState({
        lowerBound: nextProps.lowerBound,
        upperBound: nextProps.upperBound
      });
    }
  }
  render() {
    if (
      this.state.lowerBound !== undefined &&
      this.state.upperBound !== undefined
    ) {
      return (
        <span className="h5">
          <Input
            type="number"
            data-qa-id={`score-manually-input-${this.props.index}-low`}
            value={this.state.lowerBound}
            onBlur={this.onBlurChangeLowerBound}
            onChange={this.onChangeLowerValue}
          />
          {" - "}
          <Input
            type="number"
            data-qa-id={`score-manually-input-${this.props.index}-high`}
            value={this.state.upperBound}
            onBlur={this.onBlurChangeUpperBound}
            onChange={this.onChangeUpperValue}
          />
        </span>
      );
    }
    if (this.state.upperBound !== undefined) {
      return (
        <span className="h5">
          <Input
            type="number"
            data-qa-id={`score-manually-input-${this.props.index}-high`}
            value={this.state.upperBound}
            onBlur={this.onBlurChangeUpperBound}
            onChange={this.onChangeUpperValue}
          />
        </span>
      );
    }
    if (this.state.lowerBound !== undefined) {
      return (
        <span className="h5">
          <Input
            type="number"
            data-qa-id={`score-manually-input-${this.props.index}-low`}
            value={this.state.lowerBound}
            onBlur={this.onBlurChangeLowerBound}
            onChange={this.onChangeUpperValue}
          />
          &gt;
        </span>
      );
    }
    return null;
  }
}

function Bound(props: BoundProps) {
  return (
    <div className={classNames("border rounded p-3", props.className)}>
      <Field
        id={`${props.index}-name-input`}
        name={props.getFieldName(props.index)}
        component={FormGroupInputField}
        type="text"
        validate={[required]}
        data-qa-id={`bound-${props.index}-name-input`}
      />
      <div className={"text-center"}>
        <BoundRange
          index={props.index}
          lowerBound={props.lowerBound}
          upperBound={props.upperBound}
          onBlurLowerBound={props.onBlurLowerBound}
          onBlurUpperBound={props.onBlurUpperBound}
        />
        <div
          className={`${styles.boundColorBar} w-100 my-3`}
          style={{ backgroundColor: props.color }}
        />
        <div className="h5">{props.ratio.toFixed(2)}%</div>
        <div className="">{props.count}</div>
        <div className="">Identifiers</div>
      </div>
    </div>
  );
}
