import { default as React, StatelessComponent } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { authenticate } from "../actions/authActions";
import { configurePermissions } from "../actions/permissionActions";
import { getLoginRedirectUrl } from "../lib/authLib";
import { State } from "../reducers";
import {
  getIsAuthenticated,
  getIsAuthenticationPending
} from "../selectors/userSelectors";
import {
  AccessPureComponent,
  StateProps,
  DispatchProps
} from "../components/AccessComponent";
import { ExternalRedirectPureComponent } from "../components/ExternalRedirectComponent";

export interface OwnProps {
  pendingComponent?: StatelessComponent<any>;
  permissionIds: number[];
}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  return {
    hasAccess: getIsAuthenticated(state),
    checkAccessPending: getIsAuthenticationPending(state),
    deniedComponent: () => {
      return <ExternalRedirectPureComponent to={getLoginRedirectUrl()} />;
    },
    pendingComponent: ownProps.pendingComponent
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps {
  return {
    checkAccess: () => {
      dispatch(configurePermissions(ownProps.permissionIds));
      dispatch(authenticate());
    }
  };
}

export const AuthenticatedContainer = connect<
  StateProps,
  DispatchProps,
  OwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(AccessPureComponent);
