import { env } from "../env/appEnv";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { basePost } from "../../lib/httpLib";

enum HttpHeaders {
  ApiKey = "ApiKey"
}

const wpAudienceConsoleBaseUrl = `${env.REACT_APP_WP_SERVICE_API_URL}/audience-console`;
const wpSegmentCountUrl = `${wpAudienceConsoleBaseUrl}/segment-count`;

export interface WPAttribute {
  id: number;
  visitors: number;
  children: WPAttribute[];
}

export interface WPSegmentCountResponse {
  data: {
    count: {
      total: number;
      count: number;
    };
  };
}

export interface WPSegmentCount {
  id: number;
  count: number;
}

export interface WPSegmentCounts {
  buid: number;
  categories: WPSegmentCount[];
}

export function readWPSegmentCount(
  buid: number,
  categories: number[]
): Observable<WPSegmentCountResponse["data"]["count"]> {
  return basePost<WPSegmentCountResponse>(
    wpSegmentCountUrl,
    {
      buid,
      categories
    },
    {
      [HttpHeaders.ApiKey]: env.REACT_APP_WP_SERVICE_API_KEY
    },
    {
      withCredentials: true
    }
  ).pipe(map(wpSegmentCount => wpSegmentCount.data.count));
}
