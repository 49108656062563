import { default as React } from "react";
import { connect } from "react-redux";
import {
  AttributeSelectorFC,
  Props as AttributeSelectorProps,
  StateProps as AttributeSelectorStateProps,
  DispatchProps as AttributeSelectorDispatchProps
} from "../components/AttributeSelector";
import {
  getAttributeSelector,
  getSearchResultsFactory,
  getBreadcrumbs,
  getSortedAttributes
} from "../selectors/attributeSelectorSelectors";
import {
  attributeSelectorChangeSearchTermAF,
  attributeSelectorDestroyAF,
  attributeSelectorInitAF,
  attributeSelectorNavigateAttributeAF,
  attributeSelectorSelectAttributeAF,
  attributeSelectorSelectBreadCrumbAF
} from "../actions/attributeSelectorActions";
import { readAttributeForestAF } from "../actions/attributeActions";
import { State } from "../reducers";
import { Dispatch } from "redux";
import { getSelectedBusinessUnit } from "../../template/selectors/businessUnitSelectors";
import { Attribute } from "../api/attributeApi";
import { getExpressionBuilderChannel } from "../selectors/expressionBuilderSelector";
import { openCreateModalAF } from "../score/actions/createActions";
import { readScoreSegmentGoalsAF } from "../score/actions/scoreActions";
import {
  isGettingGoals,
  getScoreSegmentGoals
} from "../score/selectors/scoreSelectors";

interface OwnProps {
  selectorId: string;
}

interface ComponentProps<P> {
  componentProps: P;
}

interface LocalStateProps {
  businessUnitId: number;
}

interface LocalDispatchProps {
  init(): void;
  destroy(): void;
  getAttributes(businessUnitId: number): void;
}

interface Props
  extends LocalStateProps,
    LocalDispatchProps,
    ComponentProps<AttributeSelectorProps> {}
interface StateProps
  extends LocalStateProps,
    ComponentProps<AttributeSelectorStateProps> {}
interface DispatchProps
  extends LocalDispatchProps,
    ComponentProps<AttributeSelectorDispatchProps> {}

class AttributeSelectorPureComponent extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.init();
  }

  componentWillUnmount() {
    this.props.destroy();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.businessUnitId !== prevProps.businessUnitId) {
      this.props.getAttributes(this.props.businessUnitId);
    }
  }

  render() {
    return <AttributeSelectorFC {...this.props.componentProps} />;
  }
}

function mapStateToProps(): (state: State, ownProps: OwnProps) => StateProps {
  const getSearchResults = getSearchResultsFactory();

  return (state: State, ownProps: OwnProps) => {
    const goals = getScoreSegmentGoals(state);
    const businessUnitId = getSelectedBusinessUnit(state).id;
    const attributeSelector = getAttributeSelector(state, ownProps.selectorId);
    const searchResults = getSearchResults(state, ownProps.selectorId);
    const breadCrumbs = getBreadcrumbs(state, ownProps.selectorId);
    const channel = getExpressionBuilderChannel(state, ownProps.selectorId);
    const attributes = getSortedAttributes(state, ownProps.selectorId);

    return {
      businessUnitId,
      componentProps: {
        goals,
        attributes,
        breadCrumbs,
        searchResults,
        channel,
        isGettingGoals: isGettingGoals(state),
        inputSearchTerm: attributeSelector
          ? attributeSelector.inputSearchTerm
          : "",
        searchTerm: attributeSelector ? attributeSelector.searchTerm : ""
      }
    };
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps {
  return {
    init() {
      dispatch(
        attributeSelectorInitAF.create({}, { selectorId: ownProps.selectorId })
      );
      dispatch(readScoreSegmentGoalsAF.create({}, {}));
    },
    destroy() {
      dispatch(
        attributeSelectorDestroyAF.create(
          {},
          { selectorId: ownProps.selectorId }
        )
      );
    },
    getAttributes() {
      dispatch(readAttributeForestAF.create({}, {}));
    },
    componentProps: {
      handleSelectAttribute(attribute: Attribute) {
        dispatch(
          attributeSelectorSelectAttributeAF.create(attribute, {
            selectorId: ownProps.selectorId
          })
        );
      },
      handleChangeSearchTerm(searchTerm: string) {
        dispatch(
          attributeSelectorChangeSearchTermAF.create(
            { searchTerm },
            { selectorId: ownProps.selectorId }
          )
        );
      },
      handleNavigateAttribute(attribute: Attribute) {
        dispatch(
          attributeSelectorNavigateAttributeAF.create(attribute, {
            selectorId: ownProps.selectorId
          })
        );
      },
      handleSelectBreadCrumb(attribute: Attribute) {
        dispatch(
          attributeSelectorSelectBreadCrumbAF.create(attribute, {
            selectorId: ownProps.selectorId
          })
        );
      },
      onOpenScoreModal() {
        dispatch(openCreateModalAF.create({}, {}));
      }
    }
  };
}

function mergeProps(
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
) {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    componentProps: {
      ...stateProps.componentProps,
      ...dispatchProps.componentProps
    }
  };
}

export const AttributeSelectorContainer = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  Props,
  State
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AttributeSelectorPureComponent);
