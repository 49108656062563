import { AF, AsyncAF } from "../../../lib/actionFactoryLib";
import { ScoreSegment } from "../../api/attributeApi";

export const openDeleteModalAF = new AF<ScoreSegment>(
  "SCORE_SEGMENT_OPEN_DELETE_MODAL"
);

export const closeDeleteModalAF = new AF<{}>(
  "SCORE_SEGMENT_CLOSE_DELETE_MODAL"
);

export const readDeleteScoreSegmentByIdAF = new AsyncAF<
  { attributeId: string },
  ScoreSegment
>("READ_DELETE_SCORE_SEGMENT");

export const deleteScoreSegmentAF = new AsyncAF<ScoreSegment, ScoreSegment>(
  "SCORE_SEGMENT_DELETE"
);
