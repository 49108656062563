import { default as React } from "react";
import { Attribute } from "../api/attributeApi";
import variables from "../../../node_modules/@ione/bootstrap-variables/scss/ione/exports.scss";

const breadcrumbDivider =
  (variables &&
    variables.breadcrumbDivider &&
    variables.breadcrumbDivider.slice(1, -1)) ||
  "/";

export interface BreadCrumbsProps {
  breadCrumbs: Attribute[];
  handleSelectBreadCrumb(crumb: Attribute): void;
}

export const BreadCrumbFC: React.FC<BreadCrumbsProps> = props => {
  return (
    <div data-qa-id="breadcrumbs">
      {props.breadCrumbs.map((crumb, index) => {
        return (
          <span key={crumb.id} data-qa-id="breadcrumb">
            {index === props.breadCrumbs.length - 1 && (
              <span data-qa-id="unclickable-breadcrumb">{crumb.name}</span>
            )}
            {index !== props.breadCrumbs.length - 1 && (
              <span>
                <button
                  className={"btn btn-link-inline"}
                  data-qa-id="clickable-breadcrumb"
                  onClick={() => {
                    props.handleSelectBreadCrumb(crumb);
                  }}
                >
                  {crumb.name}
                </button>
                <span> {breadcrumbDivider} </span>
              </span>
            )}
          </span>
        );
      })}
    </div>
  );
};

BreadCrumbFC.defaultProps = {
  breadCrumbs: []
};
