import { default as React } from "react";
import { Alert } from "reactstrap";
import { MIN_CHARS_FOR_SEARCH } from "../constants/templateConstants";
import { NormalizedBusinessUnit as IBusinessUnit } from "../models/businessUnitModels";
import { BusinessUnitFC } from "./BusinessUnitComponent";
import styles from "./BusinessUnitListComponent.module.scss";

export interface BusinessUnitListProps {
  businessUnits: IBusinessUnit[];
  query: string;
  selectedBusinessUnit?: IBusinessUnit;
  recentBusinessUnits: IBusinessUnit[];
  onBusinessUnitSelect(businessUnit: IBusinessUnit): void;
  onViewRecentClick(): void;
}

export const BusinessUnitListFC: React.FC<BusinessUnitListProps> = props => {
  const isSearchMode =
    props.query && props.query.toString().length >= MIN_CHARS_FOR_SEARCH;

  return (
    <div className={styles.listContainer}>
      {isSearchMode && (
        <span
          onClick={props.onViewRecentClick}
          className={styles.clearSearchLink}
        >
          View all marketers
        </span>
      )}
      {!isSearchMode && (
        <div>
          <h5 className={styles.listHeader}>Recently Visited</h5>
          {props.recentBusinessUnits && props.recentBusinessUnits.length > 0 && (
            <ul className={styles.list}>
              {props.recentBusinessUnits.map(bu => (
                <BusinessUnitFC
                  key={bu.id}
                  businessUnit={bu}
                  onClick={props.onBusinessUnitSelect}
                  query={isSearchMode ? props.query : null}
                  selectedBusinessUnitId={props.selectedBusinessUnit.id}
                />
              ))}
            </ul>
          )}
          <hr className={styles.hr} />
          <h5 className={styles.listHeader}>All Marketers</h5>
        </div>
      )}
      {props.businessUnits && props.businessUnits.length > 0 && (
        <div>
          <ul className={styles.list}>
            {props.businessUnits.map(bu => (
              <BusinessUnitFC
                key={bu.id}
                businessUnit={bu}
                onClick={props.onBusinessUnitSelect}
                query={isSearchMode ? props.query : null}
                selectedBusinessUnitId={props.selectedBusinessUnit.id}
              />
            ))}
          </ul>
        </div>
      )}
      {(!props.businessUnits || !props.businessUnits.length) && (
        <Alert color="info" style={{ marginBottom: "0" }}>
          {props.query
            ? "No business units match your search"
            : "No business units"}
        </Alert>
      )}
    </div>
  );
};
