import { AF, AsyncAF } from "../../../lib/actionFactoryLib";
import { CrmMapping, PartialCrmMapping } from "../../api/attributeApi";

export const openCreateModalAF = new AF<{}>("CRM_OPEN_CREATE_MODAL");

export const closeCreateModalAF = new AF<{}>("CRM_CLOSE_CREATE_MODAL");

export const createCrmMappingAF = new AsyncAF<PartialCrmMapping, CrmMapping>(
  "CREATE_CRM_MAPPING"
);
