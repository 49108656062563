import { AF, AsyncAF } from "../../../lib/actionFactoryLib";
import { ScoreSegment, PartialScoreSegment } from "../../api/attributeApi";

export const openCreateModalAF = new AF<{}>("SCORE_OPEN_CREATE_MODAL");

export const closeCreateModalAF = new AF<{}>("SCORE_CLOSE_CREATE_MODAL");

export const createScoreSegmentAF = new AsyncAF<
  { score: PartialScoreSegment; formName: string },
  ScoreSegment
>("CREATE_SCORE");

export const createSetIsMax = new AF<{ isMax: boolean }>("CREATE_SET_IS_MAX");
