import { default as React } from "react";
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Field, Form, InjectedFormProps } from "redux-form";
import { ExternalSegment, externalSegmentProps } from "../../api/attributeApi";
import { FormGroupInputField } from "../../forms/components/FormGroupInputField/FormGroupInputField";

export interface StateProps {
  updating: boolean;
  dataType: string;
  externalSegment: ExternalSegment;
  initialValues: Partial<ExternalSegment>;
  allSegments: ExternalSegment[];
}

export interface InjectedProps
  extends Props,
    InjectedFormProps<ExternalSegment, Props, string> {}
export interface Props extends StateProps, DispatchProps {}

export interface DispatchProps {
  closeModal(): void;
}

export const ExternalSegmentUpdateModalFC: React.FC<InjectedProps> = props => {
  return (
    <Modal isOpen={true} toggle={props.closeModal}>
      <Form onSubmit={props.handleSubmit}>
        <ModalHeader toggle={props.closeModal}>Edit CRM Segment</ModalHeader>
        <ModalBody>
          <Field
            id="update_external_segment_name"
            label="Segment Name"
            required
            name={externalSegmentProps.name}
            component={FormGroupInputField}
            type="text"
            data-qa-id="external_segment-name-field"
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="link"
            onClick={props.closeModal}
            disabled={props.updating}
          >
            Cancel
          </Button>
          <Button
            color={"primary"}
            disabled={!props.valid || props.updating}
            onClick={props.handleSubmit}
            data-qa-id="external_segment-update-submit"
          >
            Submit
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
