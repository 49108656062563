import { AsyncAF } from "../../../lib/actionFactoryLib";
import { ScoreBucket } from "../../api/audienceApi";
import { ScoreSegment } from "../../api/attributeApi";
import { Audience } from "../../models/audienceModels";

export const readAllScoreBucketsAF = new AsyncAF<
  { model: string },
  ScoreBucket[]
>("SCORE_READ_ALL_BUCKETS");

export const readScoreSegmentByIdAF = new AsyncAF<
  { attributeId: string },
  ScoreSegment
>("READ_SCORE_SEGMENT_BY_ID");

export const readRootScoreSegmentsAF = new AsyncAF<{}, ScoreSegment[]>(
  "READ_ROOT_SCORE_SEGMENTS"
);

export const readAudiencesUsingAttributeAF = new AsyncAF<
  { attributeIds: string[] },
  Audience[]
>("READ_AUDIENCES_USING_SCORE_SEGMENT_ATTRIBUTES");
