import { default as React } from "react";
import { HeaderActionMenuFC } from "./HeaderActionMenuComponent";
import { BusinessUnitSelectorToggleFC } from "./BusinessUnitSelectorToggleComponent";
import { BusinessUnitSelectorContentFC } from "./BusinessUnitSelectorContentComponent";
import { NormalizedBusinessUnit } from "../models/businessUnitModels";

export interface Props extends StateProps, DispatchProps {}

export interface StateProps {
  selectedBusinessUnit?: NormalizedBusinessUnit;
  businessUnits: NormalizedBusinessUnit[];
  recentBusinessUnits: NormalizedBusinessUnit[];
  query: string;
  query_debounced: string;
  pending: boolean;
  error: boolean;
}

export interface DispatchProps {
  onInit(): void;
  onBusinessUnitSelect(businessUnit: NormalizedBusinessUnit): void;
  onBusinessUnitSearch(query: string): void;
  onClearBusinessUnitSearch(): void;
}

export class BusinessUnitSelectorPureComponent extends React.PureComponent<
  Props
> {
  componentDidMount() {
    this.props.onInit();
  }

  render() {
    const props = this.props;

    return (
      <HeaderActionMenuFC
        divider={true}
        id="template-business-unit-selector"
        toggle={BusinessUnitSelectorToggleFC}
        content={BusinessUnitSelectorContentFC}
        selectedBusinessUnit={props.selectedBusinessUnit}
        businessUnits={props.businessUnits}
        recentBusinessUnits={props.recentBusinessUnits}
        onBusinessUnitSelect={props.onBusinessUnitSelect}
        onBusinessUnitSearch={props.onBusinessUnitSearch}
        onClearBusinessUnitSearch={props.onClearBusinessUnitSearch}
        query={props.query}
        query_debounced={props.query_debounced}
        pending={props.pending}
        error={props.error}
      />
    );
  }
}
