import { default as React } from "react";
import { HeaderActionMenuFC } from "./HeaderActionMenuComponent";
import { GravatarContainer } from "../containers/GravatarContainer";
import { NavNode } from "../models/navigationModels";

export interface UserNavProps {
  navigation: NavNode[];
}

export const UserNavFC: React.FC<UserNavProps> = props => {
  return (
    <HeaderActionMenuFC
      id="template-user-nav"
      toggle={GravatarContainer}
      navigation={props.navigation}
    />
  );
};
