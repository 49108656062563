import { default as React } from "react";
import { NormalizedBusinessUnit } from "../models/businessUnitModels";
import { Spinner } from "reactstrap";
import { faBuilding, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./BusinessUnitSelectorToggleComponent.module.scss";

export interface BusinessUnitSelectorToggleProps {
  selectedBusinessUnit?: NormalizedBusinessUnit;
  pending: boolean;
  error: boolean;
}

export const BusinessUnitSelectorToggleFC: React.FC<
  BusinessUnitSelectorToggleProps
> = props => {
  if (props.pending || props.error || !props.selectedBusinessUnit) {
    return (
      <div>
        <Spinner size={"sm"} className={"text-white"} />
      </div>
    );
  }

  return (
    <div
      className={styles.toggleContainer}
      data-qa-id={"selected-business-unit"}
      data-business-unit-id={props.selectedBusinessUnit.id}
    >
      {!(
        props.selectedBusinessUnit.thumbnail || props.selectedBusinessUnit.logo
      ) && (
        <span className={styles.iconContainer}>
          <FontAwesomeIcon icon={faBuilding} className={styles.icon} />
        </span>
      )}
      {(props.selectedBusinessUnit.thumbnail ||
        props.selectedBusinessUnit.logo) && (
        <img
          src={
            props.selectedBusinessUnit.thumbnail ||
            props.selectedBusinessUnit.logo
          }
          alt="thumbnail"
          className={styles.thumbnail}
        />
      )}
      <span className={styles.name} data-qa-id={"selected-business-unit-name"}>
        {props.selectedBusinessUnit.name}
      </span>
      <FontAwesomeIcon icon={faCaretDown} className={styles.caret} />
    </div>
  );
};

BusinessUnitSelectorToggleFC.defaultProps = {
  selectedBusinessUnit: undefined
};
