import { default as React } from "react";
import { Helmet } from "react-helmet";
import { HomeContainer } from "../containers/HomeContainer";

export const HomePage: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Audience Console</title>
      </Helmet>
      <HomeContainer />
    </React.Fragment>
  );
};
