import {
  connectWithLifecycle,
  LifecycleDispatchProps
} from "react-lifecycle-component";
import { State } from "../../reducers";
import { Dispatch } from "redux";
import { reduxForm, formValueSelector } from "redux-form";
import {
  AttributeValue,
  PartialCrmMapping,
  crmProps,
  attributeProps
} from "../../api/attributeApi";
import {
  closeCreateModalAF,
  createCrmMappingAF
} from "../actions/createActions";
import { getCrmMappings, isFormSubmitted } from "../selectors/crmSelectors";
import {
  StateProps,
  DispatchProps,
  Props,
  CrmMappingCreateModalFC
} from "../components/CrmMappingCreateModalComponent";

const FORM_NAME = "crm_mapping_create";
const formSelector = formValueSelector(FORM_NAME);

function mapStateToProps(state: State): StateProps {
  return {
    isFormSubmit: isFormSubmitted(state),
    dataType: formSelector(
      state,
      `${crmProps.attribute}.${attributeProps.data_type_name}`
    ),
    initialValues: {
      attribute: {
        attribute_values: [] as AttributeValue[],
        data_type_name: "string"
      }
    },
    allMappings: getCrmMappings(state)
  };
}

function mapDispatchToProps(
  dispatch: Dispatch
): DispatchProps & LifecycleDispatchProps<Props> {
  return {
    componentDidMount() {},
    closeModal() {
      dispatch(closeCreateModalAF.create({}, {}));
    }
  };
}

const lifecycleWrapper = connectWithLifecycle(
  mapStateToProps,
  mapDispatchToProps
);
const formWrapper = reduxForm<PartialCrmMapping, Props>({
  form: FORM_NAME,
  onSubmit(mapping, dispatch) {
    dispatch(createCrmMappingAF.create(mapping, {}));
  }
});

export const CrmMappingCreateModalContainer = lifecycleWrapper(
  formWrapper(CrmMappingCreateModalFC)
);
