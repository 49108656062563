import { normalize } from "normalizr";
import { env } from "../../ac/env/appEnv";
import {
  NormalizedBusinessUnitSelectorData,
  businessUnitSelectorDataSchema,
  BusinessUnit
} from "../models/businessUnitModels";
import { NavigationData } from "../models/templateServiceModels";
import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";

const businessUnitsResource = `${
  env.REACT_APP_TEMPLATE_URL
}/businessunitselectordata`;
const recentBusinessUnitsResource = `${
  env.REACT_APP_TEMPLATE_URL
}/recentbusinessunits`;
const navigationResource = `${env.REACT_APP_TEMPLATE_URL}/navigation`;

export function fetchBusinessUnits(): Observable<
  NormalizedBusinessUnitSelectorData
> {
  return ajax({
    url: businessUnitsResource,
    crossDomain: true,
    withCredentials: true
  }).pipe(
    map(response => {
      return normalize(
        response.response,
        businessUnitSelectorDataSchema
      ) as NormalizedBusinessUnitSelectorData;
    })
  );
}

export function updateRecentBusinessUnits(
  businessUnitId: number
): Observable<BusinessUnit[]> {
  return ajax({
    url: recentBusinessUnitsResource,
    method: "POST",
    body: businessUnitId,
    crossDomain: true,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json"
    }
  }).pipe(
    map(response => {
      return response.response as BusinessUnit[];
    })
  );
}

export function fetchNavigation(
  businessUnitId: number
): Observable<NavigationData> {
  return ajax({
    url: `${navigationResource}?entityTypeId=4&entityId=${businessUnitId}`,
    crossDomain: true,
    withCredentials: true
  }).pipe(
    map(response => {
      return response.response as NavigationData;
    })
  );
}
