import { default as React } from "react";
import {
  ConditionBuilderBaseProps,
  inputIds,
  qaIds,
  baseStyles
} from "../SharedData";
import { CustomInput, Input } from "reactstrap";
import { AttributeOperator } from "../../../api/attributeApi";
import { NOT_EXISTS } from "../../../constants/operatorConstants";

export const FrequencyBuilder: React.FC<ConditionBuilderBaseProps> = (
  props: ConditionBuilderBaseProps
) => (
  <>
    <div key="frequency-operator" className={baseStyles.itemMarginClass}>
      <CustomInput
        id={inputIds.operatorId}
        value={props.operatorId}
        type="select"
        onChange={e => {
          props.handleChangeOperatorId(
            props.attribute,
            (e.target as any).value,
            props.value
          );
        }}
        data-qa-id={qaIds.selectedConditionOperator}
      >
        {props.attribute.attribute_operators
          .filter(a => a.id !== NOT_EXISTS)
          .map((operator: AttributeOperator) => {
            return (
              <option value={operator.id} key={operator.id}>
                {operator.name}
              </option>
            );
          })}
      </CustomInput>
    </div>
    <div key="frequency-value" className={baseStyles.itemMarginClass}>
      <Input
        id={inputIds.value}
        type="text"
        value={props.value}
        onChange={e => {
          props.handleChangeValue(
            props.attribute,
            props.operatorId,
            (e.target as any).value
          );
        }}
        data-qa-id={qaIds.selectedConditionValue}
      />
    </div>
  </>
);
