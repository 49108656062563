import { default as React } from "react";
import styles from "./HeaderBrandComponent.module.scss";

export const HeaderBrandFC: React.FC = () => {
  return (
    <div className={styles.headerBrand}>
      <img
        className={styles.logo}
        src="https://cf.ignitionone.com/loginpage/nav-logo-over-dark.svg"
        alt="IgnitionOne"
      />
    </div>
  );
};
