import { numberValidator } from "./numberValidator";
import { Validator } from "./sharedInterfaces";
import { dateValidator } from "./dateValidator";
import {
  X_DAYS_OR_LESS_AGO,
  X_DAYS_OR_MORE_AGO
} from "../../constants/operatorConstants";
import { FullAttribute } from "../../api/attributeApi";

function validateSubsetValue(
  builderId: string,
  newValue: string,
  oldValue: string,
  operatorId: string,
  attribute: FullAttribute
): boolean {
  if (
    operatorId === X_DAYS_OR_LESS_AGO.toString() ||
    operatorId === X_DAYS_OR_MORE_AGO.toString()
  ) {
    return numberValidator.validateValue(
      builderId,
      newValue,
      oldValue,
      operatorId,
      attribute
    );
  }
  return dateValidator.validateValue(
    builderId,
    newValue,
    oldValue,
    operatorId,
    attribute
  );
}

export const existsNotExistsValidator: Validator = {
  validateValue: numberValidator.validateValue,
  validateSubsetValue: validateSubsetValue
};
