import { Validator } from "./sharedInterfaces";
import {
  FALSE_VALUE,
  TRUE_VALUE
} from "../../constants/attributeDataTypeNameConstants";

function validateValue(builderId: string, newValue: string): boolean {
  if (newValue === null || newValue === undefined) {
    return false;
  }
  return newValue === FALSE_VALUE || newValue === TRUE_VALUE;
}

export const booleanValidator: Validator = {
  validateValue
};
