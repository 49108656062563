import { combineReducers } from "redux";
import { userReducer as user, UserState } from "./userReducer";
import {
  permissionsReducer as permissions,
  PermissionsState
} from "./permissionReducer";

export interface AuthState {
  readonly user?: UserState;
  readonly permissions?: PermissionsState;
}

// state as consumed by external users
export interface State {
  readonly auth?: AuthState;
}

export const rootReducer = combineReducers<AuthState>({
  user,
  permissions
});
