import { createSelector } from "reselect";
import { default as md5 } from "md5";
import { collectionMatch } from "../../lib/searchLib";
import { getUserName } from "../../auth/selectors/userSelectors";
import { MIN_CHARS_FOR_SEARCH } from "../constants/templateConstants";
import { State } from "../reducers";

export function getBusinessUnitById(state: State, id: number) {
  return state.template.businessUnits.byId[id] || null;
}

export function getSelectedBusinessUnit(state: State) {
  const selectedId = state.template.businessUnits.selectedId;
  return getBusinessUnitById(state, selectedId);
}

export function getSelectedBusinessUnitId(state: State) {
  const businessUnit = getSelectedBusinessUnit(state);
  return businessUnit ? businessUnit.id : null;
}

export function getSelectedBusinessUnitName(state: State) {
  const businessUnit = getSelectedBusinessUnit(state);
  return businessUnit ? businessUnit.name : null;
}

export function getBusinessUnits(state: State) {
  const allIds = state.template.businessUnits.allIds;
  return allIds.map(id => {
    return getBusinessUnitById(state, id);
  });
}

export function getRecentBusinessUnits(state: State) {
  const recentIds = state.template.businessUnits.recentIds;
  return recentIds.map(id => {
    return getBusinessUnitById(state, id);
  });
}

export function getBusinessUnitSearchQuery(state: State) {
  return state.template.businessUnits.query;
}

export function getBusinessUnitSearchQueryDebounced(state: State) {
  return state.template.businessUnits.query_debounced;
}

export const getBusinessUnitsOrderedByName = createSelector(
  getBusinessUnits,
  businessUnits => {
    return businessUnits.slice(0).sort((a, b) => {
      const lowerA = a.name.toLowerCase();
      const lowerB = b.name.toLowerCase();
      if (lowerA === lowerB) {
        return 0;
      }
      return lowerA < lowerB ? -1 : 1;
    });
  }
);

export const getBusinessUnitSearchResults = createSelector(
  getBusinessUnitsOrderedByName,
  getBusinessUnitSearchQueryDebounced,
  (businessUnits, query) => {
    if (!query || query.toString().length < MIN_CHARS_FOR_SEARCH) {
      return businessUnits;
    }

    return collectionMatch(businessUnits, query, "id", "name", "path");
  }
);

export const getUserHash = createSelector(
  getUserName,
  username => {
    if (!username) {
      return undefined;
    }

    return md5(username);
  }
);

export function getBusinessUnitsPending(state: State): boolean {
  return state.template.businessUnits.pending;
}

export function getBusinessUnitsError(state: State): boolean {
  return state.template.businessUnits.error;
}
