import { default as React } from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from "reactstrap";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const NoAccessFC: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>No Access</title>
      </Helmet>
      <Row>
        <Col>
          <h1>No Access</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <span data-qa-id="no-access-message">
            <FontAwesomeIcon icon={faBan} size="lg" className={"text-danger"} />
            &nbsp; You do not have permission to view this page. Contact an
            account admin if you need assistance.
          </span>
        </Col>
      </Row>
    </React.Fragment>
  );
};
