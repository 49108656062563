import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  getFilteredAudiences,
  getPendingAsyncOperationCount,
  getAudiencesPageSize,
  getNumAudiences,
  getAudiencesSearch,
  getAudiencesSort,
  getAudiencesFilter,
  getSuggestions
} from "../selectors/audienceSelectors";
import {
  clearAudiencesQueryAF,
  deleteAudienceAF,
  readAllLabelsAF,
  refreshAllAudiencesAF,
  resetAudiencesStateAF,
  setAudiencesPageSizeAF,
  setAudiencesQueryAF,
  setAudiencesSortAF,
  setAudiencesFilterLabelsAF,
  updateLabelsAF
} from "../actions/audienceActions";
import { AUDIENCE_ADD } from "../constants/pathConstants";
import { Audience } from "../models/audienceModels";
import {
  HomeComponent,
  StateProps,
  DispatchProps
} from "../components/HomeComponent";
import { shortNamesToIds } from "../constants/permissionConstants";
import { State } from "../reducers";
import { getPermissionEnabled } from "../../auth/selectors/permissionSelectors";
import { getSelectedBusinessUnit } from "../../template/selectors/businessUnitSelectors";
import { push } from "../../template/actions/trackedDataActions";
import { readAttributeForestAF } from "../actions/attributeActions";

function mapStateToProps(state: State): StateProps {
  const hasWriteAccess = getPermissionEnabled(
    state,
    shortNamesToIds.write_audience_console,
    getSelectedBusinessUnit(state).id
  );

  return {
    audiences: getFilteredAudiences(state),
    showLoadingIndicator: getPendingAsyncOperationCount(state) > 0,
    pageSize: getAudiencesPageSize(state),
    totalAudiences: getNumAudiences(state),
    businessUnitId: getSelectedBusinessUnit(state).id,
    query: getAudiencesSearch(state).query.toString(), // TODO: query can be number or string? is it safe to toString()?
    sort: getAudiencesSort(state),
    filter: getAudiencesFilter(state),
    manualLoadMoreThreshold: 50,
    enableCrudColumn: hasWriteAccess,
    enableAddAudience: hasWriteAccess,
    suggestions: getSuggestions(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    onAddClick() {
      dispatch(push(AUDIENCE_ADD));
    },
    onInit() {
      dispatch(resetAudiencesStateAF.create({}, {}));
      dispatch(refreshAllAudiencesAF.create({}, {}));
      dispatch(readAllLabelsAF.create({}, {}));
    },
    onSearchChange(searchText: string) {
      dispatch(setAudiencesQueryAF.create({ query: searchText }, {}));
    },
    onSearchClear() {
      dispatch(clearAudiencesQueryAF.create({}, {}));
    },
    onSort(sortKey: string) {
      dispatch(setAudiencesSortAF.create({ sortKey }, {}));
    },
    onFilterSet(labels: string[]) {
      dispatch(setAudiencesFilterLabelsAF.create(labels, {}));
    },
    onAudienceDelete(audienceId: number) {
      dispatch(deleteAudienceAF.create({ audienceId }, {}));
    },
    getAttributes() {
      dispatch(readAttributeForestAF.create({}, {}));
    },
    onUpdateLabels(labels: string[], audience: Audience) {
      dispatch(updateLabelsAF.create({ labels, audience }, {}));
    },
    handleLoadMore(pageSize: number) {
      // TODO: This feels like business logic. Should this be in container, reducer, or saga?
      if (pageSize < 50) {
        dispatch(
          setAudiencesPageSizeAF.create({ pageSize: pageSize + 10 }, {})
        );
      } else {
        dispatch(
          setAudiencesPageSizeAF.create({ pageSize: pageSize + 20 }, {})
        );
      }
    }
  };
}

export const HomeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeComponent);
