import { default as React } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

export interface IoPillProps {
  icon?: IconProp;
  className?: string;
}

export const IoPillFC: React.FC<IoPillProps> = props => {
  const { children, icon, className } = props;

  return (
    <div className={classNames("badge badge-pill px-4 py-2 border", className)}>
      <span className={"text-muted h5"}>
        {icon && (
          <span>
            <FontAwesomeIcon icon={icon} />
            &nbsp;
          </span>
        )}
        <span>{children}</span>
      </span>
    </div>
  );
};

IoPillFC.defaultProps = {
  icon: undefined,
  className: undefined
};
