import { default as React } from "react";
import { EXISTS } from "../../constants/operatorConstants";
import {
  baseStyles,
  ConditionBuilderBaseProps,
  qaIds,
  baseDefaultProps,
  inputIds
} from "./SharedData";
import { AttributeName } from "../AttributeName";
import { CustomInput } from "reactstrap";

export interface TopInterestProps
  extends StateProps,
    DispatchProps,
    ConditionBuilderBaseProps {}

export interface StateProps {
  attributeLevels?: number[];
  attributeLevel?: number;
  attributeCategories?: any[];
}

export interface DispatchProps {
  handleChangeLevel?(level: number): void;
}

export const TopInterestFC: React.FC<TopInterestProps> = props => {
  const exists = EXISTS.toString();
  const isCategoryDropdownDisabled =
    props.attributeLevel === undefined || Number.isNaN(props.attributeLevel);

  return (
    <div className={baseStyles.conditionContainerClass}>
      <div className={baseStyles.itemMarginClass}>
        <AttributeName
          attribute={props.attribute}
          qaNameId={qaIds.selectedConditionName}
          qaTooltipId={qaIds.selectedConditionTooltipPath}
          qaTooltipIconId={qaIds.selectedConditionTooltipIcon}
          qaTooltipPathId={qaIds.selectedConditionTooltipPath}
        />
      </div>
      {props.attribute.attribute_operators.length > 0 && (
        <div className={baseStyles.itemMarginClass}>
          <CustomInput
            id={inputIds.operatorId}
            type={"select"}
            value={props.operatorId}
            onChange={e => {
              props.handleChangeOperatorId(
                props.attribute,
                (e.currentTarget as any).value,
                props.value
              );
            }}
            data-qa-id={qaIds.selectedConditionOperator}
          >
            {props.attribute.attribute_operators.map((operator: any) => {
              return (
                <option value={operator.id} key={operator.id}>
                  {operator.name}
                </option>
              );
            })}
          </CustomInput>
        </div>
      )}
      {props.operatorId !== exists && (
        <React.Fragment>
          <div className={baseStyles.itemMarginClass}>
            <CustomInput
              id={"attribute-level"}
              type="select"
              data-qa-id="selected-condition-level"
              value={props.attributeLevel}
              onChange={e => {
                if (props.handleChangeLevel) {
                  props.handleChangeLevel(+(e.currentTarget as any).value);
                  props.handleChangeValue(
                    props.attribute,
                    props.operatorId,
                    ""
                  );
                }
              }}
            >
              <option>Select level</option>
              {props.attributeLevels.map(level => {
                return (
                  <option value={level} key={level}>
                    {level}
                  </option>
                );
              })}
            </CustomInput>
          </div>
          <div className={baseStyles.itemMarginClass}>
            <CustomInput
              id={inputIds.value}
              type="select"
              data-qa-id={qaIds.selectedConditionValue}
              value={props.value}
              disabled={isCategoryDropdownDisabled}
              onChange={e => {
                props.handleChangeValue(
                  props.attribute,
                  props.operatorId,
                  (e.currentTarget as any).value
                );
              }}
            >
              <option>Select category</option>
              {props.attributeCategories.map(category => {
                return (
                  <option value={category.id} key={category.id}>
                    {category.name}
                  </option>
                );
              })}
            </CustomInput>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

TopInterestFC.defaultProps = {
  ...baseDefaultProps,
  attributeLevels: [] as number[],
  attributeLevel: undefined as number,
  attributeCategories: [] as any[]
};
