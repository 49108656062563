export enum HttpHeaders {
  BusinessUnitId = "X-IgnitionOne-BusinessUnitId",
  Audience_id = "X-IgnitionOne-AudienceId",
  Token = "X-IgnitionOne-Token",
  ContentType = "Content-Type"
}

export enum ContentType {
  ApplicationJson = "application/json"
}
