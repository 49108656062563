import {
  CountsContainer,
  OwnProps as StateProps
} from "../counts/containers/CountsContainer";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { getExpressionBuilderQuery } from "../selectors/expressionBuilderSelector";
import { State } from "../reducers";

interface OwnProps {
  builderId: string;
  subQueryId: number;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (
  state,
  ownProps
) => {
  return {
    query: getExpressionBuilderQuery(
      state,
      ownProps.builderId,
      ownProps.subQueryId
    )
  };
};

const mapDispatchToProps: MapDispatchToProps<{}, OwnProps> = (
  dispatch,
  ownProps
) => {
  return {};
};

export const ExpressionBuilderCountContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CountsContainer);
