import { createSelector } from "reselect";
import { orderBy } from "lodash";
import { collectionMatch } from "../../lib/searchLib";
import {
  isCriterion,
  Query,
  queryToSegments
} from "../../lib/expressionBuilderLib";
import { State } from "../reducers";
import { Audience } from "../models/audienceModels";
import {
  conditionFactory,
  ExpressionSegment
} from "../models/expressionBuilderModels";
import { Condition } from "../models/conditionBuilderModels";
import { getSelectedBusinessUnit } from "../../template/selectors/businessUnitSelectors";
import { GEO_TYPE } from "../api/geoApi";
import { FullAttribute } from "../api/attributeApi";

export function getAudiences(state: State) {
  const businessUnitId = getSelectedBusinessUnit(state).id;
  const audiences = state.audiences.byBusinessUnitId[businessUnitId];

  return audiences
    ? audiences.allIds.map((id: number) => {
        return state.audiences.byBusinessUnitId[businessUnitId].byId[id] !==
          undefined
          ? state.audiences.byBusinessUnitId[businessUnitId].byId[id]
          : ({} as Audience);
      })
    : [];
}

export const getStartingPopsForAudience: (
  state: State,
  audienceId: number
) => Audience[] = createSelector(
  getAudiences,
  (state: State, audienceId: number) => {
    return audienceId;
  },
  (audiences, audienceId) => {
    return audiences.filter(a => {
      return a.starting_population_id === 0 && a.audience_id !== audienceId;
    });
  }
);

export function getSuggestions(state: State): string[] {
  return state.audiences.suggestions;
}

export function getNumAudiences(state: State) {
  const businessUnitId = getSelectedBusinessUnit(state).id;
  const audiences = state.audiences.byBusinessUnitId[businessUnitId];

  return audiences ? audiences.allIds.length : 0;
}

export function getAudiencesPageSize(state: State) {
  return state.audiences.pageSize;
}

export function getAudiencesSort(state: State) {
  return state.audiences.sort;
}

export function getAudiencesSearch(state: State) {
  return state.audiences.search;
}

export function getAudiencesFilter(state: State) {
  return state.audiences.filter;
}

export const getFilteredAudiences = createSelector(
  getAudiences,
  getAudiencesPageSize,
  getAudiencesSort,
  getAudiencesSearch,
  getAudiencesFilter,
  (audiences, pageSize, sort, search, filter) => {
    let searchResults = collectionMatch(
      audiences,
      search.query,
      ...(search.iteratees as any)
    );
    if (filter.labels.length !== 0) {
      searchResults = searchResults.filter(audience =>
        filter.labels.every(filterAudienceLabel =>
          audience.labels.includes(filterAudienceLabel)
        )
      );
    }
    return orderBy(searchResults, sort.iteratees, sort.orders);
  }
);

export function getAudience(state: State, id: number) {
  const businessUnitId = getSelectedBusinessUnit(state).id;
  const audiences = state.audiences.byBusinessUnitId[businessUnitId];

  return audiences ? audiences.byId[id] : (undefined as Audience);
}

export function getAudienceQuery(state: State, id: number): Query {
  const businessUnitId = getSelectedBusinessUnit(state).id;
  const audiences = state.audiences.byBusinessUnitId[businessUnitId];

  return audiences && audiences.byId[id] && audiences.byId[id].query_id !== 0
    ? audiences.byId[id].query
    : undefined;
}

export function getStartingPop(state: State, id: number) {
  const businessUnitId = getSelectedBusinessUnit(state).id;
  const audiences = state.audiences.byBusinessUnitId[businessUnitId];
  const audience = audiences ? audiences.byId[id] : undefined;
  return audience ? audiences.byId[audience.starting_population_id] : undefined;
}

export function getPendingAsyncOperationCount(state: State) {
  return state.asyncOperations.count;
}

export function getAttributes(state: State) {
  return state.attributes;
}
export function getAttribute(state: State) {
  return state.attribute;
}

export function getAttributeByBusinessUnitId(state: State) {
  const businessUnitId = getSelectedBusinessUnit(state).id;
  const attributeData = getAttribute(state);

  return attributeData[businessUnitId];
}

export function getAttributesByBusinessUnitId(state: State) {
  const businessUnitId = getSelectedBusinessUnit(state).id;
  const attributes = getAttributes(state);

  return attributes[businessUnitId];
}

export function getAttributeById(state: State, attributeId: string) {
  const attributes = getAttributesByBusinessUnitId(state);
  return attributes && attributes.byId
    ? attributes.byId[attributeId]
    : undefined;
}

export function getFullAttributeById(state: State, attributeId: string) {
  const attributeData = getAttributeByBusinessUnitId(state);
  return attributeData && attributeData.byId
    ? attributeData.byId[attributeId]
    : undefined;
}

export function getRootAttribute(state: State) {
  const { rootId } = getAttributesByBusinessUnitId(state);
  return getAttributeById(state, rootId);
}

export function getExpressionSegmentsFromAudience(
  state: State,
  audienceId: number
): ExpressionSegment[] {
  const audience = getAudience(state, audienceId);
  if (!audience) {
    return [];
  }

  let allAttributesExisted = true;

  let segments = audience.query ? queryToSegments(audience.query, false) : [];
  segments = segments.map(criterion => {
    if (isCriterion(criterion)) {
      const attribute = getFullAttributeById(
        state,
        criterion.id
      ) as FullAttribute;

      if (!attribute) {
        allAttributesExisted = false;
        return criterion;
      }

      const operator = attribute.attribute_operators.find(
        o => o.graphql_value === criterion.operator
      );

      const subsetOperator = attribute.attribute_subset_operators.find(
        o => o.graphql_value === criterion.subsetOperator
      );

      if (operator) {
        const condition: Condition = {
          attribute,
          operatorId: operator.id.toString(),
          value: criterion.value,
          subsetOperatorId: subsetOperator
            ? subsetOperator.id.toString()
            : undefined,
          channel: criterion.channel,
          subsetValue: criterion.subsetValue
        };

        return conditionFactory(condition, false);
      }
      console.error(
        `Graphql operator '${criterion.operator}' did not exist on attribute ${
          attribute.name
        }`
      );
    }

    return criterion;
  });

  return allAttributesExisted ? (segments as ExpressionSegment[]) : [];
}

export function getGeoLocationName(
  state: State,
  geoId: string,
  geoType: GEO_TYPE
) {
  return (
    state.geoLocation.byTypeId[geoType] &&
    state.geoLocation.byTypeId[geoType][geoId]
  );
}
