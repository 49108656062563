import { env } from "../../ac/env/appEnv";
import { User } from "../models/authModels";
import { Observable, of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";

export interface Entity {
  entityId: number;
  entityTypeId: number;
}

export interface Permission {
  permissionName: string;
  permissionShortName: string;
  permissionId: number;
  featureId: number;
  userHasPermission: number;
  entityHasPermission: number;
  allow: boolean;
}

export interface EntityMap {
  [entityId: number]: NormalizedEntity;
}

export interface NormalizedEntity extends Entity {
  permissions: { [permissionId: number]: Permission };
}

export interface ApiEntity extends Entity {
  permissions: Permission[];
}

const FAKE_USER: User = {
  ClientId: 1,
  Expiration: "Expiration",
  Key: "Key",
  LandingPage: "LandingPage",
  LanguageId: 1,
  LoginAnnouncementFlag: false,
  PasswordExpired: false,
  Permissions: "Permissions",
  Token: "Token",
  UserId: 1,
  UserName: "john.mitchell@ignitionone.com"
};

export function authenticateUser(): Observable<User> {
  if (env.REACT_APP_ENABLE_AUTH === "true") {
    return ajax({
      url: env.REACT_APP_AUTH_URL,
      crossDomain: true,
      withCredentials: true,
      headers: {}
    }).pipe(
      map(response => {
        return response.response as User;
      })
    );
  }
  return of(FAKE_USER);
}

export function getPermissionsForEntityId(
  entityId: number,
  permissions: number[],
  userId: number
): Observable<EntityMap> {
  const permissionQuery = permissions
    .map(id => {
      return `&permissionIds=${id}`;
    })
    .join("");
  return ajax({
    url: `${
      env.REACT_APP_USER_SERVICE_URL
    }/users/${userId}/permissions?entityIds=${entityId}${permissionQuery}`,
    crossDomain: true,
    withCredentials: true,
    headers: {
      ApiKey: env.REACT_APP_USER_SERVICE_API_KEY
    }
  }).pipe(
    map(response => {
      const entities: ApiEntity[] = response.response;
      return entities.reduce(
        (entityMap: { [entityId: number]: NormalizedEntity }, entity) => {
          entityMap[entity.entityId] = {
            ...entity,
            permissions: entity.permissions.reduce(
              (permMap: { [permId: number]: Permission }, perm) => {
                permMap[perm.permissionId] = {
                  ...perm
                };
                return permMap;
              },
              {}
            )
          };
          return entityMap;
        },
        {}
      );
    })
  );
}
