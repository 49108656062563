import { Action } from "redux";
import {
  closeUpdateModalAF,
  openUpdateModalAF,
  updateExternalSegmentAF
} from "../actions/externalSegmentUpdateActions";
import { ExternalSegment } from "../../api/attributeApi";

export interface State {
  showModal: boolean;
  updating: boolean;
  externalSegment?: ExternalSegment;
}

export const externalSegmentUpdateInitialState: State = {
  showModal: false,
  updating: false
};

export function updateReducer(
  previousState: State = externalSegmentUpdateInitialState,
  action: Action
): State {
  if (openUpdateModalAF.isAction(action)) {
    return {
      ...previousState,
      showModal: true,
      externalSegment: action.payload
    };
  }
  if (closeUpdateModalAF.isAction(action)) {
    return {
      ...previousState,
      showModal: false
    };
  }
  if (updateExternalSegmentAF.pendingAF.isAction(action)) {
    return {
      ...previousState,
      updating: true
    };
  }
  if (updateExternalSegmentAF.fulfilledAF.isAction(action)) {
    return externalSegmentUpdateInitialState;
  }
  if (updateExternalSegmentAF.rejectedAF.isAction(action)) {
    return {
      ...previousState,
      updating: false
    };
  }
  return previousState;
}
