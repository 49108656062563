import { Action } from "redux";
import {
  ScoreSegment,
  scoreSegmentProps,
  attributeProps
} from "../../api/attributeApi";
import {
  readRootScoreSegmentsAF,
  readAllScoreBucketsAF,
  readScoreSegmentByIdAF,
  readAudiencesUsingAttributeAF
} from "../actions/readActions";
import {
  readScoreSegmentGoalsAF,
  setScoreSegmentsSortAF
} from "../actions/scoreActions";
import { DataPoint } from "highcharts";
import { createScoreSegmentAF } from "../actions/createActions";
import { Order } from "../../constants/sharedConstants";
import { AppContext } from "../../selectors/appContextSelectors";
import { updateScoreSegmentAF } from "../actions/updateActions";
import { deleteScoreSegmentAF } from "../actions/deleteActions";
import { Audience } from "../../models/audienceModels";

export interface State {
  isGettingScoreSegment: boolean;
  isGettingRootScoreSegments: boolean;
  scoreBuckets: DataPoint[];
  isGettingGoals: boolean;
  isGettingAudiencesUsingScoreSegment: boolean;
  audiencesUsingScoreSegments: Audience[];
  goalsByBusinessUnitId: {
    [businessUnitId: string]: ScoreSegment[];
  };
  rootsByBusinessUnitId: {
    [businessUnitId: number]: {
      byId: {
        [attributeId: string]: ScoreSegment;
      };
    };
  };
  byBusinessUnitId: {
    [businessUnitId: number]: {
      byId: {
        [attributeId: string]: ScoreSegment;
      };
    };
  };
  sort: {
    iteratees: string[];
    orders: Order[];
  };
}

export const initialState: State = {
  isGettingScoreSegment: false,
  isGettingRootScoreSegments: false,
  rootsByBusinessUnitId: {},
  byBusinessUnitId: {},
  isGettingGoals: false,
  goalsByBusinessUnitId: {},
  scoreBuckets: [],
  isGettingAudiencesUsingScoreSegment: false,
  audiencesUsingScoreSegments: [],
  sort: {
    iteratees: [`${scoreSegmentProps.attribute}.${attributeProps.modified_at}`],
    orders: ["desc"]
  }
};

export function scoreSegmentAttributeReducer(
  previousState: State = initialState,
  action: Action
): State {
  if (setScoreSegmentsSortAF.isAction(action)) {
    const prevSortKey = previousState.sort.iteratees[0];
    const prevOrder = previousState.sort.orders[0];
    const nextSortKey = action.payload.sortKey;
    let nextOrder: Order;

    if (prevSortKey === nextSortKey) {
      if (prevOrder === "asc") {
        nextOrder = "desc";
      } else {
        nextOrder = "asc";
      }
    } else {
      nextOrder = "asc";
    }

    return {
      ...previousState,
      sort: {
        ...previousState.sort,
        iteratees: [nextSortKey],
        orders: [nextOrder]
      }
    };
  }
  if (
    (createScoreSegmentAF.fulfilledAF.isAction(action) ||
      updateScoreSegmentAF.fulfilledAF.isAction(action)) &&
    stateExists(previousState, action.meta.context)
  ) {
    return {
      ...previousState,
      rootsByBusinessUnitId: {
        ...previousState.rootsByBusinessUnitId,
        [action.meta.context.businessUnit.id]: {
          byId: {
            ...previousState.rootsByBusinessUnitId[
              action.meta.context.businessUnit.id
            ].byId,
            [action.payload.attribute.id]: action.payload
          }
        }
      }
    };
  }
  if (
    deleteScoreSegmentAF.fulfilledAF.isAction(action) &&
    stateExists(previousState, action.meta.context)
  ) {
    const byId = {
      ...previousState.rootsByBusinessUnitId[
        action.meta.context.businessUnit.id
      ].byId
    };
    delete byId[action.payload.attribute.id];

    return {
      ...previousState,
      rootsByBusinessUnitId: {
        ...previousState.rootsByBusinessUnitId,
        [action.meta.context.businessUnit.id]: {
          byId
        }
      }
    };
  }
  if (readRootScoreSegmentsAF.fulfilledAF.isAction(action)) {
    const currentById = previousState.rootsByBusinessUnitId[
      action.meta.context.businessUnit.id
    ]
      ? previousState.rootsByBusinessUnitId[action.meta.context.businessUnit.id]
          .byId
      : {};

    return {
      ...previousState,
      isGettingRootScoreSegments: false,
      rootsByBusinessUnitId: {
        [action.meta.context.businessUnit.id]: {
          byId: action.payload.reduce(
            (nextById, scoreSegment) => {
              nextById[scoreSegment.attribute.id] = scoreSegment;
              return nextById;
            },
            {
              ...currentById
            }
          )
        }
      }
    };
  }
  if (readRootScoreSegmentsAF.pendingAF.isAction(action)) {
    return {
      ...previousState,
      isGettingRootScoreSegments: true
    };
  }
  if (readRootScoreSegmentsAF.rejectedAF.isAction(action)) {
    return {
      ...previousState,
      isGettingRootScoreSegments: false
    };
  }
  if (readScoreSegmentByIdAF.fulfilledAF.isAction(action)) {
    const currentById = previousState.rootsByBusinessUnitId[
      action.meta.context.businessUnit.id
    ]
      ? previousState.rootsByBusinessUnitId[action.meta.context.businessUnit.id]
          .byId
      : {};
    const children = action.payload.children;

    children.sort((a, b) => {
      return a.min - b.min;
    });

    return {
      ...previousState,
      isGettingScoreSegment: false,
      byBusinessUnitId: {
        [action.meta.context.businessUnit.id]: {
          byId: {
            ...currentById,
            [action.payload.attribute.id]: {
              ...action.payload,
              children
            }
          }
        }
      }
    };
  }
  if (readScoreSegmentByIdAF.pendingAF.isAction(action)) {
    return {
      ...previousState,
      isGettingScoreSegment: true
    };
  }
  if (readScoreSegmentByIdAF.rejectedAF.isAction(action)) {
    return {
      ...previousState,
      isGettingScoreSegment: false
    };
  }
  if (readScoreSegmentGoalsAF.pendingAF.isAction(action)) {
    return {
      ...previousState,
      isGettingGoals: true
    };
  }
  if (readScoreSegmentGoalsAF.fulfilledAF.isAction(action)) {
    return {
      ...previousState,
      isGettingGoals: false,
      goalsByBusinessUnitId: {
        ...previousState.goalsByBusinessUnitId,
        [action.meta.context.businessUnit.id]: action.payload
      }
    };
  }
  if (readScoreSegmentGoalsAF.rejectedAF.isAction(action)) {
    return {
      ...previousState,
      isGettingGoals: false
    };
  }
  if (readAllScoreBucketsAF.fulfilledAF.isAction(action)) {
    // TODO: so once we leave here, the outliers are thrown out.
    // This is probably fine for now, but for future reference,
    // you could use a memoized selector for removing top scores,
    // that way you can still store the raw data in state for reference,
    // but only clean up that data if the state that selector depends on changes.
    return {
      ...previousState,
      scoreBuckets: removeTopScores(
        action.payload.map(hit => {
          return {
            x: hit.key,
            y: hit.doc_count
          };
        })
      )
    };
  }
  if (readAllScoreBucketsAF.rejectedAF.isAction(action)) {
    return {
      ...previousState,
      isGettingGoals: false
    };
  }
  if (readAudiencesUsingAttributeAF.pendingAF.isAction(action)) {
    return {
      ...previousState,
      isGettingAudiencesUsingScoreSegment: true
    };
  }
  if (readAudiencesUsingAttributeAF.fulfilledAF.isAction(action)) {
    return {
      ...previousState,
      isGettingAudiencesUsingScoreSegment: false,
      audiencesUsingScoreSegments: action.payload
    };
  }
  if (readAudiencesUsingAttributeAF.rejectedAF.isAction(action)) {
    return {
      ...previousState,
      isGettingAudiencesUsingScoreSegment: false,
      audiencesUsingScoreSegments: []
    };
  }
  return previousState;
}

function stateExists(previousState: State, context: AppContext): boolean {
  return !!(
    previousState &&
    previousState.rootsByBusinessUnitId[context.businessUnit.id] &&
    previousState.rootsByBusinessUnitId[context.businessUnit.id].byId
  );
}

function removeTopScores(data: DataPoint[]): DataPoint[] {
  const sortedData = [...data];

  sortedData.sort((a, b) => {
    return a.x - b.x;
  });

  if (!sortedData[0] || sortedData[0].x !== 0) {
    sortedData.unshift({ x: 0, y: 1 });
  }

  if (sortedData.length < 10) {
    return sortedData;
  } else {
    const q3 = sortedData[Math.ceil(sortedData.length * (3 / 4))].y,
      iqr = q3,
      maxValue = q3 + iqr * 1.5;

    const filteredSortedData = sortedData.filter(point => point.y >= maxValue);

    // https://www.highcharts.com/errors/12/
    if (filteredSortedData.length > 999) {
      filteredSortedData.length = 999;
    }

    return filteredSortedData;
  }
}
