import { default as React } from "react";
import { ConnectedRouter } from "connected-react-router";
import { DragDropContext } from "react-dnd";
import { default as HTML5Backend } from "react-dnd-html5-backend";
import { History } from "history";
import { env } from "./env/appEnv";
import { AuthorizedRoutes } from "./routes";
import { navOverrides } from "./NavOverrides";
import { idsToShortNames } from "./constants/permissionConstants";
import { Route } from "react-router-dom";
import { ErrorMonitorContainer } from "./containers/ErrorMonitorContainer";
import { NavConfig } from "../template/models/navigationModels";
import { AuthenticatedContainer } from "../auth/containers/AuthenticatedContainer";
import { TemplateFC } from "../template/components/TemplateComponent";

export interface AppProps {
  history: History;
}

const navConfig: NavConfig = {
  navOverrides,
  globalEnvironmentPrefix: env.REACT_APP_NAV_ENVIRONMENT_PREFIX,
  globalProtocol: env.REACT_APP_NAV_PROTOCOL,
  appId: "ACV2"
};

const permissionIds = Object.keys(idsToShortNames).map(key => {
  return parseInt(key, 10);
});

class AppPureComponent extends React.PureComponent<AppProps> {
  render() {
    return (
      <React.Fragment>
        <ErrorMonitorContainer />
        <AuthenticatedContainer permissionIds={permissionIds}>
          <ConnectedRouter history={this.props.history}>
            <TemplateFC navConfig={navConfig}>
              <Route component={AuthorizedRoutes} />
            </TemplateFC>
          </ConnectedRouter>
        </AuthenticatedContainer>
      </React.Fragment>
    );
  }
}

export const AppDragDropContext = DragDropContext(HTML5Backend)(
  AppPureComponent
);
