import { default as React } from "react";
import { AttributeOperator } from "../api/attributeApi";

interface ScoreProps {
  attributeOperator: AttributeOperator;
  attributeValue: string;
  attributeSubsetOperator: AttributeOperator;
  attributeSubsetValue: string;
}

export class ScoreNameComponent extends React.PureComponent<ScoreProps> {
  render() {
    return (
      <span>
        <span>
          {`${this.props.attributeOperator.name} ${this.props.attributeValue}`}
        </span>
        {" and "}
        <span>
          {`${this.props.attributeSubsetOperator.name} to ${
            this.props.attributeSubsetValue
          }`}
        </span>
      </span>
    );
  }
}
