import { default as React } from "react";
import {
  DropTarget,
  DropTargetSpec,
  DropTargetCollector,
  ConnectDropTarget
} from "react-dnd";
import {
  OperatorSegment,
  ConditionSegment
} from "../models/expressionBuilderModels";
import { CONDITION } from "../constants/dndtypeConstants";
import { Button } from "reactstrap";
import { EB_ITEM_MARGIN } from "../constants/styleConstants";

export interface ExpressionOperatorProps extends DndTargetProps {
  operator: OperatorSegment;
  toggleExpressionOperator: Function;
  moveConditionToOperator(conditionId: string, operatorId: string): void;
}

export interface DndTargetProps {
  connectDropTarget?: ConnectDropTarget;
  isOver?: boolean;
  disabled?: boolean;
}

export const ExpressionOperatorFC: React.FC<
  ExpressionOperatorProps
> = props => {
  return props.connectDropTarget(
    <div className={EB_ITEM_MARGIN}>
      <Button
        outline={props.isOver}
        onClick={() => {
          props.toggleExpressionOperator(props.operator.id);
        }}
        disabled={props.disabled}
      >
        {props.operator.value}
      </Button>
    </div>
  );
};

const dropSpec: DropTargetSpec<ExpressionOperatorProps> = {
  drop(props, monitor) {
    switch (monitor.getItemType()) {
      case CONDITION: {
        const condition = monitor.getItem() as ConditionSegment;
        props.moveConditionToOperator(condition.id, props.operator.id);
        break;
      }
      default:
        break;
    }
  }
};

const dropCollect: DropTargetCollector<DndTargetProps> = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
};

export const ExpressionOperatorDropTarget = DropTarget(
  [CONDITION],
  dropSpec,
  dropCollect
)(ExpressionOperatorFC);
