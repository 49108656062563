import { default as React } from "react";
import {
  ConditionBuilderBaseProps,
  inputIds,
  qaIds,
  baseStyles
} from "../SharedData";
import { CustomInput, Input } from "reactstrap";
import { AttributeOperator } from "../../../api/attributeApi";
import {
  ON_OR_BEFORE,
  ON_OR_AFTER,
  EQUALS,
  X_DAYS_OR_LESS_AGO,
  X_DAYS_OR_MORE_AGO
} from "../../../constants/operatorConstants";
import { DATE_FORMAT } from "../../../constants/attributeDataTypeNameConstants";
import { default as DatePicker } from "react-datepicker";
import { default as moment } from "moment";

export const RecencyBuilder: React.FC<ConditionBuilderBaseProps> = (
  props: ConditionBuilderBaseProps
) => (
  <>
    {props.attribute.attribute_subset_operators.length > 0 && (
      <div key="recency-operator" className={baseStyles.itemMarginClass}>
        <CustomInput
          id={inputIds.subsetOperatorId}
          value={props.subsetOperatorId || ""}
          type="select"
          onChange={e => {
            props.handleChangeSubsetOperatorId(
              props.attribute,
              (e.target as any).value,
              props.subsetValue
            );
          }}
          data-qa-id={qaIds.selectedConditionSubsetOperator}
        >
          {props.attribute.attribute_subset_operators.map(
            (operator: AttributeOperator) => {
              return (
                <option value={operator.id} key={operator.id}>
                  {operator.name}
                </option>
              );
            }
          )}
        </CustomInput>
      </div>
    )}
    <div key="recency-value" className={baseStyles.itemMarginClass}>
      <>
        {(props.subsetOperatorId === ON_OR_BEFORE.toString() ||
          props.subsetOperatorId === ON_OR_AFTER.toString() ||
          props.subsetOperatorId === EQUALS.toString()) && (
          <div data-qa-id={qaIds.selectedConditionSubsetValue}>
            <DatePicker
              id={inputIds.subsetValue}
              selected={props.subsetValue ? moment(props.subsetValue) : null}
              onChange={(date: moment.Moment) => {
                props.handleChangeSubsetValue(
                  props.attribute,
                  props.subsetOperatorId,
                  date ? date.format(DATE_FORMAT) : ""
                );
              }}
              className="form-control"
            />
          </div>
        )}
        {(props.subsetOperatorId === X_DAYS_OR_LESS_AGO.toString() ||
          props.subsetOperatorId === X_DAYS_OR_MORE_AGO.toString()) && (
          <div className={"d-flex align-items-center"}>
            <div className={"mr-3"}>
              <Input
                id={inputIds.subsetValue}
                type="text"
                value={props.subsetValue}
                onChange={e => {
                  props.handleChangeSubsetValue(
                    props.attribute,
                    props.subsetOperatorId,
                    (e.target as any).value
                  );
                }}
                data-qa-id={qaIds.selectedConditionSubsetValue}
              />
            </div>
            <div className={"text-nowrap"}>
              {props.subsetOperatorId === X_DAYS_OR_LESS_AGO.toString() &&
                "days or less ago"}
              {props.subsetOperatorId === X_DAYS_OR_MORE_AGO.toString() &&
                "days or more ago"}
            </div>
          </div>
        )}
      </>
    </div>
  </>
);
