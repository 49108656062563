import { Action } from "redux";
import {
  readAllMappingsAF,
  setCrmMappingsSortAF
} from "../actions/mappingsActions";
import { CrmMapping, attributeProps, crmProps } from "../../api/attributeApi";
import { createCrmMappingAF } from "../actions/createActions";
import { updateCrmMappingAF } from "../actions/updateActions";
import { deleteCrmMappingAF } from "../actions/deleteActions";
import { Order } from "../../constants/sharedConstants";

export interface State {
  isGettingMapping: boolean;
  byBusinessUnitId: {
    [businessUnitId: string]: {
      byCrmId: {
        [crmId: string]: CrmMapping;
      };
    };
  };
  sort: {
    iteratees: string[];
    orders: Order[];
  };
}

export const crmBusinessUnitIdMapInitialState: State = {
  isGettingMapping: false,
  byBusinessUnitId: {},
  sort: {
    iteratees: [`${crmProps.attribute}.${attributeProps.modified_at}`],
    orders: ["desc"]
  }
};

export function mappingsReducer(
  previousState: State = crmBusinessUnitIdMapInitialState,
  action: Action
): State {
  if (setCrmMappingsSortAF.isAction(action)) {
    const prevSortKey = previousState.sort.iteratees[0];
    const prevOrder = previousState.sort.orders[0];
    const nextSortKey = action.payload.sortKey;
    let nextOrder: Order;

    if (prevSortKey === nextSortKey) {
      if (prevOrder === "asc") {
        nextOrder = "desc";
      } else {
        nextOrder = "asc";
      }
    } else {
      nextOrder = "asc";
    }

    return {
      ...previousState,
      sort: {
        ...previousState.sort,
        iteratees: [nextSortKey],
        orders: [nextOrder]
      }
    };
  }
  if (readAllMappingsAF.pendingAF.isAction(action)) {
    return {
      ...previousState,
      isGettingMapping: true
    };
  }
  if (readAllMappingsAF.fulfilledAF.isAction(action)) {
    return {
      ...previousState,
      isGettingMapping: false,
      byBusinessUnitId: {
        ...previousState.byBusinessUnitId,
        [action.meta.context.businessUnit.id]: {
          byCrmId: action.payload
        }
      }
    };
  }
  if (readAllMappingsAF.rejectedAF.isAction(action)) {
    return {
      ...previousState,
      isGettingMapping: false
    };
  }
  if (createCrmMappingAF.fulfilledAF.isAction(action)) {
    return {
      ...previousState,
      byBusinessUnitId: {
        ...previousState.byBusinessUnitId,
        [action.meta.context.businessUnit.id]: {
          byCrmId: {
            ...previousState.byBusinessUnitId[
              action.meta.context.businessUnit.id
            ].byCrmId,
            [action.payload.id]: action.payload
          }
        }
      }
    };
  }
  if (updateCrmMappingAF.fulfilledAF.isAction(action)) {
    return {
      ...previousState,
      byBusinessUnitId: {
        ...previousState.byBusinessUnitId,
        [action.meta.context.businessUnit.id]: {
          byCrmId: {
            ...previousState.byBusinessUnitId[
              action.meta.context.businessUnit.id
            ].byCrmId,
            [action.payload.id]: action.payload
          }
        }
      }
    };
  }
  if (deleteCrmMappingAF.fulfilledAF.isAction(action)) {
    const newCrmById = {
      ...previousState.byBusinessUnitId[action.meta.context.businessUnit.id]
        .byCrmId
    };
    delete newCrmById[action.meta.baseAction.payload.id];
    return {
      ...previousState,
      byBusinessUnitId: {
        ...previousState.byBusinessUnitId,
        [action.meta.context.businessUnit.id]: {
          byCrmId: newCrmById
        }
      }
    };
  }
  return previousState;
}
