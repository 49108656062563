import { Dispatch } from "redux";
import {
  isGettingRootScoreSegments,
  showScoreSegmentCreateModal,
  showScoreSegmentDeleteModal,
  showScoreSegmentUpdateModal,
  isGettingUpdateScoreSegment,
  getScoreSegmentGoals,
  isGettingGoals,
  getScoreSegmentsSort,
  getSortedScoreSegments,
  isGettingDeleteScoreSegment
} from "../selectors/scoreSelectors";
import { State } from "../../reducers";
import { ScoreSegment } from "../../api/attributeApi";
import { getPermissionEnabled } from "../../../auth/selectors/permissionSelectors";
import { shortNamesToIds } from "../../constants/permissionConstants";
import { getSelectedBusinessUnit } from "../../../template/selectors/businessUnitSelectors";
import {
  ScoreSegmentsStateProps,
  ScoreSegmentsDispatchProps,
  ScoreSegmentFC,
  ScoreSegmentsProps
} from "../components/ScoreSegmentsComponent";
import { openCreateModalAF } from "../actions/createActions";
import { openUpdateModalAF } from "../actions/updateActions";
import { openDeleteModalAF } from "../actions/deleteActions";
import {
  setScoreSegmentsSortAF,
  scoreSegmentsDidMountAF,
  readScoreSegmentGoalsAF
} from "../actions/scoreActions";
import {
  connectWithLifecycle,
  LifecycleDispatchProps
} from "react-lifecycle-component";

export interface ScoreSegmentsContainerProps {}

function mapStateToProps(
  state: State,
  ownProps: ScoreSegmentsContainerProps
): ScoreSegmentsStateProps {
  const hasWriteAccess = getPermissionEnabled(
    state,
    shortNamesToIds.admin_write_audience_console,
    getSelectedBusinessUnit(state).id
  );
  const goals = getScoreSegmentGoals(state);

  return {
    goals,
    hasWriteAccess,
    sort: getScoreSegmentsSort(state) as {
      iteratees: string[];
      orders: string[];
    },
    scoreSegments: getSortedScoreSegments(state),
    showCreateModal: showScoreSegmentCreateModal(state),
    showUpdateModal: showScoreSegmentUpdateModal(state),
    showDeleteModal: showScoreSegmentDeleteModal(state),
    isGettingGoals: isGettingGoals(state),
    isGettingRootScoreSegments: isGettingRootScoreSegments(state),
    isGettingUpdateScoreSegment: isGettingUpdateScoreSegment(state),
    isGettingDeleteScoreSegment: isGettingDeleteScoreSegment(state)
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  props: ScoreSegmentsContainerProps
): ScoreSegmentsDispatchProps & LifecycleDispatchProps<ScoreSegmentsProps> {
  return {
    componentDidMount() {
      dispatch(scoreSegmentsDidMountAF.create({}, {}));
      dispatch(readScoreSegmentGoalsAF.create({}, {}));
    },
    onCreate() {
      dispatch(openCreateModalAF.create({}, {}));
    },
    onUpdate(scoreSegment: ScoreSegment) {
      dispatch(openUpdateModalAF.create(scoreSegment, {}));
    },
    onDelete(scoreSegment: ScoreSegment) {
      dispatch(openDeleteModalAF.create(scoreSegment, {}));
    },
    onSort(sortKey: string) {
      dispatch(setScoreSegmentsSortAF.create({ sortKey }, {}));
    }
  };
}

export const ScoreSegmentsContainer = connectWithLifecycle<
  ScoreSegmentsStateProps,
  ScoreSegmentsDispatchProps,
  ScoreSegmentsContainerProps,
  State
>(mapStateToProps, mapDispatchToProps)(ScoreSegmentFC);
