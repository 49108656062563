import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import {
  CountsComponent,
  StateProps,
  DispatchProps
} from "../components/CountsComponent";
import { Query } from "../../../lib/expressionBuilderLib";
import { getAppContext } from "../../selectors/appContextSelectors";
import { State as TemplateState } from "../../../template/reducers";
import { countErrorAF } from "../actions";

export interface OwnProps {
  query: Query;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, TemplateState> = (
  state,
  ownProps
) => {
  return {
    query: ownProps.query,
    appContext: getAppContext(state)
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  ownProps
) => {
  return {
    onError(error) {
      dispatch(countErrorAF.create(error, {}));
    }
  };
};

export const CountsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CountsComponent);
