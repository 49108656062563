import { createSelector } from "reselect";
import { match } from "../../lib/searchLib";
import { findNodes } from "../../lib/treeLib";
import { getAttributesByBusinessUnitId } from "./audienceSelectors";
import { State } from "../reducers";
import { AttributesState } from "../reducers/attributesReducer";
import { AttributeSelectorState } from "../reducers/attributeSelectorReducer";
import { Attribute } from "../api/attributeApi";
import { getExpressionBuilderChannel } from "./expressionBuilderSelector";
import { isWPAttribute } from "../constants/attributeIdConstants";

function sortAttributes(a: Attribute, b: Attribute): number {
  if (a.is_category && !b.is_category) {
    return -1;
  }
  if (!a.is_category && b.is_category) {
    return 1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

export const getBreadcrumbs = createSelector(
  getAttributesByBusinessUnitId,
  getAttributeSelector,
  (attributeData, attributeSelector) => {
    return attributeData &&
      attributeData.byId &&
      attributeSelector &&
      attributeSelector.breadCrumbs &&
      attributeSelector.breadCrumbs.length
      ? attributeSelector.breadCrumbs.map((id: string) => {
          return attributeData.byId[id];
        })
      : [];
  }
);

export const getWPAttributeIds = createSelector(
  getAttributesByBusinessUnitId,
  attributes => {
    return Object.keys(attributes.byId)
      .filter(isWPAttribute)
      .map(id => Number.parseInt(id.slice(2)));
  }
);

export const getSortedAttributes = createSelector(
  getBreadcrumbs,
  breadCrumbs => {
    let attributes = breadCrumbs.length
      ? breadCrumbs[breadCrumbs.length - 1].children
      : [];

    attributes.sort(sortAttributes);

    return attributes;
  }
);

export function getAttributeSelector(state: State, selectorId: string) {
  return state.attributeSelector
    ? state.attributeSelector[selectorId]
    : undefined;
}

export const getSearchTerm: (
  state: State,
  selectorId: string
) => string = createSelector(
  getAttributeSelector,
  (attributeSelector: AttributeSelectorState) => {
    return attributeSelector ? attributeSelector.searchTerm : undefined;
  }
);

export const getSelectedAttributeId: (
  state: State,
  selectorId: string
) => string = createSelector(
  getAttributeSelector,
  (attributeSelector: AttributeSelectorState) => {
    return attributeSelector
      ? attributeSelector.selectedAttributeId
      : undefined;
  }
);

// this is a factory so each instance of attribute selector has a memoized selector
export function getSearchResultsFactory(): (
  state: State,
  selectorId: string
) => Attribute[] {
  return createSelector(
    getSearchTerm,
    getAttributesByBusinessUnitId,
    getExpressionBuilderChannel,
    (searchTerm: string, attributeData: AttributesState) => {
      let attributes = [];

      if (
        attributeData &&
        attributeData.byId &&
        attributeData.rootId !== undefined &&
        searchTerm
      ) {
        attributes = findNodes(
          attributeData.byId[attributeData.rootId],
          (att: Attribute) => att.is_trait && match(att.tree_path, searchTerm)
        );
      }

      return attributes;
    }
  );
}
