import {
  clearAllAudiencesAF,
  readAllAudiencesAF,
  readAudienceAF,
  refreshAllAudiencesAF,
  refreshAudienceAF,
  clearAudienceAF,
  updateAudienceInitAF,
  createAudienceAF,
  updateAudienceAF,
  deleteAudienceAF,
  getGeoLocationNameFromApiAF,
  getGeoLocationNameAF,
  updateLabelsAF,
  readAllLabelsAF
} from "../actions/audienceActions";
import {
  getAudience,
  getGeoLocationName
} from "../selectors/audienceSelectors";
import {
  getAttributeIdsFromCriterion,
  isCriterion,
  queryToSegments
} from "../../lib/expressionBuilderLib";
import { getAppContext } from "../selectors/appContextSelectors";
import {
  createAudience,
  readAllAudiences,
  readAudience,
  deleteAudience,
  updateAudience,
  updateAudienceLabels,
  readLabels
} from "../api/audienceApi";
import { expressionBuilderLoadAudienceAF } from "../actions/expressionBuilder";
import { Audience } from "../models/audienceModels";
import { getExpressionBuilderQuery } from "../selectors/expressionBuilderSelector";
import { HOME, NOT_FOUND } from "../constants/pathConstants";
import { push } from "../../template/actions/trackedDataActions";
import { createOptionFromNode, getOne as getOneGeo } from "../api/geoApi";
import {
  createAsyncEpic,
  createEmittingAsyncEpic,
  createEpic,
  ofType,
  combineEpicMap,
  EpicMap
} from "../../lib/epicFactory";
import { concat, EMPTY, from, merge, race } from "rxjs";
import { State } from "../reducers";
import { loadCondition } from "./attributeEpics";
import { flatten } from "lodash";
import { filter, flatMap, map, take, ignoreElements } from "rxjs/operators";

export const epicMap: EpicMap<State> = {
  createAudienceEpic: createAsyncEpic(
    createAudienceAF,
    (action, context, state: State) => {
      const { audience, builderId } = action.payload;
      let startingPop: Audience = undefined;
      if (audience.starting_population_id) {
        startingPop = getAudience(state, audience.starting_population_id);
      }
      const query = getExpressionBuilderQuery(
        state,
        builderId,
        startingPop && startingPop.query_id
      );
      const audienceCopy = {
        ...audience,
        query
      };

      if (!query) {
        delete audienceCopy.query;
      }

      return createAudience(audienceCopy, context);
    }
  ),
  readAudienceEpic: createAsyncEpic(readAudienceAF, (action, context) => {
    return readAudience(action.payload.audienceId, context);
  }),
  readAllLabelsEpic: createAsyncEpic(readAllLabelsAF, (action, context) => {
    return readLabels(context);
  }),

  readAllLabelsUpdateEpic: createEpic<State>((action$, context) => {
    return action$.pipe(
      ofType(updateLabelsAF.fulfilledAF),
      map(() => {
        return readAllLabelsAF.create({}, {});
      })
    );
  }),

  readAllAudiencesEpic: createAsyncEpic(
    readAllAudiencesAF,
    (action, context) => {
      return readAllAudiences(context);
    }
  ),
  updateAudienceEpic: createAsyncEpic(
    updateAudienceAF,
    (action, context, state: State) => {
      const { audience, builderId } = action.payload;
      let startingPop: Audience = undefined;
      if (audience.starting_population_id) {
        startingPop = getAudience(state, audience.starting_population_id);
      }
      const query = getExpressionBuilderQuery(
        state,
        builderId,
        startingPop && startingPop.query_id
      );
      const audienceCopy = {
        ...audience,
        query
      };
      if (!query) {
        delete audienceCopy.query;
      }
      return updateAudience(audienceCopy, context);
    }
  ),
  deleteAudienceEpic: createAsyncEpic(deleteAudienceAF, (action, context) => {
    return deleteAudience(action.payload.audienceId, context).pipe(
      map(audienceId => {
        return { audienceId };
      })
    );
  }),
  deleteAudienceFulfilledEpic: createEpic((action$, store) => {
    return action$.pipe(
      ofType(deleteAudienceAF),
      map(() => {
        return refreshAllAudiencesAF.create({}, {});
      })
    );
  }),
  updateLabelsEpic: createAsyncEpic(
    updateLabelsAF,
    (action, context, state: State) => {
      const { labels, audience } = action.payload;
      const clone = {
        ...audience,
        labels: labels
      };
      return updateAudienceLabels(clone, context);
    }
  ),
  updateAudienceInitEpic: createEpic((action$, store) => {
    return action$.pipe(
      ofType(updateAudienceInitAF),
      flatMap(action => {
        const { audienceId, builderId } = action.payload;
        return concat(
          from([
            refreshAllAudiencesAF.create({}, {}),
            refreshAudienceAF.create({ audienceId }, {})
          ]),
          race(
            action$.pipe(
              ofType(refreshAudienceAF.fulfilledAF),
              filter(action => {
                return action.meta.baseAction.payload.audienceId === audienceId;
              }),
              take(1),
              map(() => {
                return expressionBuilderLoadAudienceAF.create(
                  { audienceId },
                  { builderId }
                );
              })
            ),
            action$.pipe(
              ofType(refreshAudienceAF.rejectedAF),
              filter(action => {
                return action.meta.baseAction.payload.audienceId === audienceId;
              }),
              take(1),
              flatMap(() => {
                return EMPTY;
              })
            )
          )
        );
      })
    );
  }),
  refreshAudienceEpic: createEmittingAsyncEpic(
    refreshAudienceAF,
    (action, context) => {
      const audienceId = action.payload.audienceId;
      return from([
        clearAudienceAF.create(
          { audienceId },
          {
            context,
            timestamp: Date.now()
          }
        ),
        readAudienceAF.create({ audienceId }, {})
      ]);
    },
    (action, context, state, action$) => {
      const audienceId = action.payload.audienceId;
      return race(
        action$.pipe(
          ofType(readAudienceAF.fulfilledAF),
          filter(action => {
            return action.meta.baseAction.payload.audienceId === audienceId;
          }),
          take(1),
          map(() => {
            return { audienceId };
          })
        ),
        action$.pipe(
          ofType(readAudienceAF.rejectedAF),
          filter(action => {
            return action.meta.baseAction.payload.audienceId === audienceId;
          }),
          take(1),
          ignoreElements()
        )
      );
    }
  ),
  refreshAllAudiencesEpic: createEpic((action$, state$) => {
    return action$.pipe(
      ofType(refreshAllAudiencesAF),
      flatMap(() => {
        const context = getAppContext(state$.value);
        return from([
          clearAllAudiencesAF.create(
            {},
            {
              context,
              timestamp: Date.now()
            }
          ),
          readAllAudiencesAF.create({}, {})
        ]);
      })
    );
  }),
  readAudienceFulfilledEpic: createEpic<State>((action$, store) => {
    return action$.pipe(
      ofType(readAudienceAF.fulfilledAF),
      flatMap(action => {
        const audienceId = action.meta.baseAction.payload.audienceId;
        const audience = action.payload.entities.audiences[audienceId];

        const segments = queryToSegments(audience.query);
        const loadConditions$ = flatten(
          segments.filter(isCriterion).map(segment => {
            return getAttributeIdsFromCriterion(segment).map(attributeId => {
              return loadCondition(attributeId, action$, store);
            });
          })
        );
        return merge(...loadConditions$);
      })
    );
  }),
  redirectToHomeOnUpdateEpic: createEpic((action$, store) => {
    return action$.pipe(
      ofType(updateAudienceAF.fulfilledAF),
      map(action => {
        return push(HOME);
      })
    );
  }),
  redirectToHomeOnCreateEpic: createEpic((action$, store) => {
    return action$.pipe(
      ofType(createAudienceAF.fulfilledAF),
      map(action => {
        return push(HOME);
      })
    );
  }),
  redirectTo404Epic: createEpic((action$, store) => {
    return action$.pipe(
      ofType(readAudienceAF.rejectedAF),
      map(action => push(NOT_FOUND))
    );
  }),
  getGeoLocationNameStartEpic: createEpic<State>((action$, state$) => {
    return action$.pipe(
      ofType(getGeoLocationNameAF),
      filter(action => {
        return !getGeoLocationName(
          state$.value,
          action.payload.geoId,
          action.payload.geoType
        );
      }),
      map(action => {
        return getGeoLocationNameFromApiAF.create({ ...action.payload }, {});
      })
    );
  }),
  getGeoLocationNameFromApiEpic: createAsyncEpic(
    getGeoLocationNameFromApiAF,
    (action, context, state) => {
      return getOneGeo(action.payload.geoId, action.payload.geoType).pipe(
        map(searchResults => {
          const name = createOptionFromNode(
            searchResults.results[action.payload.geoType][action.payload.geoId],
            searchResults
          ).displayName;

          return {
            name,
            ...action.payload
          };
        })
      );
    }
  )
};

export const epics = combineEpicMap(epicMap);
