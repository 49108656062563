import { combineReducers } from "redux";
import { FormStateMap, reducer as form } from "redux-form";
import { intlReducer as intl } from "react-intl-redux";
import { rootReducer as auth, AuthState } from "../../auth/reducers";
import {
  rootReducer as template,
  TemplateState
} from "../../template/reducers";
import {
  attributesReducer as attributes,
  State as AttributesState
} from "./attributesReducer";
import {
  attributeReducer as attribute,
  State as AttributeState
} from "./attributeReducer";
import {
  conditionBuilderReducer as conditionBuilder,
  State as ConditionBuilderState
} from "./conditionBuilderReducer";
import {
  expressionBuilderReducer as expressionBuilder,
  State as ExpressionBuilderState
} from "./expressionBuilderReducer";
import {
  attributeSelectorReducer as attributeSelector,
  State as AttributeSelectorState
} from "./attributeSelectorReducer";
import {
  audiencesReducer as audiences,
  State as AudiencesState
} from "./audiencesReducer";
import {
  asyncOperationsReducer as asyncOperations,
  State as AsyncOpsState
} from "./asyncOperationsReducer";
import {
  errorMonitorReducer as errorMonitor,
  State as ErrorMonitorState
} from "./errorMonitorReducer";
import {
  geoLocationReducer as geoLocation,
  State as GeoLocationState
} from "./geoLocationReducer";
import { reducers as crmReducers, State as CrmState } from "../crm/reducers";
import {
  reducers as scoreReducers,
  State as ScoreState
} from "../score/reducers";
import {
  reducers as externalSegmentReducers,
  State as ExternalSegmentState
} from "../externalSegments/reducers";

export interface State extends CrmState, ScoreState, ExternalSegmentState {
  auth?: AuthState;
  attribute: AttributeState;
  attributes: AttributesState;
  attributeSelector: AttributeSelectorState;
  conditionBuilder: ConditionBuilderState;
  expressionBuilder: ExpressionBuilderState;
  asyncOperations: AsyncOpsState;
  audiences: AudiencesState;
  form: FormStateMap;
  intl: {
    locale: string;
    messages: any;
  };
  template?: TemplateState;
  errorMonitor?: ErrorMonitorState;
  geoLocation: GeoLocationState;
}

export const INITIAL_STATE = {};

export const rootReducer = combineReducers({
  auth,
  attribute,
  attributes,
  attributeSelector,
  conditionBuilder,
  expressionBuilder,
  asyncOperations,
  audiences,
  form,
  intl,
  template,
  errorMonitor,
  geoLocation,
  ...crmReducers,
  ...scoreReducers,
  ...externalSegmentReducers
});
