import {
  ConditionData,
  ValidationRequest,
  Condition,
  ConditionBuilderMode
} from "../models/conditionBuilderModels";
import { AF } from "../../lib/actionFactoryLib";
import { AttributeChannelType } from "../constants/attributeChannelConstants";

interface BaseMeta {
  builderId: string;
}

interface LoadConditionMeta extends BaseMeta {
  mode: ConditionBuilderMode;
  conditionId: string;
}

export interface QueryMeta extends BaseMeta {
  subQueryId?: number;
}

export interface TopXInterestConditionMeta {
  level: number;
}

// union type for future condition metas
export type ConditionMeta = TopXInterestConditionMeta;

export const loadConditionMetaDefaults = {
  mode: ConditionBuilderMode.ADD,
  conditionId: undefined as string
};

export const conditionBuilderChangeDataTypeAF = new AF<
  { dataType: string },
  BaseMeta
>("CONDITION_BUILDER_CHANGE_DATA_TYPE");

export const conditionBuilderChangeOperatorIdAF = new AF<
  { operatorId: string },
  BaseMeta
>("CONDITION_BUILDER_CHANGE_OPERATOR_ID");

export const conditionBuilderChangeSubsetOperatorIdAF = new AF<
  { subsetOperatorId: string },
  BaseMeta
>("CONDITION_BUILDER_CHANGE_SUBSET_OPERATOR_ID");

export const conditionBuilderChangeValueAF = new AF<
  { value: string },
  BaseMeta
>("CONDITION_BUILDER_CHANGE_VALUE");

export const conditionBuilderChangeSubsetValueAF = new AF<
  { subsetValue: string },
  BaseMeta
>("CONDITION_BUILDER_CHANGE_SUBSET_VALUE");

export const loadConditionDefaults = {
  operatorId: undefined as string,
  value: undefined as string,
  channel: undefined as AttributeChannelType,
  subsetOperatorId: undefined as string,
  subsetValue: undefined as string
};
export const conditionBuilderLoadConditionAF = new AF<
  ConditionData,
  LoadConditionMeta
>("CONDITION_BUILDER_LOAD_CONDITION");

export const editConditionDefaults = {
  subsetOperatorId: undefined as string,
  subsetValue: undefined as string
};
export const conditionBuilderEditConditionAF = new AF<
  ConditionData,
  LoadConditionMeta
>("CONDITION_BUILDER_EDIT_CONDITION");

export const conditionBuilderClearConditionAF = new AF<
  {},
  { attributeId: string; builderId: string }
>("CONDITION_BUILDER_CLEAR_CONDITION");

export const conditionBuilderValidateOperatorIdAF = new AF<
  ValidationRequest,
  BaseMeta
>("CONDITION_BUILDER_VALIDATE_OPERATOR_ID");

export const conditionBuilderValidateValueAF = new AF<
  ValidationRequest,
  BaseMeta
>("CONDITION_BUILDER_VALIDATE_VALUE");

export const conditionBuilderValidateSubsetOperatorIdAF = new AF<
  ValidationRequest,
  BaseMeta
>("CONDITION_BUILDER_VALIDATE_SUBSET_OPERATOR_ID");

export const conditionBuilderValidateSubsetValueAF = new AF<
  ValidationRequest,
  BaseMeta
>("CONDITION_BUILDER_VALIDATE_SUBSET_VALUE");

export const conditionBuilderSaveConditionClickedAF = new AF<
  Condition,
  QueryMeta
>("CONDITION_BUILDER_SAVE_CONDITION_CLICKED");

export const conditionBuilderInitAF = new AF<{}, BaseMeta>(
  "CONDITION_BUILDER_INIT"
);

export const conditionBuilderDestroyAF = new AF<{}, BaseMeta>(
  "CONDITION_BUILDER_DESTROY"
);

export const conditionBuilderResetStateAF = new AF<{}, BaseMeta>(
  "CONDITION_BUILDER_RESET_STATE"
);

export const conditionBuilderSetMetaAF = new AF<ConditionMeta, BaseMeta>(
  "CONDITION_BUILDER_SET_CONDITION_META"
);
