import { readAllAudiencesAF, readAudienceAF } from "../actions/audienceActions";
import { Action } from "redux";

export interface State {
  readonly count: number;
}

const initialState = {
  count: 0
};

export function asyncOperationsReducer(
  asyncOperationStatus: State = initialState,
  action: Action
): State {
  if (
    readAllAudiencesAF.pendingAF.isAction(action) ||
    readAudienceAF.pendingAF.isAction(action)
  ) {
    return {
      ...asyncOperationStatus,
      count: asyncOperationStatus.count + 1
    };
  }
  if (
    readAllAudiencesAF.fulfilledAF.isAction(action) ||
    readAllAudiencesAF.rejectedAF.isAction(action) ||
    readAudienceAF.fulfilledAF.isAction(action) ||
    readAudienceAF.rejectedAF.isAction(action)
  ) {
    return {
      ...asyncOperationStatus,
      count: asyncOperationStatus.count - 1
    };
  }
  return asyncOperationStatus;
}
