import { connect } from "react-redux";
import {
  ConditionTextFC,
  StateProps,
  DispatchProps
} from "../components/ConditionTextComponent";
import { State } from "../reducers";
import { Condition } from "../models/conditionBuilderModels";
import { isTopInterestAttribute } from "../constants/attributeIdConstants";
import {
  getAttributeById,
  getFullAttributeById
} from "../selectors/audienceSelectors";
import { Dispatch } from "redux";

interface OwnProps {
  condition: Condition;
}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  const { condition } = ownProps;

  const topInterestAttribute =
    isTopInterestAttribute(condition.attribute.id) &&
    (getAttributeById(state, condition.value) ||
      getFullAttributeById(state, condition.value));

  return {
    condition,
    topInterestAttribute
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps {
  return {};
}

export const ConditionTextContainer = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  State
>(
  mapStateToProps,
  mapDispatchToProps
)(ConditionTextFC);
