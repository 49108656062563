export enum ActionTypes {
  AUTHENTICATE = "@@auth/AUTHENTICATE",
  AUTHENTICATE_PENDING = "@@auth/AUTHENTICATE_PENDING",
  AUTHENTICATE_FULFILLED = "@@auth/AUTHENTICATE_FULFILLED",
  AUTHENTICATE_REJECTED = "@@auth/AUTHENTICATE_REJECTED",
  GET_PERMISSIONS = "@@auth/GET_PERMISSIONS",
  GET_PERMISSIONS_PENDING = "@@auth/GET_PERMISSIONS_PENDING",
  GET_PERMISSIONS_FULFILLED = "@@auth/GET_PERMISSIONS_FULFILLED",
  GET_PERMISSIONS_REJECTED = "@@auth/GET_PERMISSIONS_REJECTED",
  CONFIGURE_PERMISSIONS = "@@auth/CONFIGURE_PERMISSIONS"
}
