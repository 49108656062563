import { default as React } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  getExpressionBuilderConditions,
  getExpressionBuilderSegments,
  getExpressionBuilderMode,
  getExpressionBuilderExpansionState,
  getExpressionBuilderSegmentIndexById
} from "../selectors/expressionBuilderSelector";
import { conditionBuilderLoadConditionAF } from "../actions/conditionBuilderActions";
import {
  ExpressionModes,
  ConditionSegment,
  ExpressionSegment,
  getDefaultPrefix,
  groupStartFactory,
  groupEndFactory
} from "../models/expressionBuilderModels";
import { ConditionBuilderMode } from "../models/conditionBuilderModels";
import {
  ExpressionBuilderComponent,
  StateProps as EBStateProps,
  DispatchProps as EBDispatchProps,
  Props as EBProps
} from "../components/ExpressionBuilderComponent";
import { Audience } from "../models/audienceModels";
import {
  expressionBuilderAddGroupAF,
  expressionBuilderAddPrefixAF,
  expressionBuilderDestroyAF,
  expressionBuilderExpansionAF,
  expressionBuilderMoveConditionToGroupAF,
  expressionBuilderMoveConditionToOperatorAF,
  expressionBuilderMoveSegmentAF,
  expressionBuilderRemoveConditionAF,
  expressionBuilderRemoveGroupAF,
  expressionBuilderRemovePrefixAF,
  expressionBuilderSetModeAF,
  expressionBuilderSwapConditionsAF,
  expressionBuilderToggleOperatorAF
} from "../actions/expressionBuilder";
import { State } from "../reducers";
import { getSelectedBusinessUnit } from "../../template/selectors/businessUnitSelectors";
import { NormalizedBusinessUnit } from "../../template/models/businessUnitModels";

export interface Props
  extends BaseStateProps,
    BaseDispatchProps,
    ComponentProps<EBProps> {}

export interface OwnProps {
  builderId: string;
  startingPop?: Audience;
}

export interface ComponentProps<T> {
  componentProps?: T;
}

export interface StateProps
  extends BaseStateProps,
    ComponentProps<EBStateProps> {}

interface BaseStateProps {
  businessUnit: NormalizedBusinessUnit;
}

export interface DispatchProps
  extends BaseDispatchProps,
    ComponentProps<EBDispatchProps> {}

interface BaseDispatchProps {
  init(businessUnit: NormalizedBusinessUnit): void;
  destroy(): void;
}

class ExpressionBuilderWrapper extends React.Component<Props, any> {
  componentDidMount() {
    this.props.init(this.props.businessUnit);
  }

  componentWillUnmount() {
    this.props.destroy();
  }

  render() {
    return <ExpressionBuilderComponent {...this.props.componentProps} />;
  }
}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  const conditions: ConditionSegment[] = getExpressionBuilderConditions(
    state,
    ownProps.builderId
  );
  const segments: ExpressionSegment[] = getExpressionBuilderSegments(
    state,
    ownProps.builderId
  );

  const mode = getExpressionBuilderMode(state, ownProps.builderId);
  const expand = getExpressionBuilderExpansionState(state, ownProps.builderId);

  return {
    businessUnit: getSelectedBusinessUnit(state),
    componentProps: {
      conditions: conditions || undefined,
      segments: segments || undefined,
      mode: mode || ExpressionModes.ALL,
      expand: expand || false,
      findSegmentIndex: (segmentId: string) => {
        return getExpressionBuilderSegmentIndexById(
          state,
          ownProps.builderId,
          segmentId
        );
      }
    }
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps {
  return {
    init(businessUnit: NormalizedBusinessUnit) {},
    destroy() {
      dispatch(
        expressionBuilderDestroyAF.create({}, { builderId: ownProps.builderId })
      );
    },
    componentProps: {
      handleEditCondition(condition: ConditionSegment) {
        dispatch(
          conditionBuilderLoadConditionAF.create(
            {
              attributeId: condition.value.attribute.id,
              operatorId: condition.value.operatorId,
              value: condition.value.value,
              channel: condition.value.channel,
              subsetOperatorId: condition.value.subsetOperatorId,
              subsetValue: condition.value.subsetValue,
              dataType: condition.value.dataType
            },
            {
              builderId: ownProps.builderId,
              conditionId: condition.id,
              mode: ConditionBuilderMode.EDIT
            }
          )
        );
      },
      handleDeleteCondition(condition: ConditionSegment) {
        dispatch(
          expressionBuilderRemoveConditionAF.create(
            { conditionId: condition.id },
            {
              builderId: ownProps.builderId,
              subQueryId: ownProps.startingPop && ownProps.startingPop.query_id
            }
          )
        );
      },
      onSelectMode(mode: ExpressionModes) {
        dispatch(
          expressionBuilderSetModeAF.create(
            { mode },
            {
              builderId: ownProps.builderId,
              subQueryId: ownProps.startingPop && ownProps.startingPop.query_id
            }
          )
        );
      },
      onExpandBuilderToggle(expand: boolean) {
        dispatch(
          expressionBuilderExpansionAF.create(
            { expand },
            { builderId: ownProps.builderId }
          )
        );
      },
      swapCondition: (conditionId: string, otherConditionId: string) => {
        dispatch(
          expressionBuilderSwapConditionsAF.create(
            { conditionId, otherConditionId },
            {
              builderId: ownProps.builderId,
              subQueryId: ownProps.startingPop && ownProps.startingPop.query_id
            }
          )
        );
      },
      moveSegment: (segmentId: string, index: number) => {
        dispatch(
          expressionBuilderMoveSegmentAF.create(
            { segmentId, index },
            {
              builderId: ownProps.builderId,
              subQueryId: ownProps.startingPop && ownProps.startingPop.query_id
            }
          )
        );
      },
      addExpressionPrefix() {
        dispatch(
          expressionBuilderAddPrefixAF.create(
            { index: 0, prefix: getDefaultPrefix() },
            {
              builderId: ownProps.builderId,
              subQueryId: ownProps.startingPop && ownProps.startingPop.query_id
            }
          )
        );
      },
      addExpressionGroup(querySize: number) {
        dispatch(
          expressionBuilderAddGroupAF.create(
            {
              startIndex: 0,
              endIndex: querySize,
              startGroup: groupStartFactory(),
              endGroup: groupEndFactory()
            },
            {
              builderId: ownProps.builderId,
              subQueryId: ownProps.startingPop && ownProps.startingPop.query_id
            }
          )
        );
      },
      removeExpressionGroup(groupId: string) {
        dispatch(
          expressionBuilderRemoveGroupAF.create(
            { groupId },
            {
              builderId: ownProps.builderId,
              subQueryId: ownProps.startingPop && ownProps.startingPop.query_id
            }
          )
        );
      },
      removeExpressionPrefix(prefixId: string) {
        dispatch(
          expressionBuilderRemovePrefixAF.create(
            { prefixId },
            {
              builderId: ownProps.builderId,
              subQueryId: ownProps.startingPop && ownProps.startingPop.query_id
            }
          )
        );
      },
      toggleExpressionOperator(operatorId: string) {
        dispatch(
          expressionBuilderToggleOperatorAF.create(
            { operatorId },
            {
              builderId: ownProps.builderId,
              subQueryId: ownProps.startingPop && ownProps.startingPop.query_id
            }
          )
        );
      },
      moveConditionToOperator(conditionId: string, operatorId: string) {
        dispatch(
          expressionBuilderMoveConditionToOperatorAF.create(
            { conditionId, operatorId },
            {
              builderId: ownProps.builderId,
              subQueryId: ownProps.startingPop && ownProps.startingPop.query_id
            }
          )
        );
      },
      moveConditionToGroup(conditionId: string, groupId: string) {
        dispatch(
          expressionBuilderMoveConditionToGroupAF.create(
            { conditionId, groupId },
            {
              builderId: ownProps.builderId,
              subQueryId: ownProps.startingPop && ownProps.startingPop.query_id
            }
          )
        );
      }
    }
  };
}

function mergeProps(
  stateProps: StateProps,
  dispatchProps: DispatchProps
): Props {
  return {
    ...dispatchProps,
    ...stateProps,
    componentProps: {
      ...dispatchProps.componentProps,
      ...stateProps.componentProps
    }
  };
}

export const ExpressionBuilderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ExpressionBuilderWrapper);
