import { Validator } from "./sharedInterfaces";
import { FullAttribute } from "../../api/attributeApi";

const NUMBER_REGEX = /^[0-9]+$/;

export function isValidNumber(value: string): boolean {
  if (value === null || value === undefined) {
    return false;
  }

  // make it so they can only input numbers
  if (value && !NUMBER_REGEX.test(value)) {
    return false;
  }

  // Prevent 0 followed by anything aka 000003
  if (value.length > 1 && value[0] === "0") {
    return false;
  }

  return true;
}

function validateValue(
  builderId: string,
  newValue: string,
  oldValue: string,
  operatorId: string,
  attribute: FullAttribute
): boolean {
  return isValidNumber(newValue);
}

export const numberValidator: Validator = {
  validateValue
};
