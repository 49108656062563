import {
  AudiencesNormalizedData,
  AudienceNormalizedData
} from "../api/audienceApi";
import { Audience, CreateAudience } from "../models/audienceModels";
import { AF, AsyncAF, Meta } from "../../lib/actionFactoryLib";
import { GEO_TYPE } from "../api/geoApi";
import { AudienceFilterState } from "../reducers/audiencesReducer";

export const resetAudiencesStateAF = new AF("RESET_AUDIENCES_STATE");

export const refreshAudienceAF = new AsyncAF<
  { audienceId: number },
  { audienceId: number }
>("REFRESH_AUDIENCE");

export const setAudiencesFilterLabelsAF = new AF<AudienceFilterState["labels"]>(
  "SET_AUDIENCES_FILTERS"
);

export const setAudiencesSortAF = new AF<{ sortKey: string }>(
  "SET_AUDIENCES_SORT"
);

export const setAudiencesPageSizeAF = new AF<{ pageSize: number }>(
  "SET_AUDIENCES_PAGE_SIZE"
);

export const setAudiencesQueryAF = new AF<{ query: string | number }>(
  "SET_AUDIENCES_QUERY"
);

export const clearAudiencesQueryAF = new AF("CLEAR_AUDIENCES_QUERY");

export const createAudienceAF = new AsyncAF<
  { audience: CreateAudience; builderId: string },
  AudienceNormalizedData
>("CREATE_AUDIENCE");

export const readAudienceAF = new AsyncAF<
  { audienceId: number },
  AudienceNormalizedData
>("READ_AUDIENCE");

export const readAllAudiencesAF = new AsyncAF<{}, AudiencesNormalizedData>(
  "READ_ALL_AUDIENCES"
);

export const readAllLabelsAF = new AsyncAF<{}, string[]>("READ_ALL_LABELS");

export const updateAudienceAF = new AsyncAF<
  { audience: Audience; builderId: string },
  AudienceNormalizedData
>("UPDATE_AUDIENCE");

export const deleteAudienceAF = new AsyncAF<
  { audienceId: number },
  { audienceId: number }
>("DELETE_AUDIENCE");

export const updateLabelsAF = new AsyncAF<
  { labels: string[]; audience: Audience },
  AudienceNormalizedData
>("UPDATE_LABELS");

export const clearAudienceAF = new AF<{ audienceId: number }, Meta>(
  "CLEAR_AUDIENCE"
);

export const clearAllAudiencesAF = new AF<{}, Meta>("CLEAR_ALL_AUDIENCES");

export const refreshAllAudiencesAF = new AF("REFRESH_ALL_AUDIENCES");

export const updateAudienceInitAF = new AF<{
  audienceId: number;
  builderId: string;
}>("UPDATE_AUDIENCE_INIT");

export const getGeoLocationNameAF = new AF<
  { geoId: string; geoType: GEO_TYPE },
  {}
>("GET_GEO_LOCATION_NAME_START");

export const getGeoLocationNameFromApiAF = new AsyncAF<
  { geoId: string; geoType: GEO_TYPE },
  { geoId: string; geoType: GEO_TYPE; name: string }
>("GET_GEO_LOCATION_NAME");

export const getAudiencesUsingAttributeAF = new AsyncAF<
  { attributeId: string },
  Audience[]
>("GET_AUDIENCES_USING_ATTRIBUTE");
