import {
  DispatchProps,
  StateProps,
  GeoLocationNameComponent
} from "../components/GeoLocationNameComponent";
import { State } from "../reducers";
import { Dispatch } from "redux";
import { getGeoLocationNameAF } from "../actions/audienceActions";
import { GEO_TYPE } from "../api/geoApi";
import { GEO_SERVICE_ATTRIBUTE_TO_TYPE_MAP } from "../constants/attributeIdConstants";
import { connect } from "react-redux";
import { getGeoLocationName } from "../selectors/audienceSelectors";

interface OwnProps {
  attributeId: string;
  geoLocationId: string;
}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  const geoType: GEO_TYPE =
    GEO_SERVICE_ATTRIBUTE_TO_TYPE_MAP[ownProps.attributeId];
  const geoLocationName = getGeoLocationName(
    state,
    ownProps.geoLocationId,
    geoType
  );

  return {
    geoLocationName
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps {
  return {
    onInit() {
      const geoId: string = ownProps.geoLocationId;
      const geoType: GEO_TYPE =
        GEO_SERVICE_ATTRIBUTE_TO_TYPE_MAP[ownProps.attributeId];
      dispatch(getGeoLocationNameAF.create({ geoId, geoType }, {}));
    }
  };
}

export const GeoLocationNameContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeoLocationNameComponent);
