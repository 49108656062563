import { connect } from "react-redux";
import { Dispatch } from "redux";
import { State } from "../reducers";
import { getSelectedBusinessUnitId } from "../selectors/businessUnitSelectors";
import { getNavSiteMapNavRootNode } from "../selectors/navigationSelectors";
import { fetchNavigationNodes } from "../actions/navigationActions";
import {
  HeaderPureComponent as HeaderComponent,
  StateProps,
  DispatchProps
} from "../components/HeaderComponent";
import { NavConfig } from "../models/navigationModels";

export interface OwnProps {
  navConfig?: NavConfig;
}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  return {
    navigation:
      getNavSiteMapNavRootNode(state) &&
      getNavSiteMapNavRootNode(state).children,
    businessUnitId: getSelectedBusinessUnitId(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    onInit(navConfig: NavConfig, businessUnitId: number) {
      dispatch(fetchNavigationNodes(navConfig, businessUnitId));
    },
    onBusinessUnitIdChange(
      navConfig: NavConfig,
      newBusinessUnitId: number,
      oldBusinessUnitId: number
    ) {
      dispatch(fetchNavigationNodes(navConfig, newBusinessUnitId));
    }
  };
}

export const HeaderContainer = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent);
