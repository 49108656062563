import { combineReducers } from "redux";
import { RouterState } from "connected-react-router";
import {
  businessUnitsReducer as businessUnits,
  BusinessUnitsState
} from "./businessUnitReducer";
import { navigationReducer as navigation, NavState } from "./navigationReducer";
import { AuthState } from "../../auth/reducers";

export const rootReducer = combineReducers<TemplateState>({
  businessUnits,
  navigation
});

// internal module state structure
export interface TemplateState {
  readonly businessUnits?: BusinessUnitsState;
  readonly navigation?: NavState;
}

// state as consumed by external users
export interface State {
  readonly template?: TemplateState;
  readonly auth?: AuthState;
  readonly router?: RouterState;
}
