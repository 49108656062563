import { connect } from "react-redux";
import { State } from "../reducers";
import { getUserHash } from "../selectors/businessUnitSelectors";
import {
  GravatarFC as GravatarComponent,
  StateProps
} from "../components/GravatarComponent";

function mapStateToProps(state: State): StateProps {
  return {
    hash: getUserHash(state)
  };
}

function mapDispatchToProps() {
  return {};
}

export const GravatarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GravatarComponent);
