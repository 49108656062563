import { default as React } from "react";
import { Alert } from "reactstrap";
import { ErrorLog } from "../../models/errorMonitorModels";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import styles from "./GenericErrorMessageComponent.module.scss";

const transitionMs = 500; // half a second - if you change this, update styles transition as well
const lifespanMs = 1000 * 5; // 5 seconds

export interface Props extends StateProps, DispatchProps {}

export interface StateProps {
  errorLog: ErrorLog;
}

export interface DispatchProps {
  onHideError(errorId: string): void;
}

interface LocalState {
  show: boolean;
  lifespanTimerId: number;
}

export class GenericErrorMessageComponent extends React.Component<
  Props,
  LocalState
> {
  isComponentMounted: boolean;

  constructor(props: Props) {
    super(props);

    this.isComponentMounted = false;
    this.state = {
      show: false,
      lifespanTimerId: undefined
    };
  }
  safeSetState(state: LocalState) {
    if (this.isComponentMounted) {
      this.setState(state);
    }
  }
  clearTimeouts() {
    if (this.state.lifespanTimerId !== undefined) {
      clearTimeout(this.state.lifespanTimerId);
    }
  }
  onDismiss() {
    this.clearTimeouts();
    this.safeSetState({
      ...this.state,
      show: false,
      lifespanTimerId: undefined
    });

    window.setTimeout(() => {
      this.props.onHideError(this.props.errorLog.id);
    }, transitionMs);
  }
  createLifespanTimer(): number {
    return window.setTimeout(() => {
      this.safeSetState({
        ...this.state,
        show: false
      });
      // give the css transition a chance to animate before removing from state
      window.setTimeout(
        () => this.props.onHideError(this.props.errorLog.id),
        transitionMs
      );
    }, lifespanMs);
  }
  componentDidMount() {
    // set state doesn't cause a re-render in this lifecycle method
    // so we put it in a timeout so we can get the css transition (to go from 0 to 1 opacity)
    window.setTimeout(() => {
      this.safeSetState({
        ...this.state,
        show: true,
        lifespanTimerId: this.createLifespanTimer()
      });
    }, 10);
  }
  componentWillMount() {
    this.isComponentMounted = true;
  }
  componentWillUnmount() {
    this.isComponentMounted = false;
    this.clearTimeouts();
  }
  getErrorMessage() {
    const action = this.props.errorLog.action as any;

    if (action.payload) {
      if (action.payload.message) {
        return action.payload.message;
      }
    }

    return "";
  }
  render() {
    return (
      <Alert
        color={"danger"}
        toggle={() => {
          if (this.state.show) {
            this.onDismiss();
          }
        }}
        className={classNames(
          styles.base,
          this.state.show ? styles.show : styles.hide
        )}
      >
        <div>
          <FontAwesomeIcon icon={faExclamationTriangle} />
          &nbsp;
          <span>There was an error processing your request.</span>
        </div>
        <div>{this.getErrorMessage()}</div>
      </Alert>
    );
  }
}
