import { default as React, ReactNode } from "react";
import { DropTarget, DropTargetConnector, DropTargetMonitor } from "react-dnd";
import { TRAIT } from "../constants/dndtypeConstants";
import {
  EXISTS,
  NOT_EXISTS,
  ON_ANY_DATE
} from "../constants/operatorConstants";
import {
  ConditionBuilderBaseProps,
  BaseStateProps,
  BaseDispatchProps,
  qaIds
} from "./ConditionBuilders/SharedData";
import { VisitRecencyFC } from "./ConditionBuilders/VisitRecencyComponent";
import { TopInterestContainer } from "../containers/ConditionBuilders/TopInterestContainer";
import { GeoLocationContainer } from "../containers/ConditionBuilders/GeoLocationContainer";
import {
  isGeoServiceAttribute,
  GEO_SERVICE_ATTRIBUTE_TO_TYPE_MAP,
  isTopInterestAttribute,
  WP_VISIT_RECENCY,
  WP_PAGE_URL,
  DS_VISIT_RECENCY,
  DS_PAGE_URL,
  WP_CURRENT_PAGE_URL,
  isExternalSegmentAttribute,
  isPVariableAttribute,
  PV_CONVERSION,
  PV_CONVERSION_WITH_RECENCY
} from "../constants/attributeIdConstants";
import { ConditionBuilderMode } from "../models/conditionBuilderModels";
import { EventKey } from "../constants/eventKeyConstants";
import { DataDrivenBuilderFC } from "./ConditionBuilders/DataDrivenBuilderComponent";
import { Attribute, FullAttribute } from "../api/attributeApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import { ExistsNotExistsBuilder } from "./ConditionBuilders/ExistsNotExistsBuilder";
import { SessionVariableBuilder } from "./ConditionBuilders/PVariableBuilder";
export interface ConditionBuilderProps extends StateProps, DispatchProps {}

export interface StateProps extends BaseStateProps {
  mode: ConditionBuilderMode;
  builderId: string;
  isOver: boolean;
}

export interface DispatchProps extends BaseDispatchProps {
  handleClickAdd(
    attribute: FullAttribute,
    operatorId: string,
    value: string,
    subsetOperatorId: string,
    subsetValue: string,
    model: string,
    dataLocation: string,
    dataType: string
  ): void;
  handleDragDrop(attribute: Attribute): void;
  connectDropTarget(node: ReactNode): void;
}

function getBuilderForAttribute(
  props: ConditionBuilderBaseProps,
  builderId: string
): ReactNode {
  if (
    isPVariableAttribute(props.attribute.id) &&
    props.attribute.id !== PV_CONVERSION &&
    props.attribute.id !== PV_CONVERSION_WITH_RECENCY
  ) {
    return <SessionVariableBuilder {...props} />;
  }
  if (isExternalSegmentAttribute(props.attribute.id)) {
    return <ExistsNotExistsBuilder {...props} />;
  }
  if (isGeoServiceAttribute(props.attribute.id)) {
    return (
      <GeoLocationContainer
        {...props}
        type={GEO_SERVICE_ATTRIBUTE_TO_TYPE_MAP[props.attribute.id]}
      />
    );
  }
  if (isTopInterestAttribute(props.attribute.id)) {
    return (
      <TopInterestContainer
        builderId={builderId}
        attribute={props.attribute}
        {...props}
      />
    );
  }
  if (
    props.attribute.id === WP_VISIT_RECENCY ||
    props.attribute.id === DS_VISIT_RECENCY ||
    props.attribute.id === PV_CONVERSION_WITH_RECENCY
  ) {
    return <VisitRecencyFC {...props} />;
  }

  return <DataDrivenBuilderFC {...props} />;
}

export const ConditionBuilderFC: React.FC<ConditionBuilderProps> = props => {
  function addDisabled() {
    return (
      (props.operatorId !== EXISTS.toString() &&
        props.operatorId !== NOT_EXISTS.toString() &&
        props.value === "") ||
      ((props.attribute.id === WP_PAGE_URL ||
        props.attribute.id === WP_VISIT_RECENCY ||
        props.attribute.id === WP_CURRENT_PAGE_URL ||
        props.attribute.id === DS_PAGE_URL ||
        props.attribute.id === DS_VISIT_RECENCY) &&
        props.value.length < 2) ||
      props.value.length > 50 ||
      (!!props.subsetOperatorId &&
        props.subsetOperatorId !== EXISTS.toString() &&
        props.subsetOperatorId !== ON_ANY_DATE.toString() &&
        props.subsetValue === "")
    );
  }

  return (
    <div
      data-qa-id="selected-condition"
      onKeyPress={e => {
        if (e.key === EventKey.Enter) {
          e.stopPropagation();
          e.preventDefault();
          props.handleClickAdd(
            props.attribute,
            props.operatorId,
            props.value,
            props.subsetOperatorId,
            props.subsetValue,
            props.model,
            props.dataLocation,
            props.dataType
          );
        }
      }}
    >
      {props.attribute && (
        <div>
          {getBuilderForAttribute(
            {
              attribute: props.attribute,
              handleClickClose: props.handleClickClose,
              handleChangeValue: props.handleChangeValue,
              handleChangeOperatorId: props.handleChangeOperatorId,
              value: props.value,
              operatorId: props.operatorId,
              dataType: props.dataType,
              dataLocation: props.dataLocation,
              handleChangeDataType: props.handleChangeDataType,
              handleChangeSubsetValue: props.handleChangeSubsetValue,
              handleChangeSubsetOperatorId: props.handleChangeSubsetOperatorId,
              handleClearSubset: props.handleClearSubset,
              subsetValue: props.subsetValue,
              subsetOperatorId: props.subsetOperatorId
            },
            props.builderId
          )}
          <div className={"d-flex justify-content-end mt-3"}>
            <Button
              onClick={() => {
                props.handleClickClose(props.attribute);
              }}
              className={"mr-2"}
              color="link"
              data-qa-id={qaIds.selectedConditionCloseButton}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() => {
                props.handleClickAdd(
                  props.attribute,
                  props.operatorId,
                  props.value,
                  props.subsetOperatorId,
                  props.subsetValue,
                  props.model,
                  props.dataLocation,
                  props.dataType
                );
              }}
              data-qa-id="selected-condition-add-button"
              disabled={addDisabled()}
            >
              {props.mode === ConditionBuilderMode.EDIT ? (
                <span>Update Condition</span>
              ) : (
                <span>Add Condition</span>
              )}
            </Button>
          </div>
        </div>
      )}
      {!props.attribute &&
        props.connectDropTarget(
          <div
            data-qa-id="selected-condition-drag-drop-target"
            className={`border rounded text-center p-4
              ${props.isOver ? "border-secondary text-secondary" : "text-muted"}
            `}
          >
            <span>
              <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
              <span>Drag/Drop Traits from </span>
              <span className={"d-none d-md-inline"}>Left Panel</span>
              <span className={"d-inline d-md-none"}>Top Panel</span>
            </span>
          </div>
        )}
    </div>
  );
};

ConditionBuilderFC.defaultProps = {
  attribute: undefined as any,
  value: "",
  operatorId: "",
  subsetValue: "",
  subsetOperatorId: ""
};

const conditionTarget = {
  drop(props: any, monitor: DropTargetMonitor) {
    const attribute = monitor.getItem();
    props.handleDragDrop(attribute);
  }
};

function collect(connect: DropTargetConnector, monitor: DropTargetMonitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

export const ConditionBuilderDropTarget = DropTarget(
  TRAIT,
  conditionTarget,
  collect
)(ConditionBuilderFC);
