import { createSelector } from "reselect";
import { expressionModelToQuery } from "../../lib/expressionBuilderLib";
import {
  ExpressionSegmentTypes,
  ConditionSegment
} from "../models/expressionBuilderModels";
import { ExpressionBuilderState } from "../reducers/expressionBuilderReducer";
import { State } from "../reducers";
import { getSelectedBusinessUnitId } from "../../template/selectors/businessUnitSelectors";
import { ConditionSettings } from "../models/conditionBuilderModels";

export function getExpressionBuilder(
  state: State,
  builderId: string
): ExpressionBuilderState {
  return state.expressionBuilder
    ? state.expressionBuilder[builderId]
    : undefined;
}

export const getExpressionBuilderMode = createSelector(
  getExpressionBuilder,
  expressionBuilder => {
    return expressionBuilder ? expressionBuilder.mode : undefined;
  }
);

export const getExpressionBuilderExpansionState = createSelector(
  getExpressionBuilder,
  expressionBuilder => {
    return expressionBuilder ? expressionBuilder.expand : undefined;
  }
);

export const getExpressionBuilderSegments = createSelector(
  getExpressionBuilder,
  expressionBuilder => {
    return expressionBuilder ? expressionBuilder.segments : [];
  }
);

export const getExpressionBuilderChannel = createSelector(
  getExpressionBuilderSegments,
  segments => {
    if (segments && segments.length > 0) {
      return segments
        .filter(segment => segment && segment.value)
        .map(segment => (segment.value as ConditionSettings).channel)[0];
    } else {
      return undefined;
    }
  }
);

export const getExpressionBuilderConditions = createSelector(
  getExpressionBuilderSegments,
  segments => {
    return segments
      ? (segments.filter(s => {
          return s.type === ExpressionSegmentTypes.CONDITION;
        }) as ConditionSegment[])
      : undefined;
  }
);

const expressionBuilderQuerySelector = createSelector(
  getExpressionBuilderSegments,
  getSelectedBusinessUnitId,
  (state: State, builderId: string, queryId: number) => {
    return queryId;
  },
  (segments, selectedBuId, queryId) => {
    return expressionModelToQuery(segments, selectedBuId, queryId);
  }
);

export function getExpressionBuilderQuery(
  state: State,
  builderId: string,
  queryId: number
) {
  return expressionBuilderQuerySelector(state, builderId, queryId);
}

export function getExpressionBuilderSegmentById(
  state: State,
  builderId: string,
  segmentId: string
) {
  const segments = getExpressionBuilderSegments(state, builderId);
  return segments && segments.length
    ? segments.find(s => {
        return s.id === segmentId;
      })
    : undefined;
}

export function getExpressionBuilderSegmentIndexById(
  state: State,
  builderId: string,
  segmentId: string
) {
  const segments = getExpressionBuilderSegments(state, builderId);
  return segments && segments.length
    ? segments.findIndex(s => {
        return s.id === segmentId;
      })
    : undefined;
}

export function getExpressionBuilderSegmentByIndex(
  state: State,
  builderId: string,
  index: number
) {
  const segments = getExpressionBuilderSegments(state, builderId);
  return segments && segments.length ? segments[index] : undefined;
}
