import { default as React } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { ClickAway } from "../ClickAway";
import {
  LabelsInput,
  LabelsInputStateProps,
  LabelsInputDispatchProps
} from "./LabelsInput";
import { Label } from "./Label";
import styles from "./Labels.module.scss";
import { Button } from "reactstrap";

function noop() {}

export interface LabelsStateProps extends LabelsInputStateProps {}

export interface LabelsDispatchProps extends LabelsInputDispatchProps {}

export interface LabelsProps extends LabelsStateProps, LabelsDispatchProps {}

export interface LabelsState {
  hovering: boolean;
  editing: boolean;
}

export class Labels extends React.Component<LabelsProps, LabelsState> {
  static defaultProps: Partial<LabelsStateProps> = {
    placeholder: "Labels"
  };

  labelsInputRef: React.RefObject<LabelsInput> = React.createRef();

  constructor(props: LabelsProps) {
    super(props);

    this.state = {
      hovering: false,
      editing: false
    };
  }

  onMouseEnter = (e: React.MouseEvent) => {
    this.setState({ hovering: true });
  };
  onMouseLeave = (e: React.MouseEvent) => {
    this.setState({ hovering: false });
  };
  onClick = () => {
    this.setState({ editing: true }, () => {
      if (this.labelsInputRef.current) {
        this.labelsInputRef.current.focus();
      }
    });
  };
  onClickAway = () => {
    this.setState({ editing: false, hovering: false });
  };

  render() {
    if (this.state.editing) {
      return (
        <ClickAway onClickAway={this.onClickAway}>
          <LabelsInput ref={this.labelsInputRef} {...this.props} />
        </ClickAway>
      );
    } else {
      return (
        <div
          className={classNames(
            styles.labels,
            this.state.hovering ? "border-secondary" : "border-light",
            "labels-container border c-editable rounded",
            this.props.className
          )}
          onClick={this.onClick}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          {this.props.labels.length === 0 ? (
            <span className={classNames(styles.noLabels, "d-inline-block")}>
              No Labels
            </span>
          ) : (
            this.props.labels.map(label => (
              <Label
                key={label}
                disabled
                className="mr-1 mb-1"
                label={label}
                onRemove={noop}
              />
            ))
          )}
          {this.state.hovering && (
            <Button
              size="sm"
              className={classNames(styles.edit)}
              onClick={this.onClick}
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
          )}
        </div>
      );
    }
  }
}
