import { numberValidator } from "./numberValidator";
import { createStringValidator } from "./stringValidator";
import {
  X_DAYS_OR_LESS_AGO,
  X_DAYS_OR_MORE_AGO,
  ON_OR_AFTER,
  ON_OR_BEFORE,
  EQUALS,
  GREATER_THAN,
  GREATER_THAN_OR_EQUALS,
  CONTAINS,
  LESS_THAN,
  LESS_THAN_OR_EQUALS
} from "../../constants/operatorConstants";
import { dateValidator } from "./dateValidator";
import { Validator } from "./sharedInterfaces";
import { FullAttribute } from "../../api/attributeApi";

const stringValidator = createStringValidator(0);

function validateValue(
  builderId: string,
  newValue: string,
  oldValue: string,
  operatorId: string,
  attribute: FullAttribute
): boolean {
  if (operatorId === CONTAINS.toString() || operatorId === EQUALS.toString()) {
    return stringValidator.validateValue(
      builderId,
      newValue,
      oldValue,
      operatorId,
      attribute
    );
  }
  if (
    operatorId === GREATER_THAN.toString() ||
    operatorId === GREATER_THAN_OR_EQUALS.toString() ||
    operatorId === LESS_THAN.toString() ||
    operatorId === LESS_THAN_OR_EQUALS.toString()
  ) {
    return numberValidator.validateValue(
      builderId,
      newValue,
      oldValue,
      operatorId,
      attribute
    );
  }

  if (
    operatorId === X_DAYS_OR_LESS_AGO.toString() ||
    operatorId === X_DAYS_OR_MORE_AGO.toString()
  ) {
    return numberValidator.validateValue(
      builderId,
      newValue,
      oldValue,
      operatorId,
      attribute
    );
  }
  if (
    operatorId === ON_OR_AFTER.toString() ||
    operatorId === ON_OR_BEFORE.toString()
  ) {
    return dateValidator.validateValue(
      builderId,
      newValue,
      oldValue,
      operatorId,
      attribute
    );
  }

  return true;
}

function validateSubsetValue(
  builderId: string,
  newValue: string,
  oldValue: string,
  operatorId: string,
  attribute: FullAttribute
): boolean {
  if (
    operatorId === X_DAYS_OR_LESS_AGO.toString() ||
    operatorId === X_DAYS_OR_MORE_AGO.toString()
  ) {
    return numberValidator.validateValue(
      builderId,
      newValue,
      oldValue,
      operatorId,
      attribute
    );
  }
  return dateValidator.validateValue(
    builderId,
    newValue,
    oldValue,
    operatorId,
    attribute
  );
}

export const sessionVariableValidator: Validator = {
  validateValue: validateValue,
  validateSubsetValue: validateSubsetValue
};
