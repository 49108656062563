import * as React from "react";
import { isEqual, uniqueId } from "lodash";
import { CountIndicatorFC } from "./CountIndicatorComponent";
import {
  countByCustomerLabel,
  countByCustomerTooltipText,
  countByCustomerTooltipIdPrefix
} from "../../constants/countConstants";
import { countByCustomerQaId } from "../../constants/qaIdConstants";
import { Query } from "../../../lib/expressionBuilderLib";
import { CountPayload, CountQuery } from "../../api/audienceApi";
import { readWPSegmentCount } from "../../api/wpApi";
import { AppContext } from "../../selectors/appContextSelectors";
import { Subscription } from "rxjs";
import { isWPAttribute } from "../../constants/attributeIdConstants";

export interface Props extends StateProps, DispatchProps {}

export interface StateProps {
  query: Query;
  appContext: AppContext;
}

export interface DispatchProps {
  onError(e: any): void;
}

interface State {
  counts: CountPayload;
  pending: boolean;
}

export class CountsComponent extends React.Component<Props, State> {
  id: string = uniqueId("ac_counts_");
  subscription: Subscription;

  constructor(props: Props) {
    super(props);
    this.state = {
      pending: false,
      counts: undefined
    };
  }

  componentDidUpdate(prevProps: StateProps) {
    if (this.props.query && !isEqual(this.props.query, prevProps.query)) {
      this.fetchCounts();
    }
  }

  componentDidMount() {
    if (this.props.query) {
      this.fetchCounts();
    }
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  fetchCounts() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    const query: CountQuery = {
        ...this.props.query,
        entityId: "audience"
      },
      buid = this.props.appContext.businessUnit.id,
      categories = query.variables.attributeSymbols
        .filter(attributeSymbol => isWPAttribute(attributeSymbol.attribute.id))
        .map(attributeSymbol =>
          Number.parseInt(attributeSymbol.attribute.id.slice(2))
        );

    if (categories.length === 0) {
      this.setState({
        ...this.state,
        counts: {
          byId: 0,
          byPerson: 0
        },
        pending: false
      });
    } else {
      this.setState({
        ...this.state,
        counts: undefined,
        pending: true
      });

      this.subscription = readWPSegmentCount(buid, categories).subscribe(
        counts => {
          this.setState({
            ...this.state,
            counts: {
              byId: counts.total,
              byPerson: counts.count
            },
            pending: false
          });
        },
        error => {
          this.props.onError(error);
          this.setState({
            ...this.state,
            pending: false
          });
        }
      );
    }
  }

  render() {
    const state = this.state;

    return (
      <div className={"d-flex"}>
        {/* <CountIndicatorFC
          qaId={countByIdentifierQaId}
          tooltipId={`${countByIdentifierTooltipIdPrefix}${this.id}`}
          tooltipText={countByIdentifierTooltipText}
          count={state && state.counts && state.counts.byId}
          label={countByIdentifierLabel}
          pending={state.pending}
        /> */}
        <CountIndicatorFC
          qaId={countByCustomerQaId}
          tooltipId={`${countByCustomerTooltipIdPrefix}${this.id}`}
          tooltipText={countByCustomerTooltipText}
          count={state && state.counts && state.counts.byPerson}
          label={countByCustomerLabel}
          pending={state.pending}
          className="ml-3"
        />
      </div>
    );
  }
}
