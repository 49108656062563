import { AsyncAF, AF } from "../../../lib/actionFactoryLib";
import { ExternalSegment } from "../../api/attributeApi";

export const openUpdateModalAF = new AF<ExternalSegment>(
  "EXTERNAL_SEGMENT_OPEN_UPDATE_MODAL"
);

export const closeUpdateModalAF = new AF<{}>(
  "EXTERNAL_SEGMENT_CLOSE_UPDATE_MODAL"
);

export const updateExternalSegmentAF = new AsyncAF<
  ExternalSegment,
  ExternalSegment
>("EXTERNAL_SEGMENT_UPDATE_ATTRIBUTE");
