import { default as React } from "react";
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { InjectedFormProps, Field, Form } from "redux-form";
import {
  PartialExternalSegment,
  ExternalSegment,
  externalSegmentProps
} from "../../api/attributeApi";
import { FormGroupInputField } from "../../forms/components/FormGroupInputField/FormGroupInputField";
import { EXTERNAL_SEGMENT_TYPES } from "../../constants/externalSegmentTypeConstants";

export interface InjectedProps
  extends Props,
    InjectedFormProps<PartialExternalSegment, Props, string> {}

export interface Props extends StateProps, DispatchProps {}

export interface StateProps {
  isFormSubmit: boolean;
  initialValues: PartialExternalSegment;
  allSegments: ExternalSegment[];
}

export interface DispatchProps {
  closeModal(): void;
}

export const ExternalSegmentCreateModalFC: React.FC<InjectedProps> = props => {
  return (
    <Modal isOpen={true} toggle={props.closeModal}>
      <Form onSubmit={props.handleSubmit}>
        <ModalHeader toggle={props.closeModal}>Create CRM Segment</ModalHeader>
        <ModalBody>
          <Field
            id="create_external_segment_name"
            label="Segment Name"
            required
            name={externalSegmentProps.name}
            component={FormGroupInputField}
            type="text"
            data-qa-id="external_segment-name-field"
            placeholder="Enter Text"
          />
          <Field
            id={"create_external_segment_type"}
            label="Segment Type"
            name={externalSegmentProps.external_segment_type}
            type="select"
            component={FormGroupInputField}
            data-qa-id="external_segment-type-field"
            className={"text-capitalize"}
          >
            {EXTERNAL_SEGMENT_TYPES.map((type: string) => {
              return (
                <option key={type} value={type}>
                  {type}
                </option>
              );
            })}
          </Field>
          <Field
            id={"create_external_segment_id"}
            label="Segment ID"
            name={externalSegmentProps.external_segment_id}
            component={FormGroupInputField}
            type="text"
            props={{} as any}
            data-qa-id="external_segment-id-field"
            placeholder="Enter ID"
            required
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="link"
            onClick={props.closeModal}
            disabled={props.isFormSubmit}
          >
            Cancel
          </Button>
          <Button
            color={"primary"}
            disabled={props.invalid || props.isFormSubmit}
            onClick={props.handleSubmit}
            data-qa-id="external_segment-create-submit"
          >
            Submit
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
