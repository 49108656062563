import { Dispatch } from "redux";
import {
  connectWithLifecycle,
  LifecycleDispatchProps
} from "react-lifecycle-component";
import {
  getCrmConfig,
  isGettingCrmMappings,
  showCrmCreateModal,
  showCrmDeleteModal,
  showCrmUpdateModal,
  getSortedCrmMappings,
  getCrmMappingsSort
} from "../selectors/crmSelectors";
import { State } from "../../reducers";
import { CrmMapping } from "../../api/attributeApi";
import { getPermissionEnabled } from "../../../auth/selectors/permissionSelectors";
import { shortNamesToIds } from "../../constants/permissionConstants";
import { getSelectedBusinessUnit } from "../../../template/selectors/businessUnitSelectors";
import {
  StateProps,
  DispatchProps,
  Props,
  CrmMappingFC
} from "../components/CrmMappingsComponent";
import {
  mappingsDidMountAF,
  setCrmMappingsSortAF
} from "../actions/mappingsActions";
import { openCreateModalAF } from "../actions/createActions";
import { openUpdateModalAF } from "../actions/updateActions";
import { openDeleteModalAF } from "../actions/deleteActions";
import { toggleCrmAF } from "../actions/configActions";

function mapStateToProps(state: State): StateProps {
  const hasWriteAccess = getPermissionEnabled(
    state,
    shortNamesToIds.admin_write_audience_console,
    getSelectedBusinessUnit(state).id
  );
  return {
    hasWriteAccess,
    sort: getCrmMappingsSort(state) as {
      iteratees: string[];
      orders: string[];
    },
    crmMappings: getSortedCrmMappings(state),
    showCreateModal: showCrmCreateModal(state),
    showUpdateModal: showCrmUpdateModal(state),
    showDeleteModal: showCrmDeleteModal(state),
    isGettingCrmMappings: isGettingCrmMappings(state),
    crmConfig: getCrmConfig(state)
  };
}

function mapDispatchToProps(
  dispatch: Dispatch
): DispatchProps & LifecycleDispatchProps<Props> {
  return {
    componentDidMount() {
      dispatch(mappingsDidMountAF.create({}, {}));
    },
    onCreate() {
      dispatch(openCreateModalAF.create({}, {}));
    },
    onUpdate(mapping: CrmMapping) {
      dispatch(openUpdateModalAF.create(mapping, {}));
    },
    onDelete(mapping: CrmMapping) {
      dispatch(openDeleteModalAF.create(mapping, {}));
    },
    onToggleCrm(value: boolean) {
      dispatch(toggleCrmAF.create({ value }, {}));
    },
    onSort(sortKey: string) {
      dispatch(setCrmMappingsSortAF.create({ sortKey }, {}));
    }
  };
}

export const CrmMappingsContainer = connectWithLifecycle(
  mapStateToProps,
  mapDispatchToProps
)(CrmMappingFC);
