import { default as React } from "react";
import { Helmet } from "react-helmet";
import { match } from "react-router-dom";
import { UpdateAudienceContainer } from "../containers/UpdateAudienceContainer";
import { shortNamesToIds } from "../constants/permissionConstants";
import { NoWriteAccessFC } from "../components/Access/NoWriteAccessComponent";
import { BusinessUnitAuthorizedContainer } from "../../template/containers/BusinessUnitAuthorizedContainer";

export interface UpdateAudienceProps {
  match: match<UpdateAudienceMatchParams>;
}

export interface UpdateAudienceMatchParams {
  id: string;
}

export const UpdateAudiencePage: React.FC<UpdateAudienceProps> = props => {
  const audienceId = parseInt(props.match.params.id, 10);

  return (
    <React.Fragment>
      <Helmet>
        <title>Update Audience</title>
      </Helmet>
      <BusinessUnitAuthorizedContainer
        permissionIds={[shortNamesToIds.write_audience_console]}
        deniedComponent={NoWriteAccessFC}
      >
        <UpdateAudienceContainer audienceId={audienceId} />
      </BusinessUnitAuthorizedContainer>
    </React.Fragment>
  );
};
