import { default as React } from "react";
import { ConditionBuilderBaseProps } from "../SharedData";
import { FrequencyBuilder } from "./FrequencyBuilder";
import { RecencyBuilder } from "./RecencyBuilder";

export const FrequencyWithRecencyBuilder: React.FC<
  ConditionBuilderBaseProps
> = (props: ConditionBuilderBaseProps) => (
  <>
    <FrequencyBuilder {...props} />
    <RecencyBuilder {...props} />
  </>
);
