import { default as React } from "react";
import { Helmet } from "react-helmet";
import { BusinessUnitAuthorizedContainer } from "../../../template/containers/BusinessUnitAuthorizedContainer";
import { shortNamesToIds } from "../../constants/permissionConstants";
import { NoAdminAccessFC } from "../../components/Access/NoAdminAccessComponent";
import { CrmMappingsContainer } from "../containers/CrmMappingsContainer";

export const CrmPage: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>CRM</title>
      </Helmet>
      <BusinessUnitAuthorizedContainer
        permissionIds={[shortNamesToIds.admin_read_audience_console]}
        deniedComponent={NoAdminAccessFC}
      >
        <CrmMappingsContainer />
      </BusinessUnitAuthorizedContainer>
    </React.Fragment>
  );
};
