import { Dispatch } from "redux";
import {
  connectWithLifecycle,
  LifecycleDispatchProps
} from "react-lifecycle-component";
import { State } from "../../reducers";
import {
  closeDeleteModalAF,
  deleteScoreSegmentAF
} from "../actions/deleteActions";
import {
  StateProps,
  DispatchProps,
  Props,
  ScoreSegmentDeleteModalFC
} from "../components/ScoreSegmentDeleteModalComponent";
import {
  getDeleteScoreSegment,
  getAudiencesUsingScoreSegment,
  getIsGettingAudiencesUsingScoreSegment,
  getDeleteIsDeleting
} from "../selectors/scoreSelectors";
import { ScoreSegment } from "../../api/attributeApi";

export interface OwnProps {}

function mapStateToProps(state: State, props: OwnProps): StateProps {
  return {
    scoreSegment: getDeleteScoreSegment(state),
    audiencesUsingScoreSegment: getAudiencesUsingScoreSegment(state),
    isGettingAudiencesUsingScoreSegment: getIsGettingAudiencesUsingScoreSegment(
      state
    ),
    isDeleting: getDeleteIsDeleting(state)
  };
}

function mapDispatchToProps(
  dispatch: Dispatch
): DispatchProps & LifecycleDispatchProps<Props> {
  return {
    componentDidMount() {},
    closeModal() {
      dispatch(closeDeleteModalAF.create({}, {}));
    },
    onDelete(scoreSegment: ScoreSegment) {
      dispatch(deleteScoreSegmentAF.create(scoreSegment, {}));
    }
  };
}

export const ScoreSegmentDeleteModalContainer = connectWithLifecycle(
  mapStateToProps,
  mapDispatchToProps
)(ScoreSegmentDeleteModalFC);
