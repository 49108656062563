import { combineEpics } from "redux-observable";
import { epics as crmEpics } from "./epics/crmEpics";

export { CrmPage } from "./components/CrmPage";

export { createCrmMappingAF } from "./actions/createActions";
export { updateCrmMappingAF } from "./actions/updateActions";
export { deleteCrmMappingAF } from "./actions/deleteActions";

export { reducers } from "./reducers";

export const epics = combineEpics(crmEpics);
