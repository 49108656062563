import { default as React } from "react";
import { InjectedFormProps, Normalizer } from "redux-form";
import { AudienceForm } from "./AudienceFormComponent";
import { ConditionBuilderMode } from "../models/conditionBuilderModels";
import { Audience, CreateAudience } from "../models/audienceModels";
import { AttributeChannelType } from "../constants/attributeChannelConstants";
import { NormalizedBusinessUnit } from "../../template/models/businessUnitModels";
import { Breadcrumb, BreadcrumbItem, Row, Col } from "reactstrap";
import { LinkContainer } from "../../template/containers/LinkContainer";
import { HOME } from "../constants/pathConstants";

export interface AddAudienceInjectedProps
  extends AddAudienceProps,
    InjectedFormProps<CreateAudience, AddAudienceProps, string> {}
export interface AddAudienceProps extends StateProps, DispatchProps {}

export interface StateProps {
  builderId: string;
  businessUnit: NormalizedBusinessUnit;
  startingPopulations: Audience[];
  startingPop: Audience;
  expandExpressionBuilder: boolean;
  conditionBuilderMode: ConditionBuilderMode;
  parentAudiences: Audience[];
  initialValues: Partial<CreateAudience>;
  channel: AttributeChannelType;
  onCustomIdChange: Normalizer;
  isSubmitDisabled?: boolean;
  isNameValid?: boolean;
  audienceLabelSuggestions: string[];
}

export interface DispatchProps {
  onInit(businessUnit: NormalizedBusinessUnit): void;
  onBusinessUnitChange(businessUnitId: number, fieldName: string): void;
  onNameChange(name: string): void;
  onStartingPopChange(audienceId: number, fieldName: string): void;
}

function noop(): void {
  return void 0;
}

export class AddAudiencePureComponent extends React.PureComponent<
  AddAudienceInjectedProps
> {
  static defaultProps = {
    builderId: undefined as string,
    showLoadingIndicator: true,
    initialValues: undefined as Partial<CreateAudience>,
    onNameChange: noop,
    onCustomIdChange: noop,
    onStartingPopChange: noop,
    isSubmitDisabled: false,
    isNameValid: false,
    startingPopulations: [{ id: 0, label: "All Profiles" } as any],
    startingPop: undefined as Audience,
    audienceLabelSuggestions: [] as string[]
  };

  componentDidMount() {
    this.props.onInit(this.props.businessUnit);
  }

  componentDidUpdate(prevProps: AddAudienceProps) {
    if (this.props.businessUnit.id !== prevProps.businessUnit.id) {
      this.props.onBusinessUnitChange(
        this.props.businessUnit.id,
        "business_unit_id"
      );
    }
  }

  render() {
    const props = this.props;
    const startingPopOptions = [
      ...AddAudiencePureComponent.defaultProps.startingPopulations,
      ...props.startingPopulations
        .filter(a => {
          return a.starting_population_id === 0;
        })
        .map(a => {
          return {
            id: a.audience_id,
            label: a.name
          };
        })
    ];
    const suggestions = this.props.audienceLabelSuggestions;

    return (
      <React.Fragment>
        <Row>
          <Col>
            <Breadcrumb>
              <BreadcrumbItem>
                <LinkContainer to={HOME}>Audiences</LinkContainer>
              </BreadcrumbItem>
              <BreadcrumbItem active>Add Audience</BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col>
            <AudienceForm
              handleSubmit={props.handleSubmit}
              builderId={props.builderId}
              businessUnit={props.businessUnit}
              channel={props.channel}
              submitText="Add Audience"
              onNameChange={props.onNameChange}
              disabled={["business_unit_id"]}
              onCustomIdChange={props.onCustomIdChange}
              isSubmitDisabled={props.isSubmitDisabled}
              isNameValid={props.isNameValid}
              startingPopOptions={startingPopOptions}
              audienceLabelSuggestions={suggestions}
              expandExpressionBuilder={props.expandExpressionBuilder}
              conditionBuilderMode={props.conditionBuilderMode}
              startingPop={props.startingPop}
              parentAudiences={props.parentAudiences}
              onStartingPopChange={props.onStartingPopChange}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
