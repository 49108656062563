import { NavNode as TsNavNode } from "./templateServiceModels";

export interface NavConfig {
  globalEnvironmentPrefix: string; // 'local-', 'dev-', 'qa-', or '' to use on all nodes at first
  globalProtocol: string;
  appId: string;
  navOverrides?: NavOverrideConfig[];
}

export interface NavOverrideConfig {
  navNodeId: string;
  hrefConfig: HrefConfig;
  applyToChildren?: boolean; // whether to apply this config's values to children of target node
}

export interface HrefConfig {
  protocol?: string;
  port?: string; // 8675309
  environmentPrefix?: string; // 'local-', 'dev-', 'qa-', or ''
}

// More Info Here: http://confluence.ignitionone.com/display/MEDCORE/Template+Service+Redux+Docs
export interface BaseNavNode {
  // the nodes in json
  id: string; // could potentially be a string
  name: string; // text to show for the link
  children?: NavNode[]; // so we can walk down the tree
  baseHref?: string; // starting point for creating full href
  iconClass?: string; // usually the font awesome class
  query?: {
    [key: string]: string; // in case we need to match against query string like in legacy flash.
  };
  enabled?: boolean; // whether you can click it based on permissions
  visible?: boolean; // whether you can see it based on permissions
  appId?: string; // links that are under the same application
  urlRegexPatterns?: string[]; // alternative strings used for url matching
  target?: string; // some links should be opened in new window
  badgeText?: string; // text of badge
  badgeColor?: string; // background color of badge
}

export interface NavNode extends BaseNavNode {
  parentId?: string; // so we can walk up the tree
  finalHref?: string; // final href including protocol, port, and env prefix
}

export function convertTsNavNode(
  tsNavNode: TsNavNode,
  parentId: string = undefined
): NavNode {
  return {
    parentId,
    id: tsNavNode.id,
    name: tsNavNode.text,
    children: tsNavNode.children.map(child => {
      return convertTsNavNode(child, tsNavNode.id);
    }),
    baseHref: tsNavNode.baseHref,
    iconClass: tsNavNode.iconClass,
    query: tsNavNode.query,
    enabled: tsNavNode.enabled,
    visible: tsNavNode.visible,
    appId: tsNavNode.appId,
    urlRegexPatterns: tsNavNode.urlRegexPatterns,
    target: tsNavNode.target,
    finalHref: undefined,
    badgeText: tsNavNode.badgeText,
    badgeColor: tsNavNode.badgeColor
  };
}
