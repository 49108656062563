import { normalize, schema, NormalizedData } from "normalizr";
import { env } from "../env/appEnv";
import { get, post, put, del } from "../../lib/httpLib";
import { Audience, CreateAudience } from "../models/audienceModels";
import { HttpHeaders } from "../constants/httpHeaderConstants";
import { AppContext } from "../selectors/appContextSelectors";
import { Query } from "../../lib/expressionBuilderLib";
import { TokenProvider } from "../../auth/lib/authLib";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

const AUDIENCE_RESOURCE = "audiences";

const audienceSchema = new schema.Entity(
  "audiences",
  {},
  { idAttribute: "audience_id" }
);
const audienceListSchema = [audienceSchema];

const getResourceUrl = (id: number = undefined) => {
  const url = `${env.REACT_APP_AUDIENCE_CONSOLE_API_PATH}/${AUDIENCE_RESOURCE}`;

  if (id !== undefined) {
    return `${url}/${id}`;
  }

  return url;
};

const queryPath: string = `${
  env.REACT_APP_QUERY_SERVICE_API_PATH
}/query/graphql`;

interface AudienceEntities {
  audiences: {
    [audienceId: string]: Audience;
  };
}

export interface AudienceNormalizedData
  extends NormalizedData<AudienceEntities, number> {}
export interface AudiencesNormalizedData
  extends NormalizedData<AudienceEntities, number[]> {}

export function createAudience(
  body: CreateAudience,
  context: AppContext
): Observable<AudienceNormalizedData> {
  return post<Audience>(context, getResourceUrl(), body).pipe(
    map(audience => {
      return normalize<AudienceNormalizedData>(audience, audienceSchema);
    })
  );
}

export function readAllAudiences(
  context: AppContext
): Observable<AudiencesNormalizedData> {
  return get<Audience[]>(context, getResourceUrl()).pipe(
    map(audiences => {
      return normalize<AudiencesNormalizedData>(audiences, audienceListSchema);
    })
  );
}

export function readAudience(
  audienceId: number,
  context: AppContext
): Observable<AudienceNormalizedData> {
  return get<Audience>(context, getResourceUrl(audienceId)).pipe(
    map(audience => {
      return normalize<AudienceNormalizedData>(audience, audienceSchema);
    })
  );
}

export function updateAudience(
  body: Audience,
  context: AppContext
): Observable<AudienceNormalizedData> {
  return put<Audience>(context, getResourceUrl(body.audience_id), body).pipe(
    map(audience => {
      return normalize<AudienceNormalizedData>(audience, audienceSchema);
    })
  );
}

export function updateAudienceLabels(
  body: Audience,
  context: AppContext
): Observable<AudienceNormalizedData> {
  return put<Audience>(
    context,
    `${getResourceUrl(body.audience_id)}/labels`,
    body
  ).pipe(
    map(audience => {
      return normalize<AudienceNormalizedData>(audience, audienceSchema);
    })
  );
}

export function deleteAudience(
  audienceId: number,
  context: AppContext
): Observable<number> {
  return del(context, getResourceUrl(audienceId), {
    [HttpHeaders.Token]: TokenProvider.getToken()
  }).pipe(
    map(() => {
      return audienceId; // note there is no response body. Just a 200
    })
  );
}

interface CountApiData {
  data: {
    count: {
      total: number;
      count: number;
    };
  };
}

export interface CountQuery extends Query {
  entityId: string;
  id?: number;
}

export interface CountPayload {
  byId: number;
  byPerson: number;
}

export function getRealTimeCount(
  body: CountQuery,
  context: AppContext
): Observable<CountPayload> {
  return post<CountApiData>(context, queryPath, body).pipe(
    map(result => {
      return {
        byId: result.data.count.total,
        byPerson: result.data.count.count
      };
    })
  );
}

export function readAudiencesUsingAttributes(
  attributeIds: string[],
  context: AppContext
): Observable<Audience[]> {
  return get<Audience[]>(
    context,
    `${getResourceUrl()}?attributeId=${attributeIds.join(",")}`
  );
}

export function readLabels(context: AppContext): Observable<string[]> {
  return get<string[]>(
    context,
    `${env.REACT_APP_AUDIENCE_CONSOLE_API_PATH}/labels`
  );
}

export interface ScoreBucket {
  key: number;
  doc_count: number;
}

export const scoreProps: { [K in keyof ScoreBucket]: keyof ScoreBucket } = {
  key: "key",
  doc_count: "doc_count"
};

export function readAllScoreBuckets(
  model: string,
  context: AppContext
): Observable<ScoreBucket[]> {
  return get<ScoreBucket[]>(
    context,
    `${getResourceUrl()}/scores/distribution?model=${model}`
  );
}
