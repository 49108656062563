import { default as React } from "react";
import { SitemapNavContainer } from "../containers/SitemapNavContainer";
import { SubNavContainer } from "../containers/SubNavContainer";
import styles from "./NavbarComponent.module.scss";

export const NavbarFC: React.FC = () => {
  return (
    <nav className={styles.navBar}>
      <SitemapNavContainer />
      <SubNavContainer />
    </nav>
  );
};
