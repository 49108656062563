import { default as React } from "react";
import { FrequencyBuilder } from "./FrequencyBuilder";
import { RecencyBuilder } from "./RecencyBuilder";
import { FrequencyWithRecencyBuilder } from "./FrequencyWithRecencyBuilder";
import { CustomInput, ListGroup, ListGroupItem, Row } from "reactstrap";
import {
  baseStyles,
  qaIds,
  baseDefaultProps,
  ConditionBuilderBaseProps
} from "../SharedData";
import { AttributeName } from "../../AttributeName";
import {
  GREATER_THAN,
  NOT_EXISTS,
  ON_OR_AFTER
} from "../../../constants/operatorConstants";
import { DATE_FORMAT } from "../../../constants/attributeDataTypeNameConstants";
import { default as moment } from "moment";

export enum ExistsNotExistsBuilderType {
  Frequency = "frequency",
  Recency = "recency",
  FrequencyWithRecency = "frequency_with_recency"
}

export enum ExistsNotExistsBuilderParentType {
  NotExists = "not_exists",
  Exists = "exists"
}

interface ExistsNotExistsBuilderState {
  value: ExistsNotExistsBuilderParentType;
}

export interface ExistsNotExistsBuilderProps
  extends ConditionBuilderBaseProps {}

export class ExistsNotExistsBuilder extends React.Component<
  ExistsNotExistsBuilderProps,
  ExistsNotExistsBuilderState
> {
  static defaultProps = {
    ...baseDefaultProps
  };

  constructor(props: ExistsNotExistsBuilderProps) {
    super(props);
    this.state = {
      value: ExistsNotExistsBuilderParentType.Exists
    };
  }

  resetFrequency() {
    this.props.handleChangeOperatorId(
      this.props.attribute,
      GREATER_THAN.toString(),
      "0"
    );
    this.props.handleChangeValue(
      this.props.attribute,
      GREATER_THAN.toString(),
      "0"
    );
  }
  resetRecency() {
    this.props.handleChangeSubsetOperatorId(
      this.props.attribute,
      ON_OR_AFTER.toString(),
      moment().format(DATE_FORMAT)
    );
    this.props.handleChangeSubsetValue(
      this.props.attribute,
      ON_OR_AFTER.toString(),
      moment().format(DATE_FORMAT)
    );
  }
  clearRecency() {
    this.props.handleClearSubset();
  }

  componentWillMount() {
    if (!this.props.value) {
      this.resetRecency();
      this.resetFrequency();
    }
  }

  resetNotExists() {
    this.props.handleChangeOperatorId(
      this.props.attribute,
      NOT_EXISTS.toString(),
      ""
    );
    this.props.handleChangeValue(
      this.props.attribute,
      NOT_EXISTS.toString(),
      ""
    );
    this.props.handleChangeSubsetOperatorId(this.props.attribute, "", "");
    this.props.handleChangeSubsetValue(this.props.attribute, "", "");
  }

  onSelectType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dataType = e.target.value as ExistsNotExistsBuilderType;

    switch (dataType) {
      case ExistsNotExistsBuilderType.Recency:
        this.resetRecency();
        break;
      case ExistsNotExistsBuilderType.FrequencyWithRecency:
        if (this.props.dataType === ExistsNotExistsBuilderType.Frequency) {
          this.resetRecency();
        }
        break;
      case ExistsNotExistsBuilderType.Frequency:
        this.clearRecency();
        break;
      default:
        throw new TypeError(`Invalid ExistsNotExistsBuilder type ${dataType}`);
    }

    this.props.handleChangeDataType(dataType);
  };

  onSelectTypeParent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parentDataType = e.target.value as ExistsNotExistsBuilderParentType;
    this.setState({ value: parentDataType });

    switch (parentDataType) {
      case ExistsNotExistsBuilderParentType.Exists:
        this.resetFrequency();
        this.resetRecency();
        break;
      case ExistsNotExistsBuilderParentType.NotExists:
        this.resetNotExists();
        break;
      default:
        throw new TypeError(
          `Invalid ExistsNotExistsBuilder type ${parentDataType}`
        );
    }
  };

  renderBuilder() {
    switch (this.props.dataType) {
      case ExistsNotExistsBuilderType.Frequency:
        return <FrequencyBuilder {...this.props} />;
      case ExistsNotExistsBuilderType.Recency:
        return <RecencyBuilder {...this.props} />;
      case ExistsNotExistsBuilderType.FrequencyWithRecency:
        return <FrequencyWithRecencyBuilder {...this.props} />;
      default:
        throw new TypeError(
          `Invalid ExistsNotExistsBuilder type ${this.props.dataType}`
        );
    }
  }

  render() {
    return (
      <div className={baseStyles.conditionContainerClass}>
        <ListGroup>
          <ListGroupItem>
            <Row>
              <div style={{ width: "100%" }}>
                <div className={baseStyles.itemMarginClass}>
                  <AttributeName
                    attribute={this.props.attribute}
                    qaNameId={qaIds.selectedConditionName}
                    qaTooltipId={qaIds.selectedConditionTooltipPath}
                    qaTooltipIconId={qaIds.selectedConditionTooltipIcon}
                    qaTooltipPathId={qaIds.selectedConditionTooltipPath}
                  />
                </div>
              </div>
              <div>
                Trait Should
                <div className={baseStyles.itemMarginClass}>
                  <CustomInput
                    id="type"
                    type="select"
                    onChange={this.onSelectTypeParent}
                    value={this.state.value}
                    data-qa-id={qaIds.selectedConditionType}
                  >
                    <option value={ExistsNotExistsBuilderParentType.Exists}>
                      Exists
                    </option>
                    <option value={ExistsNotExistsBuilderParentType.NotExists}>
                      Not Exists
                    </option>
                  </CustomInput>
                </div>
              </div>
            </Row>
            {this.state.value !==
              ExistsNotExistsBuilderParentType.NotExists && (
              <React.Fragment>
                <Row>With</Row>
                <Row>
                  <div className={baseStyles.itemMarginClass}>
                    <CustomInput
                      id="type"
                      type="select"
                      value={this.props.dataType}
                      onChange={this.onSelectType}
                    >
                      <option value={ExistsNotExistsBuilderType.Frequency}>
                        Frequency
                      </option>
                      <option value={ExistsNotExistsBuilderType.Recency}>
                        Recency
                      </option>
                      <option
                        value={ExistsNotExistsBuilderType.FrequencyWithRecency}
                      >
                        Frequency with Recency
                      </option>
                    </CustomInput>
                  </div>
                  {this.renderBuilder()}
                </Row>
              </React.Fragment>
            )}
          </ListGroupItem>
        </ListGroup>
      </div>
    );
  }
}
