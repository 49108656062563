import { default as React } from "react";
import { ConditionCardAdvancedDND } from "./ConditionCardAdvanced";
import { ExpressionGroupStartDND } from "./ExpressionGroupStartComponent";
import { ExpressionGroupEndDND } from "./ExpressionGroupEndComponent";
import { ExpressionOperatorDropTarget } from "./ExpressionOperatorComponent";
import { ExpressionPrefixDND } from "./ExpressionPrefixComponent";
import { ExpressionModeButtonFC } from "./ExpressionModeButton";
import {
  ExpressionModes,
  ConditionSegment,
  ExpressionSegment,
  ExpressionSegmentTypes
} from "../models/expressionBuilderModels";
import { ExpressionBuilderModalFC } from "./ExpressionBuilderModalComponent";
import { Button, Col, Row, ButtonGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCompress,
  faExpand,
  faPlus
} from "@fortawesome/free-solid-svg-icons";

export interface DispatchProps {
  onSelectMode(mode: ExpressionModes): void;
  onExpandBuilderToggle(expanded: boolean): void;
  handleEditCondition(condition: ConditionSegment): void;
  handleDeleteCondition(condition: ConditionSegment): void;
  swapCondition(conditionId: string, otherConditionId: string): void;
  moveSegment(segmentId: string, index: number): void;
  moveConditionToOperator(conditionId: string, operatorId: string): void;
  moveConditionToGroup(conditionId: string, groupId: string): void;
  addExpressionPrefix(): void;
  removeExpressionPrefix(prefixId: string): void;
  addExpressionGroup(querySize: number): void;
  removeExpressionGroup(groupId: string): void;
  toggleExpressionOperator(operatorId: string): void;
}

export interface StateProps {
  findSegmentIndex(segmentId: string): number;
  conditions: ConditionSegment[];
  segments: ExpressionSegment[];
  mode: ExpressionModes;
  expand: boolean;
}

export interface Props extends StateProps, DispatchProps {}

export class ExpressionBuilderComponent extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props);

    this.state = {
      displayModal: false,
      modeToSwitchTo: null
    };
    this.showExpressionBuilderModal = this.showExpressionBuilderModal.bind(
      this
    );
    this.hideExpressionBuilderModal = this.hideExpressionBuilderModal.bind(
      this
    );
  }

  showExpressionBuilderModal(mode: string) {
    this.setState({
      displayModal: true,
      modeToSwitchTo: mode
    });
  }

  hideExpressionBuilderModal() {
    this.setState({
      displayModal: false
    });
  }

  componentDidUpdate(prevProps: Props) {
    this.props.conditions &&
      this.props.conditions.length === 1 &&
      prevProps.conditions.length === 2 &&
      this.props.mode === ExpressionModes.ANY &&
      this.props.onSelectMode(ExpressionModes.ALL);
  }

  onSelectMode = (newMode: ExpressionModes) =>
    this.props.mode === ExpressionModes.ADVANCED &&
    newMode !== ExpressionModes.ADVANCED
      ? this.showExpressionBuilderModal(newMode)
      : this.props.onSelectMode(newMode);

  render() {
    const props = this.props,
      state = this.state;

    return (
      <React.Fragment>
        {state.displayModal && (
          <ExpressionBuilderModalFC
            mode={props.mode}
            show={true}
            onCancel={() => {
              this.hideExpressionBuilderModal();
            }}
            onSwitchMode={() => {
              this.hideExpressionBuilderModal();
              props.onSelectMode(state.modeToSwitchTo);
            }}
          />
        )}
        <Row>
          <Col className={"d-flex justify-content-between my-3"}>
            <ButtonGroup>
              <ExpressionModeButtonFC
                mode={ExpressionModes.ALL}
                selectedMode={props.mode}
                onSelectMode={this.onSelectMode}
              />
              <ExpressionModeButtonFC
                mode={ExpressionModes.ANY}
                disabled={this.props.conditions.length === 1}
                selectedMode={props.mode}
                onSelectMode={this.onSelectMode}
              />
              <ExpressionModeButtonFC
                mode={ExpressionModes.ADVANCED}
                selectedMode={props.mode}
                onSelectMode={this.onSelectMode}
              />
            </ButtonGroup>
            <Button
              className={"d-none d-lg-inline-block"}
              onClick={() => {
                props.onExpandBuilderToggle(!props.expand);
              }}
            >
              {props.expand ? (
                <span>
                  <FontAwesomeIcon icon={faCompress} className={"mr-2"} />
                  Compress
                </span>
              ) : (
                <span>
                  <FontAwesomeIcon icon={faExpand} className={"mr-2"} />
                  Expand
                </span>
              )}
            </Button>
          </Col>
        </Row>
        {props.mode === ExpressionModes.ADVANCED && (
          <Row className={"mb-3"}>
            <Col>
              <Button
                className={"mr-2"}
                onClick={() => {
                  props.addExpressionGroup(props.segments.length);
                }}
                data-qa-id="condition-builder-add-parenths"
              >
                <FontAwesomeIcon icon={faPlus} className={"mr-2"} />[ ]
              </Button>
              <Button
                onClick={props.addExpressionPrefix}
                data-qa-id="condition-builder-add-prefix"
              >
                <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                NOT
              </Button>
            </Col>
          </Row>
        )}
        <Row>
          <Col
            className={
              "d-flex flex-wrap justify-content-around justify-content-lg-start"
            }
          >
            {props.segments.map((segment: ExpressionSegment) => {
              return (
                <React.Fragment key={segment.id}>
                  {segment.type === ExpressionSegmentTypes.GROUP_START && (
                    <ExpressionGroupStartDND
                      key={segment.id}
                      group={segment}
                      removeExpressionGroup={props.removeExpressionGroup}
                      moveConditionToGroup={props.moveConditionToGroup}
                      moveSegment={props.moveSegment}
                      findSegmentIndex={props.findSegmentIndex}
                    />
                  )}
                  {segment.type === ExpressionSegmentTypes.GROUP_END && (
                    <ExpressionGroupEndDND
                      key={segment.id}
                      group={segment}
                      removeExpressionGroup={props.removeExpressionGroup}
                      moveConditionToGroup={props.moveConditionToGroup}
                      moveSegment={props.moveSegment}
                      findSegmentIndex={props.findSegmentIndex}
                    />
                  )}
                  {segment.type === ExpressionSegmentTypes.OPERATOR && (
                    <ExpressionOperatorDropTarget
                      key={segment.id}
                      operator={segment}
                      toggleExpressionOperator={props.toggleExpressionOperator}
                      moveConditionToOperator={props.moveConditionToOperator}
                      disabled={props.mode !== ExpressionModes.ADVANCED}
                    />
                  )}

                  {segment.type === ExpressionSegmentTypes.PREFIX && (
                    <ExpressionPrefixDND
                      key={segment.id}
                      prefix={segment}
                      removeExpressionPrefix={props.removeExpressionPrefix}
                    />
                  )}
                  {segment.type === ExpressionSegmentTypes.CONDITION && (
                    <ConditionCardAdvancedDND
                      key={segment.id}
                      condition={segment}
                      handleEditCondition={props.handleEditCondition}
                      handleDeleteCondition={props.handleDeleteCondition}
                      swapCondition={props.swapCondition}
                      moveSegment={props.moveSegment}
                      findSegmentIndex={props.findSegmentIndex}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
