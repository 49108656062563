import { AF, AsyncAF } from "../../../lib/actionFactoryLib";
import {
  ExternalSegment,
  PartialExternalSegment
} from "../../api/attributeApi";

export const openCreateModalAF = new AF<{}>(
  "EXTERNAL_SEGMENT_OPEN_CREATE_MODAL"
);

export const closeCreateModalAF = new AF<{}>(
  "EXTERNAL_SEGMENT_CLOSE_CREATE_MODAL"
);

export const createExternalSegmentAF = new AsyncAF<
  PartialExternalSegment,
  ExternalSegment
>("CREATE_EXTERNAL_SEGMENT");
