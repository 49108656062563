import { AttributeLookup, FullAttribute } from "../api/attributeApi";
import { AttributeChannelType } from "../constants/attributeChannelConstants";

export interface ConditionSettings {
  operatorId: string;
  value: string;
  channel: AttributeChannelType;
  subsetOperatorId?: string;
  subsetValue?: string;
  model?: string;
  dataType?: string;
  dataLocation?: string;
}

export interface Condition extends ConditionSettings {
  attribute: FullAttribute;
}

export interface ValidationRequest {
  attribute: FullAttribute;
  operatorId: string;
  value: string;
}

export interface ConditionData extends AttributeLookup, ConditionSettings {}

export enum ConditionBuilderMode {
  ADD = "ADD",
  EDIT = "EDIT"
}
