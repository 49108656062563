export const idsToShortNames = {
  357: "read_audience_console",
  358: "write_audience_console",
  359: "admin_read_audience_console",
  360: "admin_write_audience_console"
};

export const shortNamesToIds = {
  read_audience_console: 357,
  write_audience_console: 358,
  admin_read_audience_console: 359,
  admin_write_audience_console: 360
};
