import { default as React } from "react";
import { NavBadgeFC } from "./NavBadgeComponent";
import { NavNode } from "../models/navigationModels";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface SitemapNavToggleProps {
  activeNavigationNode?: NavNode;
}

export const SitemapNavToggleFC: React.FC<SitemapNavToggleProps> = props => {
  return (
    <div>
      <FontAwesomeIcon
        icon={faBars}
        className={"mr-2 text-dark font-weight-bold"}
      />
      <span className={"d-none d-sm-inline"}>
        <span>
          {props.activeNavigationNode && props.activeNavigationNode.name}
        </span>
        {props.activeNavigationNode && props.activeNavigationNode.badgeText && (
          <NavBadgeFC
            text={props.activeNavigationNode.badgeText}
            backgroundColor={props.activeNavigationNode.badgeColor}
          />
        )}
      </span>
    </div>
  );
};
