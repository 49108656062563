import { readAttributeForestAF } from "../actions/attributeActions";
import {
  attributeSelectorChangeSearchTermAF,
  attributeSelectorChangeSearchTermDebouncedAF,
  attributeSelectorInitAF
} from "../actions/attributeSelectorActions";
import { State } from "../reducers";
import { getAttributesByBusinessUnitId } from "../selectors/audienceSelectors";
import {
  combineEpicMap,
  createEpic,
  EpicMap,
  ofType
} from "../../lib/epicFactory";
import { debounceTime, filter, map } from "rxjs/operators";

export const epicMap: EpicMap<State> = {
  debounceSearchTermEpic: createEpic((action$, state$) => {
    return action$.pipe(
      ofType(attributeSelectorChangeSearchTermAF),
      debounceTime(500),
      map(action => {
        return attributeSelectorChangeSearchTermDebouncedAF.create(
          action.payload,
          action.meta
        );
      })
    );
  }),
  attributeSelectorInitEpic: createEpic<State>((action$, state$) => {
    return action$.pipe(
      ofType(attributeSelectorInitAF),
      // If we have attribute data already, we don't need to dispatch readForest
      filter(() => {
        return !getAttributesByBusinessUnitId(state$.value);
      }),
      map(() => {
        return readAttributeForestAF.create({}, {});
      })
    );
  })
};

export const epics = combineEpicMap(epicMap);
