import { AsyncAF } from "../../lib/actionFactoryLib";
import { Attribute, FullAttribute } from "../api/attributeApi";

export const readAttributeAF = new AsyncAF<
  { attributeId: string },
  FullAttribute
>("READ_ATTRIBUTE");

export const readAttributeForestAF = new AsyncAF<{}, Attribute>(
  "READ_ATTRIBUTE_FOREST"
);
