export const NUMBER = "number";
export const STRING = "string";
export const DATE = "date";
export const BOOLEAN = "boolean";
export const MULTIPLE = "multiple";
export const DATE_FORMAT = "L";
export const TRUE_VALUE = "true";
export const FALSE_VALUE = "false";

export const DATA_TYPES = [STRING, NUMBER, BOOLEAN, DATE];
export const INVALID_VALUE_TYPES = [BOOLEAN, DATE];
