import { default as React } from "react";
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputProps
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

export interface SearchBarProps extends InputProps {
  disabled?: boolean;
  placeholder?: string;
  qaId?: string;
  className?: string;
  onClearClick(): void;
}

export class SearchBarFC extends React.PureComponent<SearchBarProps> {
  static defaultProps = {
    disabled: false,
    placeholder: "Search",
    qaId: "search-bar"
  };

  textInput: HTMLInputElement;

  constructor(props: SearchBarProps) {
    super(props);

    this.onClearClick = this.onClearClick.bind(this);
    this.refSearchInput = this.refSearchInput.bind(this);
  }

  onClearClick() {
    if (this.textInput) {
      this.textInput.focus();
    }
    this.props.onClearClick();
  }
  refSearchInput(input: HTMLInputElement) {
    this.textInput = input;
  }

  render() {
    const {
      disabled,
      onClearClick,
      placeholder,
      qaId,
      className,
      ...otherProps
    } = this.props;
    const inputId = `${qaId}-input`;
    const clearId = `${qaId}-clear-button`;

    return (
      <div data-qa-id={qaId} className={className}>
        <InputGroup>
          <InputGroupAddon addonType={"prepend"}>
            <InputGroupText>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            data-qa-id={inputId}
            innerRef={this.refSearchInput}
            placeholder={placeholder}
            disabled={disabled}
            {...otherProps}
          />
          <InputGroupAddon addonType={"append"}>
            <Button onClick={this.onClearClick} data-qa-id={clearId}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
    );
  }
}
