import { default as React } from "react";

interface GeoLocationProps {
  attributeId: string;
  geoLocationId: string;
}

interface GeoLocationProps extends StateProps, DispatchProps {}

export interface StateProps {
  geoLocationName: string;
}

export interface DispatchProps {
  onInit(): void;
}

export class GeoLocationNameComponent extends React.PureComponent<
  GeoLocationProps
> {
  async componentDidMount() {
    this.props.onInit();
  }

  render() {
    return <span>{this.props.geoLocationName}</span>;
  }
}
