import { default as React } from "react";
import { Route, Switch } from "react-router-dom";
import { ScrollToTop } from "../components/ScrollToTopComponent";
import { HomePage } from "./HomePage";
import { PageNotFound } from "./NotFoundPage";
import { ForbiddenPage } from "./ForbiddenPage";
import { AddAudiencePage } from "./AddAudiencePage";
import { UpdateAudiencePage } from "./UpdateAudiencePage";
import { ServerError } from "./ServerErrorPage";
import {
  HOME,
  AUDIENCE_ADD,
  AUDIENCE_UPDATE,
  FORBIDDEN,
  ERROR,
  NOT_FOUND,
  CRM,
  SCORE_SEGMENTS,
  CRM_SEGMENTS
} from "../constants/pathConstants";
import { shortNamesToIds } from "../constants/permissionConstants";
import { NoAccessFC } from "../components/Access/NoAccessComponent";
import { BusinessUnitAuthorizedContainer } from "../../template/containers/BusinessUnitAuthorizedContainer";
import { CrmPage } from "../crm";
import { ScoreSegmentsPage } from "../score";
import { Container } from "reactstrap";
import { ExternalSegmentsPage } from "../externalSegments/components/ExternalSegmentsPage";

export const routes = (
  <ScrollToTop>
    <Container fluid>
      <Switch>
        <Route exact={true} path={HOME} component={HomePage} />
        <Route path={AUDIENCE_ADD} component={AddAudiencePage} />
        <Route path={AUDIENCE_UPDATE} component={UpdateAudiencePage} />
        <Route path={FORBIDDEN} component={ForbiddenPage} />
        <Route path={ERROR} component={ServerError} />
        <Route path={NOT_FOUND} component={PageNotFound} />
        <Route path={CRM} component={CrmPage} />
        <Route path={SCORE_SEGMENTS} component={ScoreSegmentsPage} />
        <Route path={CRM_SEGMENTS} component={ExternalSegmentsPage} />
        <Route component={PageNotFound} />
      </Switch>
    </Container>
  </ScrollToTop>
);

// https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
// We use a pathless route in app that points to this component
export class AuthorizedRoutes extends React.PureComponent {
  render() {
    return (
      <BusinessUnitAuthorizedContainer
        permissionIds={[shortNamesToIds.read_audience_console]}
        deniedComponent={NoAccessFC}
      >
        {routes}
      </BusinessUnitAuthorizedContainer>
    );
  }
}
