import { default as React } from "react";
import { Link, LinkProps } from "react-router-dom";
import { default as URI } from "urijs";
import { isObject, isString } from "lodash";

export interface AnchorLinkProps extends LinkProps {
  target?: string;
}

export const AnchorLinkFC: React.FC<AnchorLinkProps> = props => {
  const { to, target, children, ...otherProps } = props;

  if (isObject(to) || (isString(to) && !URI(to).is("absolute"))) {
    return (
      <Link
        to={to}
        target={target !== "_self" ? target : undefined}
        {...otherProps}
      >
        {children}
      </Link>
    );
  }
  return (
    <a
      href={to.toString()}
      target={target}
      rel={target === "_blank" ? "noopener noreferrer" : ""}
      {...otherProps}
    >
      {children}
    </a>
  );
};

AnchorLinkFC.defaultProps = {
  target: "_self"
};
