import { actionTypes, ChangeAction } from "redux-form";
import { Condition } from "../models/conditionBuilderModels";
import {
  getAudience,
  getFullAttributeById
} from "../selectors/audienceSelectors";
import {
  queryToSegments,
  Criterion,
  isCriterion,
  getAttributeIdsFromCriterion
} from "../../lib/expressionBuilderLib";
import { flatten } from "lodash";
import {
  conditionFactory,
  ExpressionSegment
} from "../models/expressionBuilderModels";
import { Audience, audienceProps } from "../models/audienceModels";
import {
  expressionBuilderLoadAudienceAF,
  expressionBuilderSetExpressionAF,
  expressionBuilderStartingPopChangeAF,
  expressionBuilderInitAF
} from "../actions/expressionBuilder";
import { State } from "../reducers";
import {
  combineEpicMap,
  createEpic,
  EpicMap,
  ofType
} from "../../lib/epicFactory";
import { concat, defer, from, merge } from "rxjs";
import { ofType as ofTypeRx } from "redux-observable";
import { loadCondition } from "./attributeEpics";
import { filter, flatMap, map } from "rxjs/operators";
import { getSelectedBusinessUnit } from "../../template/selectors/businessUnitSelectors";
import { Action } from "../../lib/reduxLib";

interface CriterionMeta {
  attributeId: string;
  criterion: Criterion;
}

export const epicMap: EpicMap<State> = {
  expressionBuilderLoadAudienceEpic: createEpic<State>((action$, state$) => {
    return action$.pipe(
      ofType(expressionBuilderLoadAudienceAF),
      flatMap(action => {
        const audience = getAudience(state$.value, action.payload.audienceId);
        const businessUnit = getSelectedBusinessUnit(state$.value);

        if (audience) {
          const segments = queryToSegments(audience.query);

          let subQueryId: number = undefined;

          if (audience.starting_population_id) {
            const startingPop: Audience = getAudience(
              state$.value,
              audience.starting_population_id
            );
            subQueryId = startingPop && startingPop.query_id;
          }

          if (segments && segments.length) {
            const criterionMeta: CriterionMeta[] = segments
              .filter(isCriterion)
              .map(criterion => {
                return {
                  criterion,
                  attributeId: criterion.id
                };
              });
            const loadConditions$ = flatten(
              criterionMeta.map(meta => {
                return getAttributeIdsFromCriterion(meta.criterion).map(
                  attributeId => {
                    return loadCondition(attributeId, action$, state$);
                  }
                );
              })
            );

            return concat(
              merge(...loadConditions$),
              defer(() => {
                const conditions = criterionMeta
                  .filter(meta => {
                    // make sure all attributes for criterion are in state
                    const attributeIds = getAttributeIdsFromCriterion(
                      meta.criterion
                    );
                    return (
                      attributeIds.filter(attributeId => {
                        return !!getFullAttributeById(
                          state$.value,
                          attributeId
                        );
                      }).length === attributeIds.length
                    );
                  })
                  .map(meta => {
                    const attribute = getFullAttributeById(
                      state$.value,
                      meta.attributeId
                    );
                    const operator = attribute.attribute_operators.find(o => {
                      return o.graphql_value === meta.criterion.operator;
                    });

                    const subsetOperator = attribute.attribute_subset_operators.find(
                      o => {
                        return (
                          o.graphql_value === meta.criterion.subsetOperator
                        );
                      }
                    );

                    const condition: Condition = {
                      attribute,
                      operatorId: operator.id.toString(),
                      value: meta.criterion.value,
                      channel: attribute.channel,
                      subsetOperatorId: subsetOperator
                        ? subsetOperator.id.toString()
                        : undefined,
                      subsetValue: meta.criterion.subsetValue,
                      dataType: meta.criterion.dataType,
                      dataLocation: meta.criterion.dataLocation
                    };

                    return condition;
                  });
                const segmentsWithConditionSegments = segments.map(segment => {
                  if (isCriterion(segment)) {
                    return conditionFactory(conditions.shift());
                  }
                  return segment;
                });

                return from([
                  expressionBuilderInitAF.create(
                    { businessUnit },
                    {
                      builderId: action.meta.builderId
                    }
                  ),
                  expressionBuilderSetExpressionAF.create(
                    segmentsWithConditionSegments as ExpressionSegment[],
                    {
                      subQueryId,
                      builderId: action.meta.builderId
                    }
                  )
                ]);
              })
            );
          }

          return from([
            expressionBuilderInitAF.create(
              { businessUnit },
              {
                builderId: action.meta.builderId
              }
            )
          ]);
        }
        // This is where default value is truly set. Reducer will set to false if BU is EU
        return from([
          expressionBuilderInitAF.create(
            { businessUnit },
            {
              builderId: action.meta.builderId
            }
          )
        ]);
      })
    );
  }),
  reduxChangeActionEpic: createEpic<State>((action$, state$) => {
    return action$.pipe(
      ofTypeRx<Action, ChangeAction>(actionTypes.CHANGE),
      filter(action => {
        return action.meta.field === audienceProps.starting_population_id;
      }),
      map(action => {
        const startingPopId = action.payload;
        const startingPop: Audience =
          startingPopId !== 0 && startingPopId
            ? getAudience(state$.value, startingPopId)
            : undefined;

        return expressionBuilderStartingPopChangeAF.create(
          { startingPopId },
          {
            builderId: action.meta.form,
            subQueryId: startingPop && startingPop.query_id
          }
        );
      })
    );
  })
};

export const epics = combineEpicMap(epicMap);
