import { State } from "../reducers/";
import { State as TemplateState } from "../../../template/reducers";
import { CrmConfig, CrmMapping } from "../../api/attributeApi";
import { getSelectedBusinessUnitId } from "../../../template/selectors/businessUnitSelectors";
import { Audience } from "../../models/audienceModels";
import { orderBy } from "lodash";
import { createSelector } from "reselect";

export function getCrmMappings(state: State & TemplateState): CrmMapping[] {
  const buId = getSelectedBusinessUnitId(state);
  if (
    !state.crm.mappings.byBusinessUnitId[buId] ||
    !state.crm.mappings.byBusinessUnitId[buId].byCrmId
  ) {
    return [];
  }
  return Object.keys(state.crm.mappings.byBusinessUnitId[buId].byCrmId).map(
    crmId => {
      return state.crm.mappings.byBusinessUnitId[buId].byCrmId[crmId];
    }
  );
}

export function getCrmMappingsSort(state: State) {
  return state.crm.mappings.sort;
}

export const getSortedCrmMappings = createSelector(
  getCrmMappings,
  getCrmMappingsSort,
  (crmMappings, sort) => {
    return orderBy(crmMappings, sort.iteratees, sort.orders);
  }
);

export function getCrmMappingByAttributeId(
  state: State & TemplateState,
  attributeId: string
): CrmMapping {
  const buId = getSelectedBusinessUnitId(state);

  if (
    !state.crm.mappings.byBusinessUnitId[buId] ||
    !state.crm.mappings.byBusinessUnitId[buId].byCrmId
  ) {
    return undefined;
  }

  const crmId = Object.keys(
    state.crm.mappings.byBusinessUnitId[buId].byCrmId
  ).find(crmId => {
    return (
      state.crm.mappings.byBusinessUnitId[buId].byCrmId[crmId].attribute_id ===
      attributeId
    );
  });

  return state.crm.mappings.byBusinessUnitId[buId].byCrmId[crmId];
}

export function showCrmCreateModal(state: State): boolean {
  return state.crm.create.showModal;
}

export function showCrmUpdateModal(state: State): boolean {
  return state.crm.update.showModal;
}

export function getCrmUpdateMappingAttribute(state: State): CrmMapping {
  return state.crm.update.crmMapping;
}

export function getCrmUpdateUpdating(state: State): boolean {
  return state.crm.update.updating;
}

export function showCrmDeleteModal(state: State): boolean {
  return state.crm.delete.showModal;
}

export function getDeleteCrmMapping(state: State): CrmMapping {
  return state.crm.delete.crmMapping;
}

export function isFormSubmitted(state: State): boolean {
  return state.crm.create.isFormSubmit;
}

export function getDeleteAudiencesUsingMapping(state: State): Audience[] {
  return state.crm.delete.audiencesUsingMapping;
}

export function getIsGettingAudiencesUsingMapping(state: State): boolean {
  return state.crm.delete.isGettingAudiencesUsingMapping;
}

export function getDeleteIsDeleting(state: State): boolean {
  return state.crm.delete.isDeleting;
}

export function isGettingCrmMappings(state: State): boolean {
  return state.crm.mappings.isGettingMapping;
}

export function getCrmConfig(state: State & TemplateState): CrmConfig {
  const businessUnitId = getSelectedBusinessUnitId(state);
  return state.crm.config.byBusinessUnitId[businessUnitId];
}
