import { default as React } from "react";
import { default as DatePicker } from "react-datepicker";
import moment from "moment";
import {
  EXISTS,
  ON_OR_AFTER,
  ON_OR_BEFORE,
  X_DAYS_OR_LESS_AGO,
  X_DAYS_OR_MORE_AGO,
  EQUALS,
  NOT_EXISTS,
  GREATER_THAN,
  GREATER_THAN_OR_EQUALS,
  CONTAINS,
  LESS_THAN,
  LESS_THAN_OR_EQUALS
} from "../../constants/operatorConstants";
import {
  baseStyles,
  ConditionBuilderBaseProps,
  qaIds,
  inputIds,
  baseDefaultProps
} from "./SharedData";
import {
  DATE,
  DATE_FORMAT,
  NUMBER,
  STRING,
  MULTIPLE
} from "../../constants/attributeDataTypeNameConstants";
import { AttributeOperator, AttributeValue } from "../../api/attributeApi";
import { CustomInput, Input } from "reactstrap";
import { AttributeName } from "../AttributeName";

export const DataDrivenBuilderFC: React.FC<ConditionBuilderBaseProps> = props => {
  const exists = EXISTS.toString();
  const not_exists = NOT_EXISTS.toString();

  return (
    <div className={baseStyles.conditionContainerClass}>
      <div className={baseStyles.itemMarginClass}>
        <AttributeName
          attribute={props.attribute}
          qaNameId={qaIds.selectedConditionName}
          qaTooltipId={qaIds.selectedConditionTooltipPath}
          qaTooltipIconId={qaIds.selectedConditionTooltipIcon}
          qaTooltipPathId={qaIds.selectedConditionTooltipPath}
        />
      </div>
      {props.attribute.attribute_operators.length > 0 && (
        <div className={baseStyles.itemMarginClass}>
          <CustomInput
            id={inputIds.operatorId}
            value={props.operatorId}
            type={"select"}
            onChange={e => {
              props.handleChangeOperatorId(
                props.attribute,
                (e.currentTarget as any).value,
                props.value
              );
            }}
            data-qa-id={qaIds.selectedConditionOperator}
          >
            {props.attribute.attribute_operators.map(
              (operator: AttributeOperator) => {
                return (
                  <option value={operator.id} key={operator.id}>
                    {operator.name}
                  </option>
                );
              }
            )}
          </CustomInput>
        </div>
      )}
      {props.operatorId !== exists &&
        props.operatorId !== not_exists &&
        props.attribute.attribute_values.length > 0 && (
          <div className={baseStyles.itemMarginClass}>
            <CustomInput
              id={inputIds.attributeValues}
              value={props.value}
              type={"select"}
              onChange={e => {
                props.handleChangeValue(
                  props.attribute,
                  props.operatorId,
                  (e.currentTarget as any).value
                );
              }}
              data-qa-id={qaIds.selectedConditionValue}
            >
              {props.attribute.attribute_values.map((v: AttributeValue) => {
                return (
                  <option value={v.value} key={v.id}>
                    {v.display_name}
                  </option>
                );
              })}
            </CustomInput>
          </div>
        )}
      {props.operatorId !== exists &&
        props.operatorId !== not_exists &&
        props.attribute.attribute_values.length === 0 && (
          <div className={baseStyles.itemMarginClass}>
            {(props.attribute.data_type_name === STRING ||
              props.attribute.data_type_name === NUMBER) && (
              <Input
                id={inputIds.value}
                type={"text"}
                value={props.value}
                onChange={e => {
                  props.handleChangeValue(
                    props.attribute,
                    props.operatorId,
                    (e.currentTarget as any).value
                  );
                }}
                data-qa-id={qaIds.selectedConditionValue}
              />
            )}
            {(props.attribute.data_type_name === DATE ||
              props.attribute.data_type_name === MULTIPLE) && (
              <React.Fragment>
                {(props.operatorId === ON_OR_BEFORE.toString() ||
                  props.operatorId === ON_OR_AFTER.toString()) && (
                  <div data-qa-id={qaIds.selectedConditionValue}>
                    <DatePicker
                      id={inputIds.value}
                      selected={props.value ? moment(props.value) : null}
                      onChange={(date: moment.Moment) => {
                        props.handleChangeValue(
                          props.attribute,
                          props.operatorId,
                          date ? date.format(DATE_FORMAT) : ""
                        );
                      }}
                      className="form-control"
                    />
                  </div>
                )}
                {(props.operatorId === GREATER_THAN.toString() ||
                  props.operatorId === GREATER_THAN_OR_EQUALS.toString() ||
                  props.operatorId === CONTAINS.toString() ||
                  props.operatorId === LESS_THAN.toString() ||
                  props.operatorId === LESS_THAN_OR_EQUALS.toString() ||
                  props.operatorId === EQUALS.toString()) && (
                  <Input
                    id={inputIds.value}
                    type={"text"}
                    value={props.value}
                    onChange={e => {
                      props.handleChangeValue(
                        props.attribute,
                        props.operatorId,
                        (e.currentTarget as any).value
                      );
                    }}
                    data-qa-id={qaIds.selectedConditionValue}
                  />
                )}
                {(props.operatorId === X_DAYS_OR_MORE_AGO.toString() ||
                  props.operatorId === X_DAYS_OR_LESS_AGO.toString()) && (
                  <div className={"d-flex align-items-center"}>
                    <div className={"mr-3"}>
                      <Input
                        id={inputIds.value}
                        type={"text"}
                        value={props.value}
                        onChange={e => {
                          props.handleChangeValue(
                            props.attribute,
                            props.operatorId,
                            (e.currentTarget as any).value
                          );
                        }}
                        data-qa-id={qaIds.selectedConditionValue}
                      />
                    </div>
                    <div className={"text-nowrap"}>
                      {props.operatorId === X_DAYS_OR_LESS_AGO.toString() &&
                        "days or less ago"}
                      {props.operatorId === X_DAYS_OR_MORE_AGO.toString() &&
                        "days or more ago"}
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        )}
    </div>
  );
};

DataDrivenBuilderFC.defaultProps = {
  ...baseDefaultProps
};
