import { default as React } from "react";
import {
  Row,
  Col,
  Table,
  DropdownMenu,
  DropdownItem,
  Button,
  DropdownToggle,
  UncontrolledDropdown,
  Progress
} from "reactstrap";
import { ExternalSegment, externalSegmentProps } from "../../api/attributeApi";
import { SortableTableHeaderFC } from "../../../bootstrap";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { ExternalSegmentCreateModalContainer } from "../containers/ExternalSegmentCreateModalContainer";
import { ExternalSegmentUpdateModalContainer } from "../containers/ExternalSegmentUpdateModalContainer";
import { LinkContainer } from "../../../template/containers/LinkContainer";
import { ExternalSegmentDeleteModalContainer } from "../containers/ExternalSegmentDeleteModalContainer";

export interface Props extends StateProps, DispatchProps {}

export interface StateProps {
  externalSegments: ExternalSegment[];
  sort: { iteratees: string[]; orders: string[] };
  isGettingExternalSegments: boolean;
  showCreateModal: boolean;
  showUpdateModal: boolean;
  showDeleteModal: boolean;
  hasWriteAccess: boolean;
}

export interface DispatchProps {
  onSort(sortKey: string): void;
  onCreate(): void;
  onUpdate(segment: ExternalSegment): void;
  onDelete(segment: ExternalSegment): void;
}

export const ExternalSegmentFC: React.FC<Props> = props => {
  return (
    <React.Fragment>
      <Row>
        <Col>
          <Breadcrumb>
            <BreadcrumbItem active>Admin</BreadcrumbItem>
            <BreadcrumbItem active>CRM Segments</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      {props.isGettingExternalSegments ? (
        <Row>
          <Col>
            <Progress value={100} animated color={"primary"} />
          </Col>
        </Row>
      ) : (
        <React.Fragment>
          <Row>
            <Col>
              {props.showCreateModal && <ExternalSegmentCreateModalContainer />}
              {props.showUpdateModal && <ExternalSegmentUpdateModalContainer />}
              {props.showDeleteModal && <ExternalSegmentDeleteModalContainer />}
            </Col>
          </Row>
          {props.hasWriteAccess && (
            <Row>
              <Col
                className={
                  "d-flex align-items-center justify-content-between mb-3"
                }
              >
                <Button
                  color="primary"
                  data-qa-id="external-segment-add"
                  disabled={props.isGettingExternalSegments}
                  onClick={() => {
                    props.onCreate();
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                  Segment
                </Button>
              </Col>
            </Row>
          )}
          {props.externalSegments.length > 0 && (
            <Row>
              <Col>
                <Table hover striped bordered>
                  <thead>
                    <tr>
                      <SortableTableHeaderFC
                        sortKey={externalSegmentProps.name}
                        iteratees={props.sort.iteratees}
                        orders={props.sort.orders}
                        onClick={props.onSort}
                      >
                        Name
                      </SortableTableHeaderFC>
                      <SortableTableHeaderFC
                        sortKey={externalSegmentProps.external_segment_id}
                        iteratees={props.sort.iteratees}
                        orders={props.sort.orders}
                        onClick={props.onSort}
                      >
                        External Segment ID
                      </SortableTableHeaderFC>
                      <SortableTableHeaderFC
                        sortKey={externalSegmentProps.attribute_id}
                        iteratees={props.sort.iteratees}
                        orders={props.sort.orders}
                        onClick={props.onSort}
                      >
                        Attribute ID
                      </SortableTableHeaderFC>
                      <th>Predicted Count</th>
                      <th>Segment Type</th>
                      {props.hasWriteAccess && <th>&nbsp;</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {props.externalSegments.map(segment => {
                      return (
                        <tr
                          key={segment.id}
                          data-qa-id={`external-segment-${segment.id}`}
                        >
                          <td className={"text-break"}>
                            <div className={"text-break"}>
                              <LinkContainer
                                to=""
                                onClick={() => {
                                  props.onUpdate(segment);
                                }}
                              >
                                {segment.name}
                              </LinkContainer>
                            </div>
                          </td>
                          <td>{segment.external_segment_id}</td>
                          <td>{segment.attribute_id}</td>
                          <td>{segment.score}</td>
                          <td>{segment.external_segment_type}</td>
                          <td style={{ textAlign: "center" }}>
                            <UncontrolledDropdown
                              id={`external_segment_${segment.id}`}
                            >
                              <DropdownToggle
                                size={"xs"}
                                data-qa-id={`external_segment-${
                                  segment.id
                                }-dropdown-button`}
                              >
                                <FontAwesomeIcon icon={faEllipsisH} />
                              </DropdownToggle>
                              <DropdownMenu right={true}>
                                <DropdownItem
                                  data-qa-id={`external_segment-${
                                    segment.id
                                  }-edit-link`}
                                  onClick={() => {
                                    props.onUpdate(segment);
                                  }}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  data-qa-id={`external_segment-${
                                    segment.id
                                  }-delete-link`}
                                  onClick={() => {
                                    props.onDelete(segment);
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
          {!props.externalSegments.length && (
            <p data-qa-id="no-segments-text" className={"text-center"}>
              No segments have been created yet.
            </p>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
