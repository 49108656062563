import { Dispatch } from "redux";
import {
  reduxForm,
  change,
  isInvalid,
  getFormSyncErrors,
  getFormValues
} from "redux-form";
import { connect } from "react-redux";
import {
  getExpressionBuilderExpansionState,
  getExpressionBuilderChannel
} from "../selectors/expressionBuilderSelector";
import { getConditionBuilderMode } from "../selectors/conditionBuilderSelector";
import {
  createAudienceAF,
  readAllLabelsAF,
  refreshAllAudiencesAF
} from "../actions/audienceActions";
import { maskSpecialChars } from "../constants/sharedConstants";
import {
  AddAudiencePureComponent,
  StateProps,
  DispatchProps,
  AddAudienceProps
} from "../components/AddAudienceComponent";
import {
  getAudience,
  getSuggestions,
  getStartingPopsForAudience
} from "../selectors/audienceSelectors";
import {
  Audience,
  audienceProps,
  CreateAudience
} from "../models/audienceModels";
import { State } from "../reducers";
import { getSelectedBusinessUnit } from "../../template/selectors/businessUnitSelectors";
import { expressionBuilderInitAF } from "../actions/expressionBuilder";
import { NormalizedBusinessUnit } from "../../template/models/businessUnitModels";

export const formName = "addAudience";

function audienceIdCreator(str: string) {
  return maskSpecialChars(str);
}

interface FormValues {
  starting_population_id: number;
}

function mapStateToProps(state: State): StateProps {
  const formValues: FormValues = getFormValues(formName)(state) as any;
  const errors = getFormSyncErrors(formName)(state);
  const businessUnit = getSelectedBusinessUnit(state);
  const startingPopulations = getStartingPopsForAudience(state, undefined);
  const audienceLabelSuggestions = getSuggestions(state);
  let startingPop: Audience = undefined;
  if (
    formValues &&
    formValues.starting_population_id !== 0 &&
    formValues.starting_population_id
  ) {
    startingPop = getAudience(state, formValues.starting_population_id);
  }
  const expandExpressionBuilder = getExpressionBuilderExpansionState(
    state,
    formName
  );
  const conditionBuilderMode = getConditionBuilderMode(state, formName);
  const channel = getExpressionBuilderChannel(state, formName);

  return {
    expandExpressionBuilder,
    businessUnit,
    startingPopulations,
    audienceLabelSuggestions,
    conditionBuilderMode,
    startingPop,
    channel,
    builderId: formName,
    initialValues: {
      business_unit_id: businessUnit.id,
      is_active: true,
      is_locked: false,
      labels: []
    },
    isSubmitDisabled: isInvalid(formName)(state),
    isNameValid: !(errors && (errors as any).name),
    parentAudiences: [] as Audience[],
    onCustomIdChange(value: string) {
      return audienceIdCreator(value);
    }
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    onInit(businessUnit: NormalizedBusinessUnit) {
      dispatch(refreshAllAudiencesAF.create({}, {}));
      dispatch(
        expressionBuilderInitAF.create(
          { businessUnit },
          { builderId: formName }
        )
      );
      dispatch(readAllLabelsAF.create({}, {}));
    },
    onNameChange(name: string) {
      // update the custom identifier when the name changes
      dispatch(
        change(
          formName,
          audienceProps.custom_identifier,
          audienceIdCreator(name)
        )
      );
    },
    onStartingPopChange(id: number, fieldName: string) {
      dispatch(change(formName, fieldName, id));
    },
    onBusinessUnitChange(businessUnitId: number, fieldName: string) {
      dispatch(change(formName, fieldName, businessUnitId));
    }
  };
}

const containerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
);
const formWrapper = reduxForm<CreateAudience, AddAudienceProps>({
  form: formName,
  onSubmit(audience, dispatch) {
    dispatch(createAudienceAF.create({ audience, builderId: formName }, {}));
  }
});
export const AddAudienceContainer = containerWrapper(
  formWrapper(AddAudiencePureComponent)
);
