import { default as React } from "react";
import { default as Highlighter } from "react-highlight-words";
import { NormalizedBusinessUnit as IBusinessUnit } from "../models/businessUnitModels";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./BusinessUnitComponent.module.scss";
import classNames from "classnames";

export interface BusinessUnitProps {
  businessUnit: IBusinessUnit;
  query: string;
  selectedBusinessUnitId?: number;
  onClick(businessUnit: IBusinessUnit): void;
}

export const BusinessUnitFC: React.FC<BusinessUnitProps> = props => {
  const isSearchMode = !!props.query;

  return (
    <li
      className={classNames(
        styles.businessUnit,
        isSearchMode &&
          props.selectedBusinessUnitId === props.businessUnit.id &&
          styles.selected
      )}
      onClick={() => {
        props.onClick(props.businessUnit);
      }}
      data-qa-id={props.businessUnit.id}
    >
      <span className={styles.link}>
        {(props.businessUnit.thumbnail || props.businessUnit.logo) && (
          <img
            className={styles.icon}
            src={props.businessUnit.thumbnail || props.businessUnit.logo}
            alt="thumbnail"
          />
        )}
        {!(props.businessUnit.thumbnail || props.businessUnit.logo) && (
          <FontAwesomeIcon icon={faBuilding} className={styles.icon} />
        )}
        <span className={styles.name} data-qa-id="business-unit-name">
          {isSearchMode && (
            <Highlighter
              highlightClassName={styles.highlight}
              searchWords={[props.query]}
              textToHighlight={props.businessUnit.name}
            />
          )}
          {!isSearchMode && props.businessUnit.name}
        </span>
        <span className={`path ${styles.path}`} data-qa-id="business-unit-path">
          {isSearchMode && (
            <Highlighter
              highlightClassName={styles.highlight}
              searchWords={[props.query]}
              textToHighlight={props.businessUnit.path}
            />
          )}
          {!isSearchMode && props.businessUnit.path}
        </span>
      </span>
    </li>
  );
};
