import {
  connectWithLifecycle,
  LifecycleDispatchProps
} from "react-lifecycle-component";
import { State } from "../../reducers";
import { Dispatch } from "redux";
import { reduxForm, formValueSelector } from "redux-form";
import {
  closeUpdateModalAF,
  updateCrmMappingAF
} from "../actions/updateActions";
import {
  getCrmUpdateUpdating,
  getCrmUpdateMappingAttribute,
  getCrmMappings
} from "../selectors/crmSelectors";
import { CrmMapping, crmProps, attributeProps } from "../../api/attributeApi";
import {
  StateProps,
  DispatchProps,
  Props,
  CrmMappingUpdateModalFC
} from "../components/CrmMappingUpdateModalComponent";

const FORM_NAME = "crm_mapping_update";
const formSelector = formValueSelector(FORM_NAME);

function mapStateToProps(state: State): StateProps {
  const crmMapping = getCrmUpdateMappingAttribute(state);

  return {
    crmMapping,
    allMappings: getCrmMappings(state),
    updating: getCrmUpdateUpdating(state),
    dataType: formSelector(
      state,
      `${crmProps.attribute}.${attributeProps.data_type_name}`
    ),
    initialValues: crmMapping
  };
}

function mapDispatchToProps(
  dispatch: Dispatch
): DispatchProps & LifecycleDispatchProps<Props> {
  return {
    componentDidMount() {},
    closeModal() {
      dispatch(closeUpdateModalAF.create({}, {}));
    }
  };
}

const containerWrapper = connectWithLifecycle(
  mapStateToProps,
  mapDispatchToProps
);
const formWrapper = reduxForm<CrmMapping, Props>({
  form: FORM_NAME,
  onSubmit(crmMapping: CrmMapping, dispatch: Dispatch<any>) {
    dispatch(updateCrmMappingAF.create(crmMapping, {}));
  }
});

export const CrmMappingUpdateModalContainer = containerWrapper(
  formWrapper(CrmMappingUpdateModalFC)
);
