import {
  FetchNavigationNodesAction,
  fetchNavigationNodesRejected,
  fetchNavigationNodesFulfilled,
  fetchNavigationNodesPending
} from "../actions/navigationActions";
import { fetchNavigation } from "../api/templateApi";
import { ActionTypes } from "../constants/templateConstants";
import { Action } from "../../lib/reduxLib";
import { State } from "../reducers";
import { combineEpics, Epic, ofType } from "redux-observable";
import { concat, of } from "rxjs";
import { catchError, flatMap, map } from "rxjs/operators";

export const onFetchNavigationNodesEpic: Epic<Action, Action, State> = (
  action$,
  state$
) => {
  return action$.pipe(
    ofType<Action, FetchNavigationNodesAction>(
      ActionTypes.FETCH_NAVIGATION_NODES
    ),
    flatMap(action => {
      return concat(
        of(
          fetchNavigationNodesPending(
            action.payload.businessUnitId,
            action.payload.navConfig
          )
        ),
        fetchNavigation(action.payload.businessUnitId).pipe(
          map(navigationData => {
            return fetchNavigationNodesFulfilled(
              navigationData,
              action.payload.businessUnitId,
              action.payload.navConfig
            );
          }),
          catchError(error => {
            return of(
              fetchNavigationNodesRejected(
                error,
                action.payload.businessUnitId,
                action.payload.navConfig
              )
            );
          })
        )
      );
    })
  );
};

export const epics = combineEpics(onFetchNavigationNodesEpic);
