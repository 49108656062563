import { Dispatch } from "redux";
import {
  connectWithLifecycle,
  LifecycleDispatchProps
} from "react-lifecycle-component";
import { State } from "../../reducers";
import {
  closeDeleteModalAF,
  deleteExternalSegmentAF
} from "../actions/externalSegmentDeleteActions";
import {
  getDeleteAudiencesUsingExternalSegment,
  getDeleteExternalSegment,
  getDeleteIsDeleting,
  getIsGettingAudiencesUsingExternalSegment
} from "../selectors/externalSegmentSelectors";
import { ExternalSegment } from "../../api/attributeApi";
import {
  StateProps,
  DispatchProps,
  Props,
  ExternalSegmentDeleteModalFC
} from "../components/ExternalSegmentDeleteModalComponent";

function mapStateToProps(state: State): StateProps {
  return {
    externalSegment: getDeleteExternalSegment(state),
    audiencesUsingExternalSegment: getDeleteAudiencesUsingExternalSegment(
      state
    ),
    isGettingAudiencesUsingExternalSegment: getIsGettingAudiencesUsingExternalSegment(
      state
    ),
    isDeleting: getDeleteIsDeleting(state)
  };
}

function mapDispatchToProps(
  dispatch: Dispatch
): DispatchProps & LifecycleDispatchProps<Props> {
  return {
    componentDidMount() {},
    closeModal() {
      dispatch(closeDeleteModalAF.create({}, {}));
    },
    onDelete(externalSegment: ExternalSegment) {
      dispatch(deleteExternalSegmentAF.create(externalSegment, {}));
    }
  };
}

export const ExternalSegmentDeleteModalContainer = connectWithLifecycle(
  mapStateToProps,
  mapDispatchToProps
)(ExternalSegmentDeleteModalFC);
