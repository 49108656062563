const idToken = ":id";
const PUBLIC_URL = process.env.PUBLIC_URL || "";

function replaceId(path: string, id: number) {
  return path.replace(idToken, id.toString());
}

export const FORBIDDEN = `${PUBLIC_URL}/forbidden`;
export const HOME = `${PUBLIC_URL}/`;
export const AUDIENCE_ADD = `${PUBLIC_URL}/add`;
export const BASE_STYLES = `${PUBLIC_URL}/base-styles`;
export const ERROR = `${PUBLIC_URL}/error`;
export const ACTIVATIONS = `${PUBLIC_URL}/activations`;
export const NOT_FOUND = `${PUBLIC_URL}/not-found`;

export const AUDIENCE_UPDATE = `${PUBLIC_URL}/update/${idToken}`;
export const AUDIENCE_REMOVE = `${PUBLIC_URL}/remove/${idToken}`;

export const ADMIN = `${PUBLIC_URL}/admin`;
export const CRM = `${ADMIN}/crm`;
export const SCORE_SEGMENTS = `${ADMIN}/score-segments`;
export const CRM_SEGMENTS = `${ADMIN}/crm-segments`;

export function getAudienceUpdatePath(id: number) {
  return replaceId(AUDIENCE_UPDATE, id);
}
export function getAudienceRemovePath(id: number) {
  return replaceId(AUDIENCE_REMOVE, id);
}
