import { Action } from "redux";
import {
  closeCreateModalAF,
  openCreateModalAF,
  createScoreSegmentAF,
  createSetIsMax
} from "../actions/createActions";

export interface State {
  isMax: boolean;
  showModal: boolean;
  isFormSubmitting: boolean;
}

export const scoreCreateInitialState: State = {
  isMax: true,
  showModal: false,
  isFormSubmitting: false
};

export function createReducer(
  previousState: State = scoreCreateInitialState,
  action: Action
): State {
  if (openCreateModalAF.isAction(action)) {
    return {
      ...previousState,
      showModal: true
    };
  }
  if (closeCreateModalAF.isAction(action)) {
    return scoreCreateInitialState;
  }
  if (createScoreSegmentAF.pendingAF.isAction(action)) {
    return {
      ...previousState,
      isFormSubmitting: true
    };
  }
  if (createScoreSegmentAF.rejectedAF.isAction(action)) {
    return {
      ...previousState,
      isFormSubmitting: false
    };
  }
  if (createScoreSegmentAF.fulfilledAF.isAction(action)) {
    return scoreCreateInitialState;
  }
  if (createSetIsMax.isAction(action)) {
    return {
      ...previousState,
      isMax: action.payload.isMax
    };
  }
  return previousState;
}
