import { numberValidator } from "./numberValidator";
import { createStringValidator } from "./stringValidator";
import {
  X_DAYS_OR_LESS_AGO,
  X_DAYS_OR_MORE_AGO
} from "../../constants/operatorConstants";
import {
  BOOLEAN,
  DATE,
  NUMBER,
  STRING
} from "../../constants/attributeDataTypeNameConstants";
import { dateValidator } from "./dateValidator";
import { Validator } from "./sharedInterfaces";
import { booleanValidator } from "./booleanValidator";
import { FullAttribute } from "../../api/attributeApi";

const stringValidator = createStringValidator(0);

function validateValue(
  builderId: string,
  newValue: string,
  oldValue: string,
  operatorId: string,
  attribute: FullAttribute
): boolean {
  if (attribute.data_type_name === STRING) {
    return stringValidator.validateValue(
      builderId,
      newValue,
      oldValue,
      operatorId,
      attribute
    );
  }
  if (attribute.data_type_name === NUMBER) {
    return numberValidator.validateValue(
      builderId,
      newValue,
      oldValue,
      operatorId,
      attribute
    );
  }

  if (attribute.data_type_name === DATE) {
    if (
      operatorId === X_DAYS_OR_LESS_AGO.toString() ||
      operatorId === X_DAYS_OR_MORE_AGO.toString()
    ) {
      return numberValidator.validateValue(
        builderId,
        newValue,
        oldValue,
        operatorId,
        attribute
      );
    }
    return dateValidator.validateValue(
      builderId,
      newValue,
      oldValue,
      operatorId,
      attribute
    );
  }

  if (attribute.data_type_name === BOOLEAN) {
    return booleanValidator.validateValue(
      builderId,
      newValue,
      oldValue,
      operatorId,
      attribute
    );
  }

  return true;
}

export const dataDrivenValidator: Validator = {
  validateValue
};
