import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { State } from "../reducers";
import {
  getBusinessUnitsPending,
  getBusinessUnitsError
} from "../selectors/businessUnitSelectors";
import {
  MainContentFC as MainContentComponent,
  StateProps
} from "../components/MainContentComponent";

function mapStateToProps(state: State): StateProps {
  return {
    pending: getBusinessUnitsPending(state),
    error: getBusinessUnitsError(state)
  };
}

const connectedMainContent = connect(mapStateToProps)(MainContentComponent);
export const MainContentContainer = withRouter(connectedMainContent);
