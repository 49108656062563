import { default as React } from "react";
import { SearchBarFC } from "../../bootstrap";
import { BusinessUnitListFC } from "./BusinessUnitListComponent";
import { IoneSpinnerFC } from "./IoneSpinnerComponent";
import { NormalizedBusinessUnit } from "../models/businessUnitModels";
import styles from "./BusinessUnitSelectorContentComponent.module.scss";

export interface BusinessUnitSelectorContentProps {
  pending: boolean;
  error: boolean;
  businessUnits: NormalizedBusinessUnit[];
  selectedBusinessUnit?: NormalizedBusinessUnit;
  recentBusinessUnits: NormalizedBusinessUnit[];
  query: string;
  query_debounced: string;
  onBusinessUnitSelect(businessUnit: NormalizedBusinessUnit): void;
  onBusinessUnitSearch(query: string): void;
  onClearBusinessUnitSearch(): void;
}

export const BusinessUnitSelectorContentFC: React.FC<
  BusinessUnitSelectorContentProps
> = props => {
  return (
    <div className={styles.content}>
      <SearchBarFC
        disabled={props.error || props.pending}
        onChange={e => {
          props.onBusinessUnitSearch((e.target as any).value);
        }}
        value={props.query}
        onClearClick={props.onClearBusinessUnitSearch}
        qaId="business-unit-search-bar"
      />
      {!props.error && !props.pending && (
        <BusinessUnitListFC
          businessUnits={props.businessUnits}
          selectedBusinessUnit={props.selectedBusinessUnit}
          recentBusinessUnits={props.recentBusinessUnits}
          onBusinessUnitSelect={props.onBusinessUnitSelect}
          onViewRecentClick={props.onClearBusinessUnitSearch}
          query={props.query_debounced}
        />
      )}
      {props.pending && (
        <div className={styles.loadingIndicator}>
          <div>
            <IoneSpinnerFC />
          </div>
        </div>
      )}
    </div>
  );
};
