import { createReducer, State as CreateState } from "./createReducer";
import { updateReducer, State as UpdateState } from "./updateReducer";
import { deleteReducer, State as DeleteState } from "./deleteReducer";
import {
  scoreSegmentAttributeReducer,
  State as ScoreSegmentState
} from "./readReducer";
import { combineReducers } from "redux";

export const reducers = {
  score: combineReducers({
    create: createReducer,
    update: updateReducer,
    delete: deleteReducer,
    read: scoreSegmentAttributeReducer
  })
};

export interface State {
  score: {
    create: CreateState;
    update: UpdateState;
    delete: DeleteState;
    read: ScoreSegmentState;
  };
}
