import { isFunction } from "lodash";
import { Store } from "redux";
import { env } from "../../ac/env/appEnv";
import { State } from "../reducers";
import { getUserToken } from "../selectors/userSelectors";

export function getLoginRedirectUrl() {
  return `${env.REACT_APP_LOGIN_PAGE}/?redirect=${encodeURIComponent(
    window.location.href
  )}`;
}

function userTokenFactory(store: Store<State>) {
  return () => {
    return getUserToken(store.getState());
  };
}

export class TokenProvider {
  private static factory: () => string = null;

  static setStore(store: Store<State>) {
    TokenProvider.factory = userTokenFactory(store);
  }

  static getToken() {
    if (TokenProvider.factory === null) {
      throw new Error("TokenProvider factory not set");
    } else if (!isFunction(TokenProvider.factory)) {
      throw new Error("TokenProvider factory not a valid function");
    }

    return TokenProvider.factory();
  }
}
