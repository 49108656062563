import { default as React } from "react";
import styles from "./GravatarComponent.module.scss";

export interface GravatarProps extends StateProps, DispatchProps {}

export interface StateProps {
  hash: string;
}

export interface DispatchProps {}

export const GravatarFC: React.FC<GravatarProps> = props => {
  return (
    <img
      className={styles.gravatar}
      src={`https://www.gravatar.com/avatar/${props.hash}?s=28&d=mm`}
      alt="gravatar"
    />
  );
};
