import { default as React } from "react";
import {
  Modal,
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Spinner
} from "reactstrap";
import { InjectedFormProps, Form } from "redux-form";
import { PartialScoreSegment, ScoreSegment } from "../../api/attributeApi";
import { DataPoint } from "highcharts";
import { ScoreRangeFC, Range } from "./ScoreRangeComponent";
import { Audience } from "../../models/audienceModels";
import { LinkContainer } from "../../../template/containers/LinkContainer";
import { getAudienceUpdatePath } from "../../constants/pathConstants";
import { getNameFieldName, getRangeFieldName } from "../constants/score";

export interface InjectedProps
  extends Props,
    InjectedFormProps<PartialScoreSegment, Props, string> {}
export interface Props extends StateProps, DispatchProps {}

export interface StateProps {
  scoreSegments: ScoreSegment[];
  open: boolean;
  scoreSegment: ScoreSegment;
  initialRanges: Range[];
  initialValues: Partial<PartialScoreSegment>;
  dataPoints: DataPoint[];
  goal: ScoreSegment;
  goals: ScoreSegment[];
  name: string;
  ranges: Range[];
  isFormSubmitting: boolean;
  isMax: boolean;
  audiencesUsingScoreSegment: Audience[];
  isGettingAudiencesUsingScoreSegment: boolean;
}

export interface DispatchProps {
  closeModal(): void;
  onGoalChange(goal: ScoreSegment): void;
  onSegmentNameChange(index: number, name: string): void;
  onRangesChange(ranges: Range[]): void;
  onSetIsMax(max: boolean): void;
}

function nameIsUnique(
  value: string,
  allValues: PartialScoreSegment,
  props: Props
): string | undefined {
  const scoreSegment = props.scoreSegments.find(
    scoreSegment => scoreSegment.attribute.name === value
  );

  if (scoreSegment && scoreSegment.id !== props.scoreSegment.id) {
    return "Score Segment with this name already exists";
  }
}

export const ScoreSegmentUpdateModalFC: React.FC<InjectedProps> = props => {
  return (
    <Modal
      isOpen={props.open}
      toggle={props.closeModal}
      size="lg"
      data-qa-id="update-score-modal"
    >
      <Form onSubmit={props.handleSubmit}>
        <ModalHeader toggle={props.closeModal}>
          Edit Score Segment(s)
        </ModalHeader>
        <ModalBody>
          <ScoreRangeFC
            nameIsUnique={nameIsUnique}
            initialNumberOfSegments={3}
            initialRanges={props.initialRanges}
            dataPoints={props.dataPoints}
            goal={props.goal}
            goals={props.goals}
            ranges={props.ranges}
            isMax={props.isMax}
            isEdit={true}
            onGoalChange={props.onGoalChange}
            onSegmentNameChange={props.onSegmentNameChange}
            onRangesChange={props.onRangesChange}
            getNameFieldName={getNameFieldName}
            getRangeFieldName={getRangeFieldName}
            onSetIsMax={props.onSetIsMax}
          />
        </ModalBody>
        <ModalFooter
          className={"d-flex justify-content-between align-items-center"}
        >
          {props.isGettingAudiencesUsingScoreSegment && (
            <div>
              Checking audience usage of trait "
              {props.scoreSegment.attribute.name}
              "&nbsp;
              <Spinner size={"sm"} />
            </div>
          )}
          {!props.isGettingAudiencesUsingScoreSegment &&
            props.audiencesUsingScoreSegment.length > 0 && (
              <div data-qa-id={"score-segment-update-audience-uses-container"}>
                <div>
                  Updating "{props.scoreSegment.attribute.name}" will affect the
                  following audiences:
                </div>
                <div>
                  {props.audiencesUsingScoreSegment.map((audience, index) => {
                    return (
                      <React.Fragment>
                        <LinkContainer
                          key={audience.audience_id}
                          to={getAudienceUpdatePath(audience.audience_id)}
                          data-qa-id={`score-segment-update-used-by-audience-link-${audience.audience_id}`}
                          target={"_blank"}
                        >
                          {audience.name}
                        </LinkContainer>
                        {props.audiencesUsingScoreSegment.length - 1 !==
                          index && <span>, </span>}
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            )}
          {!props.isGettingAudiencesUsingScoreSegment &&
            props.audiencesUsingScoreSegment.length === 0 && (
              <div>No audience is currently using these score segments</div>
            )}
          <div>
            <Button
              onClick={props.closeModal}
              data-qa-id="score-segment-update-cancel"
              disabled={props.isFormSubmitting}
              className={"mr-2"}
              color="link"
            >
              Cancel
            </Button>
            <Button
              color={"primary"}
              disabled={
                props.isGettingAudiencesUsingScoreSegment ||
                !props.valid ||
                props.isFormSubmitting
              }
              onClick={props.handleSubmit}
              data-qa-id="score-segment-update-submit"
            >
              Submit
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
