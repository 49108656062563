import { getGeoLocationNameFromApiAF } from "../actions/audienceActions";
import { Action } from "redux";

export interface State {
  byTypeId: {
    [geoType: string]: {
      [geoId: string]: string;
    };
  };
}

const INITIAL_STATE: State = {
  byTypeId: {}
};

export function geoLocationReducer(
  previousState: State = INITIAL_STATE,
  action: Action
): State {
  if (getGeoLocationNameFromApiAF.fulfilledAF.isAction(action)) {
    return {
      ...previousState,
      byTypeId: {
        ...previousState.byTypeId,
        [action.payload.geoType]: {
          ...previousState.byTypeId[action.payload.geoType],
          [action.payload.geoId]: action.payload.name
        }
      }
    };
  }

  return previousState;
}
