const baseEnv = window.ione_env;

export interface AppEnv extends Env {
  dataCenters: { [id: number]: DataCenter };
}

export interface DataCenter {
  id: number;
  domain: string;
}

const environment: AppEnv = {
  ...baseEnv,
  dataCenters: {}
};

const dataCenterPrefix = "REACT_APP_API_DOMAIN_FOR_DATA_CENTER_";
const dataCenterIdRegex = new RegExp(`^${dataCenterPrefix}(\\d+)$`);

Object.keys(environment)
  .map(key => {
    return key.match(dataCenterIdRegex);
  })
  .filter(matches => {
    return matches && matches.length > 0;
  })
  .map(matches => {
    // this non-null assertion is only valid because of the filter above
    return parseInt(matches![1], 10);
  })
  .forEach(id => {
    environment.dataCenters[id] = {
      id,
      domain: (environment as any)[`${dataCenterPrefix}${id}`] as string
    };
  });

export function getDataCenterDomain(
  dataCenterId: number,
  dataCenters: { [id: number]: DataCenter }
): string {
  return dataCenters[dataCenterId] ? dataCenters[dataCenterId].domain : "";
}

export const env = environment;
