import { default as React } from "react";
import {
  Modal,
  Button,
  Alert,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Spinner
} from "reactstrap";
import { CrmMapping } from "../../api/attributeApi";
import { Audience } from "../../models/audienceModels";
import { LinkContainer } from "../../../template/containers/LinkContainer";
import { getAudienceUpdatePath } from "../../constants/pathConstants";

export interface Props extends StateProps, DispatchProps {}

export interface StateProps {
  crmMapping: CrmMapping;
  audiencesUsingMapping: Audience[];
  isGettingAudiencesUsingMapping: boolean;
  isDeleting: boolean;
}

export interface DispatchProps {
  closeModal(): void;
  onDelete(crmMapping: CrmMapping): void;
}

export const CrmMappingDeleteModalFC: React.FC<Props> = props => {
  return (
    <Modal isOpen={true} toggle={props.closeModal}>
      <ModalHeader toggle={props.closeModal}>
        Delete CRM Mapping for Column {props.crmMapping.crm_column}
      </ModalHeader>
      <ModalBody>
        <Alert color={"warning"}>
          Historical data related to column {props.crmMapping.crm_column} will
          no longer be usable.
        </Alert>
        {props.isGettingAudiencesUsingMapping && (
          <div>
            Checking audience usage of trait "{props.crmMapping.attribute.name}
            "&nbsp;
            <Spinner size={"sm"} />
          </div>
        )}
        {props.audiencesUsingMapping !== undefined &&
          props.audiencesUsingMapping.length > 0 && (
            <div data-qa-id={"crm-delete-audience-uses-mapping-container"}>
              <p>
                Before deleting, remove the trait "
                {props.crmMapping.attribute.name}" from the following audiences:
              </p>
              {props.audiencesUsingMapping.map(audience => {
                return (
                  <div key={audience.audience_id}>
                    <LinkContainer
                      to={getAudienceUpdatePath(audience.audience_id)}
                      data-qa-id={`crm-delete-used-by-audience-link-${
                        audience.audience_id
                      }`}
                      target={"_blank"}
                    >
                      {audience.name}
                    </LinkContainer>
                  </div>
                );
              })}
            </div>
          )}
        {props.audiencesUsingMapping !== undefined &&
          props.audiencesUsingMapping.length === 0 && (
            <div>
              No audiences are using the CRM trait "
              {props.crmMapping.attribute.name}"
            </div>
          )}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            props.closeModal();
          }}
          color="link"
          data-qa-id={"crm-delete-cancel-button"}
          disabled={props.isDeleting}
        >
          Cancel
        </Button>
        <Button
          color={"primary"}
          data-qa-id={"crm-delete-delete-button"}
          disabled={
            props.isDeleting ||
            (props.audiencesUsingMapping === undefined ||
              props.audiencesUsingMapping.length > 0)
          }
          onClick={() => {
            props.onDelete(props.crmMapping);
          }}
        >
          Delete {props.isDeleting && <Spinner size={"sm"} />}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
