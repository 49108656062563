import { default as React } from "react";
import {
  Row,
  Col,
  Table,
  DropdownItem,
  DropdownToggle,
  Breadcrumb,
  BreadcrumbItem,
  DropdownMenu,
  Button,
  UncontrolledDropdown,
  Progress
} from "reactstrap";
import {
  ScoreSegment,
  scoreSegmentProps,
  attributeProps
} from "../../api/attributeApi";
import { ScoreSegmentCreateModalContainer } from "../containers/ScoreSegmentCreateModalContainer";
import { ScoreSegmentUpdateModalContainer } from "../containers/ScoreSegmentUpdateModalContainer";
import { ScoreSegmentDeleteModalContainer } from "../containers/ScoreSegmentDeleteModalContainer";
import { SortableTableHeaderFC } from "../../../bootstrap";
import { LinkContainer } from "../../../template/containers/LinkContainer";
import { faEllipsisH, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";

export interface ScoreSegmentsStateProps {
  goals: ScoreSegment[];
  scoreSegments: ScoreSegment[];
  sort: { iteratees: string[]; orders: string[] };
  isGettingGoals: boolean;
  isGettingRootScoreSegments: boolean;
  isGettingUpdateScoreSegment: boolean;
  isGettingDeleteScoreSegment: boolean;
  showCreateModal: boolean;
  showUpdateModal: boolean;
  showDeleteModal: boolean;
  hasWriteAccess: boolean;
}
export interface ScoreSegmentsDispatchProps {
  onSort(sortKey: string): void;
  onCreate(): void;
  onUpdate(scoreSegment: ScoreSegment): void;
  onDelete(scoreSegment: ScoreSegment): void;
}
export interface ScoreSegmentsProps
  extends ScoreSegmentsStateProps,
    ScoreSegmentsDispatchProps {}

function displayGoal(scoreSegmentModel: string) {
  switch (scoreSegmentModel) {
    case "wp":
      return "Engagement";
    default:
      return scoreSegmentModel;
  }
}

export const ScoreSegmentFC: React.FC<ScoreSegmentsProps> = props => {
  return (
    <React.Fragment>
      <React.Fragment>
        {props.showCreateModal && (
          <ScoreSegmentCreateModalContainer goals={props.goals} />
        )}
        {props.showUpdateModal && !props.isGettingUpdateScoreSegment && (
          <ScoreSegmentUpdateModalContainer goals={props.goals} />
        )}
        {props.showDeleteModal && !props.isGettingDeleteScoreSegment && (
          <ScoreSegmentDeleteModalContainer />
        )}
      </React.Fragment>
      <Row>
        <Col>
          <Breadcrumb>
            <BreadcrumbItem active>Admin</BreadcrumbItem>
            <BreadcrumbItem active>Score Segments</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      {props.isGettingGoals && (
        <Row>
          <Col>
            <Progress value={100} animated />
          </Col>
        </Row>
      )}
      {!props.isGettingGoals && (
        <React.Fragment>
          {props.hasWriteAccess && (
            <Row className={"mb-3"}>
              <Col>
                <Button
                  color="primary"
                  data-qa-id="score-segment-add"
                  disabled={props.isGettingRootScoreSegments}
                  onClick={() => {
                    props.onCreate();
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                  <span>Score Segment</span>
                </Button>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Table bordered hover striped>
                <thead>
                  <tr>
                    <SortableTableHeaderFC
                      sortKey={`${scoreSegmentProps.attribute}.${
                        attributeProps.name
                      }`}
                      iteratees={props.sort.iteratees}
                      orders={props.sort.orders}
                      onClick={props.onSort}
                    >
                      Name
                    </SortableTableHeaderFC>
                    <SortableTableHeaderFC
                      sortKey={scoreSegmentProps.model}
                      iteratees={props.sort.iteratees}
                      orders={props.sort.orders}
                      onClick={props.onSort}
                    >
                      Goal
                    </SortableTableHeaderFC>
                    <SortableTableHeaderFC
                      sortKey={`${scoreSegmentProps.attribute}.${
                        attributeProps.modified_at
                      }`}
                      iteratees={props.sort.iteratees}
                      orders={props.sort.orders}
                      onClick={props.onSort}
                      className={"d-none d-lg-table-cell"}
                    >
                      Last Modified
                    </SortableTableHeaderFC>
                    {props.hasWriteAccess && <th>&nbsp;</th>}
                  </tr>
                </thead>
                <tbody>
                  {props.scoreSegments.map(scoreSegment => {
                    return (
                      <tr
                        key={scoreSegment.id}
                        data-qa-id={`score-segment-${scoreSegment.id}`}
                      >
                        <td className={"text-break"}>
                          <div className={"text-break"}>
                            <LinkContainer
                              to=""
                              onClick={() => {
                                props.onUpdate(scoreSegment);
                              }}
                            >
                              {scoreSegment.attribute.name}
                            </LinkContainer>
                          </div>
                        </td>
                        <td>{displayGoal(scoreSegment.model)}</td>
                        <td className={"d-none d-lg-table-cell"}>
                          {DateTime.fromISO(
                            scoreSegment.attribute.modified_at
                          ).toRelative()}
                        </td>
                        {props.hasWriteAccess && (
                          <td className={"text-center"}>
                            <UncontrolledDropdown
                              id={`score_segment_${scoreSegment.id}`}
                            >
                              <DropdownToggle
                                size={"xs"}
                                data-qa-id={`score-segment-${
                                  scoreSegment.id
                                }-dropdown-button`}
                              >
                                <FontAwesomeIcon icon={faEllipsisH} />
                              </DropdownToggle>
                              <DropdownMenu right>
                                <DropdownItem
                                  data-qa-id={`score-segment-${
                                    scoreSegment.id
                                  }-edit-link`}
                                  onClick={() => {
                                    props.onUpdate(scoreSegment);
                                  }}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  data-qa-id={`score-segment-${
                                    scoreSegment.id
                                  }-delete-link`}
                                  onClick={() => {
                                    props.onDelete(scoreSegment);
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
