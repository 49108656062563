import {
  WP_PAGE_URL,
  WP_VISIT_RECENCY,
  DS_PAGE_URL,
  DS_VISIT_RECENCY,
  PV_CONVERSION_WITH_RECENCY
} from "../../constants/attributeIdConstants";
import { createStringValidator } from "./stringValidator";
import { visitRecencyValidator } from "./visitRecencyValidator";
import { existsNotExistsValidator } from "./existsNotExistsValidator";
import { dataDrivenValidator } from "./dataDrivenValidator";
import { sessionVariableValidator } from "./sessionVariableValidator";
import { Validator } from "./sharedInterfaces";
import { FullAttribute } from "../../api/attributeApi";
import { ES, PV, CV } from "../../constants/attributeSourceTypeConstants";

export function getValidator(attribute: FullAttribute): Validator {
  if (attribute.id.startsWith(ES) || attribute.id.startsWith(CV)) {
    return existsNotExistsValidator;
  }

  if (
    attribute.id === WP_VISIT_RECENCY ||
    attribute.id === DS_VISIT_RECENCY ||
    attribute.id === PV_CONVERSION_WITH_RECENCY
  ) {
    return visitRecencyValidator;
  }

  if (attribute.id.startsWith(PV)) {
    return sessionVariableValidator;
  }

  // TODO: This is not data driven. Consider adding min and max length as attribute meta data
  if (attribute.id === WP_PAGE_URL || attribute.id === DS_PAGE_URL) {
    return createStringValidator(0, 50);
  }

  return dataDrivenValidator;
}
