import { uniqueId, omit } from "lodash";
import { ErrorLog } from "../models/errorMonitorModels";
import { hideErrorActionFactory } from "../actions/errorMonitorActions";
import { Action } from "../../lib/reduxLib";

export interface State {
  byId: {
    [id: string]: ErrorLog;
  };
  visibleIdMap: {
    [id: string]: undefined;
  };
}

const initialState: State = {
  byId: {},
  visibleIdMap: {}
};

export function errorMonitorReducer(
  prevState: State = initialState,
  action: Action
): State {
  if (action.type.endsWith("_REJECTED")) {
    const id = uniqueId();
    return {
      ...prevState,
      byId: {
        ...prevState.byId,
        [id]: {
          id,
          action,
          timestampMs: Date.now()
        }
      },
      visibleIdMap: {
        ...prevState.visibleIdMap,
        [id]: undefined
      }
    };
  }
  if (hideErrorActionFactory.isAction(action)) {
    return {
      ...prevState,
      visibleIdMap: omit(prevState.visibleIdMap, action.payload.errorId)
    };
  }
  return prevState;
}
