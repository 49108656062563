import { ActionTypes } from "../constants/authConstants";
import { EntityMap } from "../api/authApi";
import { ActionM, ActionP, ActionPM } from "../../lib/reduxLib";

export interface GetPermissionsAction
  extends ActionP<GetPermissionsActionMeta> {
  readonly type: ActionTypes.GET_PERMISSIONS;
}

export interface GetPermissionsActionMeta {
  readonly entityId: number;
  readonly permissionIds: number[];
}

export interface GetPermissionsActionPayload {
  readonly entityId: number;
  readonly permissionIds: number[];
}

export interface GetPermissionsPendingAction
  extends ActionM<GetPermissionsActionMeta> {
  readonly type: ActionTypes.GET_PERMISSIONS_PENDING;
}

export interface GetPermissionsFulfilledAction
  extends ActionPM<EntityMap, GetPermissionsActionMeta> {
  readonly type: ActionTypes.GET_PERMISSIONS_FULFILLED;
}

export interface GetPermissionsRejectedAction
  extends ActionPM<Error, GetPermissionsActionMeta> {
  readonly type: ActionTypes.GET_PERMISSIONS_REJECTED;
}

export interface ConfigurePermissionsAction extends ActionP<number[]> {
  readonly type: ActionTypes.CONFIGURE_PERMISSIONS;
}

export function configurePermissions(
  permissionIds: number[]
): ConfigurePermissionsAction {
  return {
    type: ActionTypes.CONFIGURE_PERMISSIONS,
    payload: permissionIds
  };
}

export function getPermissions(
  permissionIds: number[],
  entityId: number
): GetPermissionsAction {
  return {
    type: ActionTypes.GET_PERMISSIONS,
    payload: {
      entityId,
      permissionIds
    }
  };
}

export function getPermissionsPending(
  permissionIds: number[],
  entityId: number
): GetPermissionsPendingAction {
  return {
    type: ActionTypes.GET_PERMISSIONS_PENDING,
    meta: {
      entityId,
      permissionIds
    }
  };
}

export function getPermissionsFulfilled(
  entityMap: EntityMap,
  permissionIds: number[],
  entityId: number
): GetPermissionsFulfilledAction {
  return {
    type: ActionTypes.GET_PERMISSIONS_FULFILLED,
    payload: entityMap,
    meta: {
      entityId,
      permissionIds
    }
  };
}

export function getPermissionsRejected(
  error: Error,
  permissionIds: number[],
  entityId: number
): GetPermissionsRejectedAction {
  return {
    type: ActionTypes.GET_PERMISSIONS_REJECTED,
    payload: error,
    meta: {
      entityId,
      permissionIds
    }
  };
}
