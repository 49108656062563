import { isString } from "lodash";
import { default as URI } from "urijs";
import {
  fetchBusinessUnitsPending,
  fetchBusinessUnitsFulfilled,
  fetchBusinessUnitsRejected,
  searchBusinessUnitsDebounced,
  updateRecentBusinessUnitsPending,
  updateRecentBusinessUnitsFulfilled,
  updateRecentBusinessUnitsRejected,
  SearchBusinessUnitsAction,
  SelectBusinessUnitAction,
  FetchBusinessUnitsAction,
  TrackedData
} from "../actions/businessUnitActions";
import {
  fetchBusinessUnits,
  updateRecentBusinessUnits
} from "../api/templateApi";
import {
  ActionTypes,
  SEARCH_DEBOUNCE_MS,
  TrackedData as TrackedDataEnum
} from "../constants/templateConstants";
import { Action } from "../../lib/reduxLib";
import { State } from "../reducers";
import { combineEpics, Epic, ofType } from "redux-observable";
import { concat, of } from "rxjs";
import { catchError, debounceTime, flatMap, map } from "rxjs/operators";

export const onFetchBusinessUnitsEpic: Epic<Action, Action, State> = (
  action$,
  state$
) => {
  return action$.pipe(
    ofType<Action, FetchBusinessUnitsAction>(ActionTypes.FETCH_BUSINESS_UNITS),
    flatMap(action => {
      const uri = URI(state$.value.router.location.search);
      const searchMap = uri.search(true);

      const clientId = searchMap[TrackedDataEnum.CLIENT_ID];
      const businessUnitId =
        searchMap[TrackedDataEnum.COMPANY_BUSINESS_UNIT_ID];
      const trackedData: TrackedData = {
        clientId: isString(clientId) ? +clientId : clientId,
        businessUnitId: isString(businessUnitId)
          ? +businessUnitId
          : businessUnitId
      };

      return concat(
        of(fetchBusinessUnitsPending(trackedData)),
        fetchBusinessUnits().pipe(
          map(normalizedBusUnits => {
            return fetchBusinessUnitsFulfilled(normalizedBusUnits, trackedData);
          }),
          catchError(error => {
            return of(fetchBusinessUnitsRejected(error, trackedData));
          })
        )
      );
    })
  );
};

export const onSearchBusinessUnitsEpic: Epic<Action, Action, State> = (
  action$,
  state$
) => {
  return action$.pipe(
    ofType<Action, SearchBusinessUnitsAction>(
      ActionTypes.SEARCH_BUSINESS_UNITS
    ),
    debounceTime(SEARCH_DEBOUNCE_MS),
    map(action => {
      return searchBusinessUnitsDebounced(action.payload.query);
    })
  );
};

export const onSelectBusinessUnitEpic: Epic<Action, Action, State> = (
  action$,
  state$
) => {
  return action$.pipe(
    ofType<Action, SelectBusinessUnitAction>(ActionTypes.SELECT_BUSINESS_UNIT),
    flatMap(action => {
      return concat(
        of(updateRecentBusinessUnitsPending(action.payload.id)),
        updateRecentBusinessUnits(action.payload.id).pipe(
          map(businessUnits => {
            return updateRecentBusinessUnitsFulfilled(
              businessUnits,
              action.payload.id
            );
          }),
          catchError(error => {
            return of(
              updateRecentBusinessUnitsRejected(error, action.payload.id)
            );
          })
        )
      );
    })
  );
};

export const epics = combineEpics(
  onFetchBusinessUnitsEpic,
  onSearchBusinessUnitsEpic,
  onSelectBusinessUnitEpic
);
