import { mappingsReducer, State as MappingsState } from "./mappingsReducer";
import { createReducer, State as CreateState } from "./createReducer";
import { updateReducer, State as UpdateState } from "./updateReducer";
import { deleteReducer, State as DeleteState } from "./deleteReducer";
import { combineReducers } from "redux";
import { configReducer, State as ConfigState } from "./configReducer";

export const reducers = {
  crm: combineReducers({
    mappings: mappingsReducer,
    create: createReducer,
    update: updateReducer,
    delete: deleteReducer,
    config: configReducer
  })
};

export interface State {
  crm: {
    mappings: MappingsState;
    create: CreateState;
    update: UpdateState;
    delete: DeleteState;
    config: ConfigState;
  };
}
