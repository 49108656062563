import { default as React } from "react";
import { Col, Progress, Row } from "reactstrap";
import { InjectedFormProps, Normalizer } from "redux-form";
import { isNil } from "lodash";
import { AudienceForm } from "./AudienceFormComponent";
import { Audience, audienceProps } from "../models/audienceModels";
import { ConditionBuilderMode } from "../models/conditionBuilderModels";
import { AttributeChannelType } from "../constants/attributeChannelConstants";
import { NormalizedBusinessUnit } from "../../template/models/businessUnitModels";
import { LinkContainer } from "../../template/containers/LinkContainer";
import { HOME } from "../constants/pathConstants";

export interface UpdateAudienceInjectedProps
  extends UpdateAudienceProps,
    InjectedFormProps<Audience, UpdateAudienceProps, string> {}
export interface UpdateAudienceProps extends StateProps, DispatchProps {}

export interface StateProps {
  audienceId: number;
  builderId: string;
  businessUnit: NormalizedBusinessUnit;
  startingPopulations: Audience[];
  startingPop: Audience;
  expandExpressionBuilder: boolean;
  conditionBuilderMode: ConditionBuilderMode;
  parentAudiences: Audience[];
  initialValues: Partial<Audience>;
  channel: AttributeChannelType;
  showLoadingIndicator: boolean;
  isSubmitDisabled: boolean;
  isNameValid: boolean;
  onCustomIdChange: Normalizer;
  audienceLabelSuggestions: string[];
}

export interface DispatchProps {
  onInit(): void;
  onNameChange(name: string): void;
  onStartingPopChange(audienceId: number, fieldName: string): void;
  onBusinessUnitChange(): void;
}

function isBusinessUnitContextValid(
  audience: Partial<Audience>,
  businessUnitId: number
) {
  // if we don't have audience yet, assume valid until we get it to check
  if (isNil(audience)) {
    return true;
  }

  // otherwise check that bu id on audience matches the current context
  return audience.business_unit_id === businessUnitId;
}

function noop(): void {
  return void 0;
}

export class UpdateAudiencePureComponent extends React.PureComponent<
  UpdateAudienceInjectedProps
> {
  static defaultProps = {
    builderId: undefined as string,
    showLoadingIndicator: true,
    initialValues: undefined as Partial<Audience>,
    onNameChange: noop,
    onCustomIdChange: noop,
    onStartingPopChange: noop,
    isSubmitDisabled: false,
    isNameValid: false,
    startingPopulations: [{ id: 0, label: "All Profiles" } as any],
    startingPop: undefined as Audience,
    suggestions: [] as string[]
  };

  componentDidMount() {
    const props = this.props;

    if (
      isBusinessUnitContextValid(props.initialValues, props.businessUnit.id)
    ) {
      props.onInit();
    } else {
      props.onBusinessUnitChange();
    }
  }

  componentDidUpdate(prevProps: UpdateAudienceProps) {
    if (this.props.businessUnit.id !== prevProps.businessUnit.id) {
      this.props.onBusinessUnitChange();
    } else if (
      this.props.initialValues !== prevProps.initialValues &&
      !isBusinessUnitContextValid(
        this.props.initialValues,
        this.props.businessUnit.id
      )
    ) {
      this.props.onBusinessUnitChange();
    }
  }

  render() {
    const props = this.props;
    const startingPopOptions = [
      ...UpdateAudiencePureComponent.defaultProps.startingPopulations,
      ...props.startingPopulations
        .filter(a => {
          return a.starting_population_id === 0;
        })
        .map(a => {
          return {
            id: a.audience_id,
            label: a.name
          };
        })
    ];
    const suggestions = this.props.audienceLabelSuggestions;

    return (
      <React.Fragment>
        <Row>
          <Col>
            <nav aria-label={"breadcrumb"}>
              <ol className={"breadcrumb flex-nowrap"}>
                <li className={"breadcrumb-item"}>
                  <LinkContainer to={HOME}>Audiences</LinkContainer>
                </li>
                <li className={"breadcrumb-item active text-nowrap"}>
                  Update Audience
                </li>
              </ol>
            </nav>
          </Col>
        </Row>
        <Row>
          <Col>
            {props.showLoadingIndicator ? (
              <Progress value={100} animated color={"primary"} />
            ) : (
              <AudienceForm
                handleSubmit={props.handleSubmit}
                builderId={props.builderId}
                businessUnit={props.businessUnit}
                audience={props.initialValues}
                channel={props.channel}
                submitText="Update Audience"
                disabled={[
                  "audience_id",
                  "business_unit_id",
                  audienceProps.custom_identifier
                ]}
                onNameChange={props.onNameChange}
                onCustomIdChange={props.onCustomIdChange}
                isSubmitDisabled={props.isSubmitDisabled}
                isNameValid={props.isNameValid}
                startingPopOptions={startingPopOptions}
                startingPop={props.startingPop}
                audienceLabelSuggestions={suggestions}
                expandExpressionBuilder={props.expandExpressionBuilder}
                conditionBuilderMode={props.conditionBuilderMode}
                parentAudiences={props.parentAudiences}
                onStartingPopChange={props.onStartingPopChange}
              />
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
