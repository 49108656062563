import { connect } from "react-redux";
import { State } from "../reducers";
import {
  getNavSubNavRootNode,
  getNavSelectedNode,
  getSelectedSubNavCategoryNode
} from "../selectors/navigationSelectors";
import {
  SubNavFC as SubNavComponent,
  SubNavProps
} from "../components/SubNavComponent";

function mapStateToProps(state: State): SubNavProps {
  return {
    navigation:
      getNavSubNavRootNode(state) && getNavSubNavRootNode(state).children,
    selectedNavNodeId:
      getNavSubNavRootNode(state) && getNavSelectedNode(state).id,
    selectedSubNavCategoryNodeId:
      getSelectedSubNavCategoryNode(state) &&
      getSelectedSubNavCategoryNode(state).id
  };
}

function mapDispatchToProps() {
  return {};
}

export const SubNavContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SubNavComponent);
