import { default as React } from "react";
import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Progress
} from "reactstrap";
import { PagingInfoFC, SearchBarFC } from "../../bootstrap";
import { AudienceTableComponent } from "./AudienceTableComponent";
import { Audience } from "../models/audienceModels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Labels } from "./LabelsInput/Labels";
import { AudienceFilterState } from "../reducers/audiencesReducer";

export interface HomeProps extends StateProps, DispatchProps {}

export interface StateProps {
  audiences: Audience[];
  showLoadingIndicator?: boolean;
  pageSize: number;
  totalAudiences: number;
  businessUnitId: number;
  query: string;
  sort: any;
  filter: AudienceFilterState;
  manualLoadMoreThreshold: number;
  enableCrudColumn: boolean;
  enableAddAudience: boolean;
  suggestions: string[];
}

export interface DispatchProps {
  onSort(sortKey: string): void;
  onFilterSet(labels: string[]): void;
  onSearchClear(): void;
  onInit(): void;
  onAddClick(): void;
  onAudienceDelete(audienceId: number): void;
  onUpdateLabels(labels: string[], audience: Audience): void;
  handleLoadMore(pageSize: number): void;
  onSearchChange(searchText: string): void;
  getAttributes(businessUnitId: number): void;
}

interface State {
  showSearch: boolean;
}

export class HomeComponent extends React.Component<HomeProps, State> {
  static defaultProps = {
    showLoadingIndicator: true,
    enableCrudColumn: false,
    enableAddAudience: false
  };

  constructor(props: HomeProps) {
    super(props);
    this.state = {
      showSearch: false
    };
  }

  handleClick() {
    this.setState({ showSearch: !this.state.showSearch });
  }

  componentDidMount() {
    this.props.onInit();
    this.props.getAttributes(this.props.businessUnitId);
  }

  componentDidUpdate(prevProps: HomeProps) {
    if (this.props.businessUnitId !== prevProps.businessUnitId) {
      this.props.onInit();
      this.props.getAttributes(this.props.businessUnitId);
    }
  }

  render() {
    const props = this.props;
    const state = this.state;

    return (
      <React.Fragment>
        <Row>
          <Col>
            <Breadcrumb>
              <BreadcrumbItem active>Audiences</BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
        {props.showLoadingIndicator ? (
          <Row>
            <Col>
              <Progress value={100} animated color={"primary"} />
            </Col>
          </Row>
        ) : (
          <React.Fragment>
            <Row>
              <Col
                className={"d-flex flex-row-reverse justify-content-between"}
              >
                {!state.showSearch ? (
                  <Button
                    onClick={() => {
                      this.handleClick();
                    }}
                    data-qa-id={"audiences-search-btn"}
                    disabled={props.showLoadingIndicator}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                ) : (
                  <SearchBarFC
                    disabled={props.showLoadingIndicator}
                    onClearClick={() => {
                      this.setState({
                        showSearch: false
                      });
                      props.onSearchClear();
                    }}
                    value={props.query}
                    onChange={e => {
                      props.onSearchChange(e.currentTarget.value);
                    }}
                    qaId="audiences-list-search-bar"
                  />
                )}
                {props.enableAddAudience && (
                  <Button
                    color={"primary"}
                    onClick={props.onAddClick}
                    disabled={props.showLoadingIndicator}
                    data-qa-id={"add-audience-btn"}
                  >
                    <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                    Audience
                  </Button>
                )}
              </Col>
            </Row>
            <Row className={"align-items-center"}>
              <Col lg={8}>
                <PagingInfoFC
                  current={props.audiences.length}
                  total={props.totalAudiences}
                  pluralName="audiences"
                  className={"my-3"}
                />
              </Col>
              <Col lg={4} data-qa-id="audiences-filter-by-labels">
                <Labels
                  minLength={0}
                  allowNew={false}
                  placeholder="Filter by Labels"
                  labels={this.props.filter.labels}
                  onChange={this.props.onFilterSet}
                  suggestions={props.suggestions}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <AudienceTableComponent
                  audiences={props.audiences}
                  sort={props.sort}
                  onSort={props.onSort}
                  handleLoadMore={props.handleLoadMore}
                  manualLoadMoreThreshold={props.manualLoadMoreThreshold}
                  pageSize={props.pageSize}
                  onAudienceDelete={props.onAudienceDelete}
                  onUpdateLabels={props.onUpdateLabels}
                  enableCrudColumn={props.enableCrudColumn}
                  suggestions={props.suggestions}
                  totalAudiences={props.totalAudiences}
                />
                {props.manualLoadMoreThreshold <= props.pageSize &&
                  props.pageSize <= props.audiences.length && (
                    <Button
                      color={"primary"}
                      disabled={props.pageSize > props.audiences.length}
                      onClick={() => {
                        props.handleLoadMore(props.pageSize);
                      }}
                    >
                      View More Audiences
                    </Button>
                  )}
              </Col>
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
