import { default as React, ReactNode } from "react";
import {
  DragSource,
  DragSourceSpec,
  DragSourceCollector,
  ConnectDragSource,
  DropTarget,
  DropTargetSpec,
  DropTargetCollector,
  ConnectDropTarget
} from "react-dnd";
import { PREFIX } from "../constants/dndtypeConstants";
import { PrefixSegment } from "../models/expressionBuilderModels";
import styles from "./ExpressionDraggable.module.scss";
import { ExpressionDraggableFC } from "./ExpressionDraggableComponent";

export interface ExpressionPrefixProps extends DndSourceProps, DndTargetProps {
  prefix: PrefixSegment;
  removeExpressionPrefix(prefixId: string): void;
}

export interface DndSourceProps {
  connectDragSource?: ConnectDragSource;
  isDragging?: boolean;
}

export interface DndTargetProps {
  connectDropTarget?: ConnectDropTarget;
  isOver?: boolean;
}

export const ExpressionPrefixFC: React.FC<ExpressionPrefixProps> = props => {
  return (
    <ExpressionDraggableFC
      isOver={props.isOver}
      isDragging={props.isDragging}
      connectDropTarget={props.connectDropTarget}
      connectDragSource={props.connectDragSource}
      removeExpressionDraggable={props.removeExpressionPrefix}
      draggableId={props.prefix.id}
    >
      <div className={styles.prefixContainer}>{props.prefix.value}</div>
    </ExpressionDraggableFC>
  );
};

const dropSpec: DropTargetSpec<ExpressionPrefixProps> = {
  drop(props, monitor) {
    // TODO: call the proper fn based on dropped type
  }
};

const dropCollect: DropTargetCollector<DndTargetProps> = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
};

const dragSpec: DragSourceSpec<ExpressionPrefixProps, ReactNode> = {
  beginDrag(props) {
    return props.prefix;
  }
};

const dragCollect: DragSourceCollector<DndSourceProps> = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
};

const sourceWrapper = DragSource(PREFIX, dragSpec, dragCollect);
const targetWrapper = DropTarget(PREFIX, dropSpec, dropCollect);

export const ExpressionPrefixDND = sourceWrapper(
  targetWrapper(ExpressionPrefixFC)
);
