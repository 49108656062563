import * as React from "react";
import { ConnectDragSource, ConnectDropTarget } from "react-dnd";
import { EB_ITEM_MARGIN } from "../constants/styleConstants";
import styles from "./ExpressionDraggable.module.scss";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

export interface ExpressionGroupEndProps
  extends DndSourceProps,
    DndTargetProps {
  draggableId: string;
  removeExpressionDraggable(id: string): void;
}

export interface DndSourceProps {
  connectDragSource?: ConnectDragSource;
  isDragging?: boolean;
}

export interface DndTargetProps {
  connectDropTarget?: ConnectDropTarget;
  isOver?: boolean;
}

export const ExpressionDraggableFC: React.FC<
  ExpressionGroupEndProps
> = props => {
  return (
    <div className={EB_ITEM_MARGIN}>
      {props.connectDragSource(
        props.connectDropTarget(
          <div
            className={classNames(
              "border rounded c-move",
              styles.draggableContainer,
              {
                "border-primary": props.isDragging,
                "border-secondary": props.isOver
              }
            )}
          >
            {props.removeExpressionDraggable && !props.isDragging && (
              <Button
                className={`rounded-circle ${styles.removeIconContainer}`}
                size={"xs"}
                onClick={() => {
                  props.removeExpressionDraggable(props.draggableId);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            )}
            <div>{props.children}</div>
          </div>
        )
      )}
    </div>
  );
};
