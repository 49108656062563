import * as React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface Props {
  tooltipId: string;
}

export const DefinedValuesTooltipFC: React.FC<Props> = props => {
  return (
    <React.Fragment>
      <span id={props.tooltipId}>
        <FontAwesomeIcon icon={faInfoCircle} />
      </span>
      <UncontrolledTooltip target={props.tooltipId}>
        Values found in dropdown during condition building
      </UncontrolledTooltip>
    </React.Fragment>
  );
};
