import { Action } from "redux";
import {
  ExpressionModes,
  ExpressionSegment,
  OperatorSegment,
  ExpressionSegmentTypes,
  ExpressionOperators,
  operatorFactory
} from "../models/expressionBuilderModels";
import {
  expressionBuilderAddConditionAF,
  expressionBuilderAddGroupAF,
  expressionBuilderAddPrefixAF,
  expressionBuilderDestroyAF,
  expressionBuilderEditConditionAF,
  expressionBuilderExpansionAF,
  expressionBuilderInitAF,
  expressionBuilderMoveConditionToGroupAF,
  expressionBuilderMoveConditionToOperatorAF,
  expressionBuilderMoveSegmentAF,
  expressionBuilderRemoveConditionAF,
  expressionBuilderRemoveGroupAF,
  expressionBuilderRemovePrefixAF,
  expressionBuilderResetStateAF,
  expressionBuilderSetExpressionAF,
  expressionBuilderSetModeAF,
  expressionBuilderSwapConditionsAF,
  expressionBuilderToggleOperatorAF
} from "../actions/expressionBuilder";

export const builderIdMapInitialState: BuilderIdMapState = {};
export const expressionBuilderInitialState: ExpressionBuilderState = {
  mode: ExpressionModes.ALL,
  expand: false,
  segments: []
};

export interface State extends BuilderIdMapState {}

interface BuilderIdMapState {
  [builderId: string]: ExpressionBuilderState;
}

export interface ExpressionBuilderState {
  mode: ExpressionModes;
  expand: boolean;
  segments: ExpressionSegment[];
}

interface SegmentCountType {
  ands: number;
  ors: number;
  prefixes: number;
  groups: number;
}

function handleConditionRemoval(
  segments: ExpressionSegment[],
  conditionId: string
) {
  return segments
    .filter((segment, index, array) => {
      if (
        segment.type === ExpressionSegmentTypes.PREFIX &&
        index + 1 < array.length &&
        array[index + 1].id === conditionId
      ) {
        // remove prefix if it immediately precedes condition being removed
        return false;
      }
      if (
        segment.type === ExpressionSegmentTypes.OPERATOR &&
        index + 1 < array.length &&
        array[index + 1].id === conditionId
      ) {
        // remove operator if it immediately precedes condition being removed
        return false;
      }
      if (
        segment.type === ExpressionSegmentTypes.OPERATOR &&
        index + 2 < array.length &&
        array[index + 1].type === ExpressionSegmentTypes.PREFIX &&
        array[index + 2].id === conditionId
      ) {
        // remove operator if it immediately precedes a prefix that
        // immediately precedes condition being removed
        return false;
      }

      // remove targetted condition
      return segment.id !== conditionId;
    })
    .filter((segment, index, array) => {
      if (
        segment.type === ExpressionSegmentTypes.GROUP_START &&
        index + 1 < array.length &&
        array[index + 1].type === ExpressionSegmentTypes.GROUP_END
      ) {
        // remove start of empty group
        return false;
      }
      if (
        segment.type === ExpressionSegmentTypes.GROUP_END &&
        index - 1 >= 0 &&
        array[index - 1].type === ExpressionSegmentTypes.GROUP_START
      ) {
        // remove end of empty group
        return false;
      }
      if (
        segment.type === ExpressionSegmentTypes.PREFIX &&
        index + 2 < array.length &&
        array[index + 1].type === ExpressionSegmentTypes.GROUP_START &&
        array[index + 2].type === ExpressionSegmentTypes.GROUP_END
      ) {
        // remove prefix attached to empty group
        return false;
      }

      return true;
    })
    .filter((segment, index, array) => {
      if (
        segment.type === ExpressionSegmentTypes.OPERATOR &&
        (index === 0 || index === array.length - 1)
      ) {
        // remove any operators that are at head or tail of expression array
        return false;
      }
      if (
        segment.type === ExpressionSegmentTypes.OPERATOR &&
        index - 1 >= 0 &&
        array[index - 1].type === ExpressionSegmentTypes.GROUP_START
      ) {
        // remove operators that are at head of a group
        return false;
      }
      if (
        segment.type === ExpressionSegmentTypes.OPERATOR &&
        index + 1 < array.length &&
        array[index + 1].type === ExpressionSegmentTypes.GROUP_END
      ) {
        // remove operaotrs that are at tail of a group
        return false;
      }

      return true;
    });
}

export function expressionBuilderReducer(
  prevState = builderIdMapInitialState,
  action: Action
): State {
  // Seems that init is called multiple times (perhaps because it is conditionally rendered based on expand)
  // Spreading prevState over initial state seems pointless, but removing this logic created bugs.
  // TODO: Clean up initialization / data pipeline
  if (expressionBuilderInitAF.isAction(action)) {
    return {
      ...prevState,
      [action.meta.builderId]: {
        ...expressionBuilderInitialState,
        ...prevState[action.meta.builderId]
      }
    };
  }
  // mainly used to reset segments
  if (expressionBuilderResetStateAF.isAction(action)) {
    return {
      ...prevState,
      [action.meta.builderId]: {
        ...prevState[action.meta.builderId],
        segments: []
      }
    };
  }
  if (expressionBuilderDestroyAF.isAction(action)) {
    const newIdMap = { ...prevState };
    delete newIdMap[action.meta.builderId];

    return newIdMap;
  }
  if (expressionBuilderAddConditionAF.isAction(action)) {
    const currentMode = prevState[action.meta.builderId].mode;
    const currentLength = prevState[action.meta.builderId].segments.length;

    // add default operator if length >= 1
    const operators: OperatorSegment[] =
      currentLength > 0
        ? [
            operatorFactory(
              currentMode === ExpressionModes.ANY
                ? ExpressionOperators.OR
                : ExpressionOperators.AND
            )
          ]
        : [];

    return {
      ...prevState,
      [action.meta.builderId]: {
        ...prevState[action.meta.builderId],
        segments: [
          ...prevState[action.meta.builderId].segments,
          ...operators,
          action.payload
        ]
      }
    };
  }
  if (expressionBuilderEditConditionAF.isAction(action)) {
    let segments = prevState[action.meta.builderId].segments;

    segments = segments.map(segment => {
      if (segment.id === action.payload.conditionId) {
        return {
          ...segment,
          value: action.payload.condition
        };
      }

      return segment;
    }) as ExpressionSegment[];

    return {
      ...prevState,
      [action.meta.builderId]: {
        ...prevState[action.meta.builderId],
        segments
      }
    };
  }
  if (expressionBuilderRemoveConditionAF.isAction(action)) {
    const segments = handleConditionRemoval(
      prevState[action.meta.builderId].segments,
      action.payload.conditionId
    );

    return {
      ...prevState,
      [action.meta.builderId]: {
        ...prevState[action.meta.builderId],
        segments
      }
    };
  }
  if (expressionBuilderSetModeAF.isAction(action)) {
    const mode = action.payload.mode;
    let segments = prevState[action.meta.builderId].segments;

    if (mode !== ExpressionModes.ADVANCED) {
      segments = segments
        .filter(s => {
          return (
            s.type === ExpressionSegmentTypes.CONDITION ||
            s.type === ExpressionSegmentTypes.OPERATOR
          );
        })
        .map(s => {
          if (s.type === ExpressionSegmentTypes.OPERATOR) {
            return {
              ...s,
              value:
                mode === ExpressionModes.ALL
                  ? ExpressionOperators.AND
                  : ExpressionOperators.OR
            };
          }

          return s;
        });
    }

    return {
      ...prevState,
      [action.meta.builderId]: {
        ...prevState[action.meta.builderId],
        mode,
        segments
      }
    };
  }
  if (expressionBuilderExpansionAF.isAction(action)) {
    return {
      ...prevState,
      [action.meta.builderId]: {
        ...prevState[action.meta.builderId],
        expand: action.payload.expand
      }
    };
  }
  if (expressionBuilderToggleOperatorAF.isAction(action)) {
    const segments = prevState[action.meta.builderId].segments.map(segment => {
      if (segment.id === action.payload.operatorId) {
        return {
          ...segment,
          value:
            segment.value === ExpressionOperators.AND
              ? ExpressionOperators.OR
              : ExpressionOperators.AND
        } as OperatorSegment;
      }

      return segment;
    });

    return {
      ...prevState,
      [action.meta.builderId]: {
        ...prevState[action.meta.builderId],
        segments
      }
    };
  }
  if (expressionBuilderAddPrefixAF.isAction(action)) {
    const segments = [
      ...prevState[action.meta.builderId].segments.slice(
        0,
        action.payload.index
      ),
      action.payload.prefix,
      ...prevState[action.meta.builderId].segments.slice(action.payload.index)
    ];

    return {
      ...prevState,
      [action.meta.builderId]: {
        ...prevState[action.meta.builderId],
        segments
      }
    };
  }
  if (expressionBuilderRemovePrefixAF.isAction(action)) {
    const segments = prevState[action.meta.builderId].segments.filter(s => {
      return s.id !== action.payload.prefixId;
    });

    return {
      ...prevState,
      [action.meta.builderId]: {
        ...prevState[action.meta.builderId],
        segments
      }
    };
  }
  if (expressionBuilderAddGroupAF.isAction(action)) {
    const { startGroup, startIndex, endGroup, endIndex } = action.payload;
    let segments = prevState[action.meta.builderId].segments;

    segments = [
      ...segments.slice(0, startIndex),
      startGroup,
      ...segments.slice(startIndex, endIndex),
      endGroup,
      ...segments.slice(endIndex)
    ];

    return {
      ...prevState,
      [action.meta.builderId]: {
        ...prevState[action.meta.builderId],
        segments
      }
    };
  }
  if (expressionBuilderRemoveGroupAF.isAction(action)) {
    let segments = prevState[action.meta.builderId].segments;
    const index = segments.findIndex(s => {
      return s.id === action.payload.groupId;
    });
    const group = segments[index];
    let partnerIndex;

    if (group.type === ExpressionSegmentTypes.GROUP_START) {
      let startCounts = 0;
      // search forward in the array to find corresponding group end
      // tslint:disable-next-line:no-increment-decrement
      for (let x = index + 1; x < segments.length; x++) {
        if (segments[x].type === ExpressionSegmentTypes.GROUP_START) {
          // tslint:disable-next-line:no-increment-decrement
          startCounts++;
        } else if (segments[x].type === ExpressionSegmentTypes.GROUP_END) {
          if (startCounts > 0) {
            // tslint:disable-next-line:no-increment-decrement
            startCounts--;
          } else if (partnerIndex === undefined) {
            partnerIndex = x;
          }
        }
      }
    } else {
      let endCounts = 0;
      // search backward in the array to find corresponding group start
      // tslint:disable-next-line:no-increment-decrement
      for (let x = index - 1; x >= 0; x--) {
        if (segments[x].type === ExpressionSegmentTypes.GROUP_END) {
          // tslint:disable-next-line:no-increment-decrement
          endCounts++;
        } else if (segments[x].type === ExpressionSegmentTypes.GROUP_START) {
          if (endCounts > 0) {
            // tslint:disable-next-line:no-increment-decrement
            endCounts--;
          } else if (partnerIndex === undefined) {
            partnerIndex = x;
          }
        }
      }
    }

    if (index > partnerIndex) {
      segments = [
        ...segments.slice(0, partnerIndex),
        ...segments.slice(partnerIndex + 1, index),
        ...segments.slice(index + 1)
      ];
    } else if (index < partnerIndex) {
      segments = [
        ...segments.slice(0, index),
        ...segments.slice(index + 1, partnerIndex),
        ...segments.slice(partnerIndex + 1)
      ];
    } else if (partnerIndex === undefined) {
      segments = [...segments.slice(0, index), ...segments.slice(index + 1)];
    }

    return {
      ...prevState,
      [action.meta.builderId]: {
        ...prevState[action.meta.builderId],
        segments
      }
    };
  }
  if (expressionBuilderSetExpressionAF.isAction(action)) {
    const segments = action.payload;
    const counts = segments.reduce(
      (acc: SegmentCountType, segment: ExpressionSegment) => {
        if (
          segment.type === ExpressionSegmentTypes.OPERATOR &&
          segment.value === ExpressionOperators.AND
        ) {
          // tslint:disable-next-line:no-increment-decrement
          acc.ands++;
        } else if (
          segment.type === ExpressionSegmentTypes.OPERATOR &&
          segment.value === ExpressionOperators.OR
        ) {
          // tslint:disable-next-line:no-increment-decrement
          acc.ors++;
        } else if (segment.type === ExpressionSegmentTypes.PREFIX) {
          // tslint:disable-next-line:no-increment-decrement
          acc.prefixes++;
        } else if (
          segment.type === ExpressionSegmentTypes.GROUP_START ||
          segment.type === ExpressionSegmentTypes.GROUP_END
        ) {
          // tslint:disable-next-line:no-increment-decrement
          acc.groups++;
        }

        return acc;
      },
      {
        ands: 0,
        ors: 0,
        prefixes: 0,
        groups: 0
      }
    );

    // set expression mode based on nature of expression
    let mode = ExpressionModes.ALL;
    if (
      counts.ors > 0 &&
      counts.ands === 0 &&
      counts.prefixes === 0 &&
      counts.groups === 0
    ) {
      mode = ExpressionModes.ANY;
    } else if (
      counts.prefixes > 0 ||
      counts.groups > 0 ||
      (counts.ors > 0 && counts.ands > 0)
    ) {
      mode = ExpressionModes.ADVANCED;
    }

    return {
      ...prevState,
      [action.meta.builderId]: {
        ...prevState[action.meta.builderId],
        segments,
        mode
      }
    };
  }
  if (expressionBuilderSwapConditionsAF.isAction(action)) {
    const segments = prevState[action.meta.builderId].segments.slice();
    const index = segments.findIndex(s => {
      return s.id === action.payload.conditionId;
    });
    const otherIndex = segments.findIndex(s => {
      return s.id === action.payload.otherConditionId;
    });
    const conditionToMove = segments[index];

    segments[index] = segments[otherIndex];
    segments[otherIndex] = conditionToMove;

    return {
      ...prevState,
      [action.meta.builderId]: {
        ...prevState[action.meta.builderId],
        segments
      }
    };
  }
  if (expressionBuilderMoveConditionToGroupAF.isAction(action)) {
    let segments = prevState[action.meta.builderId].segments;

    const conditionIndex = segments.findIndex(s => {
      return s.id === action.payload.conditionId;
    });
    const condition = segments[conditionIndex];
    let groupIndex = segments.findIndex(s => {
      return s.id === action.payload.groupId;
    });
    let group = segments[groupIndex];

    if (
      (conditionIndex === groupIndex + 1 &&
        group.type === ExpressionSegmentTypes.GROUP_START) ||
      (conditionIndex === groupIndex - 1 &&
        group.type === ExpressionSegmentTypes.GROUP_END)
    ) {
      // if condition is right after the targetted group start
      // or right before the targetted group end
      // no-op
    } else if (
      conditionIndex > 0 &&
      segments[conditionIndex - 1].type === ExpressionSegmentTypes.PREFIX &&
      conditionIndex - 2 === groupIndex &&
      group.type === ExpressionSegmentTypes.GROUP_START
    ) {
      // if condition is right after a prefix that is right after the targetted group start
      // no-op
    } else {
      // first remove condition
      segments = handleConditionRemoval(
        prevState[action.meta.builderId].segments,
        action.payload.conditionId
      );

      // recalc indexes after removal
      groupIndex = segments.findIndex(s => {
        return s.id === action.payload.groupId;
      });
      group = segments[groupIndex];

      if (group.type === ExpressionSegmentTypes.GROUP_START) {
        // if adding to start of group, add condition + default operator
        segments = [
          ...segments.slice(0, groupIndex + 1),
          condition,
          operatorFactory(ExpressionOperators.AND),
          ...segments.slice(groupIndex + 1)
        ];
      } else {
        // if adding to end of group, add default operator + condition
        segments = [
          ...segments.slice(0, groupIndex),
          operatorFactory(ExpressionOperators.AND),
          condition,
          ...segments.slice(groupIndex)
        ];
      }
    }

    return {
      ...prevState,
      [action.meta.builderId]: {
        ...prevState[action.meta.builderId],
        segments
      }
    };
  }
  if (expressionBuilderMoveSegmentAF.isAction(action)) {
    let segments = prevState[action.meta.builderId].segments.slice();
    const segmentIndex = segments.findIndex(s => {
      return s.id === action.payload.segmentId;
    });
    const segment = segments[segmentIndex];

    segments[segmentIndex] = undefined;

    segments.splice(action.payload.index, 0, segment);

    segments = segments.filter(s => {
      return !!s;
    });

    return {
      ...prevState,
      [action.meta.builderId]: {
        ...prevState[action.meta.builderId],
        segments
      }
    };
  }
  if (expressionBuilderMoveConditionToOperatorAF.isAction(action)) {
    let segments = prevState[action.meta.builderId].segments;

    const conditionIndex = segments.findIndex(s => {
      return s.id === action.payload.conditionId;
    });
    const condition = segments[conditionIndex];
    let operatorIndex = segments.findIndex(s => {
      return s.id === action.payload.operatorId;
    });

    // TODO: groups?
    if (
      conditionIndex === operatorIndex + 1 ||
      conditionIndex === operatorIndex - 1
    ) {
      // if targetted operator is immediately before or after condition
      // no-op
    } else if (
      conditionIndex > 0 &&
      segments[conditionIndex - 1].type === ExpressionSegmentTypes.PREFIX &&
      conditionIndex - 2 === operatorIndex
    ) {
      // if targetted operator is before NOT'd condition
      // no-op
    } else {
      // first remove condition
      segments = handleConditionRemoval(
        prevState[action.meta.builderId].segments,
        action.payload.conditionId
      );

      // recalc index after removal
      operatorIndex = segments.findIndex(s => {
        return s.id === action.payload.operatorId;
      });

      segments = [
        ...segments.slice(0, operatorIndex),
        operatorFactory(ExpressionOperators.AND),
        condition,
        ...segments.slice(operatorIndex)
      ];
    }

    return {
      ...prevState,
      [action.meta.builderId]: {
        ...prevState[action.meta.builderId],
        segments
      }
    };
  }
  return prevState;
}
