import {
  AuthenticatePendingAction,
  AuthenticateFulfilledAction,
  AuthenticateRejectedAction
} from "../actions/authActions";
import { ActionTypes } from "../constants/authConstants";
import { User } from "../models/authModels";

type AuthAction =
  | AuthenticatePendingAction
  | AuthenticateFulfilledAction
  | AuthenticateRejectedAction;

export interface UserState {
  readonly user?: User;
  readonly pending: boolean;
}

export const INITIAL_STATE: UserState = {
  user: undefined,
  pending: true
};

export function userReducer(
  prevState: UserState = INITIAL_STATE,
  action: AuthAction
): UserState {
  switch (action.type) {
    case ActionTypes.AUTHENTICATE_PENDING:
      return {
        ...prevState,
        user: undefined,
        pending: true
      };
    case ActionTypes.AUTHENTICATE_REJECTED:
      return {
        ...prevState,
        user: undefined,
        pending: false
      };
    case ActionTypes.AUTHENTICATE_FULFILLED:
      return {
        ...prevState,
        user: action.payload.user,
        pending: false
      };
    default:
      return prevState;
  }
}
