import * as React from "react";
import { WrappedFieldProps } from "redux-form";
import { InputHTMLAttributes } from "react";
import { CustomInput } from "reactstrap";
import classNames from "classnames";

export interface FormGroupInputFieldProps
  extends DefaultProps,
    WrappedFieldProps,
    InputHTMLAttributes<HTMLInputElement> {
  id: string;
}

interface DefaultProps {
  label?: string;
  "data-qa-id"?: string;
}

export class FormGroupInputField extends React.Component<
  FormGroupInputFieldProps
> {
  static defaultProps: DefaultProps = {
    label: undefined,
    "data-qa-id": undefined
  };
  render() {
    const { input, meta, label, ...props } = this.props;
    const invalidQaId = props["data-qa-id"]
      ? `${props["data-qa-id"]}-invalid-feedback`
      : undefined;
    return (
      <div className={"form-group"}>
        {label && (
          <label htmlFor={props.id}>
            {label}
            {props.required && <span> *</span>}
          </label>
        )}
        {props.type === "select" ? (
          <CustomInput {...props} {...input} type={"select"}>
            {props.children}
          </CustomInput>
        ) : (
          <input
            {...props}
            {...input}
            className={classNames("form-control", props.className, {
              "is-invalid": meta.error && meta.touched
            })}
          />
        )}
        <div className={"invalid-feedback"} data-qa-id={invalidQaId}>
          {meta.error}
        </div>
      </div>
    );
  }
}
