import { default as React } from "react";
import { default as NumberFormat } from "react-number-format";
import { isNil } from "lodash";
import { Spinner, UncontrolledTooltip } from "reactstrap";
import styles from "./CountIndicatorComponent.module.scss";
import classNames from "classnames";

export interface CountIndicatorProps {
  pending?: boolean;
  count: number;
  className?: string;
  label: string;
  qaId: string;
  tooltipText: string;
  tooltipId: string;
}

export const CountIndicatorFC: React.FC<CountIndicatorProps> = props => {
  return (
    <div
      className={classNames(
        "text-center bg-info text-light py-2 px-4",
        props.className,
        styles.indicatorContainer
      )}
      id={props.tooltipId}
    >
      {props.pending && <Spinner size={"sm"} color={"light"} />}
      {!props.pending && isNil(props.count) && <span>--</span>}
      {!props.pending && !isNil(props.count) && (
        <NumberFormat
          data-qa-id={props.qaId}
          thousandSeparator={true}
          value={props.count}
          displayType="text"
          className={"h5 text-light"}
        />
      )}
      <div className={"small"}>{props.label}</div>
      <UncontrolledTooltip placement={"top"} target={props.tooltipId}>
        {props.tooltipText}
      </UncontrolledTooltip>
    </div>
  );
};
