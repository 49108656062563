import {
  createStore,
  applyMiddleware,
  Middleware,
  StoreEnhancer,
  compose as composeType
} from "redux";
import {
  connectRouter,
  routerMiddleware as createRouterMiddleware
} from "connected-react-router";
import { createBrowserHistory } from "history";
import { combineEpics, createEpicMiddleware } from "redux-observable";
import { rootReducer, INITIAL_STATE } from "../reducers";
import { epics as templateEpics } from "../../template/epics";
import { epics as authEpics } from "../../auth/epics";
import { epics as appEpics } from "../epics";
import { BehaviorSubject } from "rxjs";
import { switchMap } from "rxjs/operators";

const history = createBrowserHistory();

function getRootEpic() {
  return combineEpics(appEpics, authEpics, templateEpics);
}

const epicMiddleware = createEpicMiddleware();
const routerMiddleware = createRouterMiddleware(history);
const defaultMiddleware = [routerMiddleware, epicMiddleware];

function configureStore(
  compose: typeof composeType,
  middleware: Middleware[] = [],
  storeEnhancers: StoreEnhancer[] = []
) {
  const store = createStore(
    connectRouter(history)(rootReducer),
    INITIAL_STATE,
    compose(
      applyMiddleware(...defaultMiddleware, ...middleware),
      ...storeEnhancers
    )
  );

  const epic$ = new BehaviorSubject(getRootEpic());
  // Every time a new epic is given to epic$ it
  // will unsubscribe from the previous one then
  // call and subscribe to the new one because of
  // how switchMap works
  const hotReloadingEpic = (...args: any[]) => {
    return epic$.pipe(
      switchMap((epic: any) => {
        return epic(...args);
      })
    );
  };

  epicMiddleware.run(hotReloadingEpic as any);

  /* TODO: get hot module replacement working again
  if (module.hot) {
    module.hot.accept('../reducers', () => {
      store.replaceReducer(connectRouter(history)(rootReducer));
    });

    module.hot.accept('../epics', () => {
      epic$.next(getRootEpic());
    });
  }
  */

  return store;
}

export { configureStore, history, routerMiddleware };
