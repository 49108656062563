import { Query } from "../../lib/expressionBuilderLib";

export interface ScoreDistribution {
  key: string;
  from: number;
  to: number;
  count: number;
}

export interface Audience extends CreateAudience {
  audience_id: number;
}

export const audienceProps: { [K in keyof Audience]: keyof Audience } = {
  audience_id: "audience_id",
  business_unit_id: "business_unit_id",
  conversions: "conversions",
  custom_identifier: "custom_identifier",
  is_active: "is_active",
  is_locked: "is_locked",
  is_read_only: "is_read_only",
  modified_at: "modified_at",
  modified_by: "modified_by",
  name: "name",
  query_id: "query_id",
  size: "size",
  starting_population_id: "starting_population_id",
  score_distribution: "score_distribution",
  query: "query",
  daily_counts: "daily_counts",
  labels: "labels"
};

export interface CreateAudience {
  business_unit_id: number;
  conversions: number;
  custom_identifier: string;
  is_active: boolean;
  is_locked: boolean;
  is_read_only: boolean;
  modified_at: string;
  modified_by: number;
  name: string;
  query_id: number;
  size: number;
  starting_population_id: number;
  score_distribution: ScoreDistribution[];
  query?: Query;
  daily_counts?: DailyCount[];
  labels: string[];
}

export interface DailyCount {
  audience_id: number;
  date: string;
  count: number;
}

export interface StartingPopOption {
  id: number;
  label: string;
}
