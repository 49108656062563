import {
  connectWithLifecycle,
  LifecycleDispatchProps
} from "react-lifecycle-component";
import { State } from "../../reducers";
import { Dispatch } from "redux";
import { reduxForm } from "redux-form";
import { PartialExternalSegment } from "../../api/attributeApi";
import {
  closeCreateModalAF,
  createExternalSegmentAF
} from "../actions/externalSegmentCreateActions";
import {
  StateProps,
  DispatchProps,
  Props,
  ExternalSegmentCreateModalFC
} from "../components/ExternalSegmentCreateModalComponent";
import {
  getExternalSegments,
  isFormSubmitted
} from "../selectors/externalSegmentSelectors";

const FORM_NAME = "external_segment_create";

function mapStateToProps(state: State): StateProps {
  return {
    isFormSubmit: isFormSubmitted(state),
    initialValues: {
      external_segment_type: "liveramp"
    },
    allSegments: getExternalSegments(state)
  };
}

function mapDispatchToProps(
  dispatch: Dispatch
): DispatchProps & LifecycleDispatchProps<Props> {
  return {
    componentDidMount() {},
    closeModal() {
      dispatch(closeCreateModalAF.create({}, {}));
    }
  };
}

const lifecycleWrapper = connectWithLifecycle(
  mapStateToProps,
  mapDispatchToProps
);
const formWrapper = reduxForm<PartialExternalSegment, Props>({
  form: FORM_NAME,
  onSubmit(segment, dispatch) {
    dispatch(createExternalSegmentAF.create(segment, {}));
  }
});

export const ExternalSegmentCreateModalContainer = lifecycleWrapper(
  formWrapper(ExternalSegmentCreateModalFC)
);
