import { Condition } from "../models/conditionBuilderModels";
import {
  ConditionSegment,
  PrefixSegment,
  ExpressionModes,
  GroupStartSegment,
  GroupEndSegment,
  ExpressionSegment
} from "../models/expressionBuilderModels";
import { AF } from "../../lib/actionFactoryLib";
import { NormalizedBusinessUnit } from "../../template/models/businessUnitModels";

interface BaseMeta extends FormMeta {
  readonly subQueryId: number;
}

interface FormMeta {
  readonly builderId: string;
}

export const expressionBuilderAddConditionAF = new AF<
  ConditionSegment,
  BaseMeta
>("EXPRESSION_BUILDER_ADD_CONDITION");

export const expressionBuilderEditConditionAF = new AF<
  { condition: Condition; conditionId: string },
  BaseMeta
>("EXPRESSION_BUILDER_EDIT_CONDITION");

export const expressionBuilderRemoveConditionAF = new AF<
  { conditionId: string },
  BaseMeta
>("EXPRESSION_BUILDER_REMOVE_CONDITION");

export const expressionBuilderLoadAudienceAF = new AF<
  { audienceId: number },
  FormMeta
>("EXPRESSION_BUILDER_LOAD_AUDIENCE");

export const expressionBuilderInitAF = new AF<
  { businessUnit: NormalizedBusinessUnit },
  FormMeta
>("EXPRESSION_BUILDER_INIT");

export const expressionBuilderDestroyAF = new AF<{}, FormMeta>(
  "EXPRESSION_BUILDER_DESTROY"
);

export const expressionBuilderResetStateAF = new AF<{}, FormMeta>(
  "EXPRESSION_BUILDER_RESET_STATE"
);

export const expressionBuilderSetModeAF = new AF<
  { mode: ExpressionModes },
  BaseMeta
>("EXPRESSION_BUILDER_SET_MODE");

export const expressionBuilderExpansionAF = new AF<
  { expand: boolean },
  FormMeta
>("EXPRESSION_BUILDER_EXPANSION");

export const expressionBuilderToggleOperatorAF = new AF<
  { operatorId: string },
  BaseMeta
>("EXPRESSION_BUILDER_TOGGLE_OPERATOR");

export const expressionBuilderAddPrefixAF = new AF<
  { index: number; prefix: PrefixSegment },
  BaseMeta
>("EXPRESSION_BUILDER_ADD_PREFIX");

export const expressionBuilderRemovePrefixAF = new AF<
  { prefixId: string },
  BaseMeta
>("EXPRESSION_BUILDER_REMOVE_PREFIX");

export const expressionBuilderAddGroupAF = new AF<
  {
    startIndex: number;
    startGroup: GroupStartSegment;
    endIndex: number;
    endGroup: GroupEndSegment;
  },
  BaseMeta
>("EXPRESSION_BUILDER_ADD_GROUP");

export const expressionBuilderRemoveGroupAF = new AF<
  { groupId: string },
  BaseMeta
>("EXPRESSION_BUILDER_REMOVE_GROUP");

export const expressionBuilderSetExpressionAF = new AF<
  ExpressionSegment[],
  BaseMeta
>("EXPRESSION_BUILDER_SET_EXPRESSION");

export const expressionBuilderSwapConditionsAF = new AF<
  { conditionId: string; otherConditionId: string },
  BaseMeta
>("EXPRESSION_BUILDER_SWAP_CONDITIONS");

export const expressionBuilderMoveConditionToGroupAF = new AF<
  { conditionId: string; groupId: string },
  BaseMeta
>("EXPRESSION_BUILDER_MOVE_CONDITION_TO_GROUP");

export const expressionBuilderMoveSegmentAF = new AF<
  { segmentId: string; index: number },
  BaseMeta
>("EXPRESSION_BUILDER_MOVE_SEGMENT");

export const expressionBuilderMoveConditionToOperatorAF = new AF<
  { conditionId: string; operatorId: string },
  BaseMeta
>("EXPRESSION_BUILDER_MOVE_CONDITION_TO_OPERATOR");

export const expressionBuilderStartingPopChangeAF = new AF<
  { startingPopId: number },
  BaseMeta
>("EXPRESSION_BUILDER_STARTING_POP_CHANGE");
