import { default as React } from "react";
import { BusinessUnitSelectorContainer } from "../containers/BusinessUnitSelectorContainer";
import { SettingsNavContainer } from "../containers/SettingsNavContainer";
import { UserNavContainer } from "../containers/UserNavContainer";
import styles from "./HeaderActionsComponent.module.scss";

export const HeaderActionFC: React.FC = () => {
  return (
    <div className={styles.headerActions}>
      <BusinessUnitSelectorContainer />
      <SettingsNavContainer />
      <UserNavContainer />
    </div>
  );
};
