import { ActionTypes } from "../constants/authConstants";
import { Action, ActionP } from "../../lib/reduxLib";
import { User } from "../models/authModels";

export interface AuthenticateAction extends Action {
  type: ActionTypes.AUTHENTICATE;
}

export interface AuthenticatePendingAction extends Action {
  type: ActionTypes.AUTHENTICATE_PENDING;
}

export interface AuthenticateFulfilledPayload {
  user: User;
}

export interface AuthenticateFulfilledAction
  extends ActionP<AuthenticateFulfilledPayload> {
  type: ActionTypes.AUTHENTICATE_FULFILLED;
}

export interface AuthenticateRejectedAction extends ActionP<Error> {
  type: ActionTypes.AUTHENTICATE_REJECTED;
}

export function authenticate(): AuthenticateAction {
  return {
    type: ActionTypes.AUTHENTICATE
  };
}

export function authenticatePending(): AuthenticatePendingAction {
  return {
    type: ActionTypes.AUTHENTICATE_PENDING
  };
}

export function authenticateFulfilled(user: User): AuthenticateFulfilledAction {
  return {
    type: ActionTypes.AUTHENTICATE_FULFILLED,
    payload: {
      user
    }
  };
}

export function authenticateRejected(error: Error): AuthenticateRejectedAction {
  return {
    type: ActionTypes.AUTHENTICATE_REJECTED,
    payload: error
  };
}
