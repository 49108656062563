import { connect } from "react-redux";
import { getSelectedBusinessUnitId } from "../selectors/businessUnitSelectors";
import { State } from "../reducers";
import {
  AuthorizedContainer,
  AuthorizedOwnProps,
  OwnProps
} from "../../auth/containers/AuthorizedContainer";
import { DispatchProps } from "../../auth/components/AccessComponent";

interface BuAuthOwnProps extends OwnProps {}

export const BusinessUnitAuthorizedContainer = connect<
  AuthorizedOwnProps,
  DispatchProps,
  BuAuthOwnProps
>(
  (state: State, ownProps: BuAuthOwnProps): AuthorizedOwnProps => {
    return {
      entityId: getSelectedBusinessUnitId(state),
      permissionIds: ownProps.permissionIds
    };
  }
)(AuthorizedContainer);
