import { default as React } from "react";
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { InjectedFormProps, Form } from "redux-form";
import { PartialScoreSegment, ScoreSegment } from "../../api/attributeApi";
import { DataPoint } from "highcharts";
import { ScoreRangeFC, Range } from "./ScoreRangeComponent";
import { getNameFieldName, getRangeFieldName } from "../constants/score";

export interface InjectedProps
  extends Props,
    InjectedFormProps<PartialScoreSegment, Props, string> {}
export interface Props extends StateProps, DispatchProps {}

export interface StateProps {
  scoreSegments: ScoreSegment[];
  open: boolean;
  initialValues: Partial<PartialScoreSegment>;
  dataPoints: DataPoint[];
  goal: ScoreSegment;
  goals: ScoreSegment[];
  name: string;
  ranges: Range[];
  isFormSubmitting: boolean;
  isMax: boolean;
}

export interface DispatchProps {
  closeModal(): void;
  onGoalChange(goal: ScoreSegment): void;
  onSegmentNameChange(index: number, name: string): void;
  onRangesChange(ranges: Range[]): void;
  onSetIsMax(max: boolean): void;
}

function nameIsUnique(
  value: string,
  allValues: PartialScoreSegment,
  props: Props
): string | undefined {
  return props.scoreSegments.find(
    scoreSegment => scoreSegment.attribute.name === value
  )
    ? `Score Segment with this name already exists`
    : undefined;
}

export const ScoreSegmentCreateModalFC: React.FC<InjectedProps> = props => {
  return (
    <Modal
      isOpen={props.open}
      toggle={props.closeModal}
      size="lg"
      data-qa-id="create-score-modal"
    >
      <Form onSubmit={props.handleSubmit}>
        <ModalHeader toggle={props.closeModal}>
          Create Score Segment(s)
        </ModalHeader>
        <ModalBody>
          <ScoreRangeFC
            nameIsUnique={nameIsUnique}
            initialNumberOfSegments={3}
            initialRanges={[]}
            dataPoints={props.dataPoints}
            goal={props.goal}
            goals={props.goals}
            ranges={props.ranges}
            isMax={props.isMax}
            onGoalChange={props.onGoalChange}
            onSegmentNameChange={props.onSegmentNameChange}
            onRangesChange={props.onRangesChange}
            getNameFieldName={getNameFieldName}
            getRangeFieldName={getRangeFieldName}
            onSetIsMax={props.onSetIsMax}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={props.closeModal}
            disabled={props.isFormSubmitting}
            data-qa-id="score-segment-create-cancel"
            color="link"
          >
            Cancel
          </Button>
          <Button
            color={"primary"}
            disabled={!props.valid || props.isFormSubmitting}
            onClick={props.handleSubmit}
            data-qa-id="score-segment-create-submit"
          >
            Submit
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
