import { Action } from "redux";
import {
  openUpdateModalAF,
  closeUpdateModalAF,
  readUpdateScoreSegmentByIdAF,
  updateScoreSegmentAF,
  updateSetIsMax
} from "../actions/updateActions";
import { ScoreSegment } from "../../api/attributeApi";

export interface State {
  isMax: boolean;
  showModal: boolean;
  isFormSubmitting: boolean;
  isGettingUpdateScoreSegment: boolean;
  scoreSegment?: ScoreSegment;
}

export const scoreUpdateInitialState: State = {
  isMax: true,
  showModal: false,
  isFormSubmitting: false,
  isGettingUpdateScoreSegment: false
};

export function updateReducer(
  previousState: State = scoreUpdateInitialState,
  action: Action
): State {
  if (openUpdateModalAF.isAction(action)) {
    return {
      ...previousState,
      showModal: true
    };
  }
  if (closeUpdateModalAF.isAction(action)) {
    return scoreUpdateInitialState;
  }
  if (readUpdateScoreSegmentByIdAF.fulfilledAF.isAction(action)) {
    const children = action.payload.children;

    children.sort((a, b) => {
      return a.min - b.min;
    });

    return {
      ...previousState,
      isGettingUpdateScoreSegment: false,
      scoreSegment: {
        ...action.payload,
        children
      }
    };
  }
  if (readUpdateScoreSegmentByIdAF.pendingAF.isAction(action)) {
    return {
      ...previousState,
      isGettingUpdateScoreSegment: true
    };
  }
  if (readUpdateScoreSegmentByIdAF.rejectedAF.isAction(action)) {
    return {
      ...previousState,
      isGettingUpdateScoreSegment: false
    };
  }
  if (updateScoreSegmentAF.pendingAF.isAction(action)) {
    return {
      ...previousState,
      isFormSubmitting: true
    };
  }
  if (updateScoreSegmentAF.rejectedAF.isAction(action)) {
    return {
      ...previousState,
      isFormSubmitting: false
    };
  }
  if (updateScoreSegmentAF.fulfilledAF.isAction(action)) {
    return scoreUpdateInitialState;
  }
  if (updateSetIsMax.isAction(action)) {
    return {
      ...previousState,
      isMax: action.payload.isMax
    };
  }
  return previousState;
}
