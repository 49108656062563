import { State } from "../reducers/";
import { State as TemplateState } from "../../../template/reducers";
import { ExternalSegment } from "../../api/attributeApi";
import { getSelectedBusinessUnitId } from "../../../template/selectors/businessUnitSelectors";
import { orderBy } from "lodash";
import { createSelector } from "reselect";
import { Audience } from "../../models/audienceModels";

export function getExternalSegments(
  state: State & TemplateState
): ExternalSegment[] {
  const buId = getSelectedBusinessUnitId(state);
  if (
    !state.externalSegments.segments.byBusinessUnitId[buId] ||
    !state.externalSegments.segments.byBusinessUnitId[buId].byExternalSegmentId
  ) {
    return [];
  }
  return Object.keys(
    state.externalSegments.segments.byBusinessUnitId[buId].byExternalSegmentId
  ).map(externalSegmentId => {
    return state.externalSegments.segments.byBusinessUnitId[buId]
      .byExternalSegmentId[externalSegmentId];
  });
}

export function getExternalSegmentsSort(state: State) {
  return state.externalSegments.segments.sort;
}

export const getSortedExternalSegments = createSelector(
  getExternalSegments,
  getExternalSegmentsSort,
  (externalSegments, sort) => {
    return orderBy(externalSegments, sort.iteratees, sort.orders);
  }
);

export function getExternalSegmentByAttributeId(
  state: State & TemplateState,
  attributeId: string
): ExternalSegment {
  const buId = getSelectedBusinessUnitId(state);

  if (
    !state.externalSegments.segments.byBusinessUnitId[buId] ||
    !state.externalSegments.segments.byBusinessUnitId[buId].byExternalSegmentId
  ) {
    return undefined;
  }

  const externalSegmentId = Object.keys(
    state.externalSegments.segments.byBusinessUnitId[buId].byExternalSegmentId
  ).find(externalSegmentId => {
    return (
      state.externalSegments.segments.byBusinessUnitId[buId]
        .byExternalSegmentId[externalSegmentId].attribute_id === attributeId
    );
  });

  return state.externalSegments.segments.byBusinessUnitId[buId]
    .byExternalSegmentId[externalSegmentId];
}

export function isGettingExternalSegments(state: State): boolean {
  return state.externalSegments.segments.isGettingExternalSegment;
}

export function showExternalSegmentCreateModal(state: State): boolean {
  return state.externalSegments.create.showModal;
}

export function isFormSubmitted(state: State): boolean {
  return state.externalSegments.create.isFormSubmit;
}

export function showExternalSegmentUpdateModal(state: State): boolean {
  return state.externalSegments.update.showModal;
}

export function getExternalSegmentUpdateAttribute(
  state: State
): ExternalSegment {
  return state.externalSegments.update.externalSegment;
}

export function getExternalSegmentUpdating(state: State): boolean {
  return state.externalSegments.update.updating;
}

export function showExternalSegmentDeleteModal(state: State): boolean {
  return state.externalSegments.delete.showModal;
}

export function getDeleteExternalSegment(state: State): ExternalSegment {
  return state.externalSegments.delete.externalSegment;
}

export function getDeleteAudiencesUsingExternalSegment(
  state: State
): Audience[] {
  return state.externalSegments.delete.audiencesUsingExternalSegment;
}

export function getIsGettingAudiencesUsingExternalSegment(
  state: State
): boolean {
  return state.externalSegments.delete.isGettingAudiencesUsingExternalSegment;
}

export function getDeleteIsDeleting(state: State): boolean {
  return state.externalSegments.delete.isDeleting;
}
