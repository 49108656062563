import { default as React } from "react";
import {
  Row,
  Col,
  Table,
  DropdownMenu,
  DropdownItem,
  Button,
  DropdownToggle,
  UncontrolledDropdown,
  CustomInput,
  Progress
} from "reactstrap";
import {
  CrmConfig,
  CrmMapping,
  crmProps,
  attributeProps
} from "../../api/attributeApi";
import { DefinedValuesTooltipFC } from "../../components/DefinedValuesTooltip";
import { CrmMappingCreateModalContainer } from "../containers/CrmMappingCreateModalContainer";
import { CrmMappingUpdateModalContainer } from "../containers/CrmMappingUpdateModalContainer";
import { CrmMappingDeleteModalContainer } from "../containers/CrmMappingDeleteModalContainer";
import { SortableTableHeaderFC } from "../../../bootstrap";
import { LinkContainer } from "../../../template/containers/LinkContainer";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faPlus } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";

export interface Props extends StateProps, DispatchProps {}

export interface StateProps {
  crmMappings: CrmMapping[];
  sort: { iteratees: string[]; orders: string[] };
  isGettingCrmMappings: boolean;
  showCreateModal: boolean;
  showUpdateModal: boolean;
  showDeleteModal: boolean;
  hasWriteAccess: boolean;
  crmConfig: CrmConfig;
}

export interface DispatchProps {
  onSort(sortKey: string): void;
  onCreate(): void;
  onUpdate(mapping: CrmMapping): void;
  onDelete(mapping: CrmMapping): void;
  onToggleCrm(value: boolean): void;
}

export const CrmMappingFC: React.FC<Props> = props => {
  return (
    <React.Fragment>
      <Row>
        <Col>
          <Breadcrumb>
            <BreadcrumbItem active>Admin</BreadcrumbItem>
            <BreadcrumbItem active>CRM Mapping</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      {props.isGettingCrmMappings ? (
        <Row>
          <Col>
            <Progress value={100} animated color={"primary"} />
          </Col>
        </Row>
      ) : (
        <React.Fragment>
          <Row>
            <Col>
              {props.showCreateModal && <CrmMappingCreateModalContainer />}
              {props.showUpdateModal && <CrmMappingUpdateModalContainer />}
              {props.showDeleteModal && <CrmMappingDeleteModalContainer />}
            </Col>
          </Row>
          {props.hasWriteAccess && (
            <Row>
              <Col
                className={
                  "d-flex align-items-center justify-content-between mb-3"
                }
              >
                <Button
                  color="primary"
                  data-qa-id="crm-mapping-add"
                  disabled={props.isGettingCrmMappings}
                  onClick={() => {
                    props.onCreate();
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                  Mapping
                </Button>
                <CustomInput
                  id={"crm-active"}
                  label={"Active"}
                  type={"switch"}
                  checked={!!props.crmConfig && props.crmConfig.active_flg}
                  onChange={() => {
                    props.onToggleCrm(
                      !(props.crmConfig && props.crmConfig.active_flg)
                    );
                  }}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Table hover striped bordered>
                <thead>
                  <tr>
                    <SortableTableHeaderFC
                      sortKey={crmProps.crm_column}
                      iteratees={props.sort.iteratees}
                      orders={props.sort.orders}
                      onClick={props.onSort}
                    >
                      Column Name
                    </SortableTableHeaderFC>
                    <SortableTableHeaderFC
                      sortKey={`${crmProps.attribute}.${attributeProps.name}`}
                      iteratees={props.sort.iteratees}
                      orders={props.sort.orders}
                      onClick={props.onSort}
                    >
                      Trait
                    </SortableTableHeaderFC>
                    <SortableTableHeaderFC
                      sortKey={`${crmProps.attribute}.${
                        attributeProps.data_type_name
                      }`}
                      iteratees={props.sort.iteratees}
                      orders={props.sort.orders}
                      onClick={props.onSort}
                    >
                      Data Type
                    </SortableTableHeaderFC>
                    <th className={"d-none d-md-table-cell"}>
                      Defined Values{" "}
                      <DefinedValuesTooltipFC
                        tooltipId={"table_defined_values_tooltip"}
                      />
                    </th>
                    <SortableTableHeaderFC
                      sortKey={`${crmProps.attribute}.${
                        attributeProps.modified_at
                      }`}
                      iteratees={props.sort.iteratees}
                      orders={props.sort.orders}
                      onClick={props.onSort}
                      className={"d-none d-xl-table-cell"}
                    >
                      Last Modified
                    </SortableTableHeaderFC>
                    {props.hasWriteAccess && <th>&nbsp;</th>}
                  </tr>
                </thead>
                <tbody>
                  {props.crmMappings.map(mapping => {
                    return (
                      <tr
                        key={mapping.id}
                        data-qa-id={`crm-mapping-${mapping.id}`}
                      >
                        <td className={"text-break"}>
                          <div className={"text-break"}>
                            <LinkContainer
                              to=""
                              onClick={() => {
                                props.onUpdate(mapping);
                              }}
                            >
                              {mapping.crm_column}
                            </LinkContainer>
                          </div>
                        </td>
                        <td className={"text-break"}>
                          <div className={"text-break"}>
                            <LinkContainer
                              to=""
                              onClick={() => {
                                props.onUpdate(mapping);
                              }}
                            >
                              {mapping.attribute.name}
                            </LinkContainer>
                          </div>
                        </td>
                        <td className={"text-capitalize"}>
                          {mapping.attribute.data_type_name}
                        </td>
                        <td className={"text-break d-none d-md-table-cell"}>
                          {mapping.attribute.attribute_values
                            .map(value => {
                              return value.value;
                            })
                            .join(", ")}
                        </td>
                        <td className={"text-break d-none d-xl-table-cell"}>
                          {DateTime.fromISO(
                            mapping.attribute.modified_at
                          ).toRelative()}
                        </td>
                        {props.hasWriteAccess && (
                          <td style={{ textAlign: "center" }}>
                            <UncontrolledDropdown
                              id={`crm_mapping_${mapping.id}`}
                            >
                              <DropdownToggle
                                size={"xs"}
                                data-qa-id={`crm-mapping-${
                                  mapping.id
                                }-dropdown-button`}
                              >
                                <FontAwesomeIcon icon={faEllipsisH} />
                              </DropdownToggle>
                              <DropdownMenu right={true}>
                                <DropdownItem
                                  data-qa-id={`crm-mapping-${
                                    mapping.id
                                  }-edit-link`}
                                  onClick={() => {
                                    props.onUpdate(mapping);
                                  }}
                                >
                                  Edit
                                </DropdownItem>
                                <DropdownItem
                                  data-qa-id={`crm-mapping-${
                                    mapping.id
                                  }-delete-link`}
                                  onClick={() => {
                                    props.onDelete(mapping);
                                  }}
                                >
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
