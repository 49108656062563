import { default as React } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Alert } from "reactstrap";
import { IoneSpinnerFC } from "./IoneSpinnerComponent";
import { TrackedDataContainer } from "../containers/TrackedDataContainer";
import styles from "./MainContentComponent.module.scss";

export interface MainContentProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{}> {}

export interface StateProps {
  pending: boolean;
  error: boolean;
}

export interface DispatchProps {}

// for some reason, setting padding on this container via emotion
// causes chart-js animations to not fire on initial load
// workaround is to set directly via style prop

export const MainContentFC: React.FC<MainContentProps> = props => {
  return (
    <div className={styles.mainContent}>
      {props.pending && <IoneSpinnerFC />}
      {props.error && <Alert color="danger">Error initializing template</Alert>}
      {!props.pending && !props.error && (
        <div>
          <TrackedDataContainer />
          {props.children}
        </div>
      )}
    </div>
  );
};
