import { default as React } from "react";
import { Helmet } from "react-helmet";

export const PageNotFound: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>404</title>
      </Helmet>
      <div className="text-center">
        <h1>Oops!</h1>
        <h2 className="text-primary">404 Error</h2>
        <h4 className="text-primary">Not Found</h4>
      </div>
    </div>
  );
};
