import { Action } from "redux";
import { readAttributeAF } from "../actions/attributeActions";
import { FullAttribute } from "../api/attributeApi";
import {
  deleteCrmMappingAF,
  createCrmMappingAF,
  updateCrmMappingAF
} from "../crm";
import { AppContext } from "../selectors/appContextSelectors";

export interface State extends BusinessUnitIdMap {}

interface BusinessUnitIdMap {
  readonly [businessUnitId: number]: AttributeState;
}

export interface AttributeState {
  readonly byId: {
    readonly [attributeId: string]: FullAttribute;
  };
}

export const businessUnitIdMapInitialState: State = {};

export function attributeReducer(
  previousState: State = businessUnitIdMapInitialState,
  action: Action
): BusinessUnitIdMap {
  if (readAttributeAF.fulfilledAF.isAction(action)) {
    const currentById = previousState[action.meta.context.businessUnit.id]
      ? previousState[action.meta.context.businessUnit.id].byId
      : {};

    return {
      ...previousState,
      [action.meta.context.businessUnit.id]: {
        byId: {
          ...currentById,
          [action.payload.id]: action.payload
        }
      }
    };
  }
  if (
    createCrmMappingAF.fulfilledAF.isAction(action) &&
    stateExists(previousState, action.meta.context)
  ) {
    const businessUnitId = action.meta.context.businessUnit.id;
    const attributeId = action.payload.attribute.id;
    const parentId = action.payload.attribute.parent_id;

    if (previousState[businessUnitId].byId[parentId]) {
      return {
        ...previousState,
        [businessUnitId]: {
          ...previousState[businessUnitId],
          byId: {
            ...previousState[businessUnitId].byId,
            [parentId]: {
              ...previousState[businessUnitId].byId[parentId],
              children: [
                ...previousState[businessUnitId].byId[parentId].children,
                action.payload.attribute
              ]
            },
            [attributeId]: action.payload.attribute
          }
        }
      };
    }
    return {
      ...previousState,
      [businessUnitId]: {
        ...previousState[businessUnitId],
        byId: {
          ...previousState[businessUnitId].byId,
          [attributeId]: action.payload.attribute
        }
      }
    };
  }
  if (
    updateCrmMappingAF.fulfilledAF.isAction(action) &&
    stateExists(previousState, action.meta.context)
  ) {
    const businessUnitId = action.meta.context.businessUnit.id;
    const attributeId = action.payload.attribute.id;
    const parentId = action.payload.attribute.parent_id;

    if (previousState[businessUnitId].byId[parentId]) {
      const children = [
        ...previousState[businessUnitId].byId[parentId].children
      ];
      const childIndex = children.findIndex(child => {
        return child.id === attributeId;
      });
      children[childIndex] = action.payload.attribute;

      return {
        ...previousState,
        [businessUnitId]: {
          ...previousState[businessUnitId],
          byId: {
            ...previousState[businessUnitId].byId,
            [attributeId]: action.payload.attribute,
            [parentId]: {
              ...previousState[businessUnitId].byId[parentId],
              children
            }
          }
        }
      };
    }
    return {
      ...previousState,
      [businessUnitId]: {
        ...previousState[businessUnitId],
        byId: {
          ...previousState[businessUnitId].byId,
          [attributeId]: action.payload.attribute
        }
      }
    };
  }
  if (
    deleteCrmMappingAF.fulfilledAF.isAction(action) &&
    stateExists(previousState, action.meta.context)
  ) {
    const businessUnitId = action.meta.context.businessUnit.id;
    const attributeId = action.meta.baseAction.payload.attribute.id;
    const parentId = action.meta.baseAction.payload.attribute.parent_id;
    const byId = previousState[businessUnitId].byId[parentId]
      ? {
          ...previousState[businessUnitId].byId,
          [parentId]: {
            ...previousState[businessUnitId].byId[parentId],
            children: previousState[businessUnitId].byId[
              parentId
            ].children.filter(child => {
              return child.id !== attributeId;
            })
          }
        }
      : { ...previousState[businessUnitId].byId };
    delete byId[attributeId];
    return {
      ...previousState,
      [businessUnitId]: {
        byId
      }
    };
  }
  return previousState;
}

function stateExists(previousState: State, context: AppContext): boolean {
  return !!(
    previousState &&
    previousState[context.businessUnit.id] &&
    previousState[context.businessUnit.id].byId
  );
}
