import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  GeoLocationComponent,
  StateProps
} from "../../components/ConditionBuilders/GeoLocationComponent";
import { State } from "../../reducers";
import { GEO_TYPE } from "../../api/geoApi";
import { ConditionBuilderBaseProps } from "../../components/ConditionBuilders/SharedData";

interface OwnProps extends ConditionBuilderBaseProps {
  type: GEO_TYPE;
}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  return {
    type: ownProps.attribute ? ownProps.type : undefined
  };
}

// this does not return dispatch props because we get those from own props
// note that this works because of how own props is merged first so we want dispatch and state mostly empty
function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): {} {
  return {};
}

export const GeoLocationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeoLocationComponent);
