import {
  ExpressionSegment,
  ConditionSegment,
  ExpressionGroups,
  ExpressionPrefixes,
  ExpressionSegmentTypes,
  ExpressionOperators,
  prefixFactory,
  operatorFactory,
  groupStartFactory,
  groupEndFactory
} from "../ac/models/expressionBuilderModels";
import { isTopInterestAttribute } from "../ac/constants/attributeIdConstants";
import { AttributeChannelType } from "../ac/constants/attributeChannelConstants";
import { AttributeOperator } from "../ac/api/attributeApi";

export type AttributeSymbol = {
  symbol: string;
  attribute: Criterion;
};

export type Criterion = {
  id: string;
  operator: string;
  value: string;
  channel: AttributeChannelType;
  subsetOperator?: string;
  subsetValue?: string;
  dataLocation?: string;
  dataType?: string;
};

export function isCriterion(obj: any): obj is Criterion {
  return (obj as Criterion).operator !== undefined;
}

export function getAttributeIdsFromCriterion(criterion: Criterion): string[] {
  return isTopInterestAttribute(criterion.id)
    ? [criterion.id, criterion.value]
    : [criterion.id];
}

export type Query = {
  query: string;
  variables: {
    expression: string;
    attributeSymbols: AttributeSymbol[];
    businessUnitId: number;
    subQueryId?: number;
  };
};

export enum QueryOperators {
  AND = "&&",
  OR = "||"
}

export enum QueryPrefixes {
  NOT = "!"
}

export enum QueryGroups {
  START = "(",
  END = ")"
}

/*tslint:disable:max-line-length*/
const COUNT_QUERY =
  "query countQuery($expression: String!, $attributeSymbols: [AttributeSymbol]!, $businessUnitId: Int!, $subQueryId: Int) { " +
  "count(expression: $expression, attributeSymbols: $attributeSymbols, businessUnitId: $businessUnitId, subQueryId: $subQueryId) { count total }" +
  "}";

const OPERATOR_MAP = {
  [ExpressionOperators.AND]: QueryOperators.AND,
  [ExpressionOperators.OR]: QueryOperators.OR
};

const GROUP_MAP = {
  [ExpressionGroups.START]: QueryGroups.START,
  [ExpressionGroups.END]: QueryGroups.END
};

const PREFIX_MAP = {
  [ExpressionPrefixes.NOT]: QueryPrefixes.NOT
};

const SPLIT_REGEX = /(&&|\|\||!|\(|\))/;

export function conditionToCriterion(condition: ConditionSegment): Criterion {
  const operator = condition.value.attribute.attribute_operators.find(
    (o: AttributeOperator) => {
      return o.id === +condition.value.operatorId;
    }
  );

  const subsetOperator = condition.value.attribute.attribute_subset_operators
    ? condition.value.attribute.attribute_subset_operators.find(
        (so: AttributeOperator) => {
          return so.id === +condition.value.subsetOperatorId;
        }
      )
    : undefined;

  return {
    id: condition.value.attribute.id,
    operator: operator.graphql_value,
    value: condition.value.value,
    channel: condition.value.channel,
    ...(subsetOperator ? { subsetOperator: subsetOperator.graphql_value } : {}),
    ...(condition.value.subsetValue !== undefined && subsetOperator
      ? { subsetValue: condition.value.subsetValue }
      : {}),
    ...(condition.value.dataLocation !== undefined
      ? { dataLocation: condition.value.dataLocation }
      : {}),
    ...(condition.value.dataType !== undefined
      ? { dataType: condition.value.dataType }
      : {})
  };
}

export function expressionModelToQuery(
  model: ExpressionSegment[],
  businessUnitId: number,
  subQueryId: number
): Query {
  if (model && model.length) {
    const { expressionStringBuilder, attributeSymbols } = model.reduce(
      (acc, segment) => {
        if (segment.type === ExpressionSegmentTypes.CONDITION) {
          acc.expressionStringBuilder.push(segment.id);

          if (segment.value) {
            acc.attributeSymbols.push({
              symbol: segment.id,
              attribute: conditionToCriterion(segment)
            });
          }
        } else if (segment.type === ExpressionSegmentTypes.OPERATOR) {
          // space on both sides of operator
          acc.expressionStringBuilder.push(` ${OPERATOR_MAP[segment.value]} `);
        } else if (segment.type === ExpressionSegmentTypes.PREFIX) {
          acc.expressionStringBuilder.push(PREFIX_MAP[segment.value]);
        } else if (segment.type === ExpressionSegmentTypes.GROUP_START) {
          acc.expressionStringBuilder.push(GROUP_MAP[segment.value]);
        } else if (segment.type === ExpressionSegmentTypes.GROUP_END) {
          acc.expressionStringBuilder.push(GROUP_MAP[segment.value]);
        }

        return acc;
      },
      {
        expressionStringBuilder: [] as string[],
        attributeSymbols: [] as AttributeSymbol[]
      }
    );

    return {
      query: COUNT_QUERY,
      variables: {
        attributeSymbols,
        businessUnitId,
        expression: expressionStringBuilder.join("")
      }
    };
  }

  return {
    query: COUNT_QUERY,
    variables: {
      businessUnitId,
      expression: "",
      attributeSymbols: []
    }
  };
}

export function queryToSegments(
  query: Query,
  generateIds: boolean = true
): (Criterion | ExpressionSegment)[] {
  if (query && query.variables) {
    const split = query.variables.expression
      .split(SPLIT_REGEX)
      .map(c => {
        return c.trim();
      })
      .filter(c => {
        return !!c;
      });

    const criteria = query.variables.attributeSymbols.reduce(
      (acc, symbol) => {
        acc[symbol.symbol] = symbol.attribute;
        return acc;
      },
      {} as { [key: string]: Criterion }
    );

    const segments = split.map(c => {
      if (c === QueryGroups.START) {
        return groupStartFactory(generateIds);
      }
      if (c === QueryGroups.END) {
        return groupEndFactory(generateIds);
      }
      if (c === QueryPrefixes.NOT) {
        return prefixFactory(ExpressionPrefixes.NOT, generateIds);
      }
      if (c === QueryOperators.AND) {
        return operatorFactory(ExpressionOperators.AND, generateIds);
      }
      if (c === QueryOperators.OR) {
        return operatorFactory(ExpressionOperators.OR, generateIds);
      }

      return criteria[c];
    });

    return segments;
  }

  return undefined;
}
