import {
  combineEpicMap,
  createAsyncEpic,
  createEpic,
  EpicMap,
  ofType
} from "../../../lib/epicFactory";
import {
  mappingsDidMountAF,
  readAllMappingsAF
} from "../actions/mappingsActions";
import {
  CrmMapping,
  readAllCrmMappings,
  createCrmMapping,
  updateCrmMapping,
  deleteCrmMapping,
  readCrmConfig,
  updateCrmConfig,
  CrmConfig
} from "../../api/attributeApi";
import { createCrmMappingAF } from "../actions/createActions";
import { updateCrmMappingAF } from "../actions/updateActions";
import { State } from "../../reducers";
import {
  deleteCrmMappingAF,
  openDeleteModalAF
} from "../actions/deleteActions";
import { readAudiencesUsingAttributes } from "../../api/audienceApi";
import { getAudiencesUsingAttributeAF } from "../../actions/audienceActions";
import { flatMap, map } from "rxjs/operators";
import { from } from "rxjs";
import {
  readCrmConfigAF,
  toggleCrmAF,
  updateCrmConfigAF
} from "../actions/configActions";
import { getCrmConfig } from "../selectors/crmSelectors";

export const epicMap: EpicMap<State> = {
  readAllCrmMappings: createAsyncEpic(
    readAllMappingsAF,
    (action, context, state) => {
      return readAllCrmMappings(context).pipe(
        map(mappings => {
          return mappings.reduce(
            (hashMap, crmMapping) => {
              hashMap[crmMapping.id] = crmMapping;
              return hashMap;
            },
            {} as { [crmId: string]: CrmMapping }
          );
        })
      );
    }
  ),
  createCrmMappingEpic: createAsyncEpic(
    createCrmMappingAF,
    (action, context, state: State) => {
      const mapping = action.payload;
      return createCrmMapping(mapping, context);
    }
  ),
  updateCrmMapping: createAsyncEpic(
    updateCrmMappingAF,
    (action, context, state) => {
      return updateCrmMapping(action.payload, context);
    }
  ),
  getAudiencesUsingAttribute: createAsyncEpic(
    getAudiencesUsingAttributeAF,
    (action, context, state) => {
      return readAudiencesUsingAttributes(
        [action.payload.attributeId],
        context
      );
    }
  ),
  openDeleteModal: createEpic<State>((action$, state$) => {
    return action$.pipe(
      ofType(openDeleteModalAF),
      map(action => {
        return getAudiencesUsingAttributeAF.create(
          { attributeId: action.payload.attribute_id },
          {}
        );
      })
    );
  }),
  deleteCrmMapping: createAsyncEpic(
    deleteCrmMappingAF,
    (action, context, state) => {
      return deleteCrmMapping(action.payload.id, context);
    }
  ),
  onMappingsDidMount: createEpic<State>((action$, state$) => {
    return action$.pipe(
      ofType(mappingsDidMountAF),
      flatMap(action => {
        return from([
          readAllMappingsAF.create({}, {}),
          readCrmConfigAF.create({}, {})
        ]);
      })
    );
  }),
  onReadCrmConfig: createAsyncEpic(
    readCrmConfigAF,
    (action, context, state) => {
      return readCrmConfig(context);
    }
  ),
  onUpdateCrmConfig: createAsyncEpic(
    updateCrmConfigAF,
    (action, context, state) => {
      return updateCrmConfig(action.payload, context);
    }
  ),
  onToggleCrm: createEpic<State>((action$, state$) => {
    return action$.pipe(
      ofType(toggleCrmAF),
      map(action => {
        const config: CrmConfig = {
          ...getCrmConfig(state$.value),
          active_flg: action.payload.value
        };
        return updateCrmConfigAF.create(config, {});
      })
    );
  })
};

export const epics = combineEpicMap(epicMap);
