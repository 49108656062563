import { default as React, ReactNode } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";

export interface ScrollToTopProps extends RouteComponentProps<{}> {
  children?: ReactNode;
}

class ScrollToTopComponent extends React.Component<ScrollToTopProps> {
  componentDidUpdate(prevProps: ScrollToTopProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export const ScrollToTop = withRouter(ScrollToTopComponent);
