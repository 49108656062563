import { default as React } from "react";
import { HeaderBrandFC } from "./HeaderBrandComponent";
import { HeaderActionFC } from "./HeaderActionsComponent";
import { NavbarFC } from "./NavbarComponent";
import { NavConfig, NavNode } from "../models/navigationModels";
import styles from "./HeaderComponent.module.scss";

export interface HeaderProps extends StateProps, DispatchProps {}

export interface StateProps {
  navigation: NavNode[];
  businessUnitId?: number;
  navConfig?: NavConfig;
}

export interface DispatchProps {
  onInit(navConfig: NavConfig, businessUnitId: number): void;
  onBusinessUnitIdChange(
    navConfig: NavConfig,
    newBusinessUnitId: number,
    oldBusinessUnitId: number
  ): void;
}

export class HeaderPureComponent extends React.PureComponent<HeaderProps> {
  static defaultProps: Partial<HeaderProps> = {
    navConfig: undefined as NavConfig,
    businessUnitId: undefined as number
  };

  componentDidMount() {
    this.props.onInit(this.props.navConfig, this.props.businessUnitId);
  }

  componentDidUpdate(prevProps: HeaderProps) {
    if (this.props.businessUnitId !== prevProps.businessUnitId) {
      this.props.onBusinessUnitIdChange(
        this.props.navConfig,
        this.props.businessUnitId,
        prevProps.businessUnitId
      );
    }
  }

  render() {
    return (
      <header className={styles.header}>
        <div className={styles.brandBar}>
          <HeaderBrandFC />
          <HeaderActionFC />
        </div>
        <NavbarFC />
      </header>
    );
  }
}
