import { State } from "../reducers";

export function getNavSelectedNode(state: State) {
  return state.template.navigation.byId
    ? state.template.navigation.byId[
        state.template.navigation.selectedNavNodeId
      ]
    : undefined;
}

export function getNavSubNavRootNode(state: State) {
  return state.template.navigation.byId
    ? state.template.navigation.byId[state.template.navigation.subNavRootNodeId]
    : undefined;
}

export function getNavSiteMapNavRootNode(state: State) {
  return state.template.navigation.byId
    ? state.template.navigation.byId[
        state.template.navigation.siteMapNavRootNodeId
      ]
    : undefined;
}

export function getNavUserNavRootNode(state: State) {
  return state.template.navigation.byId[
    state.template.navigation.userNavRootNodeId
  ]
    ? state.template.navigation.byId[
        state.template.navigation.userNavRootNodeId
      ]
    : undefined;
}

export function getNavSettingsNavRootNode(state: State) {
  return state.template.navigation.byId
    ? state.template.navigation.byId[
        state.template.navigation.settingsNavRootNodeId
      ]
    : undefined;
}

export function getSelectedSubNavCategoryNode(state: State) {
  return state.template.navigation.byId
    ? state.template.navigation.byId[
        state.template.navigation.selectedSubNavCategoryNodeId
      ]
    : undefined;
}
