import { default as React } from "react";
import {
  baseStyles,
  qaIds,
  BaseStateProps,
  BaseDispatchProps,
  baseDefaultProps,
  inputIds
} from "./SharedData";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import {
  createOptionFromNode,
  GEO_TYPE,
  GeoOption,
  getOne,
  search
} from "../../api/geoApi";
import { Subscription } from "rxjs";
import { AttributeName } from "../AttributeName";
import { CustomInput } from "reactstrap";

export interface StateProps extends BaseStateProps {
  type: GEO_TYPE;
}

export interface DispatchProps extends BaseDispatchProps {}

export interface GeoLocationProps extends StateProps, DispatchProps {}

type OptionType = GeoOption;
class GeoAsyncTypeahead extends AsyncTypeahead<OptionType> {}

export interface GeoLocationState {
  options: OptionType[];
  selected: OptionType[];
  getSub: Subscription;
  searchSub: Subscription;
}

export class GeoLocationComponent extends React.Component<
  GeoLocationProps,
  GeoLocationState
> {
  static defaultProps = {
    ...baseDefaultProps
  };

  constructor(props: GeoLocationProps) {
    super(props);
    this.state = {
      options: [],
      selected: [],
      getSub: undefined,
      searchSub: undefined
    };
  }

  componentDidMount() {
    if (this.props.value) {
      const getSub = getOne(this.props.value, this.props.type).subscribe(
        searchResults => {
          this.setState({
            ...this.state,
            selected: [
              createOptionFromNode(
                searchResults.results[this.props.type][this.props.value],
                searchResults
              )
            ]
          });
        }
      );
      this.setState({
        ...this.state,
        getSub
      });
    }
  }

  componentWillUnmount() {
    if (this.state.getSub) {
      this.state.getSub.unsubscribe();
    }
    if (this.state.searchSub) {
      this.state.searchSub.unsubscribe();
    }
  }

  onSearch(searchTerm: string) {
    const searchSub = search(searchTerm, this.props.type).subscribe(
      searchResults => {
        const resultIds = Object.keys(
          searchResults.results[searchResults.metadata.type]
        );
        this.setState({
          options: resultIds.map(id => {
            const node = searchResults.results[searchResults.metadata.type][id];
            return createOptionFromNode(node, searchResults);
          }),
          searchSub: undefined
        });
      }
    );
    this.setState({
      ...this.state,
      searchSub
    });
  }

  onSelectedChange = (newOptions: OptionType[]) => {
    if (newOptions && newOptions.length > 0) {
      this.props.handleChangeValue(
        this.props.attribute,
        this.props.operatorId,
        newOptions[0].id
      );
      this.setState({
        selected: newOptions.slice(0, 1)
      });
    } else {
      this.props.handleChangeValue(
        this.props.attribute,
        this.props.operatorId,
        ""
      );
      this.setState({
        selected: []
      });
    }
  };

  render() {
    const props = this.props;
    const state = this.state;

    return (
      <div className={baseStyles.conditionContainerClass}>
        <div className={baseStyles.itemMarginClass}>
          <AttributeName
            attribute={props.attribute}
            qaNameId={qaIds.selectedConditionName}
            qaTooltipId={qaIds.selectedConditionTooltipPath}
            qaTooltipIconId={qaIds.selectedConditionTooltipIcon}
            qaTooltipPathId={qaIds.selectedConditionTooltipPath}
          />
        </div>
        {props.attribute.attribute_operators.length > 0 && (
          <div className={baseStyles.itemMarginClass}>
            <CustomInput
              id={inputIds.operatorId}
              type={"select"}
              value={props.operatorId}
              onChange={e => {
                props.handleChangeOperatorId(
                  props.attribute,
                  (e.target as any).value,
                  props.value
                );
              }}
              data-qa-id={qaIds.selectedConditionOperator}
            >
              {props.attribute.attribute_operators.map((operator: any) => {
                return (
                  <option value={operator.id} key={operator.id}>
                    {operator.name}
                  </option>
                );
              })}
            </CustomInput>
          </div>
        )}
        <GeoAsyncTypeahead
          isLoading={!!state.searchSub}
          options={state.options}
          selected={state.selected}
          onSearch={(query: string) => {
            return this.onSearch(query);
          }}
          labelKey={(option: OptionType) => {
            return option.displayName;
          }}
          onChange={this.onSelectedChange}
          placeholder={`Search for a ${props.attribute.name}`}
          minLength={3}
          inputProps={{
            ...({
              "data-qa-id": qaIds.selectedConditionValue
            } as any)
          }}
          className={baseStyles.itemMarginClass}
        />
      </div>
    );
  }
}
