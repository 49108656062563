import { GEO_TYPE } from "../api/geoApi";
import { CV, ES, WP, PV } from "./attributeSourceTypeConstants";

export const SCORE_CATEGORIES = "AC13";
export const ENGAGEMENT_SCORE = "AC14";
export const IGNITIONONE_SCORE = "AC29";
export const WEBSITE_PERSONALIZATION = "AC44";
export const INTERESTS = "AC28";

export const TOP_INTEREST = "AC17";
export const TOP_3_INTEREST = "AC18";
export const TOP_5_INTEREST = "AC19";

export const WP_DEVICE_TYPE = "AC9";
export const DS_DEVICE_TYPE = "AC43";

export const WP_PAGE_URL = "AC3";
export const WP_FIRST_VISIT = "AC10";
export const WP_LAST_VISIT = "AC11";
export const WP_VISIT_RECENCY = "AC26";
export const WP_CURRENT_PAGE_URL = "AC36";

export const DS_PAGE_URL = "AC39";
export const DS_VISIT_RECENCY = "AC40";

export const WP_IP_LOCATION_CITY = "AC21";
export const WP_IP_LOCATION_STATE = "AC22";
export const WP_IP_LOCATION_POSTAL_CODE = "AC23";
export const WP_IP_LOCATION_COUNTRY = "AC24";

export const DS_IP_LOCATION_CITY = "AC32";
export const DS_IP_LOCATION_STATE = "AC33";
export const DS_IP_LOCATION_POSTAL_CODE = "AC34";
export const DS_IP_LOCATION_COUNTRY = "AC35";

export const PV_CONVERSION = "PV70";
export const PV_CONVERSION_WITH_RECENCY = "PV71";

const TOP_INTEREST_ATTRIBUTES: { [id: string]: string } = {
  [TOP_INTEREST]: TOP_INTEREST,
  [TOP_3_INTEREST]: TOP_3_INTEREST,
  [TOP_5_INTEREST]: TOP_5_INTEREST
};

export function isTopInterestAttribute(attributeId: string): boolean {
  return !!TOP_INTEREST_ATTRIBUTES[attributeId];
}

const GEO_SERVICE_ATTRIBUTES: { [id: string]: string } = {
  [WP_IP_LOCATION_CITY]: WP_IP_LOCATION_CITY,
  [WP_IP_LOCATION_STATE]: WP_IP_LOCATION_STATE,
  [WP_IP_LOCATION_COUNTRY]: WP_IP_LOCATION_COUNTRY,

  [DS_IP_LOCATION_CITY]: DS_IP_LOCATION_CITY,
  [DS_IP_LOCATION_STATE]: DS_IP_LOCATION_STATE,
  [DS_IP_LOCATION_COUNTRY]: DS_IP_LOCATION_COUNTRY
};

export const GEO_SERVICE_ATTRIBUTE_TO_TYPE_MAP: { [id: string]: GEO_TYPE } = {
  [WP_IP_LOCATION_CITY]: GEO_TYPE.CITY,
  [WP_IP_LOCATION_STATE]: GEO_TYPE.REGION,
  [WP_IP_LOCATION_COUNTRY]: GEO_TYPE.COUNTRY,

  [DS_IP_LOCATION_CITY]: GEO_TYPE.CITY,
  [DS_IP_LOCATION_STATE]: GEO_TYPE.REGION,
  [DS_IP_LOCATION_COUNTRY]: GEO_TYPE.COUNTRY
};

export function isGeoServiceAttribute(attributeId: string): boolean {
  return !!GEO_SERVICE_ATTRIBUTES[attributeId];
}

export function isConversionAttribute(attributeId: string): boolean {
  return attributeId.startsWith(CV);
}

export function isExternalSegmentAttribute(attributeId: string): boolean {
  return attributeId.startsWith(ES);
}

export function isPVariableAttribute(attributeId: string): boolean {
  return attributeId.startsWith(PV);
}

export function isWPAttribute(attributeId: string): boolean {
  return attributeId.startsWith(WP);
}
