import { Action } from "redux";
import { ConditionBuilderMode } from "../models/conditionBuilderModels";
import {
  conditionBuilderChangeOperatorIdAF,
  conditionBuilderChangeSubsetOperatorIdAF,
  conditionBuilderChangeSubsetValueAF,
  conditionBuilderChangeValueAF,
  conditionBuilderClearConditionAF,
  conditionBuilderDestroyAF,
  conditionBuilderEditConditionAF,
  conditionBuilderInitAF,
  conditionBuilderResetStateAF,
  conditionBuilderSetMetaAF,
  conditionBuilderChangeDataTypeAF
} from "../actions/conditionBuilderActions";

export interface State extends BuilderIdMapState {}

export interface BuilderState {
  readonly condition?: {
    readonly attributeId?: string;
    readonly operatorId?: string;
    readonly value?: string;
    readonly subsetOperatorId?: string;
    readonly subsetValue?: string;
    readonly model?: string;
    readonly dataLocation?: string;
    readonly dataType?: string;
  };
  readonly meta?: any;
  readonly mode?: ConditionBuilderMode;
  readonly conditionId?: string;
}

interface BuilderIdMapState {
  [builderId: string]: BuilderState;
}

export const builderIdMapInitialState: BuilderIdMapState = {};
export const builderInitialState: BuilderState = {
  condition: {},
  meta: {},
  mode: undefined,
  conditionId: undefined
};

export function conditionBuilderReducer(
  previousState: BuilderIdMapState = builderIdMapInitialState,
  action: Action
): BuilderIdMapState {
  if (
    conditionBuilderResetStateAF.isAction(action) ||
    conditionBuilderInitAF.isAction(action)
  ) {
    return {
      ...previousState,
      [action.meta.builderId]: {
        ...builderInitialState,
        ...previousState[action.meta.builderId]
      }
    };
  }
  if (conditionBuilderDestroyAF.isAction(action)) {
    const newIdMap = { ...previousState };
    delete newIdMap[action.meta.builderId];

    return newIdMap;
  }
  if (conditionBuilderEditConditionAF.isAction(action)) {
    return {
      ...previousState,
      [action.meta.builderId]: {
        ...previousState[action.meta.builderId],
        condition: {
          attributeId: action.payload.attributeId,
          operatorId: action.payload.operatorId,
          value: action.payload.value,
          subsetOperatorId: action.payload.subsetOperatorId,
          subsetValue: action.payload.subsetValue,
          model: action.payload.model,
          dataLocation: action.payload.dataLocation,
          dataType: action.payload.dataType
        },
        mode: action.meta.mode,
        conditionId: action.meta.conditionId
      }
    };
  }
  if (conditionBuilderChangeDataTypeAF.isAction(action)) {
    return {
      ...previousState,
      [action.meta.builderId]: {
        ...previousState[action.meta.builderId],
        condition: {
          ...previousState[action.meta.builderId].condition,
          dataType: action.payload.dataType
        }
      }
    };
  }
  if (conditionBuilderChangeOperatorIdAF.isAction(action)) {
    return {
      ...previousState,
      [action.meta.builderId]: {
        ...previousState[action.meta.builderId],
        condition: {
          ...previousState[action.meta.builderId].condition,
          operatorId: action.payload.operatorId
        }
      }
    };
  }
  if (conditionBuilderChangeSubsetOperatorIdAF.isAction(action)) {
    return {
      ...previousState,
      [action.meta.builderId]: {
        ...previousState[action.meta.builderId],
        condition: {
          ...previousState[action.meta.builderId].condition,
          subsetOperatorId: action.payload.subsetOperatorId
        }
      }
    };
  }
  if (conditionBuilderChangeValueAF.isAction(action)) {
    return {
      ...previousState,
      [action.meta.builderId]: {
        ...previousState[action.meta.builderId],
        condition: {
          ...previousState[action.meta.builderId].condition,
          value: action.payload.value
        }
      }
    };
  }
  if (conditionBuilderChangeSubsetValueAF.isAction(action)) {
    return {
      ...previousState,
      [action.meta.builderId]: {
        ...previousState[action.meta.builderId],
        condition: {
          ...previousState[action.meta.builderId].condition,
          subsetValue: action.payload.subsetValue
        }
      }
    };
  }
  if (conditionBuilderClearConditionAF.isAction(action)) {
    return {
      ...previousState,
      [action.meta.builderId]: builderInitialState
    };
  }
  if (conditionBuilderSetMetaAF.isAction(action)) {
    return {
      ...previousState,
      [action.meta.builderId]: {
        ...previousState[action.meta.builderId],
        meta: action.payload
      }
    };
  }
  return previousState;
}
