import { default as React, ReactNode } from "react";
import {
  DropTarget,
  DropTargetSpec,
  DropTargetCollector,
  ConnectDropTarget,
  DragSource,
  DragSourceSpec,
  DragSourceCollector,
  ConnectDragSource
} from "react-dnd";
import { ConditionCardComponent } from "./ConditionCardComponent";
import {
  ConditionSegment,
  GroupStartSegment,
  GroupEndSegment,
  PrefixSegment
} from "../models/expressionBuilderModels";
import {
  CONDITION,
  GROUP_START,
  GROUP_END,
  PREFIX
} from "../constants/dndtypeConstants";
import { EB_ITEM_MARGIN } from "../constants/styleConstants";

export interface ConditionCardAdvancedProps
  extends DndSourceProps,
    DndTargetProps {
  condition: ConditionSegment;
  conditionValueOverride?: string;
  conditionSubsetValueOverride?: string;
  swapCondition(conditionId: string, otherConditionId: string): void;
  moveSegment(segmentId: string, index: number): void;
  findSegmentIndex(segmentId: string): number;
  handleDeleteCondition(condition: ConditionSegment): void;
  handleEditCondition(condition: ConditionSegment): void;
}

export interface DndSourceProps {
  connectDragSource?: ConnectDragSource;
  isDragging?: boolean;
}

export interface DndTargetProps {
  connectDropTarget?: ConnectDropTarget;
  isOver?: boolean;
}

export const ConditionCardAdvancedFC: React.FC<
  ConditionCardAdvancedProps
> = props => {
  return (
    <div className={EB_ITEM_MARGIN}>
      {props.connectDragSource(
        props.connectDropTarget(
          <div>
            <ConditionCardComponent
              key={props.condition.id}
              condition={props.condition}
              handleEditCondition={props.handleEditCondition}
              handleDeleteCondition={props.handleDeleteCondition}
              conditionValueOverride={props.conditionValueOverride}
              conditionSubsetValueOverride={props.conditionSubsetValueOverride}
              isOver={props.isOver}
              isDragging={props.isDragging}
              className={"c-move"}
            />
          </div>
        )
      )}
    </div>
  );
};

const dropSpec: DropTargetSpec<ConditionCardAdvancedProps> = {
  drop(props, monitor) {
    switch (monitor.getItemType()) {
      case CONDITION: {
        const condition = monitor.getItem() as ConditionSegment;
        props.swapCondition(condition.id, props.condition.id);
        break;
      }
      case GROUP_START: {
        const group = monitor.getItem() as GroupStartSegment;
        const index = props.findSegmentIndex(props.condition.id);
        props.moveSegment(group.id, index);
        break;
      }
      case GROUP_END: {
        const group = monitor.getItem() as GroupEndSegment;
        const index = props.findSegmentIndex(props.condition.id);
        props.moveSegment(group.id, index + 1);
        break;
      }
      case PREFIX: {
        const prefix = monitor.getItem() as PrefixSegment;
        const index = props.findSegmentIndex(props.condition.id);
        props.moveSegment(prefix.id, index);
        break;
      }
      default:
        break;
    }
  }
};

const dropCollect: DropTargetCollector<DndTargetProps> = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
};

const dragSpec: DragSourceSpec<ConditionCardAdvancedProps, ReactNode> = {
  beginDrag(props) {
    return props.condition;
  }
};

const dragCollect: DragSourceCollector<DndSourceProps> = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
};

const sourceWrapper = DragSource(CONDITION, dragSpec, dragCollect);
const targetWrapper = DropTarget(
  [CONDITION, GROUP_START, GROUP_END, PREFIX],
  dropSpec,
  dropCollect
);

export const ConditionCardAdvancedDND = sourceWrapper(
  targetWrapper(ConditionCardAdvancedFC)
);
