import { Action } from "redux";
import { ROOT_ATTRIBUTE_SOURCE_TYPE } from "../api/attributeApi";
import {
  attributeSelectorChangeSearchTermAF,
  attributeSelectorChangeSearchTermDebouncedAF,
  attributeSelectorDeselectAttributeAF,
  attributeSelectorDestroyAF,
  attributeSelectorInitAF,
  attributeSelectorNavigateAttributeAF,
  attributeSelectorResetStateAF,
  attributeSelectorSelectAttributeAF,
  attributeSelectorSelectBreadCrumbAF
} from "../actions/attributeSelectorActions";

export const selectorIdMapInitialState: State = {};

export const attributeSelectorInitialState = {
  breadCrumbs: [`${ROOT_ATTRIBUTE_SOURCE_TYPE}_0`]
};

export type State = Readonly<SelectorIdMapState>;

interface SelectorIdMapState {
  [selectorId: string]: AttributeSelectorState;
}

export interface AttributeSelectorState {
  readonly breadCrumbs: string[];
  readonly selectedAttributeId: string;
  readonly inputSearchTerm: string;
  readonly searchTerm: string;
}

export function attributeSelectorReducer(
  previousState: State = selectorIdMapInitialState,
  action: Action
): SelectorIdMapState {
  if (
    attributeSelectorInitAF.isAction(action) ||
    attributeSelectorResetStateAF.isAction(action)
  ) {
    return {
      ...previousState,
      [action.meta.selectorId]: {
        ...attributeSelectorInitialState,
        ...previousState[action.meta.selectorId]
      }
    };
  }
  if (attributeSelectorDestroyAF.isAction(action)) {
    const newIdMap: SelectorIdMapState = { ...previousState };
    delete newIdMap[action.meta.selectorId];

    return newIdMap;
  }
  if (attributeSelectorSelectBreadCrumbAF.isAction(action)) {
    if (previousState[action.meta.selectorId]) {
      const attributeSelector = previousState[action.meta.selectorId];
      if (attributeSelector.breadCrumbs) {
        const index = attributeSelector.breadCrumbs.indexOf(action.payload.id);
        if (index !== -1) {
          const breadCrumbs = attributeSelector.breadCrumbs.slice(0, index + 1);

          return {
            ...previousState,
            [action.meta.selectorId]: {
              ...previousState[action.meta.selectorId],
              breadCrumbs
            }
          };
        }
      }
    }

    return previousState;
  }
  if (attributeSelectorNavigateAttributeAF.isAction(action)) {
    return {
      ...previousState,
      [action.meta.selectorId]: {
        ...previousState[action.meta.selectorId],
        breadCrumbs: [
          ...previousState[action.meta.selectorId].breadCrumbs,
          action.payload.id
        ]
      }
    };
  }
  if (attributeSelectorChangeSearchTermAF.isAction(action)) {
    return {
      ...previousState,
      [action.meta.selectorId]: {
        ...previousState[action.meta.selectorId],
        inputSearchTerm: action.payload.searchTerm
      }
    };
  }
  if (attributeSelectorChangeSearchTermDebouncedAF.isAction(action)) {
    return {
      ...previousState,
      [action.meta.selectorId]: {
        ...previousState[action.meta.selectorId],
        searchTerm: action.payload.searchTerm
      }
    };
  }
  if (attributeSelectorSelectAttributeAF.isAction(action)) {
    return {
      ...previousState,
      [action.meta.selectorId]: {
        ...previousState[action.meta.selectorId],
        selectedAttributeId: action.payload.id
      }
    };
  }
  if (attributeSelectorDeselectAttributeAF.isAction(action)) {
    return {
      ...previousState,
      [action.meta.selectorId]: {
        ...previousState[action.meta.selectorId],
        selectedAttributeId: undefined
      }
    };
  }
  return previousState;
}
