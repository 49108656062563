import { Observable, throwError } from "rxjs";
import { HttpHeaders } from "../ac/constants/httpHeaderConstants";
import { AppContext } from "../ac/selectors/appContextSelectors";
import { getLoginRedirectUrl, TokenProvider } from "../auth/lib/authLib";
import { ajax, AjaxError, AjaxResponse, AjaxRequest } from "rxjs/ajax";
import { catchError, map } from "rxjs/operators";

export interface Headers {
  [key: string]: string | number;
}

export function mergeDefaultHeaders(
  headers: Headers,
  appContext: AppContext
): Headers {
  return {
    [HttpHeaders.Token]: TokenProvider.getToken(),
    [HttpHeaders.BusinessUnitId]: appContext.businessUnit.id,
    ...headers
  };
}

export function handleResponse<T>(
  response$: Observable<AjaxResponse>
): Observable<T> {
  return response$.pipe(
    map((response: AjaxResponse) => {
      return response.response as T;
    }),
    catchError((error: AjaxError) => {
      if (error.status === 401) {
        window.location.href = getLoginRedirectUrl();
      }
      return throwError(error);
    })
  );
}

export function get<T>(
  appContext: AppContext,
  path: string,
  headers: Headers = {}
): Observable<T> {
  return baseGet(
    appContext.dataCenter.domain + path,
    mergeDefaultHeaders(headers, appContext)
  );
}

export function baseGet<T>(
  url: string,
  headers: Headers = {},
  options: Partial<AjaxRequest> = {}
): Observable<T> {
  return handleResponse(
    ajax({
      url,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...headers
      },
      crossDomain: true,
      ...options
    })
  );
}

export function post<T>(
  appContext: AppContext,
  path: string,
  data: any,
  headers: Headers = {}
): Observable<T> {
  return basePost(
    appContext.dataCenter.domain + path,
    data,
    mergeDefaultHeaders(headers, appContext)
  );
}

export function basePost<T>(
  url: string,
  data: any,
  headers: Headers = {},
  options: Partial<AjaxRequest> = {}
): Observable<T> {
  return handleResponse(
    ajax({
      url,
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        ...headers
      },
      crossDomain: true,
      ...options
    })
  );
}

export function put<T>(
  appContext: AppContext,
  path: string,
  data: any,
  headers: Headers = {}
): Observable<T> {
  return handleResponse(
    ajax({
      url: appContext.dataCenter.domain + path,
      method: "PUT",
      body: data,
      headers: {
        ...mergeDefaultHeaders(headers, appContext),
        "Content-Type": "application/json"
      },
      crossDomain: true
    })
  );
}

export function del<T>(
  appContext: AppContext,
  path: string,
  headers: Headers = {}
): Observable<T> {
  return handleResponse(
    ajax({
      url: appContext.dataCenter.domain + path,
      method: "DELETE",
      headers: mergeDefaultHeaders(headers, appContext),
      crossDomain: true
    })
  );
}

export function patch<T>(
  appContext: AppContext,
  path: string,
  data: any,
  headers: Headers = {}
): Observable<T> {
  return handleResponse(
    ajax({
      url: appContext.dataCenter.domain + path,
      method: "PATCH",
      body: data,
      headers: {
        ...mergeDefaultHeaders(headers, appContext),
        "Content-Type": "application/json"
      },
      crossDomain: true
    })
  );
}
