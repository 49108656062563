import { isFunction } from "lodash";

export function normalize(str: number | string): string {
  return str
    .toString()
    .toLowerCase()
    .trim();
}

export function match(target: number | string, query: number | string) {
  const normalizedTarget = normalize(target);
  const normalizedQuery = normalize(query);

  if (
    normalizedQuery === null ||
    normalizedQuery === undefined ||
    normalizedTarget === null ||
    normalizedTarget === undefined
  ) {
    return false;
  }

  return normalizedTarget.indexOf(normalizedQuery) !== -1;
}

export type PropFn<T> = (obj: T) => number | string;

export function objectMatch<T, K extends keyof T>(
  object: T,
  query: number | string,
  ...props: (K | PropFn<T>)[]
): boolean {
  const results: boolean[] = props.map(prop => {
    if (isFunction(prop)) {
      return match(prop(object), query);
    } else if (typeof prop === "string") {
      const value = object[prop];
      if (typeof value === "string" || typeof value === "number") {
        return match(value, query);
      }
    }
    return false;
  });

  return results.some(result => {
    return result;
  });
}

export function collectionMatch<T, K extends keyof T>(
  collection: T[],
  query: number | string,
  ...props: (K | PropFn<T>)[]
) {
  return collection.filter(item => {
    return objectMatch(item, query, ...props);
  });
}
