import { connect } from "react-redux";
import { State } from "../reducers";
import { getNavUserNavRootNode } from "../selectors/navigationSelectors";
import {
  UserNavFC as UserNavComponent,
  UserNavProps
} from "../components/UserNavComponent";

function mapStateToProps(state: State): UserNavProps {
  return {
    navigation:
      getNavUserNavRootNode(state) && getNavUserNavRootNode(state).children
  };
}

function mapDispatchToProps() {
  return {};
}

export const UserNavContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserNavComponent);
