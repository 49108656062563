import { default as React } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  ConditionBuilderDropTarget,
  ConditionBuilderProps as CBProps,
  StateProps as CBStateProps,
  DispatchProps as CBDispatchProps
} from "../components/ConditionBuilderComponent";
import { getAttributeByBusinessUnitId } from "../selectors/audienceSelectors";
import {
  getConditionBuilder,
  getConditionBuilderMode
} from "../selectors/conditionBuilderSelector";
import {
  conditionBuilderLoadConditionAF,
  conditionBuilderClearConditionAF,
  conditionBuilderValidateOperatorIdAF,
  conditionBuilderValidateValueAF,
  conditionBuilderValidateSubsetOperatorIdAF,
  conditionBuilderValidateSubsetValueAF,
  conditionBuilderSaveConditionClickedAF,
  conditionBuilderInitAF,
  conditionBuilderDestroyAF,
  loadConditionDefaults,
  loadConditionMetaDefaults,
  conditionBuilderChangeSubsetValueAF,
  conditionBuilderChangeSubsetOperatorIdAF,
  conditionBuilderChangeDataTypeAF
} from "../actions/conditionBuilderActions";
import { Audience } from "../models/audienceModels";
import { State } from "../reducers";
import { Attribute, FullAttribute } from "../api/attributeApi";

export interface ConditionBuilderWrapperProps
  extends PartialStateProps,
    PartialDispatchProps,
    ComponentProps<CBProps> {}

interface ComponentProps<P> {
  componentProps: P;
}

interface StateProps extends PartialStateProps, ComponentProps<CBStateProps> {}

interface PartialStateProps {}

interface DispatchProps
  extends PartialDispatchProps,
    ComponentProps<CBDispatchProps> {}

interface PartialDispatchProps {
  init(): void;
  destroy(): void;
}

interface OwnProps {
  builderId: string;
  startingPop: Audience;
}

class ConditionBuilderPureComponent extends React.PureComponent<
  ConditionBuilderWrapperProps
> {
  componentDidMount() {
    this.props.init();
  }

  componentWillUnmount() {
    this.props.destroy();
  }

  render() {
    return <ConditionBuilderDropTarget {...this.props.componentProps} />;
  }
}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  const conditionBuilder = getConditionBuilder(state, ownProps.builderId);
  const componentProps: CBStateProps = {
    builderId: ownProps.builderId,
    value: undefined,
    operatorId: undefined,
    subsetValue: undefined,
    subsetOperatorId: undefined,
    model: undefined,
    dataLocation: undefined,
    dataType: undefined,
    attribute: undefined,
    mode: undefined,
    isOver: undefined
  };

  if (conditionBuilder && conditionBuilder.condition) {
    const condition = conditionBuilder.condition;

    if (condition.value !== undefined) {
      componentProps.value = condition.value;
    }
    if (condition.operatorId !== undefined) {
      componentProps.operatorId = condition.operatorId;
    }
    if (condition.subsetValue !== undefined) {
      componentProps.subsetValue = condition.subsetValue;
    }
    if (condition.subsetOperatorId !== undefined) {
      componentProps.subsetOperatorId = condition.subsetOperatorId;
    }
    if (condition.attributeId !== undefined) {
      const attributeData = getAttributeByBusinessUnitId(state);
      componentProps.attribute = attributeData.byId[condition.attributeId];
    }
    if (condition.dataLocation !== undefined) {
      componentProps.dataLocation = condition.dataLocation;
    }
    if (condition.dataType !== undefined) {
      componentProps.dataType = condition.dataType;
    }
    if (condition.model !== undefined) {
      componentProps.model = condition.model;
    }
  }

  componentProps.mode = getConditionBuilderMode(state, ownProps.builderId);

  return {
    componentProps
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps {
  return {
    init() {
      dispatch(
        conditionBuilderInitAF.create({}, { builderId: ownProps.builderId })
      );
    },
    destroy() {
      dispatch(
        conditionBuilderDestroyAF.create({}, { builderId: ownProps.builderId })
      );
    },
    componentProps: {
      handleDragDrop(attribute: Attribute) {
        dispatch(
          conditionBuilderLoadConditionAF.create(
            { attributeId: attribute.id, ...loadConditionDefaults },
            { builderId: ownProps.builderId, ...loadConditionMetaDefaults }
          )
        );
      },
      handleClickClose(attribute: Attribute) {
        dispatch(
          conditionBuilderClearConditionAF.create(
            {},
            { builderId: ownProps.builderId, attributeId: attribute.id }
          )
        );
      },
      handleClickAdd(
        attribute: FullAttribute,
        operatorId: string,
        value: string,
        subsetOperatorId: string,
        subsetValue: string,
        model: string,
        dataLocation: string,
        dataType: string
      ) {
        dispatch(
          conditionBuilderSaveConditionClickedAF.create(
            {
              attribute,
              operatorId,
              value,
              subsetOperatorId,
              subsetValue,
              model,
              dataLocation,
              dataType,
              channel: attribute.channel
            },
            {
              builderId: ownProps.builderId,
              subQueryId: ownProps.startingPop && ownProps.startingPop.query_id
            }
          )
        );
      },
      handleChangeDataType(dataType: string) {
        dispatch(
          conditionBuilderChangeDataTypeAF.create(
            { dataType },
            { builderId: ownProps.builderId }
          )
        );
      },
      handleChangeOperatorId(
        attribute: FullAttribute,
        operatorId: string,
        value: string
      ) {
        dispatch(
          conditionBuilderValidateOperatorIdAF.create(
            { attribute, operatorId, value },
            { builderId: ownProps.builderId }
          )
        );
      },
      handleChangeValue(
        attribute: FullAttribute,
        operatorId: string,
        value: string
      ) {
        dispatch(
          conditionBuilderValidateValueAF.create(
            { attribute, operatorId, value },
            { builderId: ownProps.builderId }
          )
        );
      },
      handleChangeSubsetOperatorId(
        attribute: FullAttribute,
        subsetOperatorId: string,
        subsetValue: string
      ) {
        dispatch(
          conditionBuilderValidateSubsetOperatorIdAF.create(
            {
              attribute,
              operatorId: subsetOperatorId,
              value: subsetValue
            },
            { builderId: ownProps.builderId }
          )
        );
      },
      handleChangeSubsetValue(
        attribute: FullAttribute,
        subsetOperatorId: string,
        subsetValue: string
      ) {
        dispatch(
          conditionBuilderValidateSubsetValueAF.create(
            { attribute, operatorId: subsetOperatorId, value: subsetValue },
            { builderId: ownProps.builderId }
          )
        );
      },
      handleClearSubset() {
        dispatch(
          conditionBuilderChangeSubsetOperatorIdAF.create(
            { subsetOperatorId: null },
            { builderId: ownProps.builderId }
          )
        );
        dispatch(
          conditionBuilderChangeSubsetValueAF.create(
            { subsetValue: null },
            { builderId: ownProps.builderId }
          )
        );
      },
      connectDropTarget() {}
    }
  };
}

function mergeProps(
  stateProps: StateProps,
  dispatchProps: DispatchProps
): ConditionBuilderWrapperProps {
  return {
    ...stateProps,
    ...dispatchProps,
    componentProps: {
      ...stateProps.componentProps,
      ...dispatchProps.componentProps
    }
  };
}

export const ConditionBuilderContainer = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  ConditionBuilderWrapperProps,
  State
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ConditionBuilderPureComponent);

ConditionBuilderContainer.defaultProps = {
  builderId: "defaultBuilderId"
};
