import { default as React } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { tokenContainer } from "react-bootstrap-typeahead";
import classNames from "classnames";

export interface LabelProps {
  className?: string;
  disabled?: boolean;
  label: string;
  onRemove(): void;
}

export const Label: React.FC<LabelProps> = tokenContainer<LabelProps>(props => (
  <div className={classNames("badge badge-primary", props.className)}>
    <div className="label d-inline-block">
      <div className="text-truncate">{props.label}</div>
    </div>
    {props.disabled !== true && (
      <span className="label-close c-pointer ml-1" onClick={props.onRemove}>
        <FontAwesomeIcon icon={faTimes} />
      </span>
    )}
  </div>
));
