import { default as React } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { Audience } from "../models/audienceModels";
import { HOME } from "../constants/pathConstants";

export interface RemoveAudienceModalProps {
  show: boolean;
  audience: Audience;
  childAudiences: Audience[];
  onConfirm(): void;
}

export const CannotRemoveAudienceModalFC: React.FC<
  RemoveAudienceModalProps
> = props => {
  return (
    <Modal isOpen={props.show} toggle={props.onConfirm}>
      <ModalHeader toggle={props.onConfirm}>Cannot Delete Audience</ModalHeader>
      <ModalBody>
        <div className={"mb-3"}>
          This audience cannot be deleted because it is being used as a starting
          population by:
        </div>
        {props.childAudiences.map(c => {
          return (
            <div key={c.audience_id}>
              <Link to={HOME}>{c.name}</Link>
            </div>
          );
        })}
      </ModalBody>
      <ModalFooter>
        <Button
          color={"primary"}
          onClick={() => {
            props.onConfirm();
          }}
          data-qa-id="cannot-delete-audience-modal-ok"
        >
          OK
        </Button>
      </ModalFooter>
    </Modal>
  );
};
