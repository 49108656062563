import { StatelessComponent } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { State } from "../reducers";
import {
  getPermissionEnabled,
  getPermissionPending
} from "../selectors/permissionSelectors";
import {
  AccessPureComponent,
  DispatchProps,
  StateProps
} from "../components/AccessComponent";

export interface OwnProps {
  permissionIds: number[];
  pendingComponent?: StatelessComponent<any>;
  deniedComponent?: StatelessComponent<any>;
}

export interface AuthorizedOwnProps extends OwnProps {
  entityId: number;
}

function mapStateToProps(
  state: State,
  ownProps: AuthorizedOwnProps
): StateProps {
  return {
    hasAccess:
      ownProps.permissionIds.filter(id => {
        return !getPermissionEnabled(state, id, ownProps.entityId);
      }).length === 0,
    checkAccessPending:
      ownProps.permissionIds.filter(id => {
        return getPermissionPending(state, id, ownProps.entityId);
      }).length > 0,
    deniedComponent: ownProps.deniedComponent,
    pendingComponent: ownProps.pendingComponent
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  ownProps: AuthorizedOwnProps
): DispatchProps {
  return {
    checkAccess: () => {} // no-op here because we get permissions when bu changes
  };
}

export const AuthorizedContainer = connect<
  StateProps,
  DispatchProps,
  AuthorizedOwnProps
>(
  mapStateToProps,
  mapDispatchToProps
)(AccessPureComponent);
