import { default as React } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { LinkContainer } from "../containers/LinkContainer";
import { NavNode } from "../models/navigationModels";
import classNames from "classnames";
import styles from "./HeaderActionMenuComponent.module.scss";

export interface HeaderActionMenuProps {
  navigation?: NavNode[];
  content?: any;
  divider?: boolean;
  id: string;
  toggle: any;
  [prop: string]: any;
}

export const HeaderActionMenuFC: React.FC<HeaderActionMenuProps> = props => {
  const { navigation, toggle, id, content, divider, ...otherProps } = props;
  return (
    <UncontrolledDropdown id={id} className={styles.headerActionMenu}>
      <DropdownToggle
        tag={"div"}
        className={classNames(styles.dropdownToggle, divider && styles.divider)}
      >
        {React.createElement(toggle, otherProps)}
      </DropdownToggle>
      <DropdownMenu
        className={styles.dropdownMenu}
        modifiers={{ preventOverflow: { padding: 0 } }}
      >
        {content && React.createElement(content, otherProps)}
        {!content &&
          navigation &&
          navigation.map(item => {
            return (
              item.visible && (
                <li key={item.id}>
                  <LinkContainer
                    className={styles.dropdownMenuLink}
                    to={item.finalHref}
                    target={item.target}
                    data-qa-id={`nav-${item.id}`}
                  >
                    {item.iconClass && (
                      <i
                        className={classNames(
                          styles.dropdownMenuLinkIcon,
                          item.iconClass
                        )}
                      />
                    )}
                    {item.name}
                  </LinkContainer>
                </li>
              )
            );
          })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

HeaderActionMenuFC.defaultProps = {
  navigation: undefined,
  divider: false,
  content: undefined
};
