import { ChangeEvent, default as React } from "react";
import { Field, Normalizer, SubmitHandler } from "redux-form";
import { AttributeSelectorContainer } from "../containers/AttributeSelectorContainer";
import { ConditionBuilderContainer } from "../containers/ConditionBuilderContainer";
import { ExpressionBuilderContainer } from "../containers/ExpressionBuilderContainer";
import { required, maxLength } from "../../lib/validationLib";
import {
  Audience,
  audienceProps,
  CreateAudience,
  StartingPopOption
} from "../models/audienceModels";
import { ConditionBuilderMode } from "../models/conditionBuilderModels";
import { AttributeChannelType } from "../constants/attributeChannelConstants";
import { NormalizedBusinessUnit } from "../../template/models/businessUnitModels";
import { DataCenter } from "../constants/dataCenters";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  ListGroup,
  ListGroupItem,
  Row
} from "reactstrap";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormGroupInputField } from "../forms/components/FormGroupInputField/FormGroupInputField";
import { FormGroupLabelsInputField } from "../forms/components/FormGroupInputField/FormGroupLabelsInputField";
import { SwitchInputField } from "../forms/components/FormGroupInputField/SwitchInputField";
import { HOME } from "../constants/pathConstants";
import { LinkContainer } from "../../template/containers/LinkContainer";
import { ExpressionBuilderCountContainer } from "../containers/ExpressionBuilderCountContainer";

const maxLength100 = maxLength(100);

function isFieldDisabled(
  fieldName: string,
  disabledProp: boolean | string[],
  isNameValid?: boolean
): boolean {
  if (disabledProp === true || isNameValid === false) {
    return true;
  }
  if ((disabledProp as string[]).length) {
    return (disabledProp as string[]).indexOf(fieldName) > -1;
  }

  return false;
}

interface DefaultProps {
  audience: Partial<Audience>;
  submitText: string;
  startingPop: Audience;
  expandExpressionBuilder: boolean;
  startingPopOptions: StartingPopOption[];
  audienceLabelSuggestions: string[];
}

export interface AudienceFormProps extends Partial<DefaultProps> {
  handleSubmit:
    | SubmitHandler<Audience, any, string>
    | SubmitHandler<CreateAudience, any, string>;
  builderId: string;
  channel: AttributeChannelType;
  disabled: boolean | string[];
  isSubmitDisabled: boolean;
  isNameValid: boolean;
  conditionBuilderMode: ConditionBuilderMode;
  parentAudiences: Audience[];
  onCustomIdChange?: Normalizer;
  businessUnit: NormalizedBusinessUnit;
  onStartingPopChange(audienceId: number, fieldName: string): void;
  onNameChange(name: string): void;
}

// const startingPopTooltipId = "locked_starting_pop_tooltip";

export class AudienceForm extends React.Component<AudienceFormProps> {
  static defaultProps: DefaultProps = {
    audience: undefined as Audience,
    submitText: "Submit",
    startingPop: undefined,
    expandExpressionBuilder: false,
    startingPopOptions: undefined as [],
    audienceLabelSuggestions: []
  };

  render() {
    const props = this.props;
    return (
      <React.Fragment>
        <form
          onSubmit={props.handleSubmit}
          onKeyPress={e => {
            if (e.which === 13) {
              e.preventDefault();
            }
          }}
        >
          <Card>
            <CardHeader>
              {props.audience ? props.audience.name : "New Audience"}
            </CardHeader>
            <ListGroup flush disabled={!props.isNameValid}>
              <ListGroupItem>
                <Row>
                  <Col md={3}>
                    <Field
                      id={audienceProps.name}
                      name={audienceProps.name}
                      label={"Name"}
                      type={"text"}
                      placeholder="Enter an Audience Name"
                      component={FormGroupInputField}
                      validate={[required, maxLength100]}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        props.onNameChange(event.currentTarget.value);
                      }}
                      required
                      data-qa-id="audience-name-field"
                    />
                  </Col>
                  <Col md={3}>
                    <Field
                      id={audienceProps.custom_identifier}
                      label={"Audience ID"}
                      component={FormGroupInputField}
                      name={audienceProps.custom_identifier}
                      disabled={isFieldDisabled(
                        audienceProps.custom_identifier,
                        props.disabled,
                        props.isNameValid
                      )}
                      normalize={props.onCustomIdChange}
                      validate={[required, maxLength100]}
                      data-qa-id="custom-identifier-field"
                      required
                    />
                  </Col>
                  {/* Removed because of IDR Toggle Feature
                <Col md={3}>
                  <FormGroup controlId={audienceProps.starting_population_id}>
                    <Label>
                      Starting Population&nbsp;
                      {props.parentAudiences.length > 0 && (
                        <span
                          id={startingPopTooltipId}
                          data-qa-id="locked_starting_population_tooltip_trigger"
                        >
                          <FontAwesomeIcon
                            icon={faLock}
                          />
                          <Tooltip target={startingPopTooltipId}>
                            <div>
                              <div>Child Audiences</div>
                              {props.parentAudiences &&
                              props.parentAudiences.length > 0 &&
                              props.parentAudiences.map(a => {
                                return (
                                  <div key={a.audience_id}>
                                    <LinkContainer to={getAudienceOverviewPath(a.audience_id)}>
                                      {a.name}
                                    </LinkContainer>
                                  </div>
                                );
                              })}
                            </div>
                          </Tooltip>
                        </span>
                      )}
                    </Label>
                    <IoTypeaheadFC
                      qaId={"starting-population-field"}
                      typeaheadProps={{
                        onChange(selected: StartingPopOption[]) {
                          selected[0] &&
                            props.onStartingPopChange(
                              selected[0].id,
                              audienceProps.starting_population_id
                            );
                        },
                        options: props.startingPopOptions,
                        selected:
                          props.audience &&
                          props.startingPop &&
                          props.startingPop.audience_id !== 0
                            ? [`${props.startingPop.name}`]
                            : ["All Profiles"],
                        disabled:
                          isFieldDisabled(
                            audienceProps.starting_population_id,
                            props.disabled,
                            props.isNameValid
                          ) || props.parentAudiences.length > 0,
                        inputProps: {
                          name: audienceProps.starting_population_id
                        }
                      }}
                    />
                  </FormGroup>
                </Col>
                */}
                  <Col md={2}>
                    <Field
                      label={"Locked"}
                      name={audienceProps.is_locked}
                      id={"is-locked-toggle"}
                      component={SwitchInputField}
                      data-qa-id="is-locked-toggle"
                    />
                    {props.businessUnit.dataCenterId === DataCenter.EU && (
                      <p className={"custom-switch text-danger"}>
                        IDR in the EU is off; Cross Channel queries could result
                        in empty audiences.
                      </p>
                    )}
                  </Col>
                  <Col
                    md={4}
                    className={
                      "d-flex justify-content-center align-items-center justify-content-md-end"
                    }
                  >
                    <div>
                      <ExpressionBuilderCountContainer
                        builderId={props.builderId}
                        subQueryId={
                          props.startingPop && props.startingPop.query_id
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row>
                  <Col lg={4}>
                    <Field
                      id={audienceProps.labels}
                      label={"Labels"}
                      component={FormGroupLabelsInputField}
                      allowNew={true}
                      name={audienceProps.labels}
                      suggestions={props.audienceLabelSuggestions}
                      data-qa-id="audience-labels"
                    />
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem>
                <Row className={"mb-3"}>
                  <Col>Targeting</Col>
                </Row>
                <Row>
                  <Col
                    md={6}
                    lg={4}
                    xl={3}
                    className={
                      props.expandExpressionBuilder ? "d-none" : undefined
                    }
                  >
                    {!props.expandExpressionBuilder && (
                      <div>
                        <AttributeSelectorContainer
                          selectorId={props.builderId}
                        />
                      </div>
                    )}
                  </Col>
                  <Col
                    className={`${
                      props.expandExpressionBuilder
                        ? "col-12"
                        : "col-md-6 col-lg-8 col-xl-9"
                    } mt-3 mt-md-0`}
                  >
                    <div>
                      <div>
                        {!props.expandExpressionBuilder && (
                          <div>
                            <div>
                              <span>
                                {props.conditionBuilderMode ===
                                ConditionBuilderMode.EDIT ? (
                                  <span>Edit Condition</span>
                                ) : (
                                  <span>Add Condition</span>
                                )}
                              </span>
                            </div>
                            <div className={"py-3"}>
                              <ConditionBuilderContainer
                                builderId={props.builderId}
                                startingPop={props.startingPop}
                              />
                            </div>
                          </div>
                        )}
                        <div>
                          <span>Existing Conditions</span>
                        </div>
                        <ExpressionBuilderContainer
                          builderId={props.builderId}
                          startingPop={props.startingPop}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
            <CardFooter className={"d-flex justify-content-end"}>
              <LinkContainer
                to={HOME}
                data-qa-id="audience-form-cancel-button"
                className={"btn btn-link"}
              >
                Cancel
              </LinkContainer>
              <Button
                color="primary"
                type="submit"
                disabled={props.isSubmitDisabled || !props.isNameValid}
                data-qa-id="audience-form-submit-button"
              >
                {props.audience && props.audience.is_read_only ? (
                  <span>
                    <FontAwesomeIcon icon={faLock} /> Locked
                  </span>
                ) : (
                  <span>{props.submitText}</span>
                )}
              </Button>
            </CardFooter>
          </Card>
        </form>
      </React.Fragment>
    );
  }
}
