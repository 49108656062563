import { env } from "../env/appEnv";
import { del, get, post, put } from "../../lib/httpLib";
import { AppContext } from "../selectors/appContextSelectors";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AttributeChannelType } from "../constants/attributeChannelConstants";

const ATTRIBUTE_RESOURCE = "attributes";
const SCORE_RESOURCE = "scores";
const SEGMENT_RESOURCE = "segments";
const GOAL_RESOURCE = "goals";
const attributeResourcePath = `${env.REACT_APP_ATTRIBUTE_SERVICE_API_PATH}/api/${ATTRIBUTE_RESOURCE}`;

export const ROOT_ATTRIBUTE_SOURCE_TYPE: string = "ac-ui";
export const ROOT_ATTRIBUTE_ID: string = "0";

export const attributeProps: {
  [K in keyof FullAttribute]: keyof FullAttribute;
} = {
  id: "id",
  name: "name",
  parent_id: "parent_id",
  tree_path: "tree_path",
  data_type_name: "data_type_name",
  children: "children",
  is_trait: "is_trait",
  is_category: "is_category",
  source_type: "source_type",
  channel: "channel",
  attribute_values: "attribute_values",
  attribute_operators: "attribute_operators",
  attribute_subset_operators: "attribute_subset_operators",
  created_by: "created_by",
  modified_by: "modified_by",
  modified_at: "modified_at"
};

export const crmProps: { [K in keyof CrmMapping]: keyof CrmMapping } = {
  id: "id",
  crm_column: "crm_column",
  data_location: "data_location",
  attribute_id: "attribute_id",
  attribute: "attribute"
};

export const scoreSegmentProps: {
  [K in keyof ScoreSegment]: keyof ScoreSegment;
} = {
  id: "id",
  model: "model",
  min: "min",
  max: "max",
  attribute_id: "attribute_id",
  attribute: "attribute",
  children: "children"
};

export const externalSegmentProps: {
  [K in keyof ExternalSegment]: keyof ExternalSegment;
} = {
  id: "id",
  name: "name",
  attribute_id: "attribute_id",
  attribute: "attribute",
  score: "score",
  predicted_count: "predicted_count",
  business_unit: "business_unit",
  external_segment_id: "external_segment_id",
  external_segment_type: "external_segment_type"
};

export interface Attribute {
  id: string;
  name: string;
  parent_id: string;
  tree_path: string;
  data_type_name: string;
  children: Attribute[];
  is_trait: boolean;
  is_category: boolean;
  source_type: string;
  channel: AttributeChannelType;
  created_by: string;
  modified_by: string;
  modified_at: string;
  count?: number;
}

export function readAllAttributes(context: AppContext): Observable<Attribute> {
  return get<Attribute[]>(context, attributeResourcePath).pipe(
    map(attributes => {
      return {
        id: `${ROOT_ATTRIBUTE_SOURCE_TYPE}_0`,
        name: "All Traits",
        parent_id: `${ROOT_ATTRIBUTE_SOURCE_TYPE}_0`,
        tree_path: "All Traits",
        source_type: "ui",
        channel: "UI" as any,
        is_category: true,
        is_trait: false,
        children: attributes,
        data_type_name: "",
        created_by: "",
        modified_by: "",
        modified_at: ""
      };
    })
  );
}

export interface AttributeValue {
  id: number;
  value: string;
  display_name: string;
}

export interface AttributeOperator {
  id: number;
  name: string;
  graphql_value: string;
}

export interface AttributeLookup {
  attributeId: string;
}

export interface FullAttribute extends Attribute {
  attribute_values: AttributeValue[];
  attribute_operators: AttributeOperator[];
  attribute_subset_operators: AttributeOperator[];
}

export function readAttribute(
  attributeId: string,
  context: AppContext
): Observable<FullAttribute> {
  return get<FullAttribute>(
    context,
    `${attributeResourcePath}/${encodeURIComponent(attributeId)}`
  );
}

export interface CrmMapping {
  id: number;
  crm_column: string;
  data_location: string;
  attribute_id: string;
  attribute: FullAttribute;
}

export interface PartialCrmMapping {
  id?: number;
  crm_column?: string;
  data_location?: string;
  attribute_id?: string;
  attribute?: Partial<FullAttribute>;
}

export function readAllCrmMappings(
  context: AppContext
): Observable<CrmMapping[]> {
  return get<CrmMapping[]>(context, `${attributeResourcePath}/crm/mappings`);
}

export function readScoreSegmentGoals(
  context: AppContext
): Observable<ScoreSegment[]> {
  return get<ScoreSegment[]>(
    context,
    `${attributeResourcePath}/${SCORE_RESOURCE}/${GOAL_RESOURCE}`
  );
}

export function readScoreSegmentById(
  attributeId: string,
  context: AppContext
): Observable<ScoreSegment> {
  return get<ScoreSegment>(
    context,
    `${attributeResourcePath}/${attributeId}/${SCORE_RESOURCE}/${SEGMENT_RESOURCE}`
  );
}

export function createCrmMapping(
  mapping: PartialCrmMapping,
  context: AppContext
): Observable<CrmMapping> {
  return post<CrmMapping>(
    context,
    `${attributeResourcePath}/crm/mappings`,
    mapping
  );
}

export function updateCrmMapping(
  crmMapping: CrmMapping,
  context: AppContext
): Observable<CrmMapping> {
  return put<CrmMapping>(
    context,
    `${attributeResourcePath}/crm/mappings/${crmMapping.id}`,
    crmMapping
  );
}

export function deleteCrmMapping(
  crmId: number,
  context: AppContext
): Observable<undefined> {
  return del(context, `${attributeResourcePath}/crm/mappings/${crmId}`);
}

export interface CrmConfig {
  buid: number;
  active_flg: boolean;
}

export function readCrmConfig(context: AppContext): Observable<CrmConfig> {
  return get<CrmConfig>(context, `${attributeResourcePath}/crm/config`);
}

export function updateCrmConfig(
  config: CrmConfig,
  context: AppContext
): Observable<CrmConfig> {
  return put<CrmConfig>(context, `${attributeResourcePath}/crm/config`, config);
}

export interface ScoreSegment {
  id: number;
  min: number;
  max: number;
  attribute_id: string;
  attribute: FullAttribute;
  model: string;
  children: ScoreSegment[];
}

export interface PartialScoreSegment {
  id?: number;
  min?: number;
  max?: number;
  attribute_id?: string;
  attribute?: Partial<FullAttribute>;
  model?: string;
  children?: PartialScoreSegment[];
}

export function createScore(
  score: PartialScoreSegment,
  context: AppContext
): Observable<ScoreSegment> {
  return post<ScoreSegment>(
    context,
    `${attributeResourcePath}/${SCORE_RESOURCE}/${SEGMENT_RESOURCE}`,
    score
  );
}

export function updateScore(
  scoreId: number,
  score: PartialScoreSegment,
  context: AppContext
): Observable<ScoreSegment> {
  return put<ScoreSegment>(
    context,
    `${attributeResourcePath}/${SCORE_RESOURCE}/${SEGMENT_RESOURCE}/${scoreId}`,
    score
  );
}

export function readRootScoreSegments(
  context: AppContext
): Observable<ScoreSegment[]> {
  return get<ScoreSegment[]>(
    context,
    `${attributeResourcePath}/${SCORE_RESOURCE}/${SEGMENT_RESOURCE}`
  );
}

export function deleteScoreSegment(
  scoreId: number,
  context: AppContext
): Observable<undefined> {
  return del(
    context,
    `${attributeResourcePath}/${SCORE_RESOURCE}/${SEGMENT_RESOURCE}/${scoreId}`
  );
}

export interface ExternalSegment {
  id: number;
  name: string;
  attribute_id: string;
  attribute: FullAttribute;
  predicted_count: number;
  score: number;
  business_unit: number;
  external_segment_id: number;
  external_segment_type: string;
}

export interface PartialExternalSegment {
  id?: number;
  name?: string;
  external_segment_id?: number;
  external_segment_type?: string;
  attribute_id?: string;
  attribute?: Partial<FullAttribute>;
}

export function readAllExternalSegments(
  context: AppContext
): Observable<ExternalSegment[]> {
  return get<ExternalSegment[]>(
    context,
    `${attributeResourcePath}/external-segments`
  );
}

export function createExternalSegment(
  externalSegment: PartialExternalSegment,
  context: AppContext
): Observable<ExternalSegment> {
  return post<ExternalSegment>(
    context,
    `${attributeResourcePath}/external-segments`,
    externalSegment
  );
}

export function updateExternalSegment(
  externalSegment: ExternalSegment,
  context: AppContext
): Observable<ExternalSegment> {
  return put<ExternalSegment>(
    context,
    `${attributeResourcePath}/external-segments/${externalSegment.id}`,
    externalSegment
  );
}

export function deleteExternalSegment(
  externalSegmentId: number,
  context: AppContext
): Observable<undefined> {
  return del(
    context,
    `${attributeResourcePath}/external-segments/${externalSegmentId}`
  );
}
