import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UncontrolledTooltip } from "reactstrap";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FullAttribute } from "../api/attributeApi";
import { uniqueId } from "lodash";
import classNames from "classnames";

export interface Props {
  attribute: FullAttribute;
  className?: string;
  iconClass?: string;
  qaNameId?: string;
  qaTooltipIconId?: string;
  qaTooltipId?: string;
  qaTooltipPathId?: string;
}

export class AttributeName extends React.Component<Props> {
  id: string = uniqueId("attribute-name-");

  static defaultProps = {
    className: "",
    iconClass: "text-muted",
    qaNameId: "attribute-name-name",
    qaTooltipIconId: "attribute-name-tooltip-icon",
    qaTooltipId: "attribute-name-tooltip",
    qaTooltipPathId: "attribute-name-tooltip-path"
  };

  render() {
    return (
      <div className={classNames("text-break", this.props.className)}>
        <span data-qa-id={this.props.qaNameId}>
          {this.props.attribute.name}
        </span>
        &nbsp;
        <span className={this.props.iconClass}>
          <span data-qa-id={this.props.qaTooltipIconId} id={this.id}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </span>
          <UncontrolledTooltip
            placement={"top"}
            target={this.id}
            data-qa-id={this.props.qaTooltipId}
          >
            <span data-qa-id={this.props.qaTooltipPathId}>
              {this.props.attribute.tree_path}
            </span>
          </UncontrolledTooltip>
        </span>
      </div>
    );
  }
}
