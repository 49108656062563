import { ActionTypes } from "../constants/templateConstants";
import { ActionPM, ActionP, ActionM } from "../../lib/reduxLib";
import { NavigationData as TsNavigationData } from "../models/templateServiceModels";
import { NavConfig } from "../models/navigationModels";

interface FetchNavigationMeta {
  businessUnitId: number;
  navConfig: NavConfig;
}

export interface SelectNavigationNodeAction
  extends ActionP<{ readonly id: string }> {
  readonly type: ActionTypes.SELECT_NAVIGATION_NODE;
}

export interface FetchNavigationNodesAction
  extends ActionPM<FetchNavigationMeta, FetchNavigationMeta> {
  readonly type: ActionTypes.FETCH_NAVIGATION_NODES;
}

export interface FetchNavigationNodesPendingAction
  extends ActionM<FetchNavigationMeta> {
  readonly type: ActionTypes.FETCH_NAVIGATION_NODES_PENDING;
}

export interface FetchNavigationNodesFulfilledAction
  extends ActionPM<TsNavigationData, FetchNavigationMeta> {
  readonly type: ActionTypes.FETCH_NAVIGATION_NODES_FULFILLED;
}

export interface FetchNavigationNodesRejectedAction
  extends ActionPM<Error, FetchNavigationMeta> {
  readonly type: ActionTypes.FETCH_NAVIGATION_NODES_REJECTED;
}

export function selectNavigationNode(id: string): SelectNavigationNodeAction {
  return {
    type: ActionTypes.SELECT_NAVIGATION_NODE,
    payload: {
      id
    }
  };
}

export function fetchNavigationNodes(
  navConfig: NavConfig,
  businessUnitId: number
): FetchNavigationNodesAction {
  return {
    type: ActionTypes.FETCH_NAVIGATION_NODES,
    payload: {
      businessUnitId,
      navConfig
    },
    meta: {
      businessUnitId,
      navConfig
    }
  };
}

export function fetchNavigationNodesPending(
  businessUnitId: number,
  navConfig: NavConfig
): FetchNavigationNodesPendingAction {
  return {
    type: ActionTypes.FETCH_NAVIGATION_NODES_PENDING,
    meta: {
      businessUnitId,
      navConfig
    }
  };
}

export function fetchNavigationNodesFulfilled(
  navigationData: TsNavigationData,
  businessUnitId: number,
  navConfig: NavConfig
): FetchNavigationNodesFulfilledAction {
  return {
    type: ActionTypes.FETCH_NAVIGATION_NODES_FULFILLED,
    payload: navigationData,
    meta: {
      businessUnitId,
      navConfig
    }
  };
}

export function fetchNavigationNodesRejected(
  error: Error,
  businessUnitId: number,
  navConfig: NavConfig
): FetchNavigationNodesRejectedAction {
  return {
    type: ActionTypes.FETCH_NAVIGATION_NODES_REJECTED,
    payload: error,
    meta: {
      businessUnitId,
      navConfig
    }
  };
}
