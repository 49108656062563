import { AF } from "../../lib/actionFactoryLib";
import { Attribute } from "../api/attributeApi";

interface BaseMeta {
  selectorId: string;
}

export const attributeSelectorChangeSearchTermAF = new AF<
  { searchTerm: string },
  BaseMeta
>("ATTRIBUTE_SELECTOR_CHANGE_SEARCH_TERM");

export const attributeSelectorChangeSearchTermDebouncedAF = new AF<
  { searchTerm: string },
  BaseMeta
>("ATTRIBUTE_SELECTOR_CHANGE_SEARCH_TERM_DEBOUNCED");

export const attributeSelectorSelectAttributeAF = new AF<Attribute, BaseMeta>(
  "ATTRIBUTE_SELECTOR_SELECT_ATTRIBUTE"
);

export const attributeSelectorDeselectAttributeAF = new AF<Attribute, BaseMeta>(
  "ATTRIBUTE_SELECTOR_DESELECT_ATTRIBUTE"
);

export const attributeSelectorNavigateAttributeAF = new AF<Attribute, BaseMeta>(
  "ATTRIBUTE_SELECTOR_NAVIGATE_ATTRIBUTE"
);

export const attributeSelectorSelectBreadCrumbAF = new AF<Attribute, BaseMeta>(
  "ATTRIBUTE_SELECTOR_SELECT_BREAD_CRUMB"
);

export const attributeSelectorInitAF = new AF<{}, BaseMeta>(
  "ATTRIBUTE_SELECTOR_INIT"
);

export const attributeSelectorDestroyAF = new AF<{}, BaseMeta>(
  "ATTRIBUTE_SELECTOR_DESTROY"
);

export const attributeSelectorResetStateAF = new AF<{}, BaseMeta>(
  "ATTRIBUTE_SELECTOR_RESET_STATE"
);
