import { createSelector } from "reselect";
import { DataCenter, env } from "../env/appEnv";
import { NormalizedBusinessUnit } from "../../template/models/businessUnitModels";
import { getSelectedBusinessUnit } from "../../template/selectors/businessUnitSelectors";
import { State } from "../../template/reducers";

export interface AppContext {
  businessUnit: NormalizedBusinessUnit;
  dataCenter: DataCenter;
}

export const getAppContext: (state: State) => AppContext = createSelector(
  getSelectedBusinessUnit,
  bu => {
    return {
      businessUnit: bu,
      dataCenter: env.dataCenters[bu.dataCenterId]
    };
  }
);
