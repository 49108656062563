import { ActionsObservable, StateObservable } from "redux-observable";
import {
  readAttributeAF,
  readAttributeForestAF
} from "../actions/attributeActions";
import { readAttribute, readAllAttributes } from "../api/attributeApi";
import {
  combineEpicMap,
  createAsyncEpic,
  EpicMap,
  ofType,
  createEpic
} from "../../lib/epicFactory";
import { Action } from "redux";
import { State } from "../reducers";
import { getFullAttributeById } from "../selectors/audienceSelectors";
import { filter, ignoreElements, take, map } from "rxjs/operators";
import { concat, EMPTY, of, race, Observable } from "rxjs";
import { getScoreSegmentByAttributeId } from "../score/selectors/scoreSelectors";
import { readScoreSegmentByIdAF } from "../score/actions/readActions";
import { readAllMappingsAF } from "../crm/actions/mappingsActions";
import { CRM } from "../constants/attributeSourceTypeConstants";
import { getCrmMappingByAttributeId } from "../crm/selectors/crmSelectors";

export function loadCondition(
  attributeId: string,
  action$: ActionsObservable<Action>,
  state$: StateObservable<State>
): Observable<Action> {
  if (getFullAttributeById(state$.value, attributeId)) {
    // attribute is already in state
    return EMPTY;
  }
  return concat(
    of(readAttributeAF.create({ attributeId }, {})),
    race(
      action$.pipe(
        ofType(readAttributeAF.fulfilledAF),
        filter(action => {
          return action.meta.baseAction.payload.attributeId === attributeId;
        }),
        take(1),
        ignoreElements()
      ),
      action$.pipe(
        ofType(readAttributeAF.rejectedAF),
        filter(action => {
          return action.meta.baseAction.payload.attributeId === attributeId;
        }),
        take(1),
        ignoreElements()
      )
    )
  );
}

export function loadScoreCondition(
  attributeId: string,
  action$: ActionsObservable<Action>,
  state$: StateObservable<State>
): Observable<Action> {
  if (getScoreSegmentByAttributeId(state$.value, attributeId)) {
    // attribute is already in state
    return EMPTY;
  }
  return concat(
    of(readScoreSegmentByIdAF.create({ attributeId }, {})),
    race(
      action$.pipe(
        ofType(readScoreSegmentByIdAF.fulfilledAF),
        filter(action => {
          return action.meta.baseAction.payload.attributeId === attributeId;
        }),
        take(1),
        ignoreElements()
      ),
      action$.pipe(
        ofType(readScoreSegmentByIdAF.rejectedAF),
        filter(action => {
          return action.meta.baseAction.payload.attributeId === attributeId;
        }),
        take(1),
        ignoreElements()
      )
    )
  );
}

export function loadCRMCondition(
  attributeId: string,
  action$: ActionsObservable<Action>,
  state$: StateObservable<State>
): Observable<Action> {
  if (attributeId.indexOf(CRM) !== 0) {
    return EMPTY;
  }
  if (getCrmMappingByAttributeId(state$.value, attributeId)) {
    // crm mapping is already in state
    return EMPTY;
  }
  return concat(
    of(readAllMappingsAF.create({}, {})),
    race(
      action$.pipe(
        ofType(readAllMappingsAF.fulfilledAF),
        take(1),
        ignoreElements()
      ),
      action$.pipe(
        ofType(readAllMappingsAF.rejectedAF),
        take(1),
        ignoreElements()
      )
    )
  );
}

export const epicMap: EpicMap<State> = {
  readAttributeEpic: createAsyncEpic(
    readAttributeAF,
    (action, context, state) => {
      return readAttribute(action.payload.attributeId, context);
    }
  ),
  readAttributeForestEpic: createAsyncEpic(
    readAttributeForestAF,
    (action, context, state) => {
      return readAllAttributes(context);
    }
  )
};

export const epics = combineEpicMap(epicMap);
