import { default as React } from "react";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";

export const NoAdminAccessFC: React.FC = () => {
  return (
    <Row>
      <Col>
        <span data-qa-id={"no-access-message"}>
          <FontAwesomeIcon icon={faBan} size="lg" className="text-danger" />
          &nbsp; You do not have permission to view this page. Contact an
          account admin if you need assistance.
        </span>
      </Col>
    </Row>
  );
};
