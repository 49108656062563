import { default as React } from "react";
import { LinkProps } from "react-router-dom";
import { connect, MapStateToProps } from "react-redux";
import { AnchorLinkFC } from "../components/AnchorLinkComponent";
import { getTrackedDataLocationDescriptor } from "../lib/templateLib";
import { State } from "../reducers";
import { getSelectedBusinessUnit } from "../selectors/businessUnitSelectors";

export interface LinkStateProps {
  businessUnitId: number;
  clientId: number;
}

const LinkFC: React.FC<LinkProps & LinkStateProps> = props => {
  const { to, businessUnitId, clientId, children, ...otherProps } = props;
  const locationDescriptor = getTrackedDataLocationDescriptor(
    to,
    clientId,
    businessUnitId
  );

  return (
    <AnchorLinkFC to={locationDescriptor} {...otherProps}>
      {children}
    </AnchorLinkFC>
  );
};

const mapStateToProps: MapStateToProps<
  LinkStateProps,
  LinkProps,
  State
> = state => {
  const businessUnit = getSelectedBusinessUnit(state);

  return {
    businessUnitId: businessUnit ? businessUnit.id : undefined,
    clientId: businessUnit ? businessUnit.client : undefined
  };
};

function mapDispatchToProps() {
  return {};
}

export const LinkContainer = connect<LinkStateProps, {}, LinkProps>(
  mapStateToProps,
  mapDispatchToProps
)(LinkFC);
