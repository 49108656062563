export const CONTAINS = 1;
export const EQUALS = 5;
export const EXISTS = 6;
export const LESS_THAN = 7;
export const GREATER_THAN = 8;
export const IN_LAST_DAYS = 9;
export const ON_OR_BEFORE = 11;
export const ON_OR_AFTER = 12;
export const X_DAYS_OR_MORE_AGO = 13;
export const X_DAYS_OR_LESS_AGO = 14;
export const LESS_THAN_OR_EQUALS = 15;
export const GREATER_THAN_OR_EQUALS = 16;
export const NOT_EXISTS = 17;
export const ON_ANY_DATE = 18;
export const NONE = 0;
