import { default as React } from "react";

export interface ExternalRedirectProps {
  to: string;
}

export class ExternalRedirectPureComponent extends React.PureComponent<
  ExternalRedirectProps
> {
  componentDidMount() {
    window.location.href = this.props.to;
  }

  render(): null {
    return null;
  }
}
