import {
  combineEpicMap,
  createAsyncEpic,
  createEpic,
  EpicMap,
  ofType
} from "../../../lib/epicFactory";
import {
  externalSegmentsDidMountAF,
  readAllExternalSegmentsAF
} from "../actions/externalSegmentsActions";
import {
  readAllExternalSegments,
  ExternalSegment,
  createExternalSegment,
  updateExternalSegment,
  deleteExternalSegment
} from "../../api/attributeApi";
import { State } from "../../reducers";
import { flatMap, map } from "rxjs/operators";
import { from } from "rxjs";
import { createExternalSegmentAF } from "../actions/externalSegmentCreateActions";
import { updateExternalSegmentAF } from "../actions/externalSegmentUpdateActions";
import {
  deleteExternalSegmentAF,
  openDeleteModalAF
} from "../actions/externalSegmentDeleteActions";
import { getAudiencesUsingAttributeAF } from "../../actions/audienceActions";
import { readAudiencesUsingAttributes } from "../../api/audienceApi";

export const epicMap: EpicMap<State> = {
  readAllExternalSegments: createAsyncEpic(
    readAllExternalSegmentsAF,
    (action, context, state) => {
      return readAllExternalSegments(context).pipe(
        map(segments => {
          return segments.reduce(
            (hashMap, externalSegment) => {
              hashMap[externalSegment.id] = externalSegment;
              return hashMap;
            },
            {} as { [externalSegmentId: string]: ExternalSegment }
          );
        })
      );
    }
  ),
  createExternalSegmentEpic: createAsyncEpic(
    createExternalSegmentAF,
    (action, context, state: State) => {
      const externalSegment = action.payload;
      return createExternalSegment(externalSegment, context);
    }
  ),
  updateExternalSegmentEpic: createAsyncEpic(
    updateExternalSegmentAF,
    (action, context, state) => {
      return updateExternalSegment(action.payload, context);
    }
  ),
  deleteExternalSegmentEpic: createAsyncEpic(
    deleteExternalSegmentAF,
    (action, context, state) => {
      return deleteExternalSegment(action.payload.id, context);
    }
  ),
  getAudiencesUsingAttribute: createAsyncEpic(
    getAudiencesUsingAttributeAF,
    (action, context, state) => {
      return readAudiencesUsingAttributes(
        [action.payload.attributeId],
        context
      );
    }
  ),
  openDeleteModal: createEpic<State>((action$, state$) => {
    return action$.pipe(
      ofType(openDeleteModalAF),
      map(action => {
        return getAudiencesUsingAttributeAF.create(
          { attributeId: action.payload.attribute.id },
          {}
        );
      })
    );
  }),
  onExternalSegmentsDidMount: createEpic<State>((action$, state$) => {
    return action$.pipe(
      ofType(externalSegmentsDidMountAF),
      flatMap(action => {
        return from([readAllExternalSegmentsAF.create({}, {})]);
      })
    );
  })
};

export const epics = combineEpicMap(epicMap);
