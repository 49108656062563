import { Action } from "redux";
import {
  closeUpdateModalAF,
  openUpdateModalAF,
  updateCrmMappingAF
} from "../actions/updateActions";
import { CrmMapping } from "../../api/attributeApi";

export interface State {
  showModal: boolean;
  updating: boolean;
  crmMapping?: CrmMapping;
}

export const crmUpdateInitialState: State = {
  showModal: false,
  updating: false
};

export function updateReducer(
  previousState: State = crmUpdateInitialState,
  action: Action
): State {
  if (openUpdateModalAF.isAction(action)) {
    return {
      ...previousState,
      showModal: true,
      crmMapping: action.payload
    };
  }
  if (closeUpdateModalAF.isAction(action)) {
    return {
      ...previousState,
      showModal: false
    };
  }
  if (updateCrmMappingAF.pendingAF.isAction(action)) {
    return {
      ...previousState,
      updating: true
    };
  }
  if (updateCrmMappingAF.fulfilledAF.isAction(action)) {
    return crmUpdateInitialState;
  }
  if (updateCrmMappingAF.rejectedAF.isAction(action)) {
    return {
      ...previousState,
      updating: false
    };
  }
  return previousState;
}
