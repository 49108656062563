import { default as React } from "react";
import { default as Highlighter } from "react-highlight-words";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { SearchBarFC } from "../../bootstrap";
import { AttributeDragSource } from "./AttributeComponent";
import { BreadCrumbFC } from "./BreadCrumbsComponent";
import { Attribute, ScoreSegment } from "../api/attributeApi";
import { IGNITIONONE_SCORE } from "../constants/attributeIdConstants";
import { ScoreSegmentCreateModalContainer } from "../score/containers/ScoreSegmentCreateModalContainer";
import { Button, Card, CardFooter, CardHeader, Spinner } from "reactstrap";
import classNames from "classnames";
import styles from "./AttributeSelector.module.scss";
import Badge from "reactstrap/lib/Badge";

export interface Props extends StateProps, DispatchProps {}

export interface StateProps {
  isGettingGoals: boolean;
  goals: ScoreSegment[];
  breadCrumbs: Attribute[];
  attributes: Attribute[];
  inputSearchTerm?: string;
  searchTerm?: string;
  searchResults: Attribute[];
}

export interface DispatchProps {
  handleSelectAttribute(attribute: Attribute): void;
  handleNavigateAttribute(attribute: Attribute): void;
  handleSelectBreadCrumb(attribute: Attribute): void;
  handleChangeSearchTerm(searchTerm: string): void;
  onOpenScoreModal(): void;
}

export const AttributeSelectorFC: React.FC<Props> = props => {
  const isIgnitionOneScore: boolean = !!props.breadCrumbs.find(bc => {
    return bc.id === IGNITIONONE_SCORE;
  });

  return (
    <Card data-qa-id="attribute-selector">
      <CardHeader>
        <SearchBarFC
          value={props.inputSearchTerm}
          onChange={e => {
            e.preventDefault();
            e.stopPropagation();
            props.handleChangeSearchTerm((e.target as any).value);
          }}
          onClearClick={() => {
            props.handleChangeSearchTerm("");
          }}
          placeholder={"Find traits..."}
          qaId="attribute-selector-search-bar"
        />
      </CardHeader>
      <ul className={"list-group list-group-flush"}>
        <li className={"list-group-item"}>
          <BreadCrumbFC
            handleSelectBreadCrumb={props.handleSelectBreadCrumb}
            breadCrumbs={props.breadCrumbs}
          />
        </li>
      </ul>
      <ul
        className={classNames(
          "list-group list-group-flush",
          styles.attributesContainer
        )}
      >
        {!props.searchTerm &&
          props.attributes.map(att => {
            return (
              <AttributeDragSource
                key={att.id}
                handleSelectAttribute={props.handleSelectAttribute}
                attribute={att}
                handleNavigateAttribute={props.handleNavigateAttribute}
              >
                <span data-qa-id="attribute-name">
                  {att.name}
                  {att.count >= 0 && (
                    <Badge className="ml-2">
                      <FontAwesomeIcon icon={faUsers} /> {att.count}
                    </Badge>
                  )}
                </span>
              </AttributeDragSource>
            );
          })}
        {props.searchTerm &&
          props.searchResults.map(result => {
            return (
              <AttributeDragSource
                key={result.id}
                handleSelectAttribute={props.handleSelectAttribute}
                attribute={result}
              >
                <Highlighter
                  data-qa-id="attribute-highlighted-path"
                  searchWords={[props.searchTerm]}
                  textToHighlight={result.tree_path}
                />
              </AttributeDragSource>
            );
          })}
      </ul>
      <CardFooter className={"d-flex justify-content-center"}>
        {isIgnitionOneScore && props.isGettingGoals && <Spinner />}
        {isIgnitionOneScore &&
          !props.isGettingGoals &&
          props.goals.length !== 0 && (
            <React.Fragment>
              <Button
                color="primary"
                onClick={props.onOpenScoreModal}
                data-qa-id="add-segment-btn"
              >
                Add Segment
              </Button>
              <ScoreSegmentCreateModalContainer goals={props.goals} />
            </React.Fragment>
          )}
        {!isIgnitionOneScore && <span>&nbsp;</span>}
      </CardFooter>
    </Card>
  );
};

AttributeSelectorFC.defaultProps = {
  inputSearchTerm: "",
  searchTerm: "",
  attributes: [],
  breadCrumbs: [],
  searchResults: []
};
