import { default as React } from "react";
import {
  Modal,
  Button,
  Alert,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Spinner
} from "reactstrap";
import { ExternalSegment } from "../../api/attributeApi";
import { Audience } from "../../models/audienceModels";
import { LinkContainer } from "../../../template/containers/LinkContainer";
import { getAudienceUpdatePath } from "../../constants/pathConstants";

export interface Props extends StateProps, DispatchProps {}

export interface StateProps {
  externalSegment: ExternalSegment;
  audiencesUsingExternalSegment: Audience[];
  isGettingAudiencesUsingExternalSegment: boolean;
  isDeleting: boolean;
}

export interface DispatchProps {
  closeModal(): void;
  onDelete(externalSegment: ExternalSegment): void;
}

export const ExternalSegmentDeleteModalFC: React.FC<Props> = props => {
  return (
    <Modal isOpen={true} toggle={props.closeModal}>
      <ModalHeader toggle={props.closeModal}>
        Delete External Segment for Column {props.externalSegment.name}
      </ModalHeader>
      <ModalBody>
        <Alert color={"warning"}>
          Historical data related to column {props.externalSegment.name} will no
          longer be usable.
        </Alert>
        {props.isGettingAudiencesUsingExternalSegment && (
          <div>
            Checking audience usage of trait "
            {props.externalSegment.attribute.name}
            "&nbsp;
            <Spinner size={"sm"} />
          </div>
        )}
        {props.audiencesUsingExternalSegment !== undefined &&
          props.audiencesUsingExternalSegment.length > 0 && (
            <div
              data-qa-id={
                "segment-delete-audience-using-external-segment-container"
              }
            >
              <p>
                Before deleting, remove the trait "
                {props.externalSegment.attribute.name}" from the following
                audiences:
              </p>
              {props.audiencesUsingExternalSegment.map(audience => {
                return (
                  <div key={audience.audience_id}>
                    <LinkContainer
                      to={getAudienceUpdatePath(audience.audience_id)}
                      data-qa-id={`segment-delete-used-by-audience-link-${
                        audience.audience_id
                      }`}
                      target={"_blank"}
                    >
                      {audience.name}
                    </LinkContainer>
                  </div>
                );
              })}
            </div>
          )}
        {props.audiencesUsingExternalSegment !== undefined &&
          props.audiencesUsingExternalSegment.length === 0 && (
            <div>
              No audiences are using the external segment trait "
              {props.externalSegment.attribute.name}"
            </div>
          )}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            props.closeModal();
          }}
          color="link"
          data-qa-id={"segment-delete-cancel-button"}
          disabled={props.isDeleting}
        >
          Cancel
        </Button>
        <Button
          color={"primary"}
          data-qa-id={"segment-delete-delete-button"}
          disabled={
            props.isDeleting ||
            (props.audiencesUsingExternalSegment === undefined ||
              props.audiencesUsingExternalSegment.length > 0)
          }
          onClick={() => {
            props.onDelete(props.externalSegment);
          }}
        >
          Delete {props.isDeleting && <Spinner size={"sm"} />}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
