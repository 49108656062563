import * as React from "react";
import { WrappedFieldProps } from "redux-form";
import { InputHTMLAttributes } from "react";
import classNames from "classnames";

export interface SwitchInputFieldProps
  extends WrappedFieldProps,
    InputHTMLAttributes<HTMLInputElement> {
  label: string;
  id: string;
}

export class SwitchInputField extends React.Component<SwitchInputFieldProps> {
  render() {
    const { input, meta, label, ...props } = this.props;
    return (
      <div className={"custom-control custom-switch"}>
        <input
          {...props}
          {...input}
          type={"checkbox"}
          checked={input.value}
          className={classNames("custom-control-input", props.className)}
        />
        <label className={"custom-control-label"} htmlFor={props.id}>
          {label}
        </label>
      </div>
    );
  }
}
