import { push } from "connected-react-router";
import { LocationDescriptor } from "history";
import { ActionTypes } from "../constants/templateConstants";
import { SelectBusinessUnitAction } from "../actions/businessUnitActions";
import { PushAction, setTrackedData } from "../actions/trackedDataActions";
import {
  getBusinessUnitById,
  getSelectedBusinessUnit
} from "../selectors/businessUnitSelectors";
import { getTrackedDataLocationDescriptor } from "../lib/templateLib";
import { NormalizedBusinessUnit } from "../models/businessUnitModels";
import { Action } from "../../lib/reduxLib";
import { State } from "../reducers";
import { combineEpics, Epic, ofType } from "redux-observable";
import { map } from "rxjs/operators";

export const onSelectBusinessUnitEpic: Epic<Action, Action, State> = (
  action$,
  state$
) => {
  return action$.pipe(
    ofType<Action, SelectBusinessUnitAction>(ActionTypes.SELECT_BUSINESS_UNIT),
    map(action => {
      const businessUnit: NormalizedBusinessUnit = getBusinessUnitById(
        state$.value,
        action.payload.id
      );
      return setTrackedData(businessUnit.id, businessUnit.client);
    })
  );
};

export const onPushEpic: Epic<Action, Action, State> = (action$, state$) => {
  return action$.pipe(
    ofType<Action, PushAction>(ActionTypes.PUSH),
    map(action => {
      let locationDescriptor: LocationDescriptor;
      const businessUnit: NormalizedBusinessUnit = getSelectedBusinessUnit(
        state$.value
      );

      if (businessUnit) {
        locationDescriptor = getTrackedDataLocationDescriptor(
          action.payload.to,
          businessUnit.client,
          businessUnit.id
        );
      } else {
        locationDescriptor = action.payload.to;
      }

      if (typeof locationDescriptor === "string") {
        return push(locationDescriptor, action.payload.state);
      }
      return push(locationDescriptor);
    })
  );
};

export const epics = combineEpics(onPushEpic, onSelectBusinessUnitEpic);
