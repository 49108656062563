import { default as React } from "react";
import {
  Modal,
  Button,
  FormGroup,
  Label,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { Field, Form, InjectedFormProps } from "redux-form";
import { crmProps, attributeProps, CrmMapping } from "../../api/attributeApi";
import { required } from "../../../lib/validationLib";
import { AttributeValueEditorField } from "../../components/AttributeValueEditor";
import { DefinedValuesTooltipFC } from "../../components/DefinedValuesTooltip";
import { INVALID_VALUE_TYPES } from "../../constants/attributeDataTypeNameConstants";
import { FormGroupInputField } from "../../forms/components/FormGroupInputField/FormGroupInputField";
import classNames from "classnames";

export interface StateProps {
  updating: boolean;
  dataType: string;
  crmMapping: CrmMapping;
  initialValues: Partial<CrmMapping>;
  allMappings: CrmMapping[];
}

export interface InjectedProps
  extends Props,
    InjectedFormProps<CrmMapping, Props, string> {}
export interface Props extends StateProps, DispatchProps {}

export interface DispatchProps {
  closeModal(): void;
}

function attributeNameIsUnique(
  value: string,
  allValues: Partial<CrmMapping>,
  props: Props
) {
  const crmMapping = props.allMappings.find(
    crmMapping => crmMapping.attribute.name === value
  );

  if (crmMapping && crmMapping.id !== props.crmMapping.id) {
    return "Mapping with this trait name already exists";
  }
}

export const CrmMappingUpdateModalFC: React.FC<InjectedProps> = props => {
  return (
    <Modal isOpen={true} toggle={props.closeModal}>
      <Form onSubmit={props.handleSubmit}>
        <ModalHeader toggle={props.closeModal}>
          Edit {props.initialValues.crm_column} CRM Mapping
        </ModalHeader>
        <ModalBody>
          <Field
            id="update-trait-name"
            label="Trait Name"
            name={`${crmProps.attribute}.${attributeProps.name}`}
            type="text"
            component={FormGroupInputField}
            validate={[required, attributeNameIsUnique]}
            data-qa-id="crm-mapping-name-field"
            className={
              INVALID_VALUE_TYPES.indexOf(props.dataType) === -1
                ? undefined
                : "mb-0"
            }
          />
          <FormGroup
            className={classNames("mb-0", {
              "d-none": INVALID_VALUE_TYPES.indexOf(props.dataType) !== -1
            })}
          >
            <Label for={"update-defined-values"}>
              Defined Values&nbsp;
              <DefinedValuesTooltipFC
                tooltipId={"update_defined_values_tooltip"}
              />
            </Label>
            <Field
              id={"update-defined-values"}
              name={`${crmProps.attribute}.${attributeProps.attribute_values}`}
              component={AttributeValueEditorField}
              dataType={props.dataType}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="link"
            onClick={props.closeModal}
            disabled={props.updating}
          >
            Cancel
          </Button>
          <Button
            color={"primary"}
            disabled={!props.valid || props.updating}
            onClick={props.handleSubmit}
            data-qa-id="crm-mapping-update-submit"
          >
            Submit
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
