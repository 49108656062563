import { Action } from "redux";
import {
  closeCreateModalAF,
  openCreateModalAF,
  createExternalSegmentAF
} from "../actions/externalSegmentCreateActions";

export interface State {
  showModal: boolean;
  isFormSubmit: boolean;
}

export const externalSegmentCreateInitialState: State = {
  showModal: false,
  isFormSubmit: false
};

export function createReducer(
  previousState: State = externalSegmentCreateInitialState,
  action: Action
): State {
  if (openCreateModalAF.isAction(action)) {
    return {
      ...previousState,
      showModal: true
    };
  }
  if (closeCreateModalAF.isAction(action)) {
    return {
      ...previousState,
      showModal: false
    };
  }
  if (createExternalSegmentAF.pendingAF.isAction(action)) {
    return {
      ...previousState,
      isFormSubmit: true
    };
  }
  if (createExternalSegmentAF.rejectedAF.isAction(action)) {
    return {
      ...previousState,
      showModal: true,
      isFormSubmit: false
    };
  }
  if (createExternalSegmentAF.fulfilledAF.isAction(action)) {
    return {
      ...previousState,
      showModal: false,
      isFormSubmit: false
    };
  }
  return previousState;
}
