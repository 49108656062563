import { AsyncAF, AF } from "../../../lib/actionFactoryLib";
import { ExternalSegment } from "../../api/attributeApi";

export const setExternalSegmentsSortAF = new AF<{ sortKey: string }>(
  "SET_EXTERNAL_SEGMENTS_SORT"
);

export const readAllExternalSegmentsAF = new AsyncAF<
  {},
  { [id: number]: ExternalSegment }
>("READ_ALL_EXTERNAL_SEGMENTS");

export const externalSegmentsDidMountAF = new AsyncAF<{}, {}>(
  "EXTERNAL_SEGMENTS_DID_MOUNT"
);
