import { attributeProps, scoreSegmentProps } from "../../api/attributeApi";

export const Engagement = "SS46";

export const Goal = {
  Engagement
};

export const NumberOfSegments = {
  1: 1,
  2: 2,
  3: 3
};

export type NumberOfSegmentsType = 1 | 2 | 3;

export const getNameFieldName = () =>
  `${scoreSegmentProps.attribute}.${attributeProps.name}`;

export const getRangeFieldName = (index: number) =>
  `${scoreSegmentProps.children}[${index}].${scoreSegmentProps.attribute}.${attributeProps.name}`;
