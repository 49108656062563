import {
  combineEpicMap,
  EpicMap,
  createEpic,
  createAsyncEpic,
  ofType
} from "../../../lib/epicFactory";
import { State } from "../../reducers";
import { createScoreSegmentAF } from "../actions/createActions";
import {
  readScoreSegmentGoalsAF,
  scoreSegmentsDidMountAF
} from "../actions/scoreActions";
import {
  createScore,
  readScoreSegmentGoals,
  readScoreSegmentById,
  readRootScoreSegments,
  updateScore,
  deleteScoreSegment
} from "../../api/attributeApi";
import { flatMap, map } from "rxjs/operators";
import { reset } from "redux-form";
import {
  readAllScoreBuckets,
  readAudiencesUsingAttributes
} from "../../api/audienceApi";
import {
  readAllScoreBucketsAF,
  readRootScoreSegmentsAF,
  readScoreSegmentByIdAF,
  readAudiencesUsingAttributeAF
} from "../actions/readActions";
import {
  openUpdateModalAF,
  readUpdateScoreSegmentByIdAF,
  updateScoreSegmentAF
} from "../actions/updateActions";
import {
  readDeleteScoreSegmentByIdAF,
  openDeleteModalAF,
  deleteScoreSegmentAF
} from "../actions/deleteActions";

export const epicMap: EpicMap<State> = {
  readScoreSegmentGoalsEpic: createAsyncEpic(
    readScoreSegmentGoalsAF,
    (action, context, state: State) => {
      return readScoreSegmentGoals(context);
    }
  ),
  readAllScoreBuckets: createAsyncEpic(
    readAllScoreBucketsAF,
    (action, context, state) => {
      return readAllScoreBuckets(action.payload.model, context);
    }
  ),
  onScoreSegmentsDidMount: createEpic<State>((action$, state$) => {
    return action$.pipe(
      ofType(scoreSegmentsDidMountAF),
      map(action => {
        return readRootScoreSegmentsAF.create({}, {});
      })
    );
  }),
  readRootScoreSegments: createAsyncEpic(
    readRootScoreSegmentsAF,
    (action, context, state) => {
      return readRootScoreSegments(context);
    }
  ),
  readScoreSegmentByAttributeId: createAsyncEpic(
    readScoreSegmentByIdAF,
    (action, context, state) => {
      return readScoreSegmentById(action.payload.attributeId, context);
    }
  ),
  createScoreEpic: createAsyncEpic(
    createScoreSegmentAF,
    (action, context, state: State) => {
      return createScore(action.payload.score, context);
    }
  ),
  resetCreateScoreFormEpic: createEpic((action$, state) => {
    return action$.pipe(
      ofType(createScoreSegmentAF),
      flatMap(action => {
        return action$.pipe(
          ofType(createScoreSegmentAF.fulfilledAF),
          map(() => {
            return reset(action.payload.formName);
          })
        );
      })
    );
  }),
  resetUpdateScoreFormEpic: createEpic((action$, state) => {
    return action$.pipe(
      ofType(updateScoreSegmentAF),
      flatMap(action => {
        return action$.pipe(
          ofType(updateScoreSegmentAF.fulfilledAF),
          map(() => {
            return reset(action.payload.formName);
          })
        );
      })
    );
  }),
  onScoreSegmentsUpdateOpen: createEpic((action$, state) => {
    return action$.pipe(
      ofType(openUpdateModalAF),
      map(action => {
        return readUpdateScoreSegmentByIdAF.create(
          { attributeId: action.payload.attribute.id },
          {}
        );
      })
    );
  }),
  updateScoreEpic: createAsyncEpic(
    updateScoreSegmentAF,
    (action, context, state: State) => {
      return updateScore(action.payload.id, action.payload.score, context);
    }
  ),
  readUpdateScoreSegmentByIdEpic: createAsyncEpic(
    readUpdateScoreSegmentByIdAF,
    (action, context, state: State) => {
      return readScoreSegmentById(action.payload.attributeId, context);
    }
  ),
  onReadUpdateScoreSegmentFulfilled: createEpic((action$, state) => {
    return action$.pipe(
      ofType(readUpdateScoreSegmentByIdAF.fulfilledAF),
      map(action =>
        readAudiencesUsingAttributeAF.create(
          {
            attributeIds: action.payload.children.map(
              child => child.attribute.id
            )
          },
          {}
        )
      )
    );
  }),
  readDeleteScoreSegmentByIdEpic: createAsyncEpic(
    readDeleteScoreSegmentByIdAF,
    (action, context, state: State) => {
      return readScoreSegmentById(action.payload.attributeId, context);
    }
  ),
  onScoreSegmentsDeleteOpen: createEpic((action$, state) => {
    return action$.pipe(
      ofType(openDeleteModalAF),
      map(action =>
        readDeleteScoreSegmentByIdAF.create(
          { attributeId: action.payload.attribute.id },
          {}
        )
      )
    );
  }),
  onReadDeleteScoreSegmentFulfilled: createEpic((action$, state) => {
    return action$.pipe(
      ofType(readDeleteScoreSegmentByIdAF.fulfilledAF),
      map(action =>
        readAudiencesUsingAttributeAF.create(
          {
            attributeIds: action.payload.children.map(
              child => child.attribute.id
            )
          },
          {}
        )
      )
    );
  }),
  getAudiencesUsingAttribute: createAsyncEpic(
    readAudiencesUsingAttributeAF,
    (action, context, state) => {
      return readAudiencesUsingAttributes(action.payload.attributeIds, context);
    }
  ),
  deleteScoreSegments: createAsyncEpic(
    deleteScoreSegmentAF,
    (action, context, state) => {
      return deleteScoreSegment(action.payload.id, context).pipe(
        map(() => action.payload)
      );
    }
  )
};

export const epics = combineEpicMap(epicMap);
