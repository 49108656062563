import { AsyncAF, AF } from "../../../lib/actionFactoryLib";
import { ScoreSegment } from "../../api/attributeApi";

export const setScoreSegmentsSortAF = new AF<{ sortKey: string }>(
  "SET_SCORE_SEGMENTS_SORT"
);

export const scoreSegmentsDidMountAF = new AsyncAF<{}, {}>(
  "SCORE_SEGMENTS_DID_MOUNT"
);

export const readScoreSegmentGoalsAF = new AsyncAF<{}, ScoreSegment[]>(
  "READ_GOALS"
);

export const goalsDidMountAF = new AsyncAF<{}, {}>("GOALS_DID_MOUNT");
