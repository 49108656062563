import { State } from "../reducers";
import { State as TemplateState } from "../../../template/reducers";
import { getSelectedBusinessUnitId } from "../../../template/selectors/businessUnitSelectors";
import { ScoreSegment } from "../../api/attributeApi";
import { DataPoint } from "highcharts";
import { createSelector } from "reselect";
import { orderBy } from "lodash";
import { Audience } from "../../models/audienceModels";

export function getAudiencesUsingScoreSegment(state: State): Audience[] {
  return state.score.read.audiencesUsingScoreSegments;
}

export function getIsGettingAudiencesUsingScoreSegment(state: State): boolean {
  return state.score.read.isGettingAudiencesUsingScoreSegment;
}

export function getScoreSegments(state: State & TemplateState): ScoreSegment[] {
  const buId = getSelectedBusinessUnitId(state);

  if (
    !state.score.read.rootsByBusinessUnitId[buId] ||
    !state.score.read.rootsByBusinessUnitId[buId].byId
  ) {
    return [];
  }
  return Object.keys(state.score.read.rootsByBusinessUnitId[buId].byId).map(
    attributeId => {
      return state.score.read.rootsByBusinessUnitId[buId].byId[attributeId];
    }
  );
}

export const getScoreSegmentsByParentId: (
  state: State & TemplateState,
  parentId: string
) => ScoreSegment[] = createSelector(
  (state: State & TemplateState) => getScoreSegments(state),
  (state: State & TemplateState, parentId: string) => parentId,
  (scoreSegments: ScoreSegment[], parentId: string) =>
    scoreSegments.filter(
      scoreSegment => scoreSegment.attribute.parent_id === parentId
    )
);

export function getScoreSegmentsSort(state: State) {
  return state.score.read.sort;
}

export const getSortedScoreSegments = createSelector(
  getScoreSegments,
  getScoreSegmentsSort,
  (audiences, sort) => {
    return orderBy(audiences, sort.iteratees, sort.orders);
  }
);

export function isGettingGoals(state: State): boolean {
  return state.score && state.score.read && state.score.read.isGettingGoals;
}

export function isGettingScoreSegment(state: State): boolean {
  return (
    state.score && state.score.read && state.score.read.isGettingScoreSegment
  );
}

export function isGettingRootScoreSegments(state: State): boolean {
  return (
    state.score &&
    state.score.read &&
    state.score.read.isGettingRootScoreSegments
  );
}

export function getScoreSegmentGoals(
  state: State & TemplateState
): ScoreSegment[] {
  const buId = getSelectedBusinessUnitId(state);
  if (!state.score.read.goalsByBusinessUnitId[buId]) {
    return [];
  }
  return state.score.read.goalsByBusinessUnitId[buId];
}

export function getScoreSegmentByAttributeId(
  state: State & TemplateState,
  attributeId: string
) {
  const businessUnitId = getSelectedBusinessUnitId(state);
  const attributeData = state.score.read.byBusinessUnitId[businessUnitId];
  return attributeData && attributeData.byId
    ? attributeData.byId[attributeId]
    : undefined;
}

export function getScoreBuckets(state: State): DataPoint[] {
  return state.score && state.score.read ? state.score.read.scoreBuckets : [];
}

export function isCreateFormSubmitting(state: State): boolean {
  return (
    state.score && state.score.create && state.score.create.isFormSubmitting
  );
}

export function showScoreSegmentCreateModal(state: State): boolean {
  return state.score && state.score.create && state.score.create.showModal;
}

export function getCreateIsMax(state: State): boolean {
  return state.score.create.isMax;
}

export function isGettingUpdateScoreSegment(state: State): boolean {
  return (
    state.score &&
    state.score.update &&
    state.score.update.isGettingUpdateScoreSegment
  );
}

export function showScoreSegmentUpdateModal(state: State): boolean {
  return state.score && state.score.update && state.score.update.showModal;
}

export function getUpdateScoreSegment(state: State): ScoreSegment {
  return state.score && state.score.update && state.score.update.scoreSegment;
}

export function isUpdateFormSubmitting(state: State): boolean {
  return (
    state.score && state.score.update && state.score.update.isFormSubmitting
  );
}

export function getUpdateIsMax(state: State): boolean {
  return state.score.update.isMax;
}

export function isDeleteFormSubmitting(state: State): boolean {
  return (
    state.score && state.score.delete && state.score.delete.isFormSubmitting
  );
}

export function showScoreSegmentDeleteModal(state: State): boolean {
  return state.score && state.score.delete && state.score.delete.showModal;
}

export function getDeleteScoreSegment(state: State): ScoreSegment {
  return state.score && state.score.delete && state.score.delete.scoreSegment;
}

export function getDeleteIsDeleting(state: State) {
  return state.score.delete.isDeleting;
}

export function isGettingDeleteScoreSegment(state: State): boolean {
  return state.score.delete.isGettingDeleteScoreSegment;
}
