import { Dispatch } from "redux";
import { State } from "../../reducers";
import { getPermissionEnabled } from "../../../auth/selectors/permissionSelectors";
import { shortNamesToIds } from "../../constants/permissionConstants";
import { getSelectedBusinessUnit } from "../../../template/selectors/businessUnitSelectors";
import {
  connectWithLifecycle,
  LifecycleDispatchProps
} from "react-lifecycle-component";
import {
  DispatchProps,
  Props,
  StateProps
} from "../../externalSegments/components/ExternalSegmentsComponent";
import {
  getExternalSegmentsSort,
  getSortedExternalSegments,
  isGettingExternalSegments,
  showExternalSegmentCreateModal,
  showExternalSegmentDeleteModal,
  showExternalSegmentUpdateModal
} from "../selectors/externalSegmentSelectors";
import {
  externalSegmentsDidMountAF,
  setExternalSegmentsSortAF
} from "../actions/externalSegmentsActions";
import { ExternalSegmentFC } from "../components/ExternalSegmentsComponent";
import { openCreateModalAF } from "../../externalSegments/actions/externalSegmentCreateActions";
import { ExternalSegment } from "../../api/attributeApi";
import { openUpdateModalAF } from "../../externalSegments/actions/externalSegmentUpdateActions";
import { openDeleteModalAF } from "../actions/externalSegmentDeleteActions";

export interface ExternalSegmentsContainerProps {}

function mapStateToProps(state: State): StateProps {
  const hasWriteAccess = getPermissionEnabled(
    state,
    shortNamesToIds.admin_write_audience_console,
    getSelectedBusinessUnit(state).id
  );

  return {
    hasWriteAccess,
    sort: getExternalSegmentsSort(state) as {
      iteratees: string[];
      orders: string[];
    },
    externalSegments: getSortedExternalSegments(state),
    isGettingExternalSegments: isGettingExternalSegments(state),
    showCreateModal: showExternalSegmentCreateModal(state),
    showUpdateModal: showExternalSegmentUpdateModal(state),
    showDeleteModal: showExternalSegmentDeleteModal(state)
  };
}

function mapDispatchToProps(
  dispatch: Dispatch
): DispatchProps & LifecycleDispatchProps<Props> {
  return {
    componentDidMount() {
      dispatch(externalSegmentsDidMountAF.create({}, {}));
    },
    onCreate() {
      dispatch(openCreateModalAF.create({}, {}));
    },
    onSort(sortKey: string) {
      dispatch(setExternalSegmentsSortAF.create({ sortKey }, {}));
    },
    onUpdate(externalSegment: ExternalSegment) {
      dispatch(openUpdateModalAF.create(externalSegment, {}));
    },
    onDelete(externalSegment: ExternalSegment) {
      dispatch(openDeleteModalAF.create(externalSegment, {}));
    }
  };
}

export const ExternalSegmentsContainer = connectWithLifecycle(
  mapStateToProps,
  mapDispatchToProps
)(ExternalSegmentFC);
