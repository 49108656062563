import { default as React } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl-redux";
// TODO: Get hot module replacement working again
// import { AppContainer } from 'react-hot-loader';
import { AppDragDropContext } from "./ac";
import { configureStore, history } from "./ac/store/appStore";
import { compose } from "redux";
import { TokenProvider } from "./auth/lib/authLib";
import "./polyfill";
import "hammerjs";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./App.scss";

/*
import { default as whyDidYouUpdate } from 'why-did-you-update';
whyDidYouUpdate(React);
*/

const store = configureStore(
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
);

TokenProvider.setStore(store);

export const renderApp = () => {
  render(
    /* <AppContainer> */
    <Provider store={store}>
      <IntlProvider locale="en">
        <div>
          <AppDragDropContext history={history} />
        </div>
      </IntlProvider>
    </Provider>,
    /* </AppContainer>, */
    document.getElementById("root")
  );
};

/* TODO: get hot module replacement working again
if (module.hot) {
  module.hot.accept('./app', renderApp);

  module.hot.accept('./theme/globals', () => {
    injectGlobalStyles();
  });
}
*/
