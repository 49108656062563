import { default as moment } from "moment";
import { Validator } from "./sharedInterfaces";

function validateValue(builderId: string, newValue: string): boolean {
  if (newValue === null || newValue === undefined) {
    return false;
  }
  const date = moment(newValue);
  return date.isValid();
}

export const dateValidator: Validator = {
  validateValue
};
