import { take, uniq } from "lodash";
import {
  SearchBusinessUnitsAction,
  SearchBusinessUnitsDebouncedAction,
  SelectBusinessUnitAction,
  ClearBusinessUnitSearchAction,
  FetchBusinessUnitsPendingAction,
  FetchBusinessUnitsFulfilledAction,
  FetchBusinessUnitsRejectedAction
} from "../actions/businessUnitActions";
import {
  ActionTypes,
  MAX_RECENT_BUSINESS_UNITS
} from "../constants/templateConstants";
import { NormalizedBusinessUnit, Client } from "../models/businessUnitModels";
import { getBuToSelectFromFetchBuFulfilledAction } from "../lib/templateLib";

type BusinessUnitsAction =
  | SearchBusinessUnitsAction
  | SearchBusinessUnitsDebouncedAction
  | SelectBusinessUnitAction
  | ClearBusinessUnitSearchAction
  | FetchBusinessUnitsPendingAction
  | FetchBusinessUnitsFulfilledAction
  | FetchBusinessUnitsRejectedAction;

export interface BusinessUnitsState {
  readonly byId: {
    readonly [id: number]: NormalizedBusinessUnit;
  };
  readonly allIds: number[];
  readonly selectedId?: number;
  readonly recentIds: number[];
  readonly query: string;
  readonly query_debounced: string;
  readonly pending: boolean;
  readonly error: boolean;
  readonly clientsById: {
    readonly [clientId: number]: Client;
  };
}

export const INITIAL_STATE: BusinessUnitsState = {
  byId: {},
  allIds: [],
  selectedId: undefined,
  recentIds: [],
  query: "",
  query_debounced: "",
  pending: true, // set pending to true initially to prevent first render w/o business units
  error: false,
  clientsById: {}
};

export function businessUnitsReducer(
  prevState: BusinessUnitsState = INITIAL_STATE,
  action: BusinessUnitsAction
): BusinessUnitsState {
  switch (action.type) {
    case ActionTypes.SELECT_BUSINESS_UNIT:
      return {
        ...prevState,
        selectedId: action.payload.id,
        recentIds: take(
          uniq([action.payload.id, ...prevState.recentIds]),
          MAX_RECENT_BUSINESS_UNITS
        ),
        query: ""
      };
    case ActionTypes.SEARCH_BUSINESS_UNITS:
      return {
        ...prevState,
        query: action.payload.query
      };
    case ActionTypes.SEARCH_BUSINESS_UNITS_DEBOUNCED:
      return {
        ...prevState,
        query_debounced: action.payload.query
      };
    case ActionTypes.CLEAR_BUSINESS_UNIT_SEARCH:
      return {
        ...prevState,
        query: "",
        query_debounced: ""
      };
    case ActionTypes.FETCH_BUSINESS_UNITS_PENDING:
      return {
        ...prevState,
        pending: true,
        error: false
      };
    case ActionTypes.FETCH_BUSINESS_UNITS_FULFILLED:
      return {
        ...prevState,
        pending: false,
        error: false,
        byId: action.payload.entities.businessUnits || {},
        allIds: action.payload.result.businessUnits || [],
        recentIds: action.payload.result.recentBusinessUnits || [],
        clientsById: action.payload.entities.clients || {},
        selectedId: getBuToSelectFromFetchBuFulfilledAction(action)
      };
    case ActionTypes.FETCH_BUSINESS_UNITS_REJECTED:
      return {
        ...prevState,
        pending: false,
        error: true
      };
    default:
      return prevState;
  }
}
