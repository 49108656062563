import { AF, AsyncAF } from "../../../lib/actionFactoryLib";
import { CrmMapping } from "../../api/attributeApi";

export const openDeleteModalAF = new AF<CrmMapping>("CRM_OPEN_DELETE_MODAL");

export const closeDeleteModalAF = new AF<{}>("CRM_CLOSE_DELETE_MODAL");

export const deleteCrmMappingAF = new AsyncAF<CrmMapping, {}>(
  "CRM_DELETE_MAPPING"
);
