import { Action } from "redux";
import {
  closeDeleteModalAF,
  deleteExternalSegmentAF,
  openDeleteModalAF
} from "../actions/externalSegmentDeleteActions";
import { Audience } from "../../models/audienceModels";
import { ExternalSegment } from "../../api/attributeApi";
import { getAudiencesUsingAttributeAF } from "../../actions/audienceActions";

export interface State {
  showModal: boolean;
  externalSegment: ExternalSegment;
  audiencesUsingExternalSegment: Audience[];
  isGettingAudiencesUsingExternalSegment: boolean;
  isDeleting: boolean;
}

export const externalSegmentDeleteInitialState: State = {
  showModal: false,
  externalSegment: undefined,
  audiencesUsingExternalSegment: undefined,
  isGettingAudiencesUsingExternalSegment: false,
  isDeleting: false
};

export function deleteReducer(
  previousState: State = externalSegmentDeleteInitialState,
  action: Action
): State {
  if (openDeleteModalAF.isAction(action)) {
    return {
      ...previousState,
      showModal: true,
      externalSegment: action.payload
    };
  }
  if (getAudiencesUsingAttributeAF.pendingAF.isAction(action)) {
    return {
      ...previousState,
      isGettingAudiencesUsingExternalSegment: true
    };
  }
  if (getAudiencesUsingAttributeAF.fulfilledAF.isAction(action)) {
    return {
      ...previousState,
      isGettingAudiencesUsingExternalSegment: false,
      audiencesUsingExternalSegment: action.payload
    };
  }
  if (getAudiencesUsingAttributeAF.rejectedAF.isAction(action)) {
    return {
      ...previousState,
      isGettingAudiencesUsingExternalSegment: false,
      audiencesUsingExternalSegment: undefined
    };
  }
  if (closeDeleteModalAF.isAction(action)) {
    return externalSegmentDeleteInitialState;
  }
  if (deleteExternalSegmentAF.pendingAF.isAction(action)) {
    return {
      ...previousState,
      isDeleting: true
    };
  }
  if (deleteExternalSegmentAF.fulfilledAF.isAction(action)) {
    return externalSegmentDeleteInitialState;
  }
  if (deleteExternalSegmentAF.rejectedAF.isAction(action)) {
    return {
      ...previousState,
      isDeleting: false
    };
  }
  return previousState;
}
