import { default as React } from "react";
import { GenericErrorMessageComponent } from "./ErrorMessages/GenericErrorMessageComponent";
import { ErrorLog } from "../models/errorMonitorModels";
import styles from "./ErrorMonitorComponent.module.scss";

export interface Props extends StateProps, DispatchProps {}

export interface StateProps {
  errorLogs: ErrorLog[];
}

export interface DispatchProps {
  onHideError(errorId: string): void;
}

export const ErrorMonitorFC: React.FC<Props> = props => {
  const logsOrderedByTimestamp = props.errorLogs.sort((left, right) => {
    if (left.timestampMs > right.timestampMs) {
      return 1;
    }
    if (left.timestampMs < right.timestampMs) {
      return -1;
    }
    return 0;
  });
  return (
    <div className={styles.alertContainer}>
      {logsOrderedByTimestamp.map(log => {
        return (
          <GenericErrorMessageComponent
            errorLog={log}
            onHideError={(id: string) => {
              props.onHideError(id);
            }}
            key={log.id}
          />
        );
      })}
    </div>
  );
};
