import { push as routerPush } from "connected-react-router";
import { LocationDescriptor, LocationState } from "history";
import { default as URI } from "urijs";
import { ActionTypes, TrackedData } from "../constants/templateConstants";
import { ActionP } from "../../lib/reduxLib";

export interface PushAction
  extends ActionP<{ to: LocationDescriptor; state?: LocationState }> {
  readonly type: ActionTypes.PUSH;
}

export function push(
  to: LocationDescriptor,
  state?: LocationState
): PushAction {
  return {
    type: ActionTypes.PUSH,
    payload: {
      to,
      state
    }
  };
}

export const setTrackedData = (businessUnitId: number, clientId: number) => {
  // TODO: merge with existing query string values (if any)

  const uri = URI();

  uri
    .setQuery(TrackedData.COMPANY_BUSINESS_UNIT_ID, businessUnitId.toString())
    .setQuery(TrackedData.CLIENT_ID, clientId.toString());

  return routerPush({
    search: uri.search()
  });
};
