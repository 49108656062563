import { default as React } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export interface ExpressionBuilderModalProps {
  show: boolean;
  mode: string;
  onSwitchMode(): void;
  onCancel(): void;
}

export const ExpressionBuilderModalFC: React.FC<
  ExpressionBuilderModalProps
> = props => {
  return (
    <Modal isOpen={props.show} toggle={props.onCancel}>
      <ModalHeader toggle={props.onCancel}>Caution!</ModalHeader>
      <ModalBody>
        Switching modes from ADVANCED to ANY/ALL will remove any existing
        condition builder settings for this audience. Do you wish to continue?
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            props.onCancel();
          }}
          color="link"
          data-qa-id="expression-builder-modal-cancel"
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => {
            props.onSwitchMode();
          }}
          data-qa-id="expression-builder-modal-switch-mode"
        >
          Switch Mode
        </Button>
      </ModalFooter>
    </Modal>
  );
};
