import { default as React } from "react";
import { Helmet } from "react-helmet";
import { AddAudienceContainer } from "../containers/AddAudienceContainer";
import { shortNamesToIds } from "../constants/permissionConstants";
import { NoWriteAccessFC } from "../components/Access/NoWriteAccessComponent";
import { BusinessUnitAuthorizedContainer } from "../../template/containers/BusinessUnitAuthorizedContainer";

export const AddAudiencePage: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet>
        <title>Add Audience</title>
      </Helmet>
      <BusinessUnitAuthorizedContainer
        permissionIds={[shortNamesToIds.write_audience_console]}
        deniedComponent={NoWriteAccessFC}
      >
        <AddAudienceContainer />
      </BusinessUnitAuthorizedContainer>
    </React.Fragment>
  );
};
