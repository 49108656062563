import { default as React } from "react";
import { Label } from "./Label";
import { Typeahead, TokenProps } from "react-bootstrap-typeahead";
import { isString } from "lodash";
import classNames from "classnames";

const ENTER_KEYCODE = 13,
  BACKSPACE_KEYCODE = 8;

function cleanLabel(label: string = "") {
  return label
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9\-_]+/g, "")
    .slice(0, 100);
}

export interface LabelsInputStateProps {
  labels: string[];
  placeholder?: string;
  onChange(labels: string[]): void;
  suggestions: string[];
  disabled?: boolean;
  allowNew?: boolean;
  labelClassName?: string;
  className?: string;
  minLength?: number;
}

export interface LabelsInputDispatchProps {}

export interface LabelsInputProps
  extends LabelsInputStateProps,
    LabelsInputDispatchProps {}

export class LabelsInput extends React.Component<LabelsInputProps> {
  static defaultProps = {
    disabled: false,
    allowNew: true,
    minLength: 2,
    placeholder: "Labels"
  };

  typeahead: Typeahead<string | { label: string }> | null = null;

  setInputValue(text: string) {
    if (this.typeahead) {
      this.typeahead.setState({ text });
    }
  }
  getInputValue() {
    if (this.typeahead) {
      return this.typeahead.state.text;
    } else {
      return "";
    }
  }
  focus() {
    if (this.typeahead) {
      this.typeahead.focus();
    }
  }
  ref = (typeahead: Typeahead<string | { label: string }>) => {
    if (typeahead) {
      this.typeahead = (typeahead as any).getInstance();
    }
  };

  onInputChange = (value: string) => this.setInputValue(cleanLabel(value));

  isValueUniqueAndValid(value: string) {
    const isUnique = !this.props.labels.includes(value);

    return (
      value &&
      isUnique &&
      (this.props.allowNew ? isUnique : this.props.suggestions.includes(value))
    );
  }
  onKeyDown = (e: React.KeyboardEvent) => {
    const inputValue = this.getInputValue();

    if (e.keyCode === ENTER_KEYCODE && this.isValueUniqueAndValid(inputValue)) {
      this.onChange([...this.props.labels, inputValue]);
      this.setInputValue("");
    } else if (
      e.keyCode === BACKSPACE_KEYCODE &&
      !inputValue &&
      this.props.labels.length > 0
    ) {
      this.onChange(this.props.labels.slice(0, -1));
    }
  };

  onChange = (selected: Array<string | { label: string }>) => {
    const newLabels = selected.map(option =>
      isString(option) ? option : option.label
    );

    this.props.onChange(newLabels);
  };

  renderToken = (
    option: string | { label: string },
    props: TokenProps<string>,
    index: number
  ) => {
    return (
      <Label
        key={index}
        className={classNames(this.props.labelClassName, "mb-1 mr-1")}
        disabled={props.disabled}
        onRemove={props.onRemove}
        label={isString(option) ? option : option.label}
      />
    );
  };

  render() {
    return (
      <div className={classNames("labels-container", this.props.className)}>
        <Typeahead
          bsSize="sm"
          ref={this.ref}
          allowNew={this.props.allowNew}
          placeholder={this.props.placeholder}
          newSelectionPrefix="Create: "
          multiple
          onKeyDown={this.onKeyDown}
          onInputChange={this.onInputChange}
          options={this.props.suggestions}
          selected={this.props.labels}
          onChange={this.onChange}
          renderToken={this.renderToken}
          minLength={this.props.minLength}
        />
      </div>
    );
  }
}
