import { FullAttribute } from "../../api/attributeApi";

export interface ConditionBuilderBaseProps
  extends BaseStateProps,
    BaseDispatchProps {}

export interface BaseStateProps {
  attribute?: FullAttribute;
  value?: string;
  operatorId?: string;
  subsetValue?: string;
  subsetOperatorId?: string;
  model?: string;
  dataLocation?: string;
  dataType?: string;
}

export interface BaseDispatchProps {
  handleChangeDataType(dataType: string): void;
  handleChangeValue(
    attribute: FullAttribute,
    operatorId: string,
    value: string
  ): void;
  handleChangeOperatorId(
    attribute: FullAttribute,
    operatorId: string,
    value: string
  ): void;
  handleChangeSubsetValue(
    attribute: FullAttribute,
    subsetOperatorId: string,
    subsetValue: string
  ): void;
  handleChangeSubsetOperatorId(
    attribute: FullAttribute,
    subsetOperatorId: string,
    subsetValue: string
  ): void;
  handleClearSubset(): void;
  handleClickClose(attribute: FullAttribute): void;
}

export const baseDefaultProps: Partial<ConditionBuilderBaseProps> = {
  attribute: undefined as any,
  value: "",
  operatorId: "",
  subsetValue: "",
  subsetOperatorId: ""
};

export const qaIds = {
  selectedConditionName: "selected-condition-name",
  selectedConditionTooltipIcon: "selected-condition-tooltip-icon",
  selectedConditionTooltip: "selected-condition-tooltip",
  selectedConditionTooltipPath: "selected-condition-tooltip-path",
  selectedConditionOperator: "selected-condition-operator",
  selectedConditionSubsetOperator: "selected-condition-subset-operator",
  selectedConditionValue: "selected-condition-value",
  selectedConditionSubsetValue: "selected-condition-subset-value",
  selectedConditionType: "selected-condition-type",
  selectedDataTypeValue: "selected-data-type-value",
  selectedConditionCloseButton: "selected-condition-cancel-button"
};

export const baseStyles = {
  conditionContainerClass:
    "d-flex flex-wrap justify-content-start align-items-center border rounded pt-3 px-3",
  itemMarginClass: "mr-3 mb-3"
};

export const inputIds = {
  operatorId: "operatorId",
  subsetOperatorId: "subsetOperatorId",
  attributeValues: "attributeValues",
  value: "value",
  subsetValue: "subsetValue"
};
