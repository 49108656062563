import { uniq } from "lodash";
import { Action } from "redux";
import { Audience } from "../models/audienceModels";
import {
  clearAllAudiencesAF,
  clearAudienceAF,
  createAudienceAF,
  deleteAudienceAF,
  readAllAudiencesAF,
  readAudienceAF,
  resetAudiencesStateAF,
  setAudiencesPageSizeAF,
  setAudiencesQueryAF,
  setAudiencesSortAF,
  updateAudienceAF,
  clearAudiencesQueryAF,
  setAudiencesFilterLabelsAF,
  updateLabelsAF,
  readAllLabelsAF
} from "../actions/audienceActions";
import { Order } from "../constants/sharedConstants";

const initialState: State = {
  // we start at 10 because redux-infinite-scroll calls load more on page load
  // because at first the table is empty. We want to start at 20 and load more adds 10
  pageSize: 10,
  sort: {
    iteratees: ["modified_at"],
    orders: ["desc"]
  },
  search: {
    query: "",
    iteratees: ["name"]
  },
  byBusinessUnitId: {},
  filter: {
    labels: []
  },
  suggestions: []
};

export type State = AudiencesState;

interface BusinessUnitIdMapState {
  [businessUnitId: string]: AudienceIdMapState;
}

interface AudiencesState {
  pageSize: number;
  sort: {
    iteratees: string[];
    orders: ReadonlyArray<Order>;
  };
  search: {
    query: string | number;
    iteratees: string[];
  };
  filter: AudienceFilterState;
  byBusinessUnitId: BusinessUnitIdMapState;
  suggestions: string[];
}

export interface AudienceFilterState {
  labels: string[];
}

interface AudienceIdMapState {
  byId: {
    [audienceId: string]: Audience;
  };
  allIds: number[];
}

export function audiencesReducer(
  previousState: State = initialState,
  action: Action
): State {
  let prevSortKey;
  let nextSortKey;
  let prevOrder: Order;
  let nextOrder: Order;

  if (createAudienceAF.fulfilledAF.isAction(action)) {
    return {
      ...previousState,
      byBusinessUnitId: {
        ...previousState.byBusinessUnitId,
        [action.meta.context.businessUnit.id]: {
          ...previousState.byBusinessUnitId[
            action.meta.context.businessUnit.id
          ],
          byId: {
            ...(previousState.byBusinessUnitId[
              action.meta.context.businessUnit.id
            ]
              ? previousState.byBusinessUnitId[
                  action.meta.context.businessUnit.id
                ].byId
              : {}),
            ...action.payload.entities.audiences
          },
          allIds: [
            ...(previousState.byBusinessUnitId[
              action.meta.context.businessUnit.id
            ]
              ? previousState.byBusinessUnitId[
                  action.meta.context.businessUnit.id
                ].allIds
              : []),
            action.payload.result
          ]
        }
      }
    };
  }
  if (readAudienceAF.fulfilledAF.isAction(action)) {
    return {
      ...previousState,
      byBusinessUnitId: {
        ...previousState.byBusinessUnitId,
        [action.meta.context.businessUnit.id]: {
          ...previousState.byBusinessUnitId[
            action.meta.context.businessUnit.id
          ],
          byId: {
            ...(previousState.byBusinessUnitId[
              action.meta.context.businessUnit.id
            ]
              ? previousState.byBusinessUnitId[
                  action.meta.context.businessUnit.id
                ].byId
              : {}),
            ...action.payload.entities.audiences
          },
          allIds: uniq([
            ...(previousState.byBusinessUnitId[
              action.meta.context.businessUnit.id
            ]
              ? previousState.byBusinessUnitId[
                  action.meta.context.businessUnit.id
                ].allIds
              : []),
            action.payload.result
          ])
        }
      }
    };
  }
  if (readAllLabelsAF.fulfilledAF.isAction(action)) {
    return {
      ...previousState,
      suggestions: action.payload
    };
  }
  if (readAllAudiencesAF.fulfilledAF.isAction(action)) {
    return {
      ...previousState,
      byBusinessUnitId: {
        ...previousState.byBusinessUnitId,
        [action.meta.context.businessUnit.id]: {
          ...previousState.byBusinessUnitId[
            action.meta.context.businessUnit.id
          ],
          byId: action.payload.entities.audiences,
          allIds: action.payload.result
        }
      }
    };
  }
  if (updateLabelsAF.pendingAF.isAction(action)) {
    return {
      ...previousState,
      byBusinessUnitId: {
        ...previousState.byBusinessUnitId,
        [action.meta.context.businessUnit.id]: {
          ...previousState.byBusinessUnitId[
            action.meta.context.businessUnit.id
          ],
          byId: {
            ...(previousState.byBusinessUnitId[
              action.meta.context.businessUnit.id
            ]
              ? previousState.byBusinessUnitId[
                  action.meta.context.businessUnit.id
                ].byId
              : {}),
            [action.meta.baseAction.payload.audience.audience_id]: {
              ...action.meta.baseAction.payload.audience,
              labels: action.meta.baseAction.payload.labels
            }
          }
        }
      }
    };
  }
  if (
    updateAudienceAF.fulfilledAF.isAction(action) ||
    updateLabelsAF.fulfilledAF.isAction(action)
  ) {
    return {
      ...previousState,
      byBusinessUnitId: {
        ...previousState.byBusinessUnitId,
        [action.meta.context.businessUnit.id]: {
          ...previousState.byBusinessUnitId[
            action.meta.context.businessUnit.id
          ],
          byId: {
            ...(previousState.byBusinessUnitId[
              action.meta.context.businessUnit.id
            ]
              ? previousState.byBusinessUnitId[
                  action.meta.context.businessUnit.id
                ].byId
              : {}),
            ...action.payload.entities.audiences
          }
        }
      }
    };
  }
  if (deleteAudienceAF.fulfilledAF.isAction(action)) {
    return {
      ...previousState,
      byBusinessUnitId: {
        ...previousState.byBusinessUnitId,
        [action.meta.context.businessUnit.id]: {
          ...previousState.byBusinessUnitId[
            action.meta.context.businessUnit.id
          ],
          byId: {
            ...(previousState.byBusinessUnitId[
              action.meta.context.businessUnit.id
            ]
              ? previousState.byBusinessUnitId[
                  action.meta.context.businessUnit.id
                ].byId
              : {}),
            [action.payload.audienceId]: undefined
          },
          allIds: (previousState.byBusinessUnitId[
            action.meta.context.businessUnit.id
          ]
            ? previousState.byBusinessUnitId[
                action.meta.context.businessUnit.id
              ].allIds
            : []
          ).filter((id: number) => {
            return id !== action.payload.audienceId;
          })
        }
      }
    };
  }
  if (clearAudienceAF.isAction(action)) {
    return {
      ...previousState,
      byBusinessUnitId: {
        ...previousState.byBusinessUnitId,
        [action.meta.context.businessUnit.id]: {
          ...previousState.byBusinessUnitId[
            action.meta.context.businessUnit.id
          ],
          byId: {
            ...(previousState.byBusinessUnitId[
              action.meta.context.businessUnit.id
            ]
              ? previousState.byBusinessUnitId[
                  action.meta.context.businessUnit.id
                ].byId
              : {}),
            [action.payload.audienceId]: undefined
          },
          allIds: (previousState.byBusinessUnitId[
            action.meta.context.businessUnit.id
          ]
            ? previousState.byBusinessUnitId[
                action.meta.context.businessUnit.id
              ].allIds
            : []
          ).filter((id: number) => {
            return id !== action.payload.audienceId;
          })
        }
      }
    };
  }
  if (clearAllAudiencesAF.isAction(action)) {
    return {
      ...previousState,
      byBusinessUnitId: {
        ...previousState.byBusinessUnitId,
        [action.meta.context.businessUnit.id]: {
          ...previousState.byBusinessUnitId[
            action.meta.context.businessUnit.id
          ],
          byId: {},
          allIds: [],
          countsById: {}
        }
      },
      search: {
        ...previousState.search,
        query: ""
      }
    };
  }
  if (resetAudiencesStateAF.isAction(action)) {
    return {
      ...previousState,
      ...initialState
    };
  }
  if (setAudiencesPageSizeAF.isAction(action)) {
    return {
      ...previousState,
      pageSize: action.payload.pageSize
    };
  }
  if (setAudiencesSortAF.isAction(action)) {
    prevSortKey = previousState.sort.iteratees[0];
    prevOrder = previousState.sort.orders[0];
    nextSortKey = action.payload.sortKey;

    if (prevSortKey === nextSortKey) {
      if (prevOrder === "asc") {
        nextOrder = "desc";
      } else {
        nextOrder = "asc";
      }
    } else {
      nextOrder = "asc";
    }

    return {
      ...previousState,
      sort: {
        ...previousState.sort,
        iteratees: [nextSortKey],
        orders: [nextOrder]
      }
    };
  }
  if (setAudiencesQueryAF.isAction(action)) {
    return {
      ...previousState,
      search: {
        ...previousState.search,
        query: action.payload.query
      }
    };
  }
  if (clearAudiencesQueryAF.isAction(action)) {
    return {
      ...previousState,
      search: {
        ...previousState.search,
        query: ""
      }
    };
  }

  if (setAudiencesFilterLabelsAF.isAction(action)) {
    const labels = action.payload;

    return {
      ...previousState,
      filter: {
        ...previousState.filter,
        labels
      }
    };
  }

  return previousState;
}
