import { default as React } from "react";
import { Redirect as RouterRedirect } from "react-router-dom";
import { LocationDescriptor } from "history";
import { connect, MapStateToProps } from "react-redux";
import { State } from "../reducers";
import { getSelectedBusinessUnit } from "../selectors/businessUnitSelectors";
import { getTrackedDataLocationDescriptor } from "../lib/templateLib";

export interface RedirectProps {
  to: LocationDescriptor;
}

export interface RedirectStateProps {
  clientId: number;
  businessUnitId: number;
}

const RedirectFC: React.FC<RedirectProps & RedirectStateProps> = props => {
  const { to, clientId, businessUnitId, ...otherProps } = props;
  const locationDescriptor = getTrackedDataLocationDescriptor(
    to,
    clientId,
    businessUnitId
  );

  return <RouterRedirect to={locationDescriptor} {...otherProps} />;
};

const mapStateToProps: MapStateToProps<
  RedirectStateProps,
  {},
  State
> = state => {
  const businessUnit = getSelectedBusinessUnit(state);

  return {
    clientId: businessUnit ? businessUnit.client : undefined,
    businessUnitId: businessUnit ? businessUnit.id : undefined
  };
};

function mapDispatchToProps() {
  return {};
}

export const RedirectContainer = connect<RedirectStateProps, {}, RedirectProps>(
  mapStateToProps,
  mapDispatchToProps
)(RedirectFC);
