import { default as React } from "react";
import { Button } from "reactstrap";
import { ExpressionModes } from "../models/expressionBuilderModels";

interface ModeMap {
  [mode: string]: string;
}

const modeLabelMap: ModeMap = {
  [ExpressionModes.ALL]: "All of the Following",
  [ExpressionModes.ANY]: "Any of the Following",
  [ExpressionModes.ADVANCED]: "Advanced"
};

const modeLabelShortMap: ModeMap = {
  [ExpressionModes.ALL]: "All",
  [ExpressionModes.ANY]: "Any",
  [ExpressionModes.ADVANCED]: "Advanced"
};

const modeQaIdMap: ModeMap = {
  [ExpressionModes.ALL]: "expression-mode-all",
  [ExpressionModes.ANY]: "expression-mode-any",
  [ExpressionModes.ADVANCED]: "expression-mode-advanced"
};

export interface ExpressionModeButtonProps {
  mode: ExpressionModes;
  disabled?: boolean;
  selectedMode: ExpressionModes;
  onSelectMode(mode: string): void;
}

export const ExpressionModeButtonFC: React.FC<
  ExpressionModeButtonProps
> = props => {
  const isSelectedMode = props.mode === props.selectedMode;
  return (
    <Button
      outline={!isSelectedMode}
      onClick={() => props.onSelectMode(props.mode)}
      data-qa-id={modeQaIdMap[props.mode]}
      disabled={props.disabled}
    >
      <span className={"d-none d-lg-inline"}>{modeLabelMap[props.mode]}</span>
      <span className={"d-lg-none"}>{modeLabelShortMap[props.mode]}</span>
    </Button>
  );
};
