import {
  segmentsReducer,
  State as ExternalSegmentsState
} from "./externalSegmentsReducer";
import { combineReducers } from "redux";
import {
  createReducer,
  State as ExternalSegmentCreateState
} from "../../externalSegments/reducers/externalSegmentCreateReducer";
import {
  updateReducer,
  State as ExternalSegmentUpdateState
} from "../../externalSegments/reducers/externalSegmentUpdateReducer";
import {
  deleteReducer,
  State as ExternalSegmentDeleteState
} from "../../externalSegments/reducers/externalSegmentDeleteReducer";

export const reducers = {
  externalSegments: combineReducers({
    segments: segmentsReducer,
    create: createReducer,
    update: updateReducer,
    delete: deleteReducer
  })
};

export interface State {
  externalSegments: {
    segments: ExternalSegmentsState;
    create: ExternalSegmentCreateState;
    update: ExternalSegmentUpdateState;
    delete: ExternalSegmentDeleteState;
  };
}
