import { default as React } from "react";
import { NavBadgeFC } from "./NavBadgeComponent";
import { NavNode } from "../models/navigationModels";
import { LinkContainer } from "../containers/LinkContainer";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./SitemapNavContentComponent.module.scss";
import classNames from "classnames";

export interface SitemapNavContentProps {
  navigation: NavNode[];
}

export const SitemapNavContentFC: React.FC<SitemapNavContentProps> = props => {
  return (
    <React.Fragment>
      {props.navigation.map(item => {
        return (
          <div
            key={item.id}
            className={classNames(
              "navItem",
              styles.navItem,
              item.children &&
                item.children.length > 0 &&
                styles.navItemHasChildren
            )}
          >
            {item.visible && (
              <LinkContainer
                to={item.finalHref}
                target={item.target}
                data-qa-id={`site-map-nav-${item.id}`}
                className={classNames(
                  "navItemLink",
                  styles.navItemLink,
                  !item.enabled && styles.navItemDisabled
                )}
              >
                <span className={styles.productName}>
                  <span>{item.name}</span>
                  {item.badgeText && (
                    <NavBadgeFC
                      text={item.badgeText}
                      backgroundColor={item.badgeColor}
                    />
                  )}
                  {item.children && item.children.length > 0 && (
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className={styles.chevronIcon}
                    />
                  )}
                </span>
              </LinkContainer>
            )}
            {item.children && item.children.length > 0 && item.visible && (
              <div className={`navChildren ${styles.navChildren}`}>
                <div className={styles.navChildrenContainer}>
                  {item.children.map(child => {
                    return (
                      <div key={child.id}>
                        {child.visible && (
                          <div>
                            <LinkContainer
                              to={child.finalHref}
                              target={child.target}
                              data-qa-id={`site-map-nav-${child.id}`}
                              className={`${classNames(
                                styles.category,
                                child.children &&
                                  child.children.length > 0 &&
                                  styles.categoryHasChildren,
                                !child.enabled && styles.navItemDisabled
                              )}`}
                            >
                              {child.name}
                            </LinkContainer>
                            {child.children && child.children.length > 0 && (
                              <div className={styles.linkContainer}>
                                {child.children.map(grandchild => {
                                  return (
                                    <div key={grandchild.id}>
                                      {grandchild.visible && (
                                        <LinkContainer
                                          to={grandchild.finalHref}
                                          target={grandchild.target}
                                          data-qa-id={`site-map-nav-${
                                            grandchild.id
                                          }`}
                                          className={classNames(
                                            styles.link,
                                            !grandchild.enabled &&
                                              styles.navItemDisabled
                                          )}
                                        >
                                          {grandchild.name}
                                        </LinkContainer>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </React.Fragment>
  );
};
