import { default as React, StatelessComponent } from "react";

export interface Props extends StateProps, DispatchProps {}

export interface StateProps {
  checkAccessPending: boolean;
  hasAccess: boolean;
  pendingComponent?: StatelessComponent<any>;
  deniedComponent?: StatelessComponent<any>;
}

export interface DispatchProps {
  checkAccess(): void;
}

export class AccessPureComponent extends React.PureComponent<Props> {
  static defaultProps = {
    pendingComponent: undefined as StatelessComponent<any>,
    deniedComponent: undefined as StatelessComponent<any>
  };

  componentDidMount() {
    const props = this.props;

    if (!props.hasAccess) {
      props.checkAccess();
    }
  }

  render() {
    const props = this.props;

    if (props.hasAccess) {
      return <div>{props.children}</div>;
    }
    if (
      !props.hasAccess &&
      props.checkAccessPending &&
      !!props.pendingComponent
    ) {
      return <props.pendingComponent />;
    }
    if (
      !props.hasAccess &&
      !props.checkAccessPending &&
      !!props.deniedComponent
    ) {
      return <props.deniedComponent />;
    }

    return null;
  }
}
