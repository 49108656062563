import { getNodeDepth } from "../../lib/treeLib";
import {
  isTopInterestAttribute,
  SCORE_CATEGORIES
} from "../constants/attributeIdConstants";
import {
  EXISTS,
  GREATER_THAN,
  LESS_THAN_OR_EQUALS,
  NOT_EXISTS
} from "../constants/operatorConstants";
import {
  conditionBuilderEditConditionAF,
  conditionBuilderLoadConditionAF,
  conditionBuilderChangeValueAF,
  conditionBuilderChangeSubsetValueAF,
  conditionBuilderChangeOperatorIdAF,
  conditionBuilderChangeSubsetOperatorIdAF,
  conditionBuilderSetMetaAF,
  conditionBuilderClearConditionAF,
  loadConditionDefaults,
  loadConditionMetaDefaults,
  conditionBuilderValidateValueAF,
  conditionBuilderValidateSubsetValueAF,
  conditionBuilderValidateOperatorIdAF,
  conditionBuilderValidateSubsetOperatorIdAF,
  conditionBuilderSaveConditionClickedAF
} from "../actions/conditionBuilderActions";
import {
  expressionBuilderAddConditionAF,
  expressionBuilderEditConditionAF
} from "../actions/expressionBuilder";
import {
  getRootAttribute,
  getFullAttributeById
} from "../selectors/audienceSelectors";
import {
  getConditionBuilder,
  getConditionBuilderMode,
  getConditionBuilderConditionId
} from "../selectors/conditionBuilderSelector";
import { getValidator } from "./conditionValidators/getValidator";
import {
  ConditionBuilderMode,
  Condition
} from "../models/conditionBuilderModels";
import { attributeSelectorSelectAttributeAF } from "../actions/attributeSelectorActions";
import { conditionFactory } from "../models/expressionBuilderModels";
import {
  createEpic,
  ofType,
  combineEpicMap,
  EpicMap
} from "../../lib/epicFactory";
import { concat, defer, from } from "rxjs";
import {
  loadCondition,
  loadScoreCondition,
  loadCRMCondition
} from "./attributeEpics";
import { State } from "../reducers";
import { Action } from "redux";
import { Attribute } from "../api/attributeApi";
import { flatMap, map } from "rxjs/operators";
import {
  SCORE_SEGMENTS,
  CRM,
  CV,
  ES
} from "../constants/attributeSourceTypeConstants";
import { getScoreSegmentByAttributeId } from "../score/selectors/scoreSelectors";
import { getCrmMappingByAttributeId } from "../crm/selectors/crmSelectors";
import { ExistsNotExistsBuilderType } from "../components/ConditionBuilders/ExistsNotExistsBuilder";

export const epicMap: EpicMap<State> = {
  attributeSelectorSelectAttributeEpic: createEpic((action$, state$) => {
    return action$.pipe(
      ofType(attributeSelectorSelectAttributeAF),
      map(action => {
        return conditionBuilderLoadConditionAF.create(
          { attributeId: action.payload.id, ...loadConditionDefaults },
          { builderId: action.meta.selectorId, ...loadConditionMetaDefaults }
        );
      })
    );
  }),
  loadConditionEpic: createEpic<State>((action$, state$) => {
    return action$.pipe(
      ofType(conditionBuilderLoadConditionAF),
      flatMap(action => {
        const { attributeId } = action.payload;

        if (attributeId.indexOf(SCORE_SEGMENTS) === 0) {
          return concat(
            loadScoreCondition(attributeId, action$, state$),
            defer(() => {
              const ss = getScoreSegmentByAttributeId(
                state$.value,
                attributeId
              );

              const condition: Condition = {
                attribute: ss.attribute,
                operatorId: GREATER_THAN.toString(),
                value: ss.min.toString(),
                channel: ss.attribute.channel,
                model: ss.model
              };

              if (ss.max) {
                condition.subsetOperatorId = LESS_THAN_OR_EQUALS.toString();
                condition.subsetValue = ss.max.toString();
              }

              return from([
                expressionBuilderAddConditionAF.create(
                  conditionFactory(condition),
                  {
                    builderId: action.meta.builderId,
                    subQueryId: null
                  }
                )
              ]);
            })
          );
        }

        let {
          operatorId,
          value,
          subsetValue,
          subsetOperatorId,
          dataLocation,
          dataType
        } = action.payload;

        return concat(
          loadCondition(attributeId, action$, state$),
          loadCRMCondition(attributeId, action$, state$),
          defer(() => {
            const attribute = getFullAttributeById(state$.value, attributeId);

            if (operatorId === undefined) {
              operatorId = attribute.attribute_operators[0].id.toString();
            }

            if (value === undefined) {
              value =
                attribute.attribute_values.length > 0
                  ? attribute.attribute_values[0].value
                  : "";
            }

            if (
              subsetOperatorId === undefined &&
              attribute.attribute_subset_operators.length > 0
            ) {
              subsetOperatorId = attribute.attribute_subset_operators[0].id.toString();
            }

            if (subsetValue === undefined) {
              subsetValue = "";
            }

            if (attributeId.startsWith(CRM)) {
              const crmMapping = getCrmMappingByAttributeId(
                state$.value,
                attributeId
              );
              dataLocation = crmMapping.data_location;
              dataType = attribute.data_type_name;
            } else if (
              (attributeId.startsWith(CV) || attributeId.startsWith(ES)) &&
              !dataType
            ) {
              dataType = ExistsNotExistsBuilderType.FrequencyWithRecency;
            }

            // there should be no value if exists is the operator
            value =
              operatorId === (EXISTS.toString() || NOT_EXISTS.toString())
                ? ""
                : value;

            const actionsToEmit: Action[] = [
              conditionBuilderEditConditionAF.create(
                {
                  attributeId,
                  operatorId,
                  value,
                  subsetOperatorId,
                  subsetValue,
                  dataLocation,
                  dataType,
                  channel: attribute.channel
                },
                {
                  ...action.meta
                }
              )
            ];

            // populate meta for necessary attribute types
            if (value && isTopInterestAttribute(attributeId)) {
              const root: Attribute = getRootAttribute(state$.value);

              // calc relative depth of attribute node compared to score categories node
              const scoreCategoryNodeDepth = getNodeDepth(
                root,
                node => {
                  return node.id === SCORE_CATEGORIES;
                },
                node => {
                  return node.children;
                }
              );
              const attributeNodeDepth = getNodeDepth(
                root,
                node => {
                  return node.id === value;
                },
                node => {
                  return node.children;
                }
              );

              if (
                scoreCategoryNodeDepth > -1 &&
                attributeNodeDepth > scoreCategoryNodeDepth
              ) {
                const level = attributeNodeDepth - scoreCategoryNodeDepth;

                actionsToEmit.push(
                  conditionBuilderSetMetaAF.create(
                    { level },
                    { builderId: action.meta.builderId }
                  )
                );
              }
            }
            return from(actionsToEmit);
          })
        );
      })
    );
  }),
  validateValue: createEpic<State>((action$, state$) => {
    return action$.pipe(
      ofType(conditionBuilderValidateValueAF),
      flatMap(action => {
        const { attribute, operatorId, value } = action.payload;
        const conditionBuilder = getConditionBuilder(
          state$.value,
          action.meta.builderId
        );
        const validator = getValidator(attribute);
        const actionsToEmit: Action[] = [];

        const isValid = validator.validateValue(
          action.meta.builderId,
          value,
          conditionBuilder.condition.value,
          operatorId,
          attribute
        );
        if (isValid) {
          actionsToEmit.push(
            conditionBuilderChangeValueAF.create(
              { value },
              { builderId: action.meta.builderId }
            )
          );
        }

        return from(actionsToEmit);
      })
    );
  }),
  validateSubsetValue: createEpic<State>((action$, state$) => {
    return action$.pipe(
      ofType(conditionBuilderValidateSubsetValueAF),
      flatMap(action => {
        const { attribute, operatorId, value } = action.payload;
        const conditionBuilder = getConditionBuilder(
          state$.value,
          action.meta.builderId
        );
        const validator = getValidator(attribute);
        const actionsToEmit: Action[] = [];

        const isValid = validator.validateSubsetValue(
          action.meta.builderId,
          value,
          conditionBuilder.condition.subsetValue,
          operatorId,
          attribute
        );

        if (isValid) {
          actionsToEmit.push(
            conditionBuilderChangeSubsetValueAF.create(
              { subsetValue: value },
              { builderId: action.meta.builderId }
            )
          );
        }

        return from(actionsToEmit);
      })
    );
  }),
  validateOperatorId: createEpic<State>((action$, state$) => {
    return action$.pipe(
      ofType(conditionBuilderValidateOperatorIdAF),
      flatMap(action => {
        const actionsToEmit: Action[] = [];

        let value = "";
        if (
          (action.payload.operatorId !== EXISTS.toString() ||
            action.payload.operatorId !== NOT_EXISTS.toString()) &&
          action.payload.attribute.attribute_values.length > 0
        ) {
          value = action.payload.attribute.attribute_values[0].value;
        }

        actionsToEmit.push(
          conditionBuilderChangeValueAF.create(
            { value },
            { builderId: action.meta.builderId }
          )
        );
        actionsToEmit.push(
          conditionBuilderChangeOperatorIdAF.create(
            { operatorId: action.payload.operatorId },
            { builderId: action.meta.builderId }
          )
        );

        return from(actionsToEmit);
      })
    );
  }),
  validateSubsetOperatorId: createEpic<State>((action$, state$) => {
    return action$.pipe(
      ofType(conditionBuilderValidateSubsetOperatorIdAF),
      flatMap(action => {
        const { operatorId } = action.payload;
        const actionsToEmit: Action[] = [];

        actionsToEmit.push(
          conditionBuilderChangeSubsetValueAF.create(
            { subsetValue: "" },
            { builderId: action.meta.builderId }
          )
        );
        actionsToEmit.push(
          conditionBuilderChangeSubsetOperatorIdAF.create(
            { subsetOperatorId: operatorId },
            { builderId: action.meta.builderId }
          )
        );

        return from(actionsToEmit);
      })
    );
  }),
  saveConditionClickedEpic: createEpic<State>((action$, state$) => {
    return action$.pipe(
      ofType(conditionBuilderSaveConditionClickedAF),
      flatMap(action => {
        const actionsToEmit: Action[] = [];
        const mode = getConditionBuilderMode(
          state$.value,
          action.meta.builderId
        );

        if (mode === ConditionBuilderMode.ADD) {
          actionsToEmit.push(
            expressionBuilderAddConditionAF.create(
              conditionFactory(action.payload),
              {
                builderId: action.meta.builderId,
                subQueryId: action.meta.subQueryId
              }
            )
          );
        } else {
          const conditionId = getConditionBuilderConditionId(
            state$.value,
            action.meta.builderId
          );

          actionsToEmit.push(
            expressionBuilderEditConditionAF.create(
              {
                conditionId,
                condition: action.payload
              },
              {
                builderId: action.meta.builderId,
                subQueryId: action.meta.subQueryId
              }
            )
          );
        }

        actionsToEmit.push(
          conditionBuilderClearConditionAF.create(
            {},
            {
              builderId: action.meta.builderId,
              attributeId: action.payload.attribute.id
            }
          )
        );
        return from(actionsToEmit);
      })
    );
  })
};

export const epics = combineEpicMap(epicMap);
