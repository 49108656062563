import { NavOverrideConfig } from "../template/models/navigationModels";

/*
  Example Overrides
  [
    {
      navNodeId: 'Display2.0',
      hrefConfig: {
        protocol: 'https',
        environmentPrefix: '',
        port: '8001',
      },
      applyToChildren: true,
    },
    {
      navNodeId: 'Management',
      hrefConfig: {
        protocol: 'https',
        environmentPrefix: 'qa-',
        port: '9090',
      },
      applyToChildren: true,
    },
  ]

  Overrides are processed in the order they appear in the array. So generally speaking
  you want more specific overrides to be after more global overrides. In the example above,
  it is important that the Management override is after the Display2.0 override because
  Management is a child under Display2.0 and the Display2.0 override has applyToChildren
  set to true.

  !! Overrides are a local development utility. Your personal overrides should not be versioned !!

  Use the following command

  git update-index --assume-unchanged src/app/NavOverrides.ts

  to prevent changes to this file from being detected by git.
 */

export const navOverrides: NavOverrideConfig[] = [];
