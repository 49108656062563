import { Action } from "redux";
import {
  openDeleteModalAF,
  closeDeleteModalAF,
  readDeleteScoreSegmentByIdAF,
  deleteScoreSegmentAF
} from "../actions/deleteActions";
import { ScoreSegment } from "../../api/attributeApi";
import { Audience } from "../../models/audienceModels";

export interface State {
  isMax: boolean;
  showModal: boolean;
  isFormSubmitting: boolean;
  isGettingDeleteScoreSegment: boolean;
  isGettingAudiencesUsingScoreSegment: boolean;
  isDeleting: boolean;
  scoreSegment?: ScoreSegment;
  audiencesUsingScoreSegments: Audience[];
}

export const scoreDeleteInitialState: State = {
  isMax: true,
  showModal: false,
  isFormSubmitting: false,
  isGettingAudiencesUsingScoreSegment: false,
  isGettingDeleteScoreSegment: false,
  isDeleting: false,
  audiencesUsingScoreSegments: []
};

export function deleteReducer(
  previousState: State = scoreDeleteInitialState,
  action: Action
): State {
  if (openDeleteModalAF.isAction(action)) {
    return {
      ...previousState,
      showModal: true
    };
  }
  if (closeDeleteModalAF.isAction(action)) {
    return scoreDeleteInitialState;
  }
  if (readDeleteScoreSegmentByIdAF.pendingAF.isAction(action)) {
    return {
      ...previousState,
      isGettingDeleteScoreSegment: true
    };
  }
  if (readDeleteScoreSegmentByIdAF.rejectedAF.isAction(action)) {
    return {
      ...previousState,
      isGettingDeleteScoreSegment: false
    };
  }
  if (readDeleteScoreSegmentByIdAF.fulfilledAF.isAction(action)) {
    return {
      ...previousState,
      scoreSegment: action.payload,
      isGettingDeleteScoreSegment: false
    };
  }
  if (deleteScoreSegmentAF.pendingAF.isAction(action)) {
    return {
      ...previousState,
      isFormSubmitting: true
    };
  }
  if (deleteScoreSegmentAF.rejectedAF.isAction(action)) {
    return {
      ...previousState,
      isFormSubmitting: false
    };
  }
  if (deleteScoreSegmentAF.fulfilledAF.isAction(action)) {
    return scoreDeleteInitialState;
  }
  return previousState;
}
