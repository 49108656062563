import { flatten } from "../../lib/treeLib";
import { Action } from "redux";
import { readAttributeForestAF } from "../actions/attributeActions";
import { Attribute } from "../api/attributeApi";
import {
  deleteCrmMappingAF,
  createCrmMappingAF,
  updateCrmMappingAF
} from "../crm";
import { AppContext } from "../selectors/appContextSelectors";
import {
  createScoreSegmentAF,
  updateScoreSegmentAF,
  deleteScoreSegmentAF
} from "../score";
import { createExternalSegmentAF } from "../externalSegments/actions/externalSegmentCreateActions";
import { updateExternalSegmentAF } from "../externalSegments/actions/externalSegmentUpdateActions";
import { deleteExternalSegmentAF } from "../externalSegments/actions/externalSegmentDeleteActions";

export interface State extends BusinessUnitIdMapState {}

interface BusinessUnitIdMapState {
  readonly [businessUnitId: number]: AttributesState;
}

export interface AttributesState {
  readonly byId: {
    readonly [attributeId: string]: Attribute;
  };
  readonly rootId: string;
}

export function attributesReducer(
  previousState: BusinessUnitIdMapState = {},
  action: Action
): BusinessUnitIdMapState {
  if (readAttributeForestAF.fulfilledAF.isAction(action)) {
    const allAttributes = flatten(action.payload);
    return {
      ...previousState,
      [action.meta.context.businessUnit.id]: {
        byId: allAttributes.reduce((byIdMap, attribute) => {
          byIdMap[attribute.id] = attribute;
          return byIdMap;
        }, {}),
        rootId: action.payload.id
      }
    };
  }
  if (
    (createCrmMappingAF.fulfilledAF.isAction(action) ||
      createExternalSegmentAF.fulfilledAF.isAction(action)) &&
    stateExists(previousState, action.meta.context)
  ) {
    const businessUnitId = action.meta.context.businessUnit.id;
    const attributeId = action.payload.attribute.id;
    const parentId = action.payload.attribute.parent_id;
    return {
      ...previousState,
      [businessUnitId]: {
        ...previousState[businessUnitId],
        byId: {
          ...previousState[businessUnitId].byId,
          [parentId]: {
            ...previousState[businessUnitId].byId[parentId],
            children: [
              ...previousState[businessUnitId].byId[parentId].children,
              action.payload.attribute
            ]
          },
          [attributeId]: action.payload.attribute
        }
      }
    };
  }
  if (
    createScoreSegmentAF.fulfilledAF.isAction(action) &&
    stateExists(previousState, action.meta.context)
  ) {
    const businessUnitId = action.meta.context.businessUnit.id;
    const attributeId = action.payload.attribute.id;
    const parentId = action.payload.attribute.parent_id;
    return {
      ...previousState,
      [businessUnitId]: {
        ...previousState[businessUnitId],
        byId: {
          ...previousState[businessUnitId].byId,
          [parentId]: {
            ...previousState[businessUnitId].byId[parentId],
            children: [
              ...previousState[businessUnitId].byId[parentId].children,
              action.payload.attribute
            ]
          },
          [attributeId]: {
            ...action.payload.attribute,
            children: action.payload.children.map(child => child.attribute)
          }
        }
      }
    };
  }
  if (
    (updateCrmMappingAF.fulfilledAF.isAction(action) ||
      updateExternalSegmentAF.fulfilledAF.isAction(action)) &&
    stateExists(previousState, action.meta.context)
  ) {
    const businessUnitId = action.meta.context.businessUnit.id;
    const attributeId = action.payload.attribute.id;
    const parentId = action.payload.attribute.parent_id;
    const children = [...previousState[businessUnitId].byId[parentId].children];
    const childIndex = children.findIndex(child => {
      return child.id === attributeId;
    });
    children[childIndex] = action.payload.attribute;

    return {
      ...previousState,
      [businessUnitId]: {
        ...previousState[businessUnitId],
        byId: {
          ...previousState[businessUnitId].byId,
          [attributeId]: action.payload.attribute,
          [parentId]: {
            ...previousState[businessUnitId].byId[parentId],
            children
          }
        }
      }
    };
  }
  if (
    updateScoreSegmentAF.fulfilledAF.isAction(action) &&
    stateExists(previousState, action.meta.context)
  ) {
    const businessUnitId = action.meta.context.businessUnit.id;
    const attributeId = action.payload.attribute.id;
    const parentId = action.payload.attribute.parent_id;
    const children = [...previousState[businessUnitId].byId[parentId].children];
    const childIndex = children.findIndex(child => {
      return child.id === attributeId;
    });
    children[childIndex] = {
      ...action.payload.attribute,
      children: action.payload.children.map(child => child.attribute)
    };

    return {
      ...previousState,
      [businessUnitId]: {
        ...previousState[businessUnitId],
        byId: {
          ...previousState[businessUnitId].byId,
          [attributeId]: action.payload.attribute,
          [parentId]: {
            ...previousState[businessUnitId].byId[parentId],
            children
          }
        }
      }
    };
  }
  if (
    (deleteCrmMappingAF.fulfilledAF.isAction(action) ||
      deleteScoreSegmentAF.fulfilledAF.isAction(action) ||
      deleteExternalSegmentAF.fulfilledAF.isAction(action)) &&
    stateExists(previousState, action.meta.context)
  ) {
    const businessUnitId = action.meta.context.businessUnit.id;
    const attributeId = action.meta.baseAction.payload.attribute.id;
    const parentId = action.meta.baseAction.payload.attribute.parent_id;
    const byId = {
      ...previousState[businessUnitId].byId,
      [parentId]: {
        ...previousState[businessUnitId].byId[parentId],
        children: previousState[businessUnitId].byId[parentId].children.filter(
          child => {
            return child.id !== attributeId;
          }
        )
      }
    };
    delete byId[attributeId];
    return {
      ...previousState,
      [businessUnitId]: {
        ...previousState[businessUnitId],
        byId
      }
    };
  }
  return previousState;
}

function stateExists(previousState: State, context: AppContext): boolean {
  return !!(
    previousState &&
    previousState[context.businessUnit.id] &&
    previousState[context.businessUnit.id].byId
  );
}
