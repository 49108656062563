import { default as React } from "react";
import { default as DatePicker } from "react-datepicker";
import moment from "moment";
import {
  ON_OR_AFTER,
  ON_OR_BEFORE,
  X_DAYS_OR_LESS_AGO,
  X_DAYS_OR_MORE_AGO,
  EQUALS,
  GREATER_THAN,
  GREATER_THAN_OR_EQUALS,
  CONTAINS,
  LESS_THAN,
  LESS_THAN_OR_EQUALS
} from "../../../constants/operatorConstants";
import {
  baseStyles,
  ConditionBuilderBaseProps,
  qaIds,
  inputIds
} from "../SharedData";
import { DATE_FORMAT } from "../../../constants/attributeDataTypeNameConstants";
import { AttributeOperator } from "../../../api/attributeApi";
import { CustomInput, Input } from "reactstrap";

export const PVariableBuilder: React.FC<ConditionBuilderBaseProps> = (
  props: ConditionBuilderBaseProps
) => (
  <>
    {props.attribute.attribute_operators.length > 0 && (
      <div className={baseStyles.itemMarginClass}>
        <CustomInput
          id={inputIds.operatorId}
          value={props.operatorId}
          type={"select"}
          onChange={e => {
            props.handleChangeOperatorId(
              props.attribute,
              (e.currentTarget as any).value,
              props.value
            );
          }}
          data-qa-id={qaIds.selectedConditionOperator}
        >
          {props.attribute.attribute_operators.map(
            (operator: AttributeOperator) => {
              return (
                <option value={operator.id} key={operator.id}>
                  {operator.name}
                </option>
              );
            }
          )}
        </CustomInput>
      </div>
    )}
    <div className={baseStyles.itemMarginClass}>
      {(props.operatorId === ON_OR_BEFORE.toString() ||
        props.operatorId === ON_OR_AFTER.toString()) && (
        <div data-qa-id={qaIds.selectedConditionValue}>
          <DatePicker
            id={inputIds.value}
            selected={props.value ? moment(props.value) : null}
            onChange={(date: moment.Moment) => {
              props.handleChangeValue(
                props.attribute,
                props.operatorId,
                date ? date.format(DATE_FORMAT) : ""
              );
            }}
            className="form-control"
          />
        </div>
      )}
      {(props.operatorId === GREATER_THAN.toString() ||
        props.operatorId === GREATER_THAN_OR_EQUALS.toString() ||
        props.operatorId === CONTAINS.toString() ||
        props.operatorId === LESS_THAN.toString() ||
        props.operatorId === LESS_THAN_OR_EQUALS.toString() ||
        props.operatorId === EQUALS.toString()) && (
        <Input
          id={inputIds.value}
          type={"text"}
          value={props.value}
          onChange={e => {
            props.handleChangeValue(
              props.attribute,
              props.operatorId,
              (e.currentTarget as any).value
            );
          }}
          data-qa-id={qaIds.selectedConditionValue}
        />
      )}
      {(props.operatorId === X_DAYS_OR_MORE_AGO.toString() ||
        props.operatorId === X_DAYS_OR_LESS_AGO.toString()) && (
        <div className={"d-flex align-items-center"}>
          <div className={"mr-3"}>
            <Input
              id={inputIds.value}
              type={"text"}
              value={props.value}
              onChange={e => {
                props.handleChangeValue(
                  props.attribute,
                  props.operatorId,
                  (e.currentTarget as any).value
                );
              }}
              data-qa-id={qaIds.selectedConditionValue}
            />
          </div>
          <div className={"text-nowrap"}>
            {props.operatorId === X_DAYS_OR_LESS_AGO.toString() &&
              "days or less ago"}
            {props.operatorId === X_DAYS_OR_MORE_AGO.toString() &&
              "days or more ago"}
          </div>
        </div>
      )}
    </div>
  </>
);
