import { default as URI } from "urijs";
import {
  SelectNavigationNodeAction,
  FetchNavigationNodesFulfilledAction
} from "../actions/navigationActions";
import { ActionTypes } from "../constants/templateConstants";
import {
  NavNode,
  convertTsNavNode,
  HrefConfig,
  NavConfig
} from "../models/navigationModels";
import { flatten } from "../../lib/treeLib";
import { Location, Action as HistoryAction } from "history";
import { ActionP } from "../../lib/reduxLib";

interface LocationChangeAction
  extends ActionP<{ action: HistoryAction; location: Location }> {
  type: ActionTypes.LOCATION_CHANGE;
}

type NavigationAction =
  | SelectNavigationNodeAction
  | FetchNavigationNodesFulfilledAction
  | LocationChangeAction;

export interface NavState extends NavNodeMapping, NavNodeSelectedData {
  readonly rootNavNodeId: string;
  readonly userNavRootNodeId: string;
  readonly settingsNavRootNodeId: string;
  readonly siteMapNavRootNodeId: string;
  readonly navConfig: NavConfig;
}

interface NavNodeSelectedData {
  readonly selectedNavNodeId: string;
  readonly selectedSubNavCategoryNodeId: string;
  readonly subNavRootNodeId: string;
}

interface NavNodeMapping {
  readonly byId: { [id: string]: NavNode };
  readonly nodeIdMapByAppId: { [appId: string]: { [navNodeId: string]: true } };
  readonly nodeIdToSubNavIdMap: { [navNodeId: string]: string };
}

export const INITIAL_STATE: NavState = {
  rootNavNodeId: undefined as string,
  selectedNavNodeId: undefined as string,
  userNavRootNodeId: undefined as string,
  settingsNavRootNodeId: undefined as string,
  siteMapNavRootNodeId: undefined as string,
  subNavRootNodeId: undefined as string,
  selectedSubNavCategoryNodeId: undefined as string,
  byId: {},
  nodeIdMapByAppId: {},
  nodeIdToSubNavIdMap: {},
  navConfig: {} as NavConfig
};

export function navigationReducer(
  prevState: NavState = INITIAL_STATE,
  action: NavigationAction
): NavState {
  switch (action.type) {
    case ActionTypes.LOCATION_CHANGE: {
      if (
        prevState.navConfig &&
        prevState.navConfig.appId &&
        prevState.nodeIdMapByAppId[prevState.navConfig.appId]
      ) {
        const selectedNavNodeData = getSelectedNavNodeData(
          action.payload.location.pathname,
          prevState
        );

        return {
          ...prevState,
          ...selectedNavNodeData
        };
      }
      return prevState;
    }
    case ActionTypes.SELECT_NAVIGATION_NODE:
      return {
        ...prevState,
        selectedNavNodeId: action.payload.id
      };
    case ActionTypes.FETCH_NAVIGATION_NODES_FULFILLED: {
      const { navConfig } = action.meta;
      const tsNavigationData = action.payload;

      const maps = flatten(convertTsNavNode(tsNavigationData.navTree)).reduce(
        (maps: NavNodeMapping, navNode: NavNode) => {
          maps.byId[navNode.id] = navNode;

          if (navNode.appId) {
            if (!maps.nodeIdMapByAppId[navNode.appId]) {
              maps.nodeIdMapByAppId[navNode.appId] = {};
            }

            maps.nodeIdMapByAppId[navNode.appId][navNode.id] = true;
          }

          return maps;
        },
        {
          byId: {},
          nodeIdMapByAppId: {},
          nodeIdToSubNavIdMap: {}
        } as NavNodeMapping
      );

      const siteMapNavRootNodeId =
        tsNavigationData.navTree.children[
          tsNavigationData.sectionMap.sitemapNav
        ].id;
      const settingsNavRootNodeId =
        tsNavigationData.navTree.children[
          tsNavigationData.sectionMap.settingsNav
        ].children[0].id;
      const userNavRootNodeId =
        tsNavigationData.navTree.children[tsNavigationData.sectionMap.userNav]
          .children[0].id;

      [
        ...maps.byId[siteMapNavRootNodeId].children.map((navNode: NavNode) => {
          return navNode.id;
        }),
        settingsNavRootNodeId,
        userNavRootNodeId
      ].forEach(subNavNodeId => {
        flatten(maps.byId[subNavNodeId]).forEach(navNode => {
          maps.nodeIdToSubNavIdMap[navNode.id] = subNavNodeId;
        });
      });

      const defaultHrefConfig: HrefConfig = {
        protocol: navConfig.globalProtocol,
        environmentPrefix: navConfig.globalEnvironmentPrefix
      };

      const unprocessedNavNodesById: { [nodeId: string]: {} } = {
        ...maps.byId
      };

      if (navConfig.navOverrides) {
        navConfig.navOverrides.forEach(overrideConfig => {
          const hrefConfig = {
            ...defaultHrefConfig,
            ...overrideConfig.hrefConfig
          };

          const nodeToOverride = newNavState.byId[overrideConfig.navNodeId];
          const nodesToPopulate = overrideConfig.applyToChildren
            ? flatten(nodeToOverride)
            : [nodeToOverride];

          nodesToPopulate.forEach(node => {
            populateFinalHref(navConfig.appId, node, hrefConfig);
            delete unprocessedNavNodesById[node.id];
          });
        });
      }

      Object.keys(unprocessedNavNodesById).forEach(id => {
        populateFinalHref(navConfig.appId, maps.byId[id], defaultHrefConfig);
        delete unprocessedNavNodesById[id];
      });

      const newNavState: NavState = {
        siteMapNavRootNodeId,
        settingsNavRootNodeId,
        userNavRootNodeId,
        navConfig,
        rootNavNodeId: tsNavigationData.navTree.id,
        byId: maps.byId,
        nodeIdMapByAppId: maps.nodeIdMapByAppId,
        nodeIdToSubNavIdMap: maps.nodeIdToSubNavIdMap,
        selectedNavNodeId: undefined,
        subNavRootNodeId: undefined,
        selectedSubNavCategoryNodeId: undefined
      };

      const selectedNavNodeData = getSelectedNavNodeData(
        new URI().pathname(),
        newNavState
      );

      return {
        ...prevState,
        ...newNavState,
        ...selectedNavNodeData
      };
    }
    default:
      return prevState;
  }
}

function getSelectedNavNodeData(
  pathname: string,
  navState: NavState
): NavNodeSelectedData {
  const nodeIdsInThisApp = Object.keys(
    navState.nodeIdMapByAppId[navState.navConfig.appId] || {}
  );
  if (!nodeIdsInThisApp.length && console && console.error) {
    console.error(`No nav nodes exist for app id ${navState.navConfig.appId}`);
  }

  const matchedNodeId =
    nodeIdsInThisApp.find((id: string) => {
      const nodeUri = URI(navState.byId[id].finalHref);
      return nodeUri.pathname() === pathname;
    }) ||
    nodeIdsInThisApp.find((id: string) => {
      return (
        navState.byId[id].urlRegexPatterns &&
        navState.byId[id].urlRegexPatterns.some(regexString => {
          return window.location.href.match(regexString) !== null;
        })
      );
    });

  if (!matchedNodeId) {
    return {
      subNavRootNodeId: undefined,
      selectedNavNodeId: undefined,
      selectedSubNavCategoryNodeId: undefined
    };
  }

  const subNavRootNodeId = navState.nodeIdToSubNavIdMap[matchedNodeId];
  let categoryNode = navState.byId[matchedNodeId];

  // selected node should be a child of sub nav root node
  while (categoryNode.parentId && categoryNode.parentId !== subNavRootNodeId) {
    categoryNode = navState.byId[categoryNode.parentId];
  }

  return {
    subNavRootNodeId,
    selectedNavNodeId: matchedNodeId,
    selectedSubNavCategoryNodeId: categoryNode.id
  };
}

function populateFinalHref(
  appId: string,
  nodeToPopulate: NavNode,
  config: HrefConfig
) {
  /*
    If URI doesn't have a domain, that means we have a partial baseHref like
    audiences.ignitionone.com. In cases like this, URI thinks the entire string
    is the path like it's a relative url.
  */
  const isFullUrl: boolean = !!URI(nodeToPopulate.baseHref).domain();

  if (isFullUrl) {
    nodeToPopulate.finalHref = nodeToPopulate.baseHref;
    return;
  }

  const uri = URI(`//${nodeToPopulate.baseHref}`);
  uri.query(nodeToPopulate.query);

  if (appId === nodeToPopulate.appId) {
    nodeToPopulate.finalHref =
      (process.env.PUBLIC_URL || "") + uri.path() + uri.search();
  } else {
    uri
      .protocol(config.protocol)
      .subdomain(config.environmentPrefix + uri.subdomain());

    if (config.port !== undefined) {
      uri.port(config.port);
    }

    nodeToPopulate.finalHref = uri.normalize().href();
  }
}
