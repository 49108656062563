import { connect } from "react-redux";
import { State } from "../reducers";
import { getNavSettingsNavRootNode } from "../selectors/navigationSelectors";
import {
  SettingsNavFC as SettingsNavComponent,
  SettingsNavProps
} from "../components/SettingsNavComponent";

function mapStateToProps(state: State): SettingsNavProps {
  return {
    navigation:
      getNavSettingsNavRootNode(state) &&
      getNavSettingsNavRootNode(state).children
  };
}

function mapDispatchToProps() {
  return {};
}

export const SettingsNavContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsNavComponent);
