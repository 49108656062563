import { Dispatch } from "redux";
import {
  connectWithLifecycle,
  LifecycleDispatchProps
} from "react-lifecycle-component";
import { State } from "../../reducers";
import {
  closeDeleteModalAF,
  deleteCrmMappingAF
} from "../actions/deleteActions";
import {
  getDeleteAudiencesUsingMapping,
  getDeleteCrmMapping,
  getDeleteIsDeleting,
  getIsGettingAudiencesUsingMapping
} from "../selectors/crmSelectors";
import { CrmMapping } from "../../api/attributeApi";
import {
  StateProps,
  DispatchProps,
  Props,
  CrmMappingDeleteModalFC
} from "../components/CrmMappingDeleteModalComponent";

function mapStateToProps(state: State): StateProps {
  return {
    crmMapping: getDeleteCrmMapping(state),
    audiencesUsingMapping: getDeleteAudiencesUsingMapping(state),
    isGettingAudiencesUsingMapping: getIsGettingAudiencesUsingMapping(state),
    isDeleting: getDeleteIsDeleting(state)
  };
}

function mapDispatchToProps(
  dispatch: Dispatch
): DispatchProps & LifecycleDispatchProps<Props> {
  return {
    componentDidMount() {},
    closeModal() {
      dispatch(closeDeleteModalAF.create({}, {}));
    },
    onDelete(crmMapping: CrmMapping) {
      dispatch(deleteCrmMappingAF.create(crmMapping, {}));
    }
  };
}

export const CrmMappingDeleteModalContainer = connectWithLifecycle(
  mapStateToProps,
  mapDispatchToProps
)(CrmMappingDeleteModalFC);
