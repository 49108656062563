export enum ActionTypes {
  SELECT_BUSINESS_UNIT = "@@template/SELECT_BUSINESS_UNIT",
  SEARCH_BUSINESS_UNITS = "@@template/SEARCH_BUSINESS_UNITS",
  SEARCH_BUSINESS_UNITS_DEBOUNCED = "@@template/SEARCH_BUSINESS_UNITS_DEBOUNCED",
  CLEAR_BUSINESS_UNIT_SEARCH = "@@template/CLEAR_BUSINESS_UNIT_SEARCH",
  FETCH_BUSINESS_UNITS = "@@template/FETCH_BUSINESS_UNITS",
  FETCH_BUSINESS_UNITS_PENDING = "@@template/FETCH_BUSINESS_UNITS_PENDING",
  FETCH_BUSINESS_UNITS_FULFILLED = "@@template/FETCH_BUSINESS_UNITS_FULFILLED",
  FETCH_BUSINESS_UNITS_REJECTED = "@@template/FETCH_BUSINESS_UNITS_REJECTED",
  UPDATE_RECENT_BUSINESS_UNITS_PENDING = "@@template/UPDATE_RECENT_BUSINESS_UNITS_PENDING",
  UPDATE_RECENT_BUSINESS_UNITS_FULFILLED = "@@template/UPDATE_RECENT_BUSINESS_UNITS_FULFILLED",
  UPDATE_RECENT_BUSINESS_UNITS_REJECTED = "@@template/UPDATE_RECENT_BUSINESS_UNITS_REJECTED",
  SELECT_NAVIGATION_NODE = "@@template/SELECT_NAVIGATION_NODE",
  FETCH_NAVIGATION_NODES = "@@template/FETCH_NAVIGATION_NODES",
  FETCH_NAVIGATION_NODES_PENDING = "@@template/FETCH_NAVIGATION_NODES_PENDING",
  FETCH_NAVIGATION_NODES_FULFILLED = "@@template/FETCH_NAVIGATION_NODES_FULFILLED",
  FETCH_NAVIGATION_NODES_REJECTED = "@@template/FETCH_NAVIGATION_NODES_REJECTED",
  LOCATION_CHANGE = "@@router/LOCATION_CHANGE",
  PUSH = "@@template/PUSH"
}

export const MAX_RECENT_BUSINESS_UNITS = 4;
export const MIN_CHARS_FOR_SEARCH = 2;
export const SEARCH_DEBOUNCE_MS = 250;

export enum TrackedData {
  CLIENT_ID = "clientId",
  COMPANY_BUSINESS_UNIT_ID = "companyBusinessUnitId"
}
