import { LocationDescriptor } from "history";
import { isNil, isString, head, isObject } from "lodash";
import { default as URI } from "urijs";
import { TrackedData } from "../constants/templateConstants";
import { FetchBusinessUnitsFulfilledAction } from "../actions/businessUnitActions";

const getTrackedDataURI = (
  to: LocationDescriptor,
  clientId: number,
  businessUnitId: number
) => {
  let uri;

  if (isString(to)) {
    uri = URI(to);
  } else if (isObject(to) && !isNil(to.search)) {
    uri = URI(to.search);
  } else {
    uri = URI();
  }

  if (
    !uri.hasQuery(TrackedData.COMPANY_BUSINESS_UNIT_ID) &&
    !uri.hasQuery(TrackedData.CLIENT_ID) &&
    !isNil(clientId) &&
    !isNil(businessUnitId)
  ) {
    uri
      .setQuery(TrackedData.COMPANY_BUSINESS_UNIT_ID, businessUnitId.toString())
      .setQuery(TrackedData.CLIENT_ID, clientId.toString());
  }

  return uri;
};

export function getTrackedDataLocationDescriptor(
  to: LocationDescriptor,
  clientId: number,
  businessUnitId: number
): LocationDescriptor {
  const uri = getTrackedDataURI(to, clientId, businessUnitId);

  if (isString(to)) {
    return uri.href();
  }

  return {
    ...to,
    search: uri.search()
  };
}

// logic was in reducer. Selected id must be set in reducer for app to work correctly.
// separated out so that permissions can be fetched on fulfillment of bu's
export function getBuToSelectFromFetchBuFulfilledAction(
  action: FetchBusinessUnitsFulfilledAction
) {
  const trackedDataId = action.meta.trackedData.businessUnitId;
  const recentId = head(action.payload.result.recentBusinessUnits);
  let selectedId;

  if (action.payload.result.businessUnits.indexOf(trackedDataId) >= 0) {
    selectedId = trackedDataId;
  } else {
    selectedId = recentId;
  }
  return selectedId;
}
