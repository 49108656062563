import { Validator } from "./sharedInterfaces";
import { FullAttribute } from "../../api/attributeApi";

function getValidateValue(minLength: number, maxLength: number) {
  return function validateValue(
    builderId: string,
    newValue: string,
    oldValue: string,
    operatorId: string,
    attribute: FullAttribute
  ): boolean {
    if (newValue === null || newValue === undefined) {
      return false;
    }

    if (newValue.length > maxLength) {
      return false;
    }

    if (newValue.length < minLength) {
      return false;
    }

    return true;
  };
}

export function createStringValidator(
  minLength?: number,
  maxLength?: number
): Validator {
  return {
    validateValue: getValidateValue(
      minLength ? minLength : 0,
      maxLength ? maxLength : 300
    )
  };
}
