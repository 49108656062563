import * as React from "react";
import { WrappedFieldProps } from "redux-form";
import { InputHTMLAttributes } from "react";
import { Label, FormGroup } from "reactstrap";
import { Labels } from "../../../components/LabelsInput";

export interface FormGroupLabelsInputFieldProps
  extends DefaultProps,
    WrappedFieldProps,
    InputHTMLAttributes<HTMLInputElement>,
    LabelsInputFieldProps {
  id: string;
}

interface DefaultProps {
  label?: string;
  "data-qa-id"?: string;
}

export interface LabelsInputFieldProps {
  disabled?: boolean;
  suggestions: string[];
}

export class FormGroupLabelsInputField extends React.Component<
  FormGroupLabelsInputFieldProps
> {
  render() {
    const { label, ...props } = this.props;

    return (
      <FormGroup data-qa-id={props["data-qa-id"]}>
        {label && (
          <Label htmlFor={props.id}>
            {label}
            {props.required && <span> *</span>}
          </Label>
        )}
        <Labels
          disabled={props.disabled}
          labels={props.input.value}
          onChange={props.input.onChange}
          suggestions={props.suggestions}
        />
      </FormGroup>
    );
  }
}
