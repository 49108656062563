import { createSelector } from "reselect";
import { drill, getMaxDepth } from "../../lib/treeLib";
import { getAttributeById } from "./audienceSelectors";
import { WP } from "../constants/attributeSourceTypeConstants";
import { SCORE_CATEGORIES } from "../constants/attributeIdConstants";
import { State } from "../reducers";
import { BuilderState } from "../reducers/conditionBuilderReducer";
import { Attribute } from "../api/attributeApi";

export function getConditionBuilder(
  state: State,
  builderId: string
): BuilderState {
  return state.conditionBuilder ? state.conditionBuilder[builderId] : undefined;
}

export const getConditionBuilderValue = createSelector(
  getConditionBuilder,
  conditionBuilder => {
    return conditionBuilder && conditionBuilder.condition
      ? conditionBuilder.condition.value
      : undefined;
  }
);

export const getConditionBuilderOperatorId = createSelector(
  getConditionBuilder,
  conditionBuilder => {
    return conditionBuilder && conditionBuilder.condition
      ? conditionBuilder.condition.operatorId
      : undefined;
  }
);

export const getConditionBuilderSubsetValue = createSelector(
  getConditionBuilder,
  conditionBuilder => {
    return conditionBuilder && conditionBuilder.condition
      ? conditionBuilder.condition.subsetValue
      : undefined;
  }
);

export const getConditionBuilderSubsetOperatorId = createSelector(
  getConditionBuilder,
  conditionBuilder => {
    return conditionBuilder && conditionBuilder.condition
      ? conditionBuilder.condition.subsetOperatorId
      : undefined;
  }
);

export const getConditionBuilderMeta = createSelector(
  getConditionBuilder,
  conditionBuilder => {
    return conditionBuilder ? conditionBuilder.meta : undefined;
  }
);

export const getConditionBuilderAttributeLevel = createSelector(
  getConditionBuilderMeta,
  meta => {
    return meta ? meta.level : undefined;
  }
);

export const getConditionBuilderMode = createSelector(
  getConditionBuilder,
  conditionBuilder => {
    return conditionBuilder ? conditionBuilder.mode : undefined;
  }
);

export const getConditionBuilderConditionId = createSelector(
  getConditionBuilder,
  conditionBuilder => {
    return conditionBuilder && conditionBuilder.conditionId
      ? conditionBuilder.conditionId
      : undefined;
  }
);

export function getScoreCategoryAttribute(state: State) {
  return getAttributeById(state, SCORE_CATEGORIES);
}

export const getScoreCategoriesMaxDepth = createSelector(
  getScoreCategoryAttribute,
  (scoreCategory: Attribute) => {
    return getMaxDepth(scoreCategory);
  }
);

export const getScoreCategoriesDepthLevels = createSelector(
  getScoreCategoriesMaxDepth,
  (maxDepth: number) => {
    if (maxDepth >= 0) {
      const levels: number[] = [];

      // tslint:disable-next-line:no-increment-decrement
      for (let i = 1; i <= maxDepth; i++) {
        levels.push(i);
      }

      return levels;
    }

    return [];
  }
);

export const getConditionBuilderWpScoreCategoriesByLevel = createSelector(
  getScoreCategoryAttribute,
  getConditionBuilderAttributeLevel,
  (scoreCategory: Attribute, level: number) => {
    if (!scoreCategory || level === undefined || Number.isNaN(level)) {
      return [];
    }

    const nodes = drill(scoreCategory, level);

    if (nodes && nodes.length) {
      return nodes.filter(
        node => (node.is_trait || node.is_category) && node.source_type === WP
      );
    }

    return [];
  }
);
