import { Action } from "redux";
import {
  closeDeleteModalAF,
  deleteCrmMappingAF,
  openDeleteModalAF
} from "../actions/deleteActions";
import { Audience } from "../../models/audienceModels";
import { CrmMapping } from "../../api/attributeApi";
import { getAudiencesUsingAttributeAF } from "../../actions/audienceActions";

export interface State {
  showModal: boolean;
  crmMapping: CrmMapping;
  audiencesUsingMapping: Audience[];
  isGettingAudiencesUsingMapping: boolean;
  isDeleting: boolean;
}

export const crmDeleteInitialState: State = {
  showModal: false,
  crmMapping: undefined,
  audiencesUsingMapping: undefined,
  isGettingAudiencesUsingMapping: false,
  isDeleting: false
};

export function deleteReducer(
  previousState: State = crmDeleteInitialState,
  action: Action
): State {
  if (openDeleteModalAF.isAction(action)) {
    return {
      ...previousState,
      showModal: true,
      crmMapping: action.payload
    };
  }
  if (getAudiencesUsingAttributeAF.pendingAF.isAction(action)) {
    return {
      ...previousState,
      isGettingAudiencesUsingMapping: true
    };
  }
  if (getAudiencesUsingAttributeAF.fulfilledAF.isAction(action)) {
    return {
      ...previousState,
      isGettingAudiencesUsingMapping: false,
      audiencesUsingMapping: action.payload
    };
  }
  if (getAudiencesUsingAttributeAF.rejectedAF.isAction(action)) {
    return {
      ...previousState,
      isGettingAudiencesUsingMapping: false,
      audiencesUsingMapping: undefined
    };
  }
  if (closeDeleteModalAF.isAction(action)) {
    return crmDeleteInitialState;
  }
  if (deleteCrmMappingAF.pendingAF.isAction(action)) {
    return {
      ...previousState,
      isDeleting: true
    };
  }
  if (deleteCrmMappingAF.fulfilledAF.isAction(action)) {
    return crmDeleteInitialState;
  }
  if (deleteCrmMappingAF.rejectedAF.isAction(action)) {
    return {
      ...previousState,
      isDeleting: false
    };
  }
  return previousState;
}
