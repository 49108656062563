import { AF, AsyncAF } from "../../../lib/actionFactoryLib";
import { ExternalSegment } from "../../api/attributeApi";

export const openDeleteModalAF = new AF<ExternalSegment>(
  "EXTERNAL_SEGMENT_OPEN_DELETE_MODAL"
);

export const closeDeleteModalAF = new AF<{}>(
  "EXTERNAL_SEGMENT_CLOSE_DELETE_MODAL"
);

export const deleteExternalSegmentAF = new AsyncAF<ExternalSegment, {}>(
  "EXTERNAL_SEGMENT_DELETE_MODAL"
);
