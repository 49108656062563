import { connect } from "react-redux";
import { Dispatch } from "redux";
import { State } from "../reducers";
import {
  ErrorMonitorFC,
  StateProps,
  DispatchProps
} from "../components/ErrorMonitorComponent";
import { hideErrorActionFactory } from "../actions/errorMonitorActions";

function mapStateToProps(state: State): StateProps {
  const errorLogs = Object.keys(state.errorMonitor.visibleIdMap).map(id => {
    return state.errorMonitor.byId[id];
  });
  return {
    errorLogs
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    onHideError(errorId: string) {
      dispatch(hideErrorActionFactory.create({ errorId }, {}));
    }
  };
}

export const ErrorMonitorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorMonitorFC);
