import {
  getPermissions,
  getPermissionsPending,
  GetPermissionsAction,
  getPermissionsFulfilled,
  getPermissionsRejected
} from "../actions/permissionActions";
import {
  authenticatePending,
  authenticateFulfilled,
  authenticateRejected
} from "../actions/authActions";
import { authenticateUser, getPermissionsForEntityId } from "../api/authApi";
import { ActionTypes } from "../constants/authConstants";
import { getUserId } from "../selectors/userSelectors";
import { getTrackedPermissionIds } from "../selectors/permissionSelectors";
import { Action } from "../../lib/reduxLib";
import {
  FetchBusinessUnitsFulfilledAction,
  SelectBusinessUnitAction
} from "../../template/actions/businessUnitActions";
import { getBuToSelectFromFetchBuFulfilledAction } from "../../template/lib/templateLib";
import { ActionTypes as TemplateActionTypes } from "../../template/constants/templateConstants";
import { combineEpics, Epic, ofType } from "redux-observable";
import { concat, of } from "rxjs";
import { State } from "../reducers";
import { catchError, flatMap, map } from "rxjs/operators";

export const onAuthenticateEpic: Epic<Action, Action, State> = (
  action$,
  state$
) => {
  return action$.pipe(
    ofType(ActionTypes.AUTHENTICATE),
    flatMap(action => {
      return concat(
        of(authenticatePending()),
        authenticateUser().pipe(
          map(user => {
            return authenticateFulfilled(user);
          }),
          catchError(error => {
            return of(authenticateRejected(error));
          })
        )
      );
    })
  );
};

export const onFetchPermissionsEpic: Epic<Action, Action, State> = (
  action$,
  state$
) => {
  return action$.pipe(
    ofType<Action, GetPermissionsAction>(ActionTypes.GET_PERMISSIONS),
    flatMap(action => {
      const userId = getUserId(state$.value);
      return concat(
        of(
          getPermissionsPending(
            action.payload.permissionIds,
            action.payload.entityId
          )
        ),
        getPermissionsForEntityId(
          action.payload.entityId,
          action.payload.permissionIds,
          userId
        ).pipe(
          map(entityMap => {
            return getPermissionsFulfilled(
              entityMap,
              action.payload.permissionIds,
              action.payload.entityId
            );
          }),
          catchError(error => {
            return of(
              getPermissionsRejected(
                error,
                action.payload.permissionIds,
                action.payload.entityId
              )
            );
          })
        )
      );
    })
  );
};

export const onSelectBusinessUnitEpic: Epic<Action, Action, State> = (
  action$,
  state$
) => {
  return action$.pipe(
    ofType<Action, SelectBusinessUnitAction>(
      TemplateActionTypes.SELECT_BUSINESS_UNIT
    ),
    map(action => {
      const trackedPermissionIds: number[] = getTrackedPermissionIds(
        state$.value
      );
      return getPermissions(trackedPermissionIds, action.payload.id);
    })
  );
};

export const onFetchBusinessUnitsEpic: Epic<Action, Action, State> = (
  action$,
  state$
) => {
  return action$.pipe(
    ofType<Action, FetchBusinessUnitsFulfilledAction>(
      TemplateActionTypes.FETCH_BUSINESS_UNITS_FULFILLED
    ),
    map(action => {
      const trackedPermissionIds: number[] = getTrackedPermissionIds(
        state$.value
      );
      return getPermissions(
        trackedPermissionIds,
        getBuToSelectFromFetchBuFulfilledAction(action)
      );
    })
  );
};

export const epics = combineEpics(
  onSelectBusinessUnitEpic,
  onAuthenticateEpic,
  onFetchBusinessUnitsEpic,
  onFetchPermissionsEpic
);
