import { AF, AsyncAF } from "./actionFactoryLib";
import { ActionP, ActionPM } from "./reduxLib";
import { AppContext, getAppContext } from "../ac/selectors/appContextSelectors";
import { concat, EMPTY, Observable, of, UnaryFunction } from "rxjs";
import {
  ActionsObservable,
  combineEpics,
  Epic,
  ofType as ofTypeRx,
  StateObservable
} from "redux-observable";
import { Action } from "redux";
import { State } from "../template/reducers";
import { catchError, flatMap, map } from "rxjs/operators";

interface ObservableFactory<S extends State, A, R> {
  (
    action: A,
    context: AppContext,
    state: S,
    action$: Observable<Action>
  ): Observable<R>;
}

export function ofType<P, M>(
  actionFactory: AF<P, M>
): UnaryFunction<Observable<Action>, Observable<ActionPM<P, M>>> {
  return (source: Observable<Action>) => {
    return source.pipe(
      ofTypeRx<Action, ReturnType<typeof actionFactory.create>>(
        actionFactory.type
      )
    );
  };
}

export const emptyActionsObservableFactory: ObservableFactory<
  any,
  Action,
  Action
> = () => {
  return EMPTY;
};

export interface EpicMap<State> {
  [epicName: string]: Epic<Action, Action, State>;
}

export function combineEpicMap<State>(
  epicMap: EpicMap<State>
): Epic<Action, Action, State> {
  return combineEpics(
    ...Object.keys(epicMap).map(epicName => {
      return epicMap[epicName];
    })
  );
}

// exists mainly for typing
export function createEpic<State>(
  epic: Epic<Action, Action, State>
): Epic<Action, Action, State> {
  return epic;
}

export function createAsyncEpic<S extends State, BP, FP>(
  actionFactory: AsyncAF<BP, FP>,
  fulfilledPayloadFactoryFn: ObservableFactory<S, ActionP<BP>, FP>
) {
  return createEmittingAsyncEpic<S, BP, FP>(
    actionFactory,
    emptyActionsObservableFactory,
    fulfilledPayloadFactoryFn
  );
}

export function createEmittingAsyncEpic<S extends State, BP, FP>(
  actionFactory: AsyncAF<BP, FP>,
  actionsObservableFactoryFn: ObservableFactory<S, ActionP<BP>, Action>,
  fulfilledPayloadFactoryFn: ObservableFactory<S, ActionP<BP>, FP>
) {
  return function(
    action$: ActionsObservable<Action>,
    state$: StateObservable<S>
  ) {
    return action$.pipe(
      ofTypeRx<Action, ActionP<BP>>(actionFactory.type),
      flatMap(
        (action: ActionP<BP>): Observable<Action> => {
          const context = getAppContext(state$.value);
          const meta = {
            context,
            timestamp: Date.now(),
            baseAction: action
          };
          return concat(
            of(actionFactory.pendingAF.create({}, meta)),
            actionsObservableFactoryFn(action, context, state$.value, action$),
            fulfilledPayloadFactoryFn(
              action,
              context,
              state$.value,
              action$
            ).pipe(
              map(payload => {
                return actionFactory.fulfilledAF.create(payload, meta);
              }),
              catchError(error => {
                return of(actionFactory.rejectedAF.create(error, meta));
              })
            )
          );
        }
      )
    );
  };
}
