import { Condition } from "./conditionBuilderModels";

export enum ExpressionModes {
  ALL = "ALL",
  ANY = "ANY",
  ADVANCED = "ADVANCED"
}

export enum ExpressionOperators {
  AND = "AND",
  OR = "OR"
}

export enum ExpressionPrefixes {
  NOT = "NOT"
}

export enum ExpressionGroups {
  START = "(",
  END = ")"
}

export enum ExpressionSegmentTypes {
  OPERATOR = "OPERATOR",
  CONDITION = "CONDITION",
  GROUP_START = "GROUP_START",
  GROUP_END = "GROUP_END",
  PREFIX = "PREFIX"
}

interface Segment {
  id: string;
  type: ExpressionSegmentTypes;
}

export type ExpressionSegment =
  | OperatorSegment
  | ConditionSegment
  | GroupStartSegment
  | GroupEndSegment
  | PrefixSegment;

export interface OperatorSegment extends Segment {
  type: ExpressionSegmentTypes.OPERATOR;
  value: ExpressionOperators;
}

export interface ConditionSegment extends Segment {
  type: ExpressionSegmentTypes.CONDITION;
  value: Condition;
}

export interface GroupStartSegment extends Segment {
  type: ExpressionSegmentTypes.GROUP_START;
  value: ExpressionGroups.START;
}

export interface GroupEndSegment extends Segment {
  type: ExpressionSegmentTypes.GROUP_END;
  value: ExpressionGroups.END;
}

export interface PrefixSegment extends Segment {
  type: ExpressionSegmentTypes.PREFIX;
  value: ExpressionPrefixes;
}

// tslint:disable-next-line:max-line-length
// https://stackoverflow.com/questions/11089399/count-with-a-b-c-d-instead-of-0-1-2-3-with-javascript
// produces A, B, C.... AA, AB, AC.... etc.
const toABC = (num: number): string => {
  const mod = num % 26;
  let pow = (num / 26) | 0;

  // tslint:disable-next-line:no-increment-decrement
  const out = mod ? String.fromCharCode(64 + mod) : (pow--, "Z");

  return pow ? toABC(pow) + out : out;
};

export const reservedIds: { [id: string]: boolean } = {
  IN_LAST_DAYS: true,
  AND: true,
  OR: true,
  NOT: true,
  TRUE: true,
  FALSE: true,
  GT: true,
  GE: true,
  LT: true,
  LE: true,
  EQ: true,
  NEQ: true,
  CONTAINS: true,
  IN: true,
  DIFFERENT: true,
  EXISTS: true,
  ON_OR_BEFORE: true,
  ON_OR_AFTER: true,
  X_DAYS_OR_MORE_AGO: true,
  X_DAYS_OR_LESS_AGO: true,
  STARTS_WTIH: true,
  ENDS_WITH: true,
  LIKE: true,
  LPAREN: true,
  RPAREN: true,
  DATE: true,
  INTEGER: true,
  DECIMAL: true,
  IDENTIFIER: true,
  STRING: true,
  WS: true
};

let idCount = 0;

function uniqueId() {
  idCount += 1;
  return idCount;
}

export const idFactory = () => {
  let id: string;
  do {
    // tslint:disable-next-line:no-param-reassign
    id = toABC(+uniqueId());
  } while (reservedIds[id]);
  return id;
};

export function operatorFactory(
  operator: ExpressionOperators,
  generateId: boolean = true
): OperatorSegment {
  return {
    id: generateId ? idFactory() : undefined,
    type: ExpressionSegmentTypes.OPERATOR,
    value: operator
  };
}

export function prefixFactory(
  prefix: ExpressionPrefixes,
  generateId: boolean = true
): PrefixSegment {
  return {
    id: generateId ? idFactory() : undefined,
    type: ExpressionSegmentTypes.PREFIX,
    value: prefix
  };
}

export function getDefaultPrefix() {
  return prefixFactory(ExpressionPrefixes.NOT);
}

export function groupStartFactory(
  generateId: boolean = true
): GroupStartSegment {
  return {
    id: generateId ? idFactory() : undefined,
    type: ExpressionSegmentTypes.GROUP_START,
    value: ExpressionGroups.START
  };
}

export function groupEndFactory(generateId: boolean = true): GroupEndSegment {
  return {
    id: generateId ? idFactory() : undefined,
    type: ExpressionSegmentTypes.GROUP_END,
    value: ExpressionGroups.END
  };
}

export function conditionFactory(
  condition: Condition,
  generateId: boolean = true
): ConditionSegment {
  return {
    id: generateId ? idFactory() : undefined,
    type: ExpressionSegmentTypes.CONDITION,
    value: condition
  };
}
