import { connect } from "react-redux";
import { Dispatch } from "redux";
import { State } from "../reducers";
import {
  getNavSiteMapNavRootNode,
  getNavSubNavRootNode
} from "../selectors/navigationSelectors";
import {
  SitemapNavFC as SitemapNavComponent,
  StateProps,
  DispatchProps
} from "../components/SitemapNavComponent";

function mapStateToProps(state: State): StateProps {
  return {
    navigation:
      getNavSiteMapNavRootNode(state) &&
      getNavSiteMapNavRootNode(state).children,
    subNavRootNode: getNavSubNavRootNode(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {};
}

export const SitemapNavContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SitemapNavComponent);
