import { max } from "lodash";

function forEachNodeFunc(tree: any, func: Function, childrenProp = "children") {
  if (tree[childrenProp]) {
    tree[childrenProp].forEach((child: any) => {
      forEachNodeFunc(child, func);
    });
  }
  func(tree);
}

export const forEachNode = forEachNodeFunc;

export function findNodes(
  tree: any,
  searchFunc: Function,
  childrenProp = "children"
) {
  const foundNodes: any[] = [];
  forEachNode(
    tree,
    (node: any) => {
      return searchFunc(node) && foundNodes.push(node);
    },
    childrenProp
  );
  return foundNodes;
}

export function flattenTree<T extends Record<K, T[]>, K extends string>(
  tree: T,
  childrenProp: K
): T[] {
  return findNodes(
    tree,
    () => {
      return true;
    },
    childrenProp
  );
}

export function flatten(tree: any, childrenProp = "children") {
  return findNodes(
    tree,
    () => {
      return true;
    },
    childrenProp
  );
}

/**
 * Find all nodes in a tree at a specific depth.
 * Uses BFS so algorithm will terminate at targetted depth.
 * @param tree - tree to drill into
 * @param clevel - current level
 * @param rlevel - requested level
 * @param result - result queue
 * @param childrenProp - name of property where children are stored
 */
function drillFunc(
  tree: any,
  clevel: number,
  rlevel: number,
  result: any[],
  childrenProp = "children"
) {
  if (clevel === rlevel) {
    result.push(tree);
  } else if (tree[childrenProp]) {
    tree[childrenProp].forEach((child: any) => {
      drillFunc(child, clevel + 1, rlevel, result, childrenProp);
    });
  }
}

export function drill<T>(tree: T, depth: number, childrenProp = "children") {
  const result: T[] = [];
  drillFunc(tree, 0, depth, result, childrenProp);
  return result;
}

function depthFunc(
  tree: any,
  clevel: number,
  childrenProp = "children"
): number {
  if (tree[childrenProp] && tree[childrenProp].length) {
    const depths: any[] = tree[childrenProp].map((child: any) => {
      return depthFunc(child, clevel + 1, childrenProp);
    });

    return max(depths);
  }

  return clevel;
}

export const getMaxDepth = (tree: any, childrenProp = "children") => {
  return depthFunc(tree, 0);
};

function depthSearchFunc<T>(
  tree: T,
  clevel: number,
  predicate: (tree: T) => boolean,
  childrenGetter: (tree: T) => T[]
): number {
  if (predicate(tree)) {
    return clevel;
  }

  const children = childrenGetter(tree);

  if (children && children.length) {
    const results: number[] = children
      .map(child => {
        return depthSearchFunc(child, clevel + 1, predicate, childrenGetter);
      })
      .filter(r => {
        return r !== -1;
      });

    if (results.length) {
      return results[0];
    }
  }

  return -1;
}

/**
 * Returns depth of node that satisfies given predicate (-1 if not found)
 * @param tree - tree to search
 * @param predicate - function to test for target node
 * @param childrenProp - name of property where children are stored
 */
export function getNodeDepth<T>(
  tree: T,
  predicate: (node: T) => boolean,
  childrenGetter: (node: T) => T[]
) {
  return depthSearchFunc(tree, 0, predicate, childrenGetter);
}
