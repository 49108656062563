import { default as React } from "react";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import { WrappedFieldProps } from "redux-form";
import { AttributeValue } from "../api/attributeApi";
import { NUMBER } from "../constants/attributeDataTypeNameConstants";
import { isValidNumber } from "../epics/conditionValidators/numberValidator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export interface Props {
  dataType: string;
  list: AttributeValue[];
  onChange(list: AttributeValue[]): void;
  renderAttributeValue(value: AttributeValue): React.ReactNode;
  dataQaIdText: string;
  dataQaIdAdd: string;
  dataQaIdDelete: string;
}

export interface State {
  text: string;
  invalid: boolean;
}

function isUniqueAttributeValue(list: AttributeValue[], text: string): boolean {
  return !list.some((value: AttributeValue) => {
    return value.value === text;
  });
}

export class AttributeValueEditor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      text: "",
      invalid: true
    };

    this.onAdd = this.onAdd.bind(this);
    this.createOnRemove = this.createOnRemove.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }
  onAdd() {
    const text = this.state.text.trim();

    if (text && isUniqueAttributeValue(this.props.list, text)) {
      this.props.onChange([
        ...this.props.list,
        {
          id: 0,
          value: text,
          display_name: text
        }
      ]);
      this.setState({
        ...this.state,
        text: "",
        invalid: true
      });
    }
  }
  createOnRemove(index: number) {
    return () => {
      const newList = this.props.list.slice();

      newList.splice(index, 1);
      this.props.onChange(newList);
    };
  }
  onTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    const text = e.target.value;

    if (this.props.dataType === NUMBER) {
      if (isValidNumber(text) || !text) {
        this.setState({
          ...this.state,
          text,
          invalid: !text
        });
      }
    } else {
      this.setState({
        ...this.state,
        text,
        invalid: !text
      });
    }
  }
  onKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      e.preventDefault();
      this.onAdd();
    }
  }
  componentDidUpdate(previousProps: Props) {
    if (
      previousProps.dataType &&
      previousProps.dataType !== this.props.dataType
    ) {
      this.props.onChange([]);
    }
  }
  render() {
    return (
      <div>
        <FormGroup>
          <InputGroup>
            <Input
              type="text"
              value={this.state.text}
              placeholder="Enter a Value"
              onChange={this.onTextChange}
              onKeyPress={this.onKeyPress}
              data-qa-id={this.props.dataQaIdText}
            />
            <InputGroupAddon addonType={"append"}>
              <Button
                disabled={this.state.invalid}
                onClick={this.onAdd}
                data-qa-id={this.props.dataQaIdAdd}
              >
                Add
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
        <div>
          {this.props.list.map((value: AttributeValue, index: number) => {
            return (
              <Button
                key={index}
                className={"mr-2 mb-2"}
                onClick={this.createOnRemove(index)}
                color="danger"
                data-qa-id={`${this.props.dataQaIdDelete}-${index}`}
              >
                <span
                  className={"mr-2"}
                  style={{
                    whiteSpace: "pre-wrap"
                  }}
                >
                  {this.props.renderAttributeValue(value)}
                </span>
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            );
          })}
        </div>
      </div>
    );
  }
}

export interface AttributeValueEditorFieldProps extends WrappedFieldProps {
  dataType: string;
}

export function AttributeValueEditorField(
  props: AttributeValueEditorFieldProps
) {
  return (
    <AttributeValueEditor
      dataType={props.dataType}
      list={props.input.value}
      onChange={props.input.onChange}
      dataQaIdText="crm-mapping-attribute_values-text"
      dataQaIdAdd="crm-mapping-attribute_values-add"
      dataQaIdDelete="crm-mapping-attribute_values-delete"
      renderAttributeValue={(
        attributeValue: AttributeValue
      ): React.ReactNode => {
        return attributeValue.value;
      }}
    />
  );
}
