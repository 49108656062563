import { schema } from "normalizr";

interface BaseBusinessUnit {
  id: number;
  name: string;
  path: string;
  thumbnail: string;
  dataCenterId: number;
  logo: string;
  currencyId: number;
  timeZoneId: number;
  typeId: number;
}

export interface BusinessUnit extends BaseBusinessUnit {
  client: Client;
  associatedClients: Client[];
}

export interface Client {
  id: number;
  name: string;
  parentId: number;
  levelId: number;
  typeId: number;
  statusFlag: number;
  createdDate: string;
  isAgency: boolean;
  isAgencyAffiliate: boolean;
  isDaylightSaving: boolean;
  isGoogleUser: boolean;
  isbiAccess: boolean;
  logoUrl: string;
  stylsheet: string;
  timeZoneDisplayId: number;
  utcHourDiff: number;
}

export interface BusinessUnitSelectorData {
  businessUnits: BusinessUnit[];
  recentBusinessUnits: BusinessUnit[];
}

const clientSchema = new schema.Entity("clients");
const businessUnitSchema = new schema.Entity("businessUnits", {
  client: clientSchema,
  associatedClients: [clientSchema]
});

export const businessUnitSelectorDataSchema = {
  businessUnits: [businessUnitSchema],
  recentBusinessUnits: [businessUnitSchema]
};

export interface NormalizedBusinessUnit extends BaseBusinessUnit {
  client: number;
  associatedClients: number[];
}

export interface NormalizedBusinessUnitSelectorData {
  result: {
    businessUnits: number[];
    recentBusinessUnits: number[];
  };
  entities: {
    businessUnits: {
      [businessUnitId: number]: NormalizedBusinessUnit;
    };
    clients: {
      [clientId: number]: Client;
    };
  };
}
