import { Action } from "redux";
import { ActionP, ActionPM } from "./reduxLib";
import { AppContext } from "../ac/selectors/appContextSelectors";

export interface BuMeta {
  businessUnitId: number;
}

export interface Meta {
  timestamp: number;
  context: AppContext;
}

interface RichMeta<A extends Action = undefined> extends Meta {
  baseAction: A;
}

export const pendingPostfix = "_PENDING";
export const fulfilledPostfix = "_FULFILLED";
export const rejectedPostfix = "_REJECTED";

export class AF<Payload = {}, Meta = {}> {
  type: string;
  constructor(type: string) {
    this.type = type;
  }
  create(payload: Payload, meta: Meta): ActionPM<Payload, Meta> {
    return {
      payload,
      meta,
      type: this.type
    };
  }
  isAction(action: Action): action is ActionPM<Payload, Meta> {
    return action.type === this.type;
  }
}

export class AsyncAF<BasePayload, FulfilledPayload> extends AF<
  BasePayload,
  {}
> {
  pendingAF: AF<{}, RichMeta<ActionP<BasePayload>>>;
  fulfilledAF: AF<FulfilledPayload, RichMeta<ActionP<BasePayload>>>;
  rejectedAF: AF<any, RichMeta<ActionP<BasePayload>>>;
  constructor(type: string) {
    super(type);
    this.pendingAF = new AF<{}, RichMeta<ActionP<BasePayload>>>(
      `${type}${pendingPostfix}`
    );
    this.fulfilledAF = new AF<FulfilledPayload, RichMeta<ActionP<BasePayload>>>(
      `${type}${fulfilledPostfix}`
    );
    this.rejectedAF = new AF<any, RichMeta<ActionP<BasePayload>>>(
      `${type}${rejectedPostfix}`
    );
  }
}
