import { default as React } from "react";
import styles from "./FooterComponent.module.scss";

export const FooterFC: React.FC = () => {
  return (
    <footer className={styles.footer}>
      <span>
        © {new Date().getFullYear()} IgnitionOne, Inc. All Rights Reserved |
        Customer Support: 866-847-3286
      </span>
    </footer>
  );
};
