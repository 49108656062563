import { epics as businessUnitEpics } from "./businessUnitEpics";
import { epics as trackedDataEpics } from "./trackedDataEpics";
import { epics as navigationEpics } from "./navigationEpics";
import { combineEpics } from "redux-observable";

export const epics = combineEpics(
  businessUnitEpics,
  trackedDataEpics,
  navigationEpics
);
