import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  BusinessUnitSelectorPureComponent as BusinessUnitSelectorComponent,
  StateProps,
  DispatchProps
} from "../components/BusinessUnitSelectorComponent";
import { NormalizedBusinessUnit } from "../models/businessUnitModels";
import { State } from "../reducers";
import {
  getBusinessUnitSearchResults,
  getRecentBusinessUnits,
  getSelectedBusinessUnit,
  getBusinessUnitSearchQuery,
  getBusinessUnitSearchQueryDebounced,
  getBusinessUnitsPending,
  getBusinessUnitsError
} from "../selectors/businessUnitSelectors";
import {
  selectBusinessUnit,
  searchBusinessUnits,
  clearBusinessUnitSearch,
  fetchBusinessUnits
} from "../actions/businessUnitActions";

function mapStateToProps(state: State): StateProps {
  return {
    businessUnits: getBusinessUnitSearchResults(state),
    recentBusinessUnits: getRecentBusinessUnits(state),
    selectedBusinessUnit: getSelectedBusinessUnit(state),
    query: getBusinessUnitSearchQuery(state),
    query_debounced: getBusinessUnitSearchQueryDebounced(state),
    pending: getBusinessUnitsPending(state),
    error: getBusinessUnitsError(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    onBusinessUnitSelect(businessUnit: NormalizedBusinessUnit) {
      dispatch(selectBusinessUnit(businessUnit.id));
    },
    onBusinessUnitSearch(query: string) {
      dispatch(searchBusinessUnits(query));
    },
    onClearBusinessUnitSearch() {
      dispatch(clearBusinessUnitSearch());
    },
    onInit() {
      dispatch(fetchBusinessUnits());
    }
  };
}

export const BusinessUnitSelectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessUnitSelectorComponent);
