import { default as React } from "react";
import { NavNode } from "../models/navigationModels";
import { LinkContainer } from "../containers/LinkContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import styles from "./SubNavComponent.module.scss";
import classNames from "classnames";

export interface SubNavProps {
  navigation: NavNode[];
  selectedNavNodeId: string;
  selectedSubNavCategoryNodeId: string;
}

export const SubNavFC: React.FC<SubNavProps> = props => {
  return (
    <div className={styles.subnav}>
      {props.navigation &&
        props.navigation.map((i: NavNode) => {
          const hasChildren = i.children && i.children.length > 0;
          return (
            <div key={i.id}>
              {i.visible && (
                <div className={classNames(hasChildren && styles.subnavParent)}>
                  <LinkContainer
                    key={i.id}
                    to={i.finalHref}
                    target={i.target}
                    data-qa-id={`nav-${i.id}`}
                    className={classNames(
                      styles.subnavLink,
                      (i.id === props.selectedNavNodeId ||
                        i.id === props.selectedSubNavCategoryNodeId) &&
                        styles.subnavActive,
                      !i.enabled && styles.navItemDisabled
                    )}
                  >
                    {i.name}
                    {hasChildren && (
                      <FontAwesomeIcon
                        className={styles.subnavParentIcon}
                        icon={faCaretDown}
                      />
                    )}
                  </LinkContainer>
                  {hasChildren && (
                    <div className={styles.subnavChildren}>
                      {i.children.map((c: NavNode) => (
                        <span key={c.id}>
                          {c.visible && (
                            <LinkContainer
                              key={c.id}
                              to={c.finalHref}
                              target={c.target}
                              data-qa-id={`nav-${c.id}`}
                              className={classNames(
                                styles.subnavChildLink,
                                !c.enabled && styles.navItemDisabled,
                                c.id === props.selectedNavNodeId &&
                                  styles.subnavChildLinkSelected
                              )}
                            >
                              {c.name}
                            </LinkContainer>
                          )}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
};

SubNavFC.defaultProps = {
  selectedNavNodeId: undefined as string,
  selectedSubNavCategoryNodeId: undefined as string
};
