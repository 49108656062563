import { default as React } from "react";
import { AttributeOperator } from "../api/attributeApi";

interface TopInterestProps {
  attributeOperator: AttributeOperator;
  attributeValue: string;
}

export class TopInterestComponent extends React.PureComponent<
  TopInterestProps
> {
  render() {
    return (
      <span>
        <span>
          {`${this.props.attributeValue} ${this.props.attributeOperator.name} `}
        </span>
      </span>
    );
  }
}
