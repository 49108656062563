import { default as React } from "react";
import { HeaderActionMenuFC } from "./HeaderActionMenuComponent";
import { NavNode } from "../models/navigationModels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";

export interface SettingsNavProps {
  navigation: NavNode[];
}

export const SettingsNavFC: React.FC<SettingsNavProps> = props => {
  return (
    <HeaderActionMenuFC
      id="template-settings-nav"
      toggle={() => {
        return (
          <FontAwesomeIcon icon={faCog} size={"lg"} className="text-white" />
        );
      }}
      navigation={props.navigation}
      divider={true}
    />
  );
};
