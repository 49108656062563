import {
  GetPermissionsPendingAction,
  GetPermissionsFulfilledAction,
  GetPermissionsRejectedAction,
  ConfigurePermissionsAction
} from "../actions/permissionActions";
import { ActionTypes } from "../constants/authConstants";

type PermissionsAction =
  | GetPermissionsPendingAction
  | GetPermissionsFulfilledAction
  | GetPermissionsRejectedAction
  | ConfigurePermissionsAction;

export interface PermissionState {
  enabled: boolean;
  pending: boolean;
}

export interface PermissionsState {
  readonly settings: {
    readonly trackedPermissionIds?: number[];
  };
  readonly byEntityId: {
    readonly [entityId: number]: {
      readonly [permissionId: number]: PermissionState;
    };
  };
}

export const INITIAL_STATE: PermissionsState = {
  settings: {
    trackedPermissionIds: []
  },
  byEntityId: {}
};

export function permissionsReducer(
  prevState: PermissionsState = INITIAL_STATE,
  action: PermissionsAction
): PermissionsState {
  switch (action.type) {
    case ActionTypes.CONFIGURE_PERMISSIONS:
      return {
        ...prevState,
        settings: {
          ...prevState.settings,
          trackedPermissionIds: action.payload
        }
      };
    case ActionTypes.GET_PERMISSIONS_PENDING:
      return {
        ...prevState,
        byEntityId: {
          ...prevState.byEntityId,
          [action.meta.entityId]: {
            ...prevState.byEntityId[action.meta.entityId],
            ...action.meta.permissionIds.reduce(
              (idMap: { [permId: number]: PermissionState }, id) => {
                idMap[id] = { enabled: false, pending: true };
                return idMap;
              },
              {}
            )
          }
        }
      };
    case ActionTypes.GET_PERMISSIONS_REJECTED:
      return {
        ...prevState,
        byEntityId: {
          ...prevState.byEntityId,
          [action.meta.entityId]: {
            ...prevState.byEntityId[action.meta.entityId],
            ...action.meta.permissionIds.reduce(
              (idMap: { [permId: number]: PermissionState }, id) => {
                idMap[id] = { enabled: false, pending: false };
                return idMap;
              },
              {}
            )
          }
        }
      };
    case ActionTypes.GET_PERMISSIONS_FULFILLED:
      const fulfilledAction = action as GetPermissionsFulfilledAction;

      const defaultPermissionMap = fulfilledAction.meta.permissionIds.reduce(
        (idMap: { [permId: number]: PermissionState }, id) => {
          idMap[id] = { enabled: false, pending: false };
          return idMap;
        },
        {}
      );

      const fulfilledPermissions = fulfilledAction.payload[action.meta.entityId]
        ? fulfilledAction.payload[action.meta.entityId].permissions
        : {};

      const fulfilledPermissionMap = Object.keys(fulfilledPermissions)
        .map(key => {
          return parseInt(key, 10);
        })
        .reduce((idMap: { [permId: number]: PermissionState }, id) => {
          idMap[id] = { enabled: true, pending: false };
          return idMap;
        }, {});

      const finalPermissionMap = {
        ...defaultPermissionMap, // marks all permissions we asked for as no longer pending
        ...fulfilledPermissionMap // sets enabled to true for the ones we got back
      };

      return {
        ...prevState,
        byEntityId: {
          ...prevState.byEntityId,
          [action.meta.entityId]: {
            ...prevState.byEntityId[action.meta.entityId],
            ...finalPermissionMap
          }
        }
      };
    default:
      return prevState;
  }
}
