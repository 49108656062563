import { CrmConfig } from "../../api/attributeApi";
import { Action } from "../../../lib/reduxLib";
import { readCrmConfigAF, updateCrmConfigAF } from "../actions/configActions";

export interface State {
  byBusinessUnitId: Record<string, CrmConfig>;
}

const initialState: State = {
  byBusinessUnitId: {}
};

export function configReducer(prevState: State = initialState, action: Action) {
  if (
    readCrmConfigAF.fulfilledAF.isAction(action) ||
    updateCrmConfigAF.fulfilledAF.isAction(action)
  ) {
    return {
      ...prevState,
      byBusinessUnitId: {
        ...prevState.byBusinessUnitId,
        [action.meta.context.businessUnit.id]: {
          ...action.payload
        }
      }
    };
  }
  return prevState;
}
