import { default as React } from "react";
import {
  Modal,
  Button,
  FormGroup,
  Label,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { InjectedFormProps, Field, Form } from "redux-form";
import {
  crmProps,
  attributeProps,
  PartialCrmMapping,
  CrmMapping
} from "../../api/attributeApi";
import { maxLength, required } from "../../../lib/validationLib";
import { AttributeValueEditorField } from "../../components/AttributeValueEditor";
import { DefinedValuesTooltipFC } from "../../components/DefinedValuesTooltip";
import {
  DATA_TYPES,
  INVALID_VALUE_TYPES
} from "../../constants/attributeDataTypeNameConstants";
import { FormGroupInputField } from "../../forms/components/FormGroupInputField/FormGroupInputField";
import classNames from "classnames";

const maxLength100 = maxLength(100);

export interface InjectedProps
  extends Props,
    InjectedFormProps<PartialCrmMapping, Props, string> {}

export interface Props extends StateProps, DispatchProps {}

export interface StateProps {
  isFormSubmit: boolean;
  dataType: string;
  initialValues: PartialCrmMapping;
  allMappings: CrmMapping[];
}

export interface DispatchProps {
  closeModal(): void;
}

function columnNameIsUnique(
  value: string,
  allValues: PartialCrmMapping,
  props: Props
): string | undefined {
  return props.allMappings.find(mapping => mapping.crm_column === value)
    ? "Mapping with this column name already exists"
    : undefined;
}

function attributeNameIsUnique(
  value: string,
  allValues: PartialCrmMapping,
  props: Props
): string | undefined {
  return props.allMappings.find(mapping => mapping.attribute.name === value)
    ? "Mapping with this trait name already exists"
    : undefined;
}

export const CrmMappingCreateModalFC: React.FC<InjectedProps> = props => {
  return (
    <Modal isOpen={true} toggle={props.closeModal}>
      <Form onSubmit={props.handleSubmit}>
        <ModalHeader toggle={props.closeModal}>Create CRM Mapping</ModalHeader>
        <ModalBody>
          <Field
            id="create_crm_column_name"
            label="CRM Column Name"
            required
            name={crmProps.crm_column}
            component={FormGroupInputField}
            type="text"
            validate={[required, maxLength100, columnNameIsUnique]}
            data-qa-id="crm-mapping-column-name-field"
            placeholder="Enter Text"
          />
          <Field
            id={"create_crm_trait_name"}
            label="Trait Name"
            name={`${crmProps.attribute}.${attributeProps.name}`}
            component={FormGroupInputField}
            type="text"
            validate={[required, maxLength100, attributeNameIsUnique]}
            props={{} as any}
            data-qa-id="crm-mapping-attribute-name-field"
            placeholder="Enter Text"
            required
          />
          <Field
            id={"create_crm_date_type_name"}
            label="Data Type"
            name={`${crmProps.attribute}.${attributeProps.data_type_name}`}
            type="select"
            component={FormGroupInputField}
            data-qa-id="crm-mapping-data-type-name-field"
            className={"text-capitalize"}
          >
            {DATA_TYPES.map((type: string) => {
              return (
                <option key={type} value={type}>
                  {type}
                </option>
              );
            })}
          </Field>
          <FormGroup
            className={classNames("mb-0", {
              "d-none": INVALID_VALUE_TYPES.indexOf(props.dataType) !== -1
            })}
          >
            <Label for={"create-defined-values"}>
              Defined Values&nbsp;
              <DefinedValuesTooltipFC
                tooltipId={"create_defined_values_tooltip"}
              />
            </Label>
            <Field
              id={"create-defined-values"}
              name={`${crmProps.attribute}.${attributeProps.attribute_values}`}
              dataType={props.dataType}
              component={AttributeValueEditorField}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="link"
            onClick={props.closeModal}
            disabled={props.isFormSubmit}
          >
            Cancel
          </Button>
          <Button
            color={"primary"}
            disabled={props.invalid || props.isFormSubmit}
            onClick={props.handleSubmit}
            data-qa-id="crm-mapping-create-submit"
          >
            Submit
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
