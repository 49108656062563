import { default as React } from "react";
import { DragSource, DragSourceMonitor, ConnectDragSource } from "react-dnd";
import { TRAIT } from "../constants/dndtypeConstants";
import { IDragSourceConnector } from "../types/react-dnd";
import { Attribute } from "../api/attributeApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import { ENGAGEMENT_SCORE } from "../constants/attributeIdConstants";
import classNames from "classnames";

export interface AttributeProps {
  attribute: Attribute;
  connectDragSource?: ConnectDragSource;
  handleSelectAttribute(attribute: Attribute): void;
  handleNavigateAttribute?(attribute: Attribute): void;
}

export const qaIds = {
  categoryIcon: "category-icon",
  attributeSelectButton: "attribute-select-button"
};

export const AttributeFC: React.FC<AttributeProps> = props => {
  const disabled = props.attribute.id === ENGAGEMENT_SCORE;
  const element = (
    <li
      id={`attribute-${props.attribute.id}`}
      data-qa-id="attribute"
      className={classNames(
        "list-group-item d-flex flex-shrink-0 justify-content-between align-items-center",
        {
          "c-pointer":
            props.attribute.is_category && props.handleNavigateAttribute,
          "c-move": !disabled && props.attribute.is_trait,
          "text-muted": disabled
        }
      )}
      onClick={() => {
        if (
          !disabled &&
          props.attribute.is_category &&
          props.handleNavigateAttribute
        ) {
          props.handleNavigateAttribute(props.attribute);
        }
      }}
    >
      <div className={"d-flex align-items-center"}>
        {props.attribute.is_category && (
          <FontAwesomeIcon
            icon={faFolder}
            className={"mr-3"}
            data-qa-id={qaIds.categoryIcon}
          />
        )}
        <div className={"text-break"}>{props.children}</div>
      </div>
      {props.attribute.is_trait && (
        <Button
          size={"xs"}
          data-qa-id={qaIds.attributeSelectButton}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            props.handleSelectAttribute(props.attribute);
          }}
          disabled={disabled}
          className={"ml-3"}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      )}
    </li>
  );

  return !disabled && props.attribute.is_trait && props.connectDragSource
    ? props.connectDragSource(element)
    : element;
};

const spec = {
  beginDrag(props: AttributeProps) {
    return props.attribute;
  }
};

function collect(connect: IDragSourceConnector, monitor: DragSourceMonitor) {
  return {
    connectDragSource: connect.dragSource("copy"),
    isDragging: monitor.isDragging()
  };
}

export const AttributeDragSource = DragSource(
  TRAIT,
  spec,
  collect
)(AttributeFC);
