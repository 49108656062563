import { env } from "../env/appEnv";
import { handleResponse } from "../../lib/httpLib";
import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";

const searchUrl = `${env.REACT_APP_GEO_SERVICE_API_URL}/search`;

export enum GEO_TYPE {
  CITY = "CITY",
  REGION = "REGION",
  COUNTRY = "COUNTRY"
}

export const LIMIT = 500;

export const GEO_TYPE_TO_RESOURCE_MAP: { [type: string]: string } = {
  [GEO_TYPE.CITY]: "cities",
  [GEO_TYPE.REGION]: "regions",
  [GEO_TYPE.COUNTRY]: "countries"
};

export interface GeoNode {
  id: string;
  name: string;
  parentId: string;
  parentType: GEO_TYPE;
}

export interface SearchResults {
  metadata: {
    totalFound: number;
    limit: number;
    type: string;
  };
  results: {
    [id: string]: {
      [id: string]: GeoNode;
    };
  };
}

export function search(
  searchTerm: string,
  type: GEO_TYPE
): Observable<SearchResults> {
  return handleResponse(
    ajax({
      url: `${searchUrl}?searchText=${searchTerm}&type=${type}&limit=${LIMIT}`,
      method: "GET",
      crossDomain: true
    })
  );
}

export interface GeoOption {
  id: string;
  name: string;
  displayName: string;
}

export function createOptionFromNode(
  node: GeoNode,
  searchResults: SearchResults
): GeoOption {
  const parentNodeName =
    node.parentType && node.parentId
      ? searchResults.results[node.parentType][node.parentId].name
      : undefined;
  return {
    id: node.id,
    name: node.name,
    displayName: (parentNodeName ? `${parentNodeName}, ` : "") + node.name
  };
}

export function getOne(id: string, type: GEO_TYPE): Observable<SearchResults> {
  return handleResponse(
    ajax({
      url: `${env.REACT_APP_GEO_SERVICE_API_URL}/${
        GEO_TYPE_TO_RESOURCE_MAP[type]
      }/${id}`,
      method: "GET",
      crossDomain: true
    })
  );
}
