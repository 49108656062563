import { default as React } from "react";
import { RecencyBuilder } from "./RecencyBuilder";
import { PVariableBuilder } from "./PVariableBuilder";
import { CustomInput, Input, ListGroup, ListGroupItem, Row } from "reactstrap";
import {
  baseStyles,
  qaIds,
  baseDefaultProps,
  ConditionBuilderBaseProps
} from "../SharedData";
import { AttributeName } from "../../AttributeName";
import { CONTAINS, ON_OR_AFTER } from "../../../constants/operatorConstants";
import { DATE_FORMAT } from "../../../constants/attributeDataTypeNameConstants";
import { default as moment } from "moment";

export enum SessionVariableLocationType {
  DataCollection = "DataCollection",
  Conversion = "conversion"
}

interface PVariableLocationBuilderState {
  value: SessionVariableLocationType;
}

export interface SessionVariableBuilderProps
  extends ConditionBuilderBaseProps {}

export class SessionVariableBuilder extends React.Component<
  SessionVariableBuilderProps,
  PVariableLocationBuilderState
> {
  static defaultProps = {
    ...baseDefaultProps
  };

  constructor(props: SessionVariableBuilderProps) {
    super(props);
    this.state = {
      value: SessionVariableLocationType.DataCollection
    };
  }

  resetFrequency() {
    this.props.handleChangeOperatorId(
      this.props.attribute,
      CONTAINS.toString(),
      ""
    );
    this.props.handleChangeValue(this.props.attribute, CONTAINS.toString(), "");
  }
  resetRecency() {
    this.props.handleChangeSubsetOperatorId(
      this.props.attribute,
      ON_OR_AFTER.toString(),
      moment().format(DATE_FORMAT)
    );
    this.props.handleChangeSubsetValue(
      this.props.attribute,
      ON_OR_AFTER.toString(),
      moment().format(DATE_FORMAT)
    );
  }
  clearRecency() {
    this.props.handleClearSubset();
  }

  componentWillMount() {
    if (!this.props.value) {
      this.resetNotExists();
      this.resetFrequency();
    } else if (this.props.dataType) {
      this.setState({ value: SessionVariableLocationType.Conversion });
    }
  }

  resetNotExists() {
    this.props.handleChangeSubsetOperatorId(this.props.attribute, "", "");
    this.props.handleChangeSubsetValue(this.props.attribute, "", "");
  }

  onSelectTypeParent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parentDataType = e.target.value as SessionVariableLocationType;
    this.setState({ value: parentDataType });
    this.props.handleChangeDataType("");
    switch (parentDataType) {
      case SessionVariableLocationType.Conversion:
        this.resetFrequency();
        this.resetRecency();
        break;
      case SessionVariableLocationType.DataCollection:
        this.resetNotExists();
        break;
      default:
        throw new TypeError(
          `Invalid ExistsNotExistsBuilder type ${parentDataType}`
        );
    }
  };

  render() {
    return (
      <div className={baseStyles.conditionContainerClass}>
        <ListGroup>
          <ListGroupItem>
            <Row>
              <div style={{ width: "100%" }}>
                <div className={baseStyles.itemMarginClass}>
                  <AttributeName
                    attribute={this.props.attribute}
                    qaNameId={qaIds.selectedConditionName}
                    qaTooltipId={qaIds.selectedConditionTooltipPath}
                    qaTooltipIconId={qaIds.selectedConditionTooltipIcon}
                    qaTooltipPathId={qaIds.selectedConditionTooltipPath}
                  />
                </div>
              </div>
              <div>
                Look for Session Variable in
                <div className={baseStyles.itemMarginClass}>
                  <CustomInput
                    id="type"
                    type="select"
                    onChange={this.onSelectTypeParent}
                    value={this.state.value}
                    data-qa-id={qaIds.selectedConditionType}
                  >
                    <option value={SessionVariableLocationType.DataCollection}>
                      Data Collection
                    </option>
                    <option value={SessionVariableLocationType.Conversion}>
                      Conversion
                    </option>
                  </CustomInput>
                </div>
              </div>
            </Row>
            {this.state.value === SessionVariableLocationType.Conversion && (
              <React.Fragment>
                <Row>With CPID</Row>
                <Row>
                  <div className={baseStyles.itemMarginClass}>
                    <Input
                      id="type"
                      type={"text"}
                      value={this.props.dataType}
                      data-qa-id={qaIds.selectedDataTypeValue}
                      onChange={e => {
                        this.props.handleChangeDataType(e.target.value);
                      }}
                    />
                  </div>
                  <PVariableBuilder {...this.props} />
                  <RecencyBuilder {...this.props} />
                </Row>
              </React.Fragment>
            )}
            {this.state.value ===
              SessionVariableLocationType.DataCollection && (
              <Row>
                <PVariableBuilder {...this.props} />
              </Row>
            )}
          </ListGroupItem>
        </ListGroup>
      </div>
    );
  }
}
