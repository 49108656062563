import { default as React } from "react";
import { ConditionSegment } from "../models/expressionBuilderModels";
import {
  isGeoServiceAttribute,
  GEO_SERVICE_ATTRIBUTE_TO_TYPE_MAP
} from "../constants/attributeIdConstants";
import { createOptionFromNode, GEO_TYPE, getOne } from "../api/geoApi";
import { Subscription } from "rxjs";
import { StandardConditionCardComponent } from "./StandardConditionCardComponent";

export interface ConditionCardProps {
  condition: ConditionSegment;
  conditionValueOverride?: string;
  conditionSubsetValueOverride?: string;
  isOver?: boolean;
  isDragging?: boolean;
  className?: string;
  handleDeleteCondition(condition: ConditionSegment): void;
  handleEditCondition(condition: ConditionSegment): void;
}

export interface ConditionCardState {
  geoLocationName: string;
}

export class ConditionCardComponent extends React.Component<
  ConditionCardProps,
  ConditionCardState
> {
  getSub: Subscription;

  static defaultProps = {
    condition: undefined as ConditionSegment,
    conditionValueOverride: undefined as string
  };

  constructor(props: ConditionCardProps) {
    super(props);
    this.state = {
      geoLocationName: undefined as string
    };
  }

  componentDidMount() {
    if (
      this.props.condition.value &&
      isGeoServiceAttribute(this.props.condition.value.attribute.id)
    ) {
      const id: string = this.props.condition.value.value;
      const type: GEO_TYPE =
        GEO_SERVICE_ATTRIBUTE_TO_TYPE_MAP[
          this.props.condition.value.attribute.id
        ];
      this.getSub = getOne(id, type).subscribe(searchResults => {
        this.setState({
          ...this.state,
          geoLocationName: createOptionFromNode(
            searchResults.results[type][id],
            searchResults
          ).displayName
        });
      });
    }
  }

  componentWillUnmount() {
    if (this.getSub) {
      this.getSub.unsubscribe();
    }
  }

  render() {
    if (!this.props.condition) {
      return <div />;
    }
    const pathFragments = this.props.condition.value
      ? this.props.condition.value.attribute.tree_path.split("/")
      : ["Unknown"];
    const rootName = pathFragments[0];
    const leafName = pathFragments[pathFragments.length - 1];

    return (
      <StandardConditionCardComponent
        className={this.props.className}
        rootName={rootName}
        leafName={leafName}
        geoLocationName={this.state.geoLocationName}
        {...this.props}
      />
    );
  }
}
