import { Action } from "redux";
import {
  attributeProps,
  ExternalSegment,
  externalSegmentProps
} from "../../api/attributeApi";
import { Order } from "../../constants/sharedConstants";
import {
  readAllExternalSegmentsAF,
  setExternalSegmentsSortAF
} from "../actions/externalSegmentsActions";
import { createExternalSegmentAF } from "../actions/externalSegmentCreateActions";
import { updateExternalSegmentAF } from "../actions/externalSegmentUpdateActions";
import { deleteExternalSegmentAF } from "../actions/externalSegmentDeleteActions";

export interface State {
  isGettingExternalSegment: boolean;
  byBusinessUnitId: {
    [businessUnitId: string]: {
      byExternalSegmentId: {
        [externalSegmentId: string]: ExternalSegment;
      };
    };
  };
  sort: {
    iteratees: string[];
    orders: Order[];
  };
}

export const externalSegmentBusinessUnitIdMapInitialState: State = {
  isGettingExternalSegment: false,
  byBusinessUnitId: {},
  sort: {
    iteratees: [
      `${externalSegmentProps.attribute}.${attributeProps.modified_at}`
    ],
    orders: ["desc"]
  }
};

export function segmentsReducer(
  previousState: State = externalSegmentBusinessUnitIdMapInitialState,
  action: Action
): State {
  if (setExternalSegmentsSortAF.isAction(action)) {
    const prevSortKey = previousState.sort.iteratees[0];
    const prevOrder = previousState.sort.orders[0];
    const nextSortKey = action.payload.sortKey;
    let nextOrder: Order;

    if (prevSortKey === nextSortKey) {
      if (prevOrder === "asc") {
        nextOrder = "desc";
      } else {
        nextOrder = "asc";
      }
    } else {
      nextOrder = "asc";
    }

    return {
      ...previousState,
      sort: {
        ...previousState.sort,
        iteratees: [nextSortKey],
        orders: [nextOrder]
      }
    };
  }
  if (readAllExternalSegmentsAF.pendingAF.isAction(action)) {
    return {
      ...previousState,
      isGettingExternalSegment: true
    };
  }
  if (readAllExternalSegmentsAF.fulfilledAF.isAction(action)) {
    return {
      ...previousState,
      isGettingExternalSegment: false,
      byBusinessUnitId: {
        ...previousState.byBusinessUnitId,
        [action.meta.context.businessUnit.id]: {
          byExternalSegmentId: action.payload
        }
      }
    };
  }
  if (readAllExternalSegmentsAF.rejectedAF.isAction(action)) {
    return {
      ...previousState,
      isGettingExternalSegment: false
    };
  }
  if (createExternalSegmentAF.fulfilledAF.isAction(action)) {
    return {
      ...previousState,
      byBusinessUnitId: {
        ...previousState.byBusinessUnitId,
        [action.meta.context.businessUnit.id]: {
          byExternalSegmentId: {
            ...previousState.byBusinessUnitId[
              action.meta.context.businessUnit.id
            ].byExternalSegmentId,
            [action.payload.id]: action.payload
          }
        }
      }
    };
  }
  if (updateExternalSegmentAF.fulfilledAF.isAction(action)) {
    return {
      ...previousState,
      byBusinessUnitId: {
        ...previousState.byBusinessUnitId,
        [action.meta.context.businessUnit.id]: {
          byExternalSegmentId: {
            ...previousState.byBusinessUnitId[
              action.meta.context.businessUnit.id
            ].byExternalSegmentId,
            [action.payload.id]: action.payload
          }
        }
      }
    };
  }
  if (deleteExternalSegmentAF.fulfilledAF.isAction(action)) {
    const newSegmentById = {
      ...previousState.byBusinessUnitId[action.meta.context.businessUnit.id]
        .byExternalSegmentId
    };
    delete newSegmentById[action.meta.baseAction.payload.id];
    return {
      ...previousState,
      byBusinessUnitId: {
        ...previousState.byBusinessUnitId,
        [action.meta.context.businessUnit.id]: {
          byExternalSegmentId: newSegmentById
        }
      }
    };
  }
  return previousState;
}
