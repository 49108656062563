import { default as React } from "react";
import { Helmet } from "react-helmet";

export const ServerError: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>500</title>
      </Helmet>
      <h1>500</h1>
    </div>
  );
};
