import { AsyncAF, AF } from "../../../lib/actionFactoryLib";
import { CrmMapping } from "../../api/attributeApi";

export const setCrmMappingsSortAF = new AF<{ sortKey: string }>(
  "SET_CRM_MAPPINGS_SORT"
);

export const readAllMappingsAF = new AsyncAF<{}, { [id: number]: CrmMapping }>(
  "CRM_READ_ALL_MAPPINGS"
);

export const mappingsDidMountAF = new AsyncAF<{}, {}>("CRM_MAPPINGS_DID_MOUNT");
