import { default as React } from "react";
import styles from "./NavBadgeComponent.module.scss";

export interface NavBadgeProps {
  text: string;
  backgroundColor: string;
}

export const NavBadgeFC: React.FC<NavBadgeProps> = props => {
  return (
    <span
      className={styles.badge}
      style={{ backgroundColor: props.backgroundColor }}
    >
      {props.text}
    </span>
  );
};
