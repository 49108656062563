import { AsyncAF, AF } from "../../../lib/actionFactoryLib";
import { CrmMapping } from "../../api/attributeApi";

export const openUpdateModalAF = new AF<CrmMapping>("CRM_OPEN_UPDATE_MODAL");

export const closeUpdateModalAF = new AF<{}>("CRM_CLOSE_UPDATE_MODAL");

export const updateCrmMappingAF = new AsyncAF<CrmMapping, CrmMapping>(
  "CRM_UPDATE_MAPPING_ATTRIBUTE"
);
