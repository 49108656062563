import { default as React } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown
} from "reactstrap";
import { default as NumberFormat } from "react-number-format";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { last } from "lodash";
import { RemoveAudienceModalFC } from "./RemoveAudienceModalComponent";
import { SortableTableHeaderFC } from "../../bootstrap";
import { Audience } from "../models/audienceModels";
import { getAudienceUpdatePath } from "../constants/pathConstants";
import { CannotRemoveAudienceModalFC } from "./CannotRemoveAudienceModalComponent";
import { RedirectContainer } from "../../template/containers/RedirectContainer";
import { LinkContainer } from "../../template/containers/LinkContainer";
import { Labels } from "./LabelsInput";
import variables from "../../../node_modules/@ione/bootstrap-variables/scss/ione/exports.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faLock } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";
import numeral from "numeral";
import classNames from "classnames";
import styles from "./AudienceTableComponent.module.scss";

export interface AudienceTableProps {
  audiences: Audience[];
  sort: { iteratees: string[]; orders: string[] };
  pageSize: number;
  manualLoadMoreThreshold: number;
  enableCrudColumn: boolean;
  suggestions: string[];
  onSort(sortKey: string): void;
  handleLoadMore(pageSize: number): void;
  onAudienceDelete(audienceId: number): void;
  onUpdateLabels(labels: string[], audience: Audience): void;
  totalAudiences: number;
}

interface AudienceTableState {
  redirectTo: string;
  displayDeleteModal: boolean;
  audienceToDelete: Audience;
  displayCannotDeleteModal: boolean;
  childAudiences: Audience[];
}

export class AudienceTableComponent extends React.Component<
  AudienceTableProps,
  AudienceTableState
> {
  static defaultProps = {
    enableCrudColumn: false
  };

  constructor(props: AudienceTableProps) {
    super(props);

    this.state = {
      redirectTo: null,
      displayDeleteModal: false,
      audienceToDelete: null,
      displayCannotDeleteModal: false,
      childAudiences: []
    };
    this.handleClick = this.handleClick.bind(this);
    this.showDeleteModal = this.showDeleteModal.bind(this);
    this.hideDeleteModal = this.hideDeleteModal.bind(this);
  }

  handleClick(linkUrl: any) {
    this.setState({ redirectTo: linkUrl });
  }

  handleDelete(audience: Audience) {
    const childAudiences: Audience[] = this.props.audiences.filter(a => {
      return a.starting_population_id === audience.audience_id;
    });
    if (childAudiences.length) {
      this.showCannotDeleteModal(audience, childAudiences);
    } else {
      this.showDeleteModal(audience);
    }
  }

  showCannotDeleteModal(audience: Audience, childAudiences: Audience[]) {
    this.setState({
      ...this.state,
      childAudiences,
      displayCannotDeleteModal: true,
      audienceToDelete: audience
    });
  }

  hideCannotDeleteModal() {
    this.setState({
      ...this.state,
      displayCannotDeleteModal: false,
      audienceToDelete: undefined,
      childAudiences: []
    });
  }

  showDeleteModal(audience: Audience) {
    this.setState({
      ...this.state,
      displayDeleteModal: true,
      audienceToDelete: audience
    });
  }

  hideDeleteModal() {
    this.setState({
      ...this.state,
      displayDeleteModal: false,
      audienceToDelete: undefined
    });
  }

  render() {
    const props = this.props;
    const state = this.state;

    return (
      <div>
        {state.redirectTo && <RedirectContainer to={state.redirectTo} />}
        {state.displayDeleteModal && (
          <RemoveAudienceModalFC
            audience={state.audienceToDelete}
            show={state.displayDeleteModal}
            onCancel={() => {
              this.hideDeleteModal();
            }}
            onDelete={audienceToDelete => {
              this.hideDeleteModal();
              props.onAudienceDelete(audienceToDelete.audience_id);
            }}
          />
        )}
        {state.displayCannotDeleteModal && (
          <CannotRemoveAudienceModalFC
            audience={state.audienceToDelete}
            childAudiences={state.childAudiences}
            onConfirm={() => {
              this.hideCannotDeleteModal();
            }}
            show={state.displayCannotDeleteModal}
          />
        )}
        <div>
          {props.audiences && props.audiences.length > 0 && (
            <Table bordered striped hover className={"table-pointer mb-0"}>
              <thead>
                <tr>
                  <th>Id</th>
                  <SortableTableHeaderFC
                    sortKey="name"
                    iteratees={props.sort.iteratees}
                    orders={props.sort.orders}
                    onClick={props.onSort}
                  >
                    Name
                  </SortableTableHeaderFC>
                  <th className="d-none d-lg-table-cell">Labels</th>
                  <th>Activations</th>
                  <SortableTableHeaderFC
                    sortKey="modified_at"
                    iteratees={props.sort.iteratees}
                    orders={props.sort.orders}
                    onClick={props.onSort}
                    className={"d-none d-lg-table-cell"}
                  >
                    Modified
                  </SortableTableHeaderFC>
                  {props.enableCrudColumn && <th>&nbsp;</th>}
                </tr>
              </thead>
              <tbody>
                {props.audiences.map(a => {
                  const count =
                    a.daily_counts && a.daily_counts.length
                      ? last(a.daily_counts).count
                      : 0;
                  return (
                    <tr key={a.audience_id.toString()}>
                      <td className="align-middle">{a.audience_id}</td>
                      <td className="align-middle">
                        <div className={"text-break"}>
                          <LinkContainer
                            to={getAudienceUpdatePath(a.audience_id)}
                            data-qa-id="audience-name"
                          >
                            {a.name}
                          </LinkContainer>
                          {a.is_locked && (
                            <span
                              data-qa-id="audience-locked-icon"
                              className={"ml-2"}
                            >
                              <FontAwesomeIcon icon={faLock} />
                            </span>
                          )}
                        </div>
                      </td>
                      <td
                        className={classNames(
                          "d-none d-lg-table-cell align-middle",
                          styles.audienceLabelsInput
                        )}
                        data-qa-id={`audience-labels-${a.audience_id}`}
                      >
                        <Labels
                          allowNew={true}
                          labels={a.labels}
                          className="w-100"
                          onChange={labels => props.onUpdateLabels(labels, a)}
                          suggestions={this.props.suggestions}
                        />
                      </td>
                      <td className="align-middle">
                        <NumberFormat
                          displayType="text"
                          thousandSeparator={true}
                          value={count}
                          data-qa-id="audience-size"
                          className={"d-none d-md-inline"}
                        />
                        <span className={"d-md-none"}>
                          {numeral(count).format("0a")}
                        </span>
                        <span
                          className={"d-none d-lg-inline ml-2"}
                          data-qa-id="audience-size-sparkline"
                        >
                          <Sparklines
                            data={
                              a.daily_counts
                                ? a.daily_counts.slice().map(dc => {
                                    return dc.count;
                                  })
                                : []
                            }
                            limit={5}
                            svgWidth={80}
                            svgHeight={18}
                            margin={5}
                          >
                            <SparklinesLine
                              color={variables.primary}
                              style={{ fill: "none", strokeWidth: 5 }}
                            />
                          </Sparklines>
                        </span>
                      </td>
                      <td className={"d-none d-lg-table-cell align-middle"}>
                        <span data-qa-id="audience-modified-time">
                          {DateTime.fromISO(a.modified_at).toRelative()}
                        </span>
                      </td>
                      {props.enableCrudColumn && (
                        <td className={"text-center align-middle"}>
                          <UncontrolledDropdown
                            id={`audience-${a.audience_id}`}
                            data-qa-id="audience-action-dropdown"
                          >
                            <DropdownToggle size={"xs"}>
                              <FontAwesomeIcon icon={faEllipsisH} />
                            </DropdownToggle>
                            <DropdownMenu right={true}>
                              <DropdownItem
                                data-qa-id="edit-link"
                                tag={LinkContainer}
                                to={getAudienceUpdatePath(a.audience_id)}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                className={"c-pointer"}
                                onClick={() => {
                                  this.handleDelete(a);
                                }}
                                data-qa-id="delete-link"
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
          {(!props.audiences || !props.audiences.length) &&
            props.totalAudiences < 1 && (
              <p className={"text-center"}>
                No audiences have been created yet.
              </p>
            )}
          {(!props.audiences || !props.audiences.length) &&
            props.totalAudiences > 0 && (
              <p className={"text-center"}>
                Your search criteria doesn't match any existing audiences.
              </p>
            )}
        </div>
      </div>
    );
  }
}
