import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  reduxForm,
  isInvalid,
  getFormSyncErrors,
  change,
  getFormValues
} from "redux-form";
import {
  readAllLabelsAF,
  updateAudienceAF,
  updateAudienceInitAF
} from "../actions/audienceActions";
import {
  getAudience,
  getAudiences,
  getSuggestions,
  getPendingAsyncOperationCount,
  getStartingPopsForAudience
} from "../selectors/audienceSelectors";
import {
  getExpressionBuilderExpansionState,
  getExpressionBuilderChannel
} from "../selectors/expressionBuilderSelector";
import { getConditionBuilderMode } from "../selectors/conditionBuilderSelector";
import { HOME } from "../constants/pathConstants";
import { maskSpecialChars } from "../constants/sharedConstants";
import {
  UpdateAudiencePureComponent,
  DispatchProps,
  StateProps,
  UpdateAudienceProps
} from "../components/UpdateAudienceComponent";
import { Audience } from "../models/audienceModels";
import { State } from "../reducers";
import { getSelectedBusinessUnit } from "../../template/selectors/businessUnitSelectors";
import { push } from "../../template/actions/trackedDataActions";

interface OwnProps {
  audienceId: number;
}

interface FormValues {
  starting_population_id: number;
}

const formName = "updateAudience";
const builderId = formName;

function audienceIdCreator(str: string) {
  return maskSpecialChars(str);
}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  const formValues: FormValues = getFormValues(formName)(state) as FormValues;
  const errors = getFormSyncErrors(formName)(state);
  const audience = getAudience(state, ownProps.audienceId);
  let startingPop: Audience = undefined;
  if (
    formValues &&
    formValues.starting_population_id !== 0 &&
    formValues.starting_population_id
  ) {
    startingPop = getAudience(state, formValues.starting_population_id);
  }
  const startingPopulations = getStartingPopsForAudience(
    state,
    ownProps.audienceId
  );
  const audiences = getAudiences(state);
  const businessUnit = getSelectedBusinessUnit(state);
  const expandExpressionBuilder = getExpressionBuilderExpansionState(
    state,
    builderId
  );
  const audienceLabelSuggestions = getSuggestions(state);
  const conditionBuilderMode = getConditionBuilderMode(state, builderId);
  const channel = getExpressionBuilderChannel(state, formName);

  const parentAudiences = audiences.filter(a => {
    return audience && a.starting_population_id === audience.audience_id;
  });

  return {
    audienceId: ownProps.audienceId,
    builderId,
    businessUnit,
    startingPopulations,
    audienceLabelSuggestions,
    startingPop,
    expandExpressionBuilder,
    conditionBuilderMode,
    parentAudiences,
    channel,
    initialValues: audience,
    showLoadingIndicator: getPendingAsyncOperationCount(state) > 0,
    isSubmitDisabled:
      isInvalid(formName)(state) || (audience && audience.is_read_only),
    isNameValid: !(errors && (errors as any).name),
    onCustomIdChange(value: string) {
      return audienceIdCreator(value);
    }
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps {
  return {
    onInit() {
      dispatch(
        updateAudienceInitAF.create(
          { builderId, audienceId: ownProps.audienceId },
          {}
        )
      );
      dispatch(readAllLabelsAF.create({}, {}));
    },
    onNameChange() {
      // making it a noop because we don't want to sync identifier with name after the audience has been created
      return void 0;
    },
    onStartingPopChange(id: number, fieldName: string) {
      dispatch(change(formName, fieldName, id));
    },
    onBusinessUnitChange() {
      dispatch(push(HOME));
    }
  };
}

const containerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
);
const formWrapper = reduxForm<Audience, UpdateAudienceProps>({
  form: formName,
  onSubmit(audience: Audience, dispatch: Dispatch) {
    dispatch(updateAudienceAF.create({ audience, builderId }, {}));
  }
});
export const UpdateAudienceContainer = containerWrapper(
  formWrapper(UpdateAudiencePureComponent)
);
