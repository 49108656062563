import { default as React } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faSort,
  faSortDown,
  faSortUp
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

export interface SortableTableHeaderProps {
  sortKey: string;
  iteratees: string[];
  orders: string[];
  onClick(sortKey: string): void;
  className?: string;
}

export const SortableTableHeaderFC: React.FC<
  SortableTableHeaderProps
> = props => {
  let icon: IconProp;
  let iconState;
  const idx = props.iteratees.indexOf(props.sortKey);

  if (idx !== -1) {
    if (props.orders[idx] === "asc") {
      icon = faSortUp;
    } else {
      icon = faSortDown;
    }
    iconState = "text-body";
  } else {
    icon = faSort;
    iconState = "text-muted";
  }

  return (
    <th
      className={classNames("c-pointer", props.className)}
      onClick={() => {
        props.onClick(props.sortKey);
      }}
    >
      {props.children}
      <span>
        &nbsp;
        <FontAwesomeIcon icon={icon} className={iconState} />
      </span>
    </th>
  );
};
