import { default as React } from "react";
import styles from "./IoneSpinnerComponent.module.scss";

export const IoneSpinnerFC: React.FC = () => {
  return (
    <div className={styles.spinner}>
      <div className={styles.left} />
      <div className={styles.right} />
    </div>
  );
};
