import { combineEpics } from "redux-observable";
import { epics as attributeEpics } from "./attributeEpics";
import { epics as attributeSelectorEpics } from "./attributeSelectorEpics";
import { epics as audienceEpics } from "./audienceEpics";
import { epics as expressionBuilderEpics } from "./expressionBuilderEpics";
import { epics as conditionBuilderEpics } from "./conditionBuilderEpics";
import { epics as crmEpics } from "../crm";
import { epics as scoreEpics } from "../score";
import { epics as externalSegmentEpics } from "../externalSegments";

export const epics = combineEpics(
  attributeEpics,
  attributeSelectorEpics,
  audienceEpics,
  expressionBuilderEpics,
  conditionBuilderEpics,
  crmEpics,
  scoreEpics,
  externalSegmentEpics
);
