import { AsyncAF, AF } from "../../../lib/actionFactoryLib";
import { ScoreSegment, PartialScoreSegment } from "../../api/attributeApi";

export const openUpdateModalAF = new AF<ScoreSegment>(
  "SCORE_SEGMENT_OPEN_UPDATE_MODAL"
);

export const closeUpdateModalAF = new AF<{}>(
  "SCORE_SEGMENT_CLOSE_UPDATE_MODAL"
);

export const updateScoreSegmentAF = new AsyncAF<
  { id: number; score: PartialScoreSegment; formName: string },
  ScoreSegment
>("SCORE_SEGMENT_UPDATE");

export const readUpdateScoreSegmentByIdAF = new AsyncAF<
  { attributeId: string },
  ScoreSegment
>("READ_UPDATE_SCORE_SEGMENT");

export const updateSetIsMax = new AF<{ isMax: boolean }>("UPDATE_SET_IS_MAX");
