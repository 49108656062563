import { default as React } from "react";
import { FooterFC } from "./FooterComponent";
import { HeaderContainer } from "../containers/HeaderContainer";
import { MainContentContainer } from "../containers/MainContentContainer";
import { NavConfig } from "../models/navigationModels";

export interface TemplateProps {
  navConfig: NavConfig;
}

export const TemplateFC: React.FC<TemplateProps> = props => {
  return (
    <div>
      <HeaderContainer navConfig={props.navConfig} />
      <MainContentContainer>{props.children}</MainContentContainer>
      <FooterFC />
    </div>
  );
};
