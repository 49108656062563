import { default as React, ReactNode } from "react";
import {
  DragSource,
  DragSourceSpec,
  DragSourceCollector,
  ConnectDragSource,
  DropTarget,
  DropTargetSpec,
  DropTargetCollector,
  ConnectDropTarget
} from "react-dnd";
import { GROUP_END, CONDITION } from "../constants/dndtypeConstants";
import {
  GroupEndSegment,
  ConditionSegment
} from "../models/expressionBuilderModels";
import styles from "./ExpressionDraggable.module.scss";
import { ExpressionDraggableFC } from "./ExpressionDraggableComponent";
import classNames from "classnames";

export interface ExpressionGroupEndProps
  extends DndSourceProps,
    DndTargetProps {
  removeExpressionGroup(groupId: string): void;
  group: GroupEndSegment;
  moveConditionToGroup(conditionId: string, groupId: string): void;
  moveSegment(segmentId: string, index: number): void;
  findSegmentIndex(segmentId: string): number;
}

export interface DndSourceProps {
  connectDragSource?: ConnectDragSource;
  isDragging?: boolean;
}

export interface DndTargetProps {
  connectDropTarget?: ConnectDropTarget;
  isOver?: boolean;
}

export const ExpressionGroupEndFC: React.FC<
  ExpressionGroupEndProps
> = props => {
  return (
    <ExpressionDraggableFC
      isOver={props.isOver}
      isDragging={props.isDragging}
      connectDropTarget={props.connectDropTarget}
      connectDragSource={props.connectDragSource}
      removeExpressionDraggable={props.removeExpressionGroup}
      draggableId={props.group.id}
    >
      <div
        className={classNames(
          styles.expressionGroupContainer,
          styles.symbolEndContainer
        )}
      >
        ]
      </div>
    </ExpressionDraggableFC>
  );
};

const dropSpec: DropTargetSpec<ExpressionGroupEndProps> = {
  drop(props, monitor) {
    switch (monitor.getItemType()) {
      case CONDITION: {
        const condition = monitor.getItem() as ConditionSegment;
        props.moveConditionToGroup(condition.id, props.group.id);
        break;
      }
      case GROUP_END: {
        const group = monitor.getItem() as GroupEndSegment;
        const index = props.findSegmentIndex(props.group.id);
        props.moveSegment(group.id, index + 1);
        break;
      }
      default:
        break;
    }
  }
};

const dropCollect: DropTargetCollector<DndTargetProps> = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
};

const dragSpec: DragSourceSpec<ExpressionGroupEndProps, ReactNode> = {
  beginDrag(props) {
    return props.group;
  }
};

const dragCollect: DragSourceCollector<DndSourceProps> = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
};

const sourceWrapper = DragSource(GROUP_END, dragSpec, dragCollect);
const targetWrapper = DropTarget([GROUP_END, CONDITION], dropSpec, dropCollect);

export const ExpressionGroupEndDND = sourceWrapper(
  targetWrapper(ExpressionGroupEndFC)
);
