import { default as React } from "react";
import { ConditionSegment } from "../models/expressionBuilderModels";
import { ConditionTextContainer } from "../containers/ConditionTextContainer";
import { ConditionCardState } from "./ConditionCardComponent";
import {
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uniqueId } from "lodash";
import styles from "./StandardConditionCardComponent.module.scss";
import { SCORE_SEGMENTS } from "../constants/attributeSourceTypeConstants";
import { AttributeName } from "./AttributeName";
import classNames from "classnames";
import { isTopInterestAttribute } from "../constants/attributeIdConstants";

export interface ConditionCardProps {
  condition: ConditionSegment;
  geoLocationName: string;
  conditionValueOverride?: string;
  conditionSubsetValueOverride?: string;
  isOver?: boolean;
  isDragging?: boolean;
  rootName: string;
  leafName: string;
  className?: string;
  handleDeleteCondition(condition: ConditionSegment): void;
  handleEditCondition(condition: ConditionSegment): void;
}

export class StandardConditionCardComponent extends React.Component<
  ConditionCardProps,
  ConditionCardState
> {
  static defaultProps = {
    condition: undefined as ConditionSegment,
    conditionValueOverride: undefined as string,
    className: ""
  };

  id: string = uniqueId("cc");

  render() {
    const isScoreSegment =
      this.props.condition.value &&
      this.props.condition.value.attribute.source_type === SCORE_SEGMENTS;
    return (
      <Card
        data-qa-id="condition-card"
        className={classNames(styles.card, this.props.className, {
          "bg-primary text-white": isScoreSegment,
          "border-secondary": this.props.isOver,
          "border-primary": this.props.isDragging
        })}
      >
        <CardHeader className={"d-flex justify-content-between"}>
          {this.props.condition.value ? (
            <AttributeName
              attribute={this.props.condition.value.attribute}
              iconClass={isScoreSegment ? "text-light" : undefined}
              qaNameId={"condition-card-name"}
              qaTooltipIconId={"condition-card-tooltip-icon"}
              qaTooltipId={"condition-card-tooltip"}
            />
          ) : (
            <span data-qa-id="condition-card-name">Unknown</span>
          )}
          <UncontrolledDropdown
            id={`condition-card-dropdown-${this.id}`}
            className={"ml-3"}
          >
            <DropdownToggle
              size={"xs"}
              color={isScoreSegment ? "dark" : "secondary"}
              data-qa-id="condition-card-options-toggle"
            >
              <FontAwesomeIcon icon={faEllipsisH} />
            </DropdownToggle>
            <DropdownMenu right>
              {!isScoreSegment && (
                <DropdownItem
                  data-qa-id="condition-card-edit-option"
                  onClick={() => {
                    this.props.handleEditCondition(this.props.condition);
                  }}
                >
                  Edit
                </DropdownItem>
              )}
              <DropdownItem
                data-qa-id="condition-card-delete-option"
                onClick={() => {
                  this.props.handleDeleteCondition(this.props.condition);
                }}
              >
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </CardHeader>
        <CardBody>
          <div>
            <div>
              <span
                data-qa-id="condition-card-root"
                className={"font-weight-bold"}
              >
                {this.props.rootName}
              </span>
            </div>
            <div data-qa-id="condition-card-condition">
              {this.props.condition.value &&
              isTopInterestAttribute(
                this.props.condition.value.attribute.id
              ) ? (
                <span>
                  <ConditionTextContainer
                    condition={this.props.condition.value}
                  />
                  <span>{this.props.leafName}</span>
                </span>
              ) : (
                <React.Fragment>
                  <span>{this.props.leafName}</span>
                  &nbsp;
                  <span>
                    {this.props.condition.value && (
                      <ConditionTextContainer
                        condition={this.props.condition.value}
                      />
                    )}
                  </span>
                </React.Fragment>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}
