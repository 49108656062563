/*
 * Collection of validation functions to work w/ redux-form
 * Validation functions take in a value and return undefined if value is valid
 * and return an error (usually a string) if value is invalid
 * http://redux-form.com/6.4.3/examples/fieldLevelValidation/
 */
export function required(value: any) {
  return value ? undefined : "Required";
}

export function minLength(min: number) {
  return (value: string) => {
    return value && value.length < min
      ? `Must be ${min} characters or more`
      : undefined;
  };
}

export function maxLength(max: number) {
  return (value: string) => {
    return value && value.length > max
      ? `Must be ${max} characters or less`
      : undefined;
  };
}

export function isNumber(value: any) {
  return value && isNaN(Number(value)) ? "Must be a number" : undefined;
}

export function minValue(min: number) {
  return (value: number) => {
    return value && value < min ? `Must be at least ${min}` : undefined;
  };
}

export function maxValue(max: number) {
  return (value: number) => {
    return value && value > max ? `Must be at most ${max}` : undefined;
  };
}

export function regex(rx: RegExp, err: string) {
  return (value: string) => {
    return value && rx.test(value) ? undefined : err;
  };
}
