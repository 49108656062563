import { connect } from "react-redux";
import {
  TopInterestFC,
  StateProps,
  DispatchProps
} from "../../components/ConditionBuilders/TopInterestComponent";
import {
  getScoreCategoriesDepthLevels,
  getConditionBuilderAttributeLevel,
  getConditionBuilderWpScoreCategoriesByLevel
} from "../../selectors/conditionBuilderSelector";
import { conditionBuilderSetMetaAF } from "../../actions/conditionBuilderActions";
import { State } from "../../reducers";
import { Dispatch } from "redux";
import { ConditionBuilderBaseProps } from "../../components/ConditionBuilders/SharedData";

interface OwnProps extends ConditionBuilderBaseProps {
  builderId: string;
}

function mapStateToProps(state: State, ownProps: OwnProps): StateProps {
  if (ownProps.attribute) {
    return {
      attributeLevels: getScoreCategoriesDepthLevels(state),
      attributeLevel: getConditionBuilderAttributeLevel(
        state,
        ownProps.builderId
      ),
      attributeCategories: getConditionBuilderWpScoreCategoriesByLevel(
        state,
        ownProps.builderId
      )
    };
  }

  return {};
}

function mapDispatchToProps(
  dispatch: Dispatch,
  ownProps: OwnProps
): DispatchProps {
  return {
    handleChangeLevel(level: number) {
      dispatch(
        conditionBuilderSetMetaAF.create(
          { level },
          { builderId: ownProps.builderId }
        )
      );
    }
  };
}

export const TopInterestContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TopInterestFC);
